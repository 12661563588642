import { RoleAndRate, Task, Site } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { estimatorStrings, TASK_TYPES } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useEstimatorTasks, useOpenState, useRemoveEstimatorTaskGroupFromSite } from '@cdw-selline/ui/hooks';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

import React, { useState } from 'react';

import { MemorizedTask } from '../../estimate-details/task-item/TaskItem';
import DialogConfirm from '../../../dialog-confirm/DialogConfirm';

export interface TaskListProps {
  projectItemId: string;
  site: Site;
  expanded?: boolean;
  hideZeroQuantity?: boolean;
  handleTaskEdit: (taskId: string, sideId: string) => void;
  handleVersionSyncOpen: (task: Task) => void;
  handleNotesOpen: (currentTask: Task) => void;
  currentRolesAndRates: RoleAndRate[];
  handleRemoveCustomTask: (taskId: string) => void;
  activeTaskId: string;
  fullSiteLoad: boolean;
  setFullSiteLoad: (value: boolean) => void;
  handleTextTaskEdit: (taskId: string, sideId: string) => void;
  handleProposalDescriptionDialogOpen: (task: Task) => void;
  isReadOnly: boolean;
  tabView?: boolean;
  includesSummaryTasks?: boolean;
  setFullSummarySiteLoad: (value: boolean) => void;
}

export function TaskList({
  projectItemId,
  site,
  expanded,
  hideZeroQuantity,
  handleTaskEdit,
  handleVersionSyncOpen,
  handleNotesOpen,
  currentRolesAndRates,
  handleRemoveCustomTask,
  activeTaskId,
  fullSiteLoad,
  setFullSiteLoad,
  handleTextTaskEdit,
  handleProposalDescriptionDialogOpen,
  isReadOnly,
  tabView,
  includesSummaryTasks,
  setFullSummarySiteLoad
}: TaskListProps) {
  const theme = useTheme();
  const { TASK_COLUMNS } = estimatorStrings;

  const [taskGroupToRemove, setTaskGroupToRemove] = useState<any>();
  const { estimatorTasks, addTaskToWriteQueue, updateTaskLoading } =
    useEstimatorTasks(
      site.id,
      activeTaskId,
      fullSiteLoad,
      setFullSiteLoad,
      tabView,
      includesSummaryTasks
    );

  const {
    isOpen: removeDialogConfirmOpen,
    handleClose: handleRemoveDialogConfirmClose,
    handleOpen: handleRemoveDialogConfirmOpen,
  } = useOpenState();

  const handleDialogCancel = () => {
    setTaskGroupToRemove(null);
    handleRemoveDialogConfirmClose();
  };

  const {
    handleRemoveEstimatorTaskGroupFromSite,
  } = useRemoveEstimatorTaskGroupFromSite();


  const handleDialogRemove = () => {
    const callback = () => {
      setFullSiteLoad(true);
      setFullSummarySiteLoad(true);
    }
    handleRemoveEstimatorTaskGroupFromSite(
      projectItemId,
      taskGroupToRemove.siteId,
      taskGroupToRemove.taskGroupId,
      callback
    );
    handleDialogCancel();
  };

  const handleRemoveTaskGroup = (task)=> {
    setTaskGroupToRemove(task);
    handleRemoveDialogConfirmOpen();
  }

  
  const visibleCategory = [];
  const visibleTaskGroups = [];
  estimatorTasks
    ?.filter(
      (task) =>
        !task.hide &&
        !(
          hideZeroQuantity &&
          (([TASK_TYPES.YES_NO, TASK_TYPES.HOURS, TASK_TYPES.COST].includes(
            task.type
          ) &&
            !task.quantity) ||
            (task.type === TASK_TYPES.LIST && !task.dropDownIndex))
        )
    )
    .forEach((task, index, map) => {
      if (index === 0 || task.category !== map[index - 1].category) {
        visibleCategory.push(task.category);        
      }

      if (index === 0 || task.taskGroupId !== map[index - 1].taskGroupId) {
        visibleTaskGroups.push(task.taskGroupName);
      }
    });

    const customTasksExists = estimatorTasks.some(task=>task.custom);


  if (!expanded) return null;

  return (
    <Table size="small" data-testid="task-list-table">
      <TableHead>
        <TableRow>
          <TableCell></TableCell>
          <TableCell>Qty/Section</TableCell>
          <TableCell></TableCell>
          <TableCell>Task</TableCell>
          <TableCell>Units</TableCell>
          <TableCell>Primary Role</TableCell>
          <TableCell>Secondary Role</TableCell>
          <TableCell>Overtime/Add'l GP</TableCell>
          <TableCell>Hours/MSRP</TableCell>
          <TableCell sx={{ minWidth: '120px' }}>Total</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {estimatorTasks?.map?.((task, index, map) => (
          <React.Fragment key={index}>
            {(visibleTaskGroups.length > 1 && (index === 0 ||
              (task.taskGroupId !== map[index - 1].taskGroupId && !task.custom &&
                visibleTaskGroups.includes(task.taskGroupName)))) && (
              <TableRow
                key={`${site.id}-${task.taskGroupId}-${index}`}
                sx={{ backgroundColor: theme.palette.grey[500] }}
              >
                <TableCell
                  colSpan={TASK_COLUMNS.length + 1}
                  sx={{ color: theme.palette.grey[50], fontSize: '18px', fontWeight: 'bold' }}
                >
                  {task.taskGroupName}
                  {task.taskGroupName && <Button
                    size="small"
                    aria-label="delete"
                    title='Remove Task Group'
                    sx={{
                      ml: 2,
                      backgroundColor: 'white', 
                      ':hover': {
                        bgcolor: theme.palette.grey[200],
                      }
                    }}
                    onClick={() => handleRemoveTaskGroup(task)}
                  >
                    Remove Task Group
                  </Button>}
                </TableCell>
              </TableRow>
            )}
            {customTasksExists && task.custom && task.taskGroupId !== map[index - 1]?.taskGroupId && (
                <TableRow
                  key={`${site.id}-custom-${index}`}
                  sx={{ backgroundColor: theme.palette.grey[500] }}
                >
                  <TableCell
                    colSpan={TASK_COLUMNS.length + 1}
                    sx={{ color: theme.palette.grey[50], fontSize: '18px', fontWeight: 'bold' }}
                  >Custom Tasks</TableCell>
                </TableRow>
            )}
            {(index === 0 ||
              (task.category !== map[index - 1].category &&
                visibleCategory.includes(task.category))) && (
              <TableRow
                key={`${site.id}-${task.category}-${index}`}
                sx={{ backgroundColor: theme.palette.grey[600] }}
              >
                <TableCell
                  colSpan={TASK_COLUMNS.length + 1}
                  sx={{ color: theme.palette.common.white, fontSize: '15px' }}
                >
                  {task.category}
                </TableCell>
              </TableRow>
            )}
            <MemorizedTask
              {...{
                task,
                taskIndex: index,
                projectItemId,
                key: `${task.id}`,
                expanded,
                hideZeroQuantity,
                handleTaskEdit,
                handleVersionSyncOpen,
                handleNotesOpen,
                currentRolesAndRates,
                handleProposalDescriptionDialogOpen,
                addTaskToWriteQueue,
                handleRemoveCustomTask,
                updateTaskLoading,
                handleTextTaskEdit,
                isReadOnly,
              }}
            />
          </React.Fragment>
        ))}
      </TableBody>
      {removeDialogConfirmOpen && (
        <DialogConfirm
          title="Remove Estimator Task group from Site"
          isOpen={removeDialogConfirmOpen}
          handleClose={handleDialogCancel}
          handleYes={handleDialogRemove}
        >
          <Typography>
            Are you sure you want to permanently remove the task group: {taskGroupToRemove.taskGroupName} from the site?
          </Typography>
        </DialogConfirm>
      )}
    </Table>
  );
}

export default TaskList;
