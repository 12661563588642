import React from 'react';
import { Link, Route } from 'react-router-dom';

/* eslint-disable-next-line */
export interface PhasesCollectionPageProps {}

export function PhasesCollectionPage(props: PhasesCollectionPageProps) {
  return (
    <div>
      <h1>Welcome to PhasesCollectionPage!</h1>

      <ul>
        <li>
          <Link to="/">PhasesCollectionPage root</Link>
        </li>
      </ul>
      <Route
        path="/"
        render={() => <div>This is the PhasesCollectionPage root route.</div>}
      />
    </div>
  );
}

export default PhasesCollectionPage;
