import { Practice } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, MenuItem, Box } from '@mui/material';
import {
  FormText,
  FormCheckbox,
  FormSelect,
  getActionButtons,
  FormAutocomplete,
} from '../../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { AdminAccessTable } from '@cdw-selline/ui/components';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { cloneDeep } from 'lodash';
import { useServicePractices, useServiceLocations } from '@cdw-selline/ui/hooks';

export interface PracticeFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (practice: Practice) => void;
  practice?: Practice | null;
  practiceLoading?: boolean;
  practiceError?: ApolloError;
}

const newPracticeDefaults: Practice = {
  bidAssurance: '',
  id: '',
  managedSvc: false,
  name: '',
  peerReviewEmail: '',
  practiceUsernames: [],
  psm: '',
  serviceLocationId: '',
  web: '',
};

export function PracticeForm({
  isOpen,
  handleClose,
  handleSubmit,
  practice,
  practiceLoading,
  practiceError,
}: PracticeFormProps) {
  const [practiceFormData, setPracticeFormData] = useState(
    practice ?? newPracticeDefaults
  );
  const [error, setError] = useState(false);
  const alertState = useAlertsState();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (practice?.id) {
      setPracticeFormData(practice);
      setRows(practice?.practiceUsernames.map((p, i) => ({
        id: i,
        email: p,
        name: p,
      })));
    }
  }, [practice]);

  const {
    data: servicePractices,
    loading: servicePracticeLoading,
    error: servicePracticeError,
  } = useServicePractices({});

  const {
    data: serviceLocations,
    error: serviceLocationsError,
    loading: serviceLocationsLoading,
  } = useServiceLocations({ name: 'asc' });

  const servicePracticeOptions =
    servicePractices.servicePractices.map((practice) => {
      return {
        id: practice.id,
        label: practice.name,
      };
    }) ?? [];

  const serviceLocationOptions =
    serviceLocations.map((location) => {
      return {
        id: location.id,
        label: location.name,
      };
    }) ?? [];

  const handleFormClose = () => {
    setPracticeFormData(newPracticeDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (practiceFormData.name && practiceFormData.salesForceId) {
      handleSubmit(practiceFormData);
      handleClose();
    } else {
      setError(true);
    }
  };

  if (practiceLoading || servicePracticeLoading || serviceLocationsLoading) return <Typography>Loading form</Typography>;

  if (practiceError || servicePracticeError || serviceLocationsError) return <Typography>Error loading form</Typography>;

  const getTitle = () => {
    return (practiceFormData.id && `Edit Practice`) || 'Add Practice';
  };

  const handlePracticeUserSave = (params) => {
    if (practiceFormData.practiceUsernames?.some((item) => item === params.email)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.SUCCESS,
        message: 'This user is already an admin!',
      });
      return;
    }
    const practiceUsernames = cloneDeep(practiceFormData.practiceUsernames || []);
    practiceUsernames.unshift(params.email);

    setPracticeFormData((s) => ({
      ...s,
      practiceUsernames,
    }));
    setRows(practiceUsernames.map((p, i) => ({
      id: i,
      email: p,
      name: p,
    })));
  };

  const handlePracticeUserDelete = (userEmail: string) => {
    const practiceUsernames = cloneDeep(practiceFormData.practiceUsernames || []);
    const updatedUsernames = practiceUsernames.filter((user) => user !== userEmail);

    setPracticeFormData((s) => ({
      ...s,
      practiceUsernames: updatedUsernames,
    }));
    setRows(updatedUsernames.map((p, i) => ({
      id: i,
      email: p,
      name: p,
    })));
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="practice-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Practice Name"
            required={true}
            error={error && !practiceFormData.name}
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <FormAutocomplete
            name="salesForceId"
            label="SalesForce Practice"
            required={true}
            error={error && !practiceFormData.salesForceId}
            options={servicePracticeOptions}
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <FormAutocomplete
            name="serviceLocationId"
            label="Service Location ID"
            options={serviceLocationOptions}
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <FormCheckbox
            name="managedSvc"
            label="Is Managed SVC?"
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <FormText
            name="psm"
            label="Primary Sales Manager"
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <FormText
            name="peerReviewEmail"
            label="Peer Review Email"
            setFormData={setPracticeFormData}
            formData={practiceFormData}
          />
          <AdminAccessTable
            {...{
              title: 'Practice Admins',
              rows: rows,
              handleSave: handlePracticeUserSave,
              handleDelete: handlePracticeUserDelete,
            }}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}
