import React from 'react';
import {
  Box,
  Button,
  Grid,
  CircularProgress,
  Typography,
  useTheme,
} from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RmsGPAnalysisTable } from '@cdw-selline/ui/pages';
import { ServiceSummary } from '@cdw-selline/ui/components';
import { useRmsGPAnalysis } from '@cdw-selline/ui/hooks';
import { getCurrentUser } from '@cdw-selline/ui/state';

export interface RmsGPAnalysisTabProps {
  isReadOnly?: boolean;
}

export const RmsGPAnalysisTab = ({isReadOnly}: RmsGPAnalysisTabProps) => {
  const {
    rmsGPAnalysis,
    rmsGPAnalysisLoading,
    rmsGPAnalysisError,
    handleTaskGPUpdate,
    handleResetTaskMinGP,
  } = useRmsGPAnalysis();
  const currentUser = getCurrentUser();
  const theme = useTheme();

  if (rmsGPAnalysisLoading) return <CircularProgress />;
  if (rmsGPAnalysisError) return <div>ERROR: Failed to Load</div>;

  return (
    <Box sx={{ mt: 2, p: 2 }}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="h3" sx={{ color: '#cc0000' }}>
          For CDW internal use only. The information below is not to be shared
          outside of CDW.
        </Typography>
        {currentUser.managedServicesAdmin && !isReadOnly && (
          <Button
            variant="contained"
            sx={{ background: theme.palette.primary.dark }}
            onClick={() => handleResetTaskMinGP()}
          >
            Reset Task Min GP
          </Button>
        )}
      </Grid>
      <Grid
        item
        md={6}
        container
        spacing={3}
        sx={{ mb: 5, mt: 2 }}
        justifyContent={'space-between'}
      >
        <ServiceSummary />
      </Grid>
      {!rmsGPAnalysisLoading && (
        <Grid item xs={12}>
          <RmsGPAnalysisTable
            items={rmsGPAnalysis.rmsItems}
            totalMonthlyCharges={rmsGPAnalysis.totalMonthlyCharges}
            unitGPUpdate={handleTaskGPUpdate}
            isManagedServiceAdmin={currentUser.managedServicesAdmin}
            theme={theme}
            isReadOnly={isReadOnly}
          />
        </Grid>
      )}
    </Box>
  );
};

export default RmsGPAnalysisTab;
