// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CollectionsDataTable } from '@cdw-selline/ui/components';
import { useAdminServiceSkills } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import LoadingOverlay from 'libs/ui/components/src/lib/LoadingOverlay';
import React from 'react';
  
  export const ServiceSkillsCollectionPage = () => {
    const dataTableProps = useAdminServiceSkills();
  
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <CollectionsDataTable
          {...dataTableProps}
          height="calc(100vh - 13em)"
          parent="ServiceSkillsCollectionPage"
          paginationMode="server"
          handleSync={dataTableProps.syncServiceSkills}
        />
      </Box>
    );
  };
  
  export default ServiceSkillsCollectionPage;