// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  EstimatorVariablesTab,
  EstimatorTab,
  RmsGPAnalysisTab,
} from '@cdw-selline/ui/pages';
import * as React from 'react';
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Tab,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  useManagedServices,
  useProjectItem,
  useProjectItemEstimator,
  useProjectPracticesAndRatesQuery,
  useOpenState,
  useProjects,
} from '@cdw-selline/ui/hooks';
import { useParams } from 'react-router-dom';
import ProjectOutputTab from '../ProjectOutputTab';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  EstimatorSowComponent,
  DialogConfirm,
} from '@cdw-selline/ui/components';
import { getReadOnlyMode } from '@cdw-selline/ui/helpers';
import ProposalTab from '../proposal-tab/ProposalTab';
import RMSProposalTab from '../rms-proposal-tab/RMSProposalTab';
import ActiveTasksTab from '../active-tasks-tab/ActiveTasksTab';
import ProjectAgiloftTab from '../project-agiloft-tab/ProjectAgiloftTab';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { SalesforceSvgLogo, AgiloftSvgLogo } from '@cdw-selline/ui/components';

/* eslint-disable-next-line */
export interface ProjectItemEstimatorTabProps {}

export function ProjectItemEstimatorTab(props: ProjectItemEstimatorTabProps) {
  const useStyles = makeStyles({
    logo: {
      height: '24px',
      margin: '8px',
      cursor: "pointer"
    },
  });

  const classes = useStyles();
  const theme = useTheme();
  const tabColor = theme.palette.secondary.main;

  const [value, setValue] = React.useState('1');
  const [title, setTitle] = React.useState('');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { id, projectItemId } = useParams<{ id: string, projectItemId: string }>();
  const { data } = useProjectItem(projectItemId);
  const { data: estimatorData, handleCopyProjectItemEstimator } = useProjectItemEstimator();
  const currentUser = getCurrentUser();
  const {
    isOpen: isConfirmOpen,
    handleClose: handleConfirmClose,
    handleOpen: handleConfirmOpen,
  } = useOpenState();

  const handleConfirmYes = () => {
    handleConfirmClose();
    handleCopyProjectItemEstimator();
  };
  const handleRemoveCancel = () => {
    handleConfirmClose();
  };

  const generatePSALink = (psaEstimateId) => {
    const urlInstance = new URL(project.salesForceUrl);

    return `${urlInstance.origin}/lightning/r/ffscpq__Estimate__c/${psaEstimateId}/view`;
  };

  const {
    currentPractices,
    currentRolesAndRates,
    loading: practicesAndRatesLoading,
    error: practicesAndRatesError,
  } = useProjectPracticesAndRatesQuery(projectItemId);

  const { includesManagedServices } = useManagedServices(projectItemId);
  const { data: project, loading, error } = useProjects({ id });

  const isReadOnly = getReadOnlyMode(project);

  //TODO Move title to project title
  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    data ? setTitle(data?.name) : null;
  }, [data]);

  const handleSalesforceRedirect = () => {
    const psaEstimateLink = generatePSALink(estimatorData.psaEstimateId);
    window.open(psaEstimateLink, "_blank");
  };

  const handleAgiloftRedirect = () => {
    window.open(estimatorData.agiloftUrl, "_blank");
  };

  return (
    <Grid
      item
      xl={12}
      xs={12}
      sx={{
        justifyContent: 'center',
        display: 'flex',
        mb: '1em',
        maxWidth: '100%',
        flexBasis: '100%',
      }}
    >
      <Card sx={{ width: '100%', mb: '.5em' }}>
        <CardHeader />
        <Box sx={{ width: '100%', padding: '0' }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <TabList
                TabIndicatorProps={{
                  style: {
                    background: `${tabColor}`,
                  },
                }}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Variables"
                  value="1"
                  data-testid="estimator-variables-tab-button"
                />
                <Tab
                  label="Estimator"
                  value="2"
                  data-testid="estimator-details-tab-button"
                />
                <Tab
                  label="BA Review"
                  value="3"
                  data-testid="ba-review-tab-button"
                />
                <Tab
                  label="Output"
                  value="4"
                  data-testid="estimator-output-tab-button"
                />
                <Tab
                  label="Proposal"
                  value="5"
                  data-testid="project-proposal-link"
                />
                {includesManagedServices && (
                  <Tab
                    label="RMS Proposal"
                    value="6"
                    data-testid="project-rms-proposal-button"
                  />
                )}
                {includesManagedServices && (
                  <Tab
                    label="RMS GP Analysis"
                    value="7"
                    data-testid="project-rms-analysis-button"
                  />
                )}
                <Tab label="SOW" value="8" data-testid="project-sow-link" />
                {currentUser.admin && (
                  <Tab
                    label="AGILOFT"
                    value="9"
                    data-testid="project-agiloft-tab"
                  />
                )}
              </TabList>
              <Grid display={'flex'}>
                {estimatorData.psaEstimateId && (
                  <SalesforceSvgLogo
                    className={classes.logo}
                    onClick={handleSalesforceRedirect}
                  />
                )}

                {estimatorData.agiloftUrl && (
                  <Tooltip title="Click here to navigate directly to Agiloft">
                    <div>
                      <AgiloftSvgLogo
                        className={classes.logo}
                        onClick={handleAgiloftRedirect}
                      />
                    </div>
                  </Tooltip>
                )}
                <Tooltip title="Copy Estimator">
                  <IconButton
                    size="medium"
                    aria-label="edit"
                    onClick={handleConfirmOpen}
                    data-testid={`copy-estimator-icon`}
                  >
                    <ContentCopyIcon fontSize="medium" />
                  </IconButton>
                </Tooltip>
              </Grid>
              <DialogConfirm
                {...{
                  isOpen: isConfirmOpen,
                  handleClose: handleRemoveCancel,
                  title: 'Copy Estimator',
                  handleYes: handleConfirmYes,
                }}
              >
                <Typography sx={{ padding: '10px 2px' }}>
                  Would you like to copy the Estimator?
                </Typography>
              </DialogConfirm>
            </Box>
            <TabPanel value="1" sx={{ width: '100%', padding: '0' }}>
              <EstimatorVariablesTab
                {...{
                  projectItemId,
                  currentPractices,
                  currentRolesAndRates,
                  practicesAndRatesLoading,
                  practicesAndRatesError,
                  projectItem: data,
                  isReadOnly,
                }}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ width: '100%', padding: '0' }}>
              <EstimatorTab
                {...{
                  projectItemId,
                  currentPractices,
                  currentRolesAndRates,
                  practicesAndRatesLoading,
                  practicesAndRatesError,
                  isReadOnly,
                }}
              />
            </TabPanel>
            <TabPanel value="3" sx={{ width: '100%', padding: '0' }}>
              <ActiveTasksTab />
            </TabPanel>
            <TabPanel value="4" sx={{ width: '100%', padding: '0' }}>
              <ProjectOutputTab isReadOnly={isReadOnly} />
            </TabPanel>
            {process.env.NODE_ENV !== 'selline-dev' && (
              <>
                <TabPanel value="5" sx={{ width: '100%', padding: '0' }}>
                  <ProposalTab isReadOnly={isReadOnly} />
                </TabPanel>
                <TabPanel value="6" sx={{ width: '100%', padding: '0' }}>
                  <RMSProposalTab isReadOnly={isReadOnly} />
                </TabPanel>
                <TabPanel value="7" sx={{ width: '100%', padding: '0' }}>
                  <RmsGPAnalysisTab isReadOnly={isReadOnly} />
                </TabPanel>
              </>
            )}
            <TabPanel value="8" sx={{ width: '100%', padding: '0' }}>
              <EstimatorSowComponent isReadOnly={isReadOnly} />
            </TabPanel>
            {currentUser.admin && (
              <TabPanel value="9" sx={{ width: '100%', padding: '0' }}>
                <ProjectAgiloftTab
                  projectId={id}
                  projectItemId={projectItemId}
                />
              </TabPanel>
            )}
          </TabContext>
        </Box>
      </Card>
    </Grid>
  );
}

export default ProjectItemEstimatorTab;
