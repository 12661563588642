import {
  CDWFallback,
  UserAccessTable,
  CiscoAnnuity,
  SalesforceSvgLogo,
} from '@cdw-selline/ui/components';
import {
  useProjectItem,
  useProjects,
  useOpenState,
  useProjectStatus,
  useCopyProject,
} from '@cdw-selline/ui/hooks';
import React, { useEffect } from 'react';
import { Route, Switch, useLocation, useParams } from 'react-router-dom';
import { ProjectDetailTab } from './tabs/project-detail/ProjectDetailTab';
import { ProjectSowTab } from './tabs/project-sow-tab/ProjectSowTab';
import { OutputTab } from './tabs/ProjectOutputTab';
import { ProjectItemEstimatorTab } from './tabs/project-item-estimator/ProjectItemEstimatorTab';
import ProposalTab from './tabs/proposal-tab/ProposalTab';
import RMSProposalTab from './tabs/rms-proposal-tab/RMSProposalTab';
import ActiveTasksTab from './tabs/active-tasks-tab/ActiveTasksTab';

import {
  Box,
  Card,
  Divider,
  Grid,
  Typography,
  Backdrop,
  Stack,
  Link,
  Breadcrumbs,
  Tooltip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import { useHistory } from 'react-router-dom';
import { ROUTES } from '@cdw-selline/ui/constants';
import ProjectItemQuoteTab from './tabs/project-item-quote-tab/ProjectItemQuoteTab';
import { themeGlobals } from '@cdw-selline/ui/theme';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import LaunchIcon from '@mui/icons-material/Launch';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { DialogConfirm } from '../../../../components/src/lib/dialog-confirm/DialogConfirm';
import { formatCurrency } from '@cdw-selline/ui/helpers';

/* eslint-disable-next-line */
export interface ProjectPageProps {}

const PROJECT_PAGE_ROUTES = [
  {
    path: '/project/:id',
    component: ProjectDetailTab,
  },
  {
    path: '/project/:id/project-notes',
    component: (props) => <>Hello Project Notes Tab</>,
  },
  {
    path: '/project/:id/output',
    component: OutputTab,
  },
  {
    path: '/project/:id/proposal',
    component: ProposalTab,
  },
  {
    path: '/project/:id/proposal/:proposalId',
    component: ProposalTab,
  },
  {
    path: '/project/:id/rms-proposal',
    component: RMSProposalTab,
  },
  {
    path: '/project/:id/sow',
    component: ProjectSowTab,
  },
  {
    path: '/project/:id/user-access',
    component: UserAccessTable,
  },
  {
    path: '/project/:id/estimator/:projectItemId',
    component: ProjectItemEstimatorTab,
  },
  {
    path: '/project/:id/activetasks/:projectItemId',
    component: ActiveTasksTab,
  },
  {
    path: '/project/:id/quote/:projectItemId',
    component: ProjectItemQuoteTab,
  },
  {
    path: '/project/:id/ciscoAnnuity/:projectItemId',
    component: CiscoAnnuity,
  },
];

export const ProjectPage = (props: ProjectPageProps) => {
  const useStyles = makeStyles({
    logo: {
      height: '24px',
      cursor: "pointer"
    },
  });

  const classes = useStyles();
  const theme = useTheme();
  const tabColor = theme.palette.secondary.main;
  
  const [drawerState, setDrawerState] = React.useState(false);
  const { id, projectItemId } =
    useParams<{ id: string; projectItemId: string }>();
  const {
    data: project,
    loading,
    error,
    error: getProjectError,
    updateProjectError,
    updateProject,
    updateProjectLoading,
  } = useProjects({ id });
  const { data: projectItem } = useProjectItem(projectItemId);

  const projectItemTitle = projectItem?.name ?? '';
  const { deleteOpportunity } = useProjectStatus({ id });
  const { handleCopyProject } = useCopyProject();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const router = useHistory();
  const handleClick = () => {
    router.push(`${ROUTES.PROJECT}/${id}`);
  };

  const toggleDrawer = (open: boolean): void => {
    setDrawerState(() => open);
  };

  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    handleDeleteDialogConfirmOpen();
  };

  const handleDialogDelete = (e) => {
    e.stopPropagation();
    deleteOpportunity({
      variables: {
        updateProjectStatusParams: {
          id,
          status: 'Delete',
          closedReason: '',
          closedStatus: '',
        },
      },
    });
  };

  const clickCopyProject = () => {
    handleCopyProject(id);
  };

  if (loading)
    return (
      <Backdrop
        sx={{
          zIndex: (theme) => theme.zIndex.drawer - 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        open
        data-testid="progressbar"
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            xs={12}
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <Typography> Loading Project Data...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CDWFallback />
          </Grid>
        </Stack>
      </Backdrop>
    );
  if (error) return <Typography>Error!</Typography>;

  return (
    <Box sx={{ ...themeGlobals.containerMargins }}>
      <Grid item xs={12}>
        <Card
          sx={{
            width: '100%',
            p: '.5em',
            mb: '.5em',
            position: 'relative',
          }}
        >
          <Box sx={{ display: 'flex', width: '100%' }}>
            <Box sx={{ width: '98vw' }}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  data-testid="project-name-link"
                  underline="hover"
                  color="inherit"
                  onClick={handleClick}
                  sx={{ fontSize: '1.5em', cursor: 'pointer' }}
                >
                  {project?.name}
                </Link>
                {projectItemTitle && (
                  <Link
                    underline="hover"
                    color="text.primary"
                    sx={{ fontSize: '1.5em' }}
                  >
                    {projectItemTitle}
                  </Link>
                )}
              </Breadcrumbs>
              <Typography>{project?.customerName}</Typography>
            </Box>
            <Box sx={{ padding: '10px 4px' }}>
              {project?.onedriveLink && (
                <Tooltip enterDelay={300} title="Open OneDrive">
                  <CloudCircleIcon
                    sx={{ cursor: 'pointer' }}
                    onClick={() => {
                      window.open(project.onedriveLink, '_blank');
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ padding: '10px 4px' }}>
              {project?.salesForceUrl && (
                <Grid title="Open Salesforce Opportunity">
                  <SalesforceSvgLogo
                      className={classes.logo}
                      onClick={() => {
                        window.open(project.salesForceUrl, '_blank');
                      }}
                  />
                </Grid>
              )}
            </Box>
            {/* <Box sx={{ padding: '10px 4px' }}>
              <Tooltip enterDelay={300} title="Copy Opportunity">
                <ContentCopyIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={clickCopyProject}
                />
              </Tooltip>
            </Box> */}
            <Box sx={{ padding: '10px 4px' }}>
              <Tooltip enterDelay={300} title="Delete Opportunity">
                <DeleteIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={handleDeleteClick}
                />
              </Tooltip>
              <DialogConfirm
                title="Delete Opportunity?"
                isOpen={deleteDialogConfirmOpen}
                handleClose={handleDeleteDialogConfirmClose}
                handleYes={handleDialogDelete}
              >
                <Typography>
                  Are you sure you want to delete this Opportunity?
                </Typography>
              </DialogConfirm>
            </Box>
          </Box>
          <Divider />
          <Grid
            container
            item
            xs={12}
            sx={{ display: 'flex', marginTop: '5px' }}
            columns={{ xs: 7, sm: 7 }}
          >
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Opportunity Owner</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {project?.projectOwnerDisplayName ?? ''}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Stage</Typography>
              </Box>
              <Box>
                <Typography variant="body1">{project?.status}</Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Services Amount</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {formatCurrency(project?.serviceTotal ?? 0)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Product Amount</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {formatCurrency(project?.productTotal ?? 0)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Subscription Amount</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {formatCurrency(project?.subscriptionTotal ?? 0)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Opportunity Total</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {formatCurrency(
                    project?.serviceTotal +
                      project?.productTotal +
                      project?.subscriptionTotal ?? 0
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">
                  Managed Service Amount
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {formatCurrency(project?.managedServiceTotal ?? 0)}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={1}>
              <Box>
                <Typography variant="subtitle2">Expected Close Date</Typography>
              </Box>
              <Box>
                <Typography variant="body1">
                  {project?.estClose
                    ? new Date(project.estClose).toLocaleDateString()
                    : ''}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Card>
        <Grid>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Switch>
              {PROJECT_PAGE_ROUTES.map(
                ({ path, component: Component }, idx) => (
                  <Route exact path={path} key={`project-tab-${idx}`}>
                    <Component
                      {...{
                        project,
                        id,
                        getProjectError,
                        updateProject,
                        updateProjectError,
                        updateProjectLoading,
                        toggleDrawer,
                        drawerState,
                      }}
                    />
                  </Route>
                )
              )}
            </Switch>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProjectPage;
