import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { GetEstimateToPsaResponse } from '@cdw-selline/common/types';
import { GET_PSA_BREAKDOWN_QUERY } from '@cdw-selline/ui/queries';
import {
  GridColDef,
} from '@mui/x-data-grid-pro';
import { formatCurrency } from '@cdw-selline/ui/helpers';

export const usePsaBreakdownSummary = () => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { loading, error, data } = useQuery<{
    getPsaBreakdownByProjectId: GetEstimateToPsaResponse;
  }>(GET_PSA_BREAKDOWN_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    variables: { id: projectItemId },
  });

  const wrapperEstimate = data?.getPsaBreakdownByProjectId?.inputs
    ? data?.getPsaBreakdownByProjectId?.inputs[0].wrapperestimate
    : {};

  const columns: GridColDef[] = [
    { field: 'billing', headerName: 'Bill Amount', flex:1, align: 'right' },
  ];

  const calculateBillingSums = (rows) => {
    const billingMap = {};

    rows.forEach((row) => {
      const { hierarchy, billing } = row;
      const billingString =
        typeof billing === 'string' ? billing : String(billing);
      const parsedBilling =
        parseFloat(billingString?.replace(/[^0-9.-]+/g, '')) || 0;

      hierarchy.forEach((_, index) => {
        const parentKey = hierarchy.slice(0, index + 1).join('>');

        if (!billingMap[parentKey]) {
          billingMap[parentKey] = 0;
        }
        billingMap[parentKey] += parsedBilling;
      });
    });

    return rows.map((row) => {
      const key = row.hierarchy.join('>');
      return {
        ...row,
        billing: formatCurrency(billingMap[key] || 0),
      };
    });
  };

  const createGridRows = () => {
    if (!wrapperEstimate || Object.keys(wrapperEstimate).length === 0) {
      return [];
    }

    const generateRandomId = () =>
      `id-${Date.now()}-${Math.floor(Math.random() * 10000)}`;

    const estimateRows = [
      {
        hierarchy: [wrapperEstimate.estimate.Name],
        id: generateRandomId(),
        billing: '',
        type: wrapperEstimate.estimate.Project_Type__c,
      },
    ];

    const estimateProductsRows = wrapperEstimate.estimateProducts.map(
      (estimateObj, index) => ({
        hierarchy: [wrapperEstimate.estimate.Name, estimateObj.Name],
        id: generateRandomId(),
        billing: estimateObj.travel_bill_rate,
        type: estimateObj.contractType,
      })
    );

    const estimateLinesRows = wrapperEstimate.estimateLines.map(
      (estimateObj, index) => ({
        hierarchy: [
          wrapperEstimate.estimate.Name,
          estimateObj.estimateProduct,
          estimateObj.name,
        ],
        id: generateRandomId(),
        billing: formatCurrency(
          estimateObj.netBillAmount
        ),
        startDate: estimateObj.startDate,
        type: estimateObj.contractType,
      })
    );

    const estimateRoleRequestsRows = wrapperEstimate.estimateRoleRequests.map(
      (estimateObj, index) => ({
        hierarchy: [
          wrapperEstimate.estimate.Name,
          estimateObj.estimateProduct,
          estimateObj.estimateLineName,
          estimateObj.role,
        ],
        id: generateRandomId(),
        billing: formatCurrency(
          estimateObj.hours * estimateObj.bill_Rate_Override
        ),
        type: '',
      })
    );


    const gridRows = calculateBillingSums([
      ...estimateRows,
      ...estimateProductsRows,
      ...estimateLinesRows,
      ...estimateRoleRequestsRows,
    ]);

    const pathsSet = new Set();
    const hasDuplicatePath = gridRows.some(row => {
      const pathString = row.hierarchy.join('>');

      if (pathsSet.has(pathString)) {
        return true;
      }

      pathsSet.add(pathString);

      return false; 
    });

    if (hasDuplicatePath) {
      console.error(
        'Duplicate path detected in PSA breakdown summary',
        gridRows
      );
    }

    return hasDuplicatePath ? [] : gridRows;
  };

  return {
    estimateToPsaResponse: createGridRows(),
    error,
    loading,
    columns,
  };
};
