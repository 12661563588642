import { RoleAndRate, Site, Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { estimatorStrings, labelStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useCopyProjectSite,
  useRemoveTasksBySite,
  useSetSiteInfo,
  useRestoreEstimatorTask,
  useOpenState,
} from '@cdw-selline/ui/hooks';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import TaskList from '../task-list/TaskList';
import { CustomTasksList } from './CustomTasksList';

import DialogConfirm from '../../../dialog-confirm/DialogConfirm';
export interface SiteCardProps {
  site: Site;
  projectItemId: string;
  handleTaskEdit: (taskId: string, siteId: string) => void;
  handleVersionSyncOpen: (task: Task) => void;
  handleNotesOpen: (currentTask: Task) => void;
  currentRolesAndRates: RoleAndRate[];
  handleAddTaskGroup: (siteId: string) => void;
  handleSyncTaskGroup: (siteId: string) => void;
  handleRemoveCustomTask: (taskId: string) => void;
  activeTaskId: string;
  fullSiteLoad: boolean;
  setFullSiteLoad: (value: boolean) => void;
  handleTextTaskEdit: (taskId: string, siteId: string) => void;
  handleProposalDescriptionDialogOpen: (task: Task) => void;
  isReadOnly: boolean;
  tabView?: boolean;
  includesSummaryTasks?: boolean;
  handleRemoveSiteClick?: () => void;
  handleCopySiteClick?: () => void;
  setIsCustomTasksList: (value: boolean) => void;
  isCustomTasksList: boolean;
  setFullSummarySiteLoad: (value: boolean) => void;
}

export function SiteCard({
  site,
  projectItemId,
  handleTaskEdit,
  handleVersionSyncOpen,
  handleNotesOpen,
  currentRolesAndRates,
  handleAddTaskGroup,
  handleSyncTaskGroup,
  handleRemoveCustomTask,
  handleProposalDescriptionDialogOpen,
  activeTaskId,
  fullSiteLoad,
  setFullSiteLoad,
  handleTextTaskEdit,
  isReadOnly,
  tabView,
  includesSummaryTasks,
  handleRemoveSiteClick,
  handleCopySiteClick,
  setIsCustomTasksList,
  isCustomTasksList,
  setFullSummarySiteLoad
}: SiteCardProps) {
  const {
    handleRemoveSite,
    loading: removeSiteLoading,
    error: removeSiteError,
  } = useRemoveTasksBySite();

  const {
    isOpen: removeDialogConfirmOpen,
    handleClose: handleRemoveDialogConfirmClose,
    handleOpen: handleRemoveDialogConfirmOpen,
  } = useOpenState();

  const { handleSetSite } = useSetSiteInfo();
  const [hideZeroQuantity, setHideZeroQuantity] = useState(site.hideZeroQuantity);
  const TabView = localStorage.getItem("TabView") === "true" ? true : false;
  const expanded = TabView || (site.expanded ?? false);
  const taskButtonCssConfig = { marginLeft: 5, height: '4.3em', overflow: 'hidden', width: "auto", minWidth:"170px", padding: "2px" };
const taskTextFieldCssConfig = { minWidth: '5em', textAlign: 'center', height: '4.3em' };
  const handleHideZeroQuantity = ()=> {
    handleSetSite(projectItemId, site?.id, {
      hideZeroQuantity: !hideZeroQuantity,
    });
    setHideZeroQuantity(!hideZeroQuantity);
  };
  const { isLatestVersionTaskGroup } = useRestoreEstimatorTask(null, site.id, projectItemId);

  // Removing the useRef for now
  // const availableRoles = useRef(currentRolesAndRates);

  const {
    handleCopyProjectSite,
    loading: copySiteLoading,
    error: copySiteError,
  } = useCopyProjectSite(includesSummaryTasks);

  const handleSiteInfoChange = (e, previousSite: string) => {
    handleSetSite(projectItemId, previousSite, {
      [e.target.name]: e.target.value,
      expanded
    },
    includesSummaryTasks,
  );
  };

  const handleCopyClick = () => {
    handleCopyProjectSite(projectItemId, site.id, site.name);
    handleCopySiteClick && handleCopySiteClick();
  };

  const toggleAccordian = () => {
    handleSetSite(projectItemId, site?.id, {
      expanded: !expanded,
    });
  };

  const handleCustomTaskAdd = (siteId) => {
    !expanded && toggleAccordian();

    handleTaskEdit('', siteId);
  };

  const handleOpenImportCustomTasks = () => {
    !expanded && toggleAccordian();
    setIsCustomTasksList(true);
  };

  const handleDialogRemove = () => {
    handleRemoveSite(projectItemId, site.id);
    handleRemoveDialogConfirmClose();
    handleRemoveSiteClick && handleRemoveSiteClick()
  };

  const handleDialogCancel = () => {
    handleRemoveDialogConfirmClose();
  };
  const siteBusy = removeSiteLoading || copySiteLoading;

  if (removeSiteError)
    return <Typography>{estimatorStrings.REMOVE_SITE_ERROR}</Typography>;

  if (isCustomTasksList && site.expanded)
    return <CustomTasksList siteId={site.id} setIsCustomTasksList={setIsCustomTasksList}></CustomTasksList>;

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
        padding: '0',
      }}
    >
      <AccordionSummary
        style={{ cursor: 'default' }}
        {...(
          TabView ? {} : {expandIcon:
            <ExpandMoreIcon
              data-testid="expand-site"
              onClick={toggleAccordian}
              style={{ cursor: 'pointer' }}
            />
          }
        )}
        aria-controls="site task list"
      >
       
       <Grid container sx={{ justifyContent: 'space-between' }} >
       
          <Grid
            item
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'space-between',
            }}

            noValidate
            autoComplete="off"
            xs={12}
            xl={7}
          >
            <TextField
              label="Quantity"
              name="quantity"
              defaultValue={site?.quantity ?? 1}
              onBlur={(e) => handleSiteInfoChange(e, site?.id)}
              data-testid="site-details-quantity-input"
              sx={{...taskTextFieldCssConfig, minWidth: '2em' }}
              disabled={isReadOnly}
            />
            <TextField
              label="Site"
              name="name"
              defaultValue={site?.name}
              onBlur={(e) => handleSiteInfoChange(e, site?.id)}
              data-testid="site-details-name-input"
              disabled={isReadOnly}
              size="medium"  
               sx={taskTextFieldCssConfig}
              
            />
            <TextField
              label="Address"
              name="address"
              defaultValue={site?.address ?? ''}
              onBlur={(e) => handleSiteInfoChange(e, site?.id)}
              data-testid="site-details-address-input"
              disabled={isReadOnly}
              size="medium"
              sx={{ ...taskTextFieldCssConfig, minWidth: '4em' }}
            />
            <TextField
              label="Floor"
              name="floor"
              defaultValue={site?.floor ?? ''}
              onBlur={(e) => handleSiteInfoChange(e, site?.id)}
              data-testid="site-details-floor-input"
              disabled={isReadOnly}
              size="medium"
              sx={ taskTextFieldCssConfig }
            />
            {!isReadOnly && <Button
              size="large"
              style={{...taskButtonCssConfig, minWidth: "8em" }}
              title="Add Custom Task"
              variant="outlined"
              onClick={(event) => handleCustomTaskAdd(site?.id)}
            >
              + Add Custom Task
            </Button>}
            {!isReadOnly && process.env.NX_PROJECT_ID === 'selline-dev' && <Button
              size="large"
              style={{...taskButtonCssConfig, minWidth: "9em" }}
              title="Import Custom Tasks"
              variant="outlined"
              onClick={handleOpenImportCustomTasks}
            >
              + Import Custom Tasks
            </Button>}
            {!isReadOnly && <Button
              size="large"
              style={{...taskButtonCssConfig, minWidth: "8em" }}
              title="Add Task Group"
              variant="outlined"
              data-testid={`add-task-button-${site.name}`}
              onClick={() => handleAddTaskGroup(site.id)}
            >
              + Add Task Group
            </Button>}
            {!isReadOnly && <Button
              size="large"
              color="error"
              style={taskButtonCssConfig}
              title="Show/Hide Tasks with no quantity"
              variant="outlined"
              data-testid={`show-task-with-no-quantity-button-${site.name}`}
              onClick= {handleHideZeroQuantity}
            >
              {hideZeroQuantity && 'Show ' || 'Hide '} Tasks with no quantity
            </Button>}
            {siteBusy && <CircularProgress />}
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '0',
            }}
          >
            {!isReadOnly && <ButtonGroup variant="text">
            {isLatestVersionTaskGroup && <Button
                color="error"
                onClick={() =>  handleSyncTaskGroup(site.id)}
              >
                {labelStrings.UPDATE_ALL_TASKS}
              </Button>}
              <Button color="error" onClick={handleRemoveDialogConfirmOpen}>
                {labelStrings.REMOVE_SITE}
              </Button>
              <Button onClick={handleCopyClick}>
                {labelStrings.COPY_SITE}
              </Button>
            </ButtonGroup>}
            {!TabView && <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                ml: '2em',
              }}
              onClick={toggleAccordian}
            >
              <Typography color="primary">
                {expanded ? 'Collapse' : 'Expand'} Site
              </Typography>
            </Grid>}
            {removeDialogConfirmOpen && (
              <DialogConfirm
                title="Remove Site"
                isOpen={removeDialogConfirmOpen}
                handleClose={handleDialogCancel}
                handleYes={handleDialogRemove}
              >
                <Typography>
                  Are you sure you want to permanently remove that site and all tasks
                  in the site?
                </Typography>
              </DialogConfirm>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0',
        }}
      >
        <TaskList
          projectItemId={projectItemId}
          site={site}
          expanded={expanded}
          hideZeroQuantity={hideZeroQuantity}
          handleTaskEdit={handleTaskEdit}
          handleVersionSyncOpen={handleVersionSyncOpen}
          handleNotesOpen={handleNotesOpen}
          currentRolesAndRates={currentRolesAndRates}
          handleRemoveCustomTask={handleRemoveCustomTask}
          activeTaskId={activeTaskId}
          fullSiteLoad={fullSiteLoad}
          setFullSiteLoad={setFullSiteLoad}
          handleTextTaskEdit={handleTextTaskEdit}
          handleProposalDescriptionDialogOpen={
            handleProposalDescriptionDialogOpen
          }
          isReadOnly={isReadOnly}
          tabView={TabView}
          includesSummaryTasks={includesSummaryTasks}
          setFullSummarySiteLoad={setFullSummarySiteLoad}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default SiteCard;
