/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import React from 'react';
import {
  RmsProposalTemplateTierSites,
  RmsProposal,
} from '@cdw-selline/common/types';
import {
  Box,
  Typography,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Paper,
  Grid,
} from '@mui/material';

import { FormCheckbox } from '@cdw-selline/ui/components';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { makeStyles } from '@mui/styles';

interface TemplateTypeProps {
  costItems: RmsProposalTemplateTierSites[];
  totalMonthlyCharges: number;
  bronzeTotal: number;
  silverTotal: number;
  goldTotal: number;
  updateProposalData: (rmsProposal: RmsProposal) => void;
  rmsProposal: RmsProposal;
  printView?: boolean;
}

const useStyles = makeStyles({
  sectionHeader: {
    backgroundColor: '#cc0000',
    padding: '5px',
    minHeight: '25px',
    borderTop: '10px solid #D3D3D3',
    borderBottom: '10px solid #D3D3D3',
    textTransform: 'uppercase',
  },
  siteHeader: {
    backgroundColor: '#cc0000',
    padding: '5px',
    textTransform: 'uppercase',
    border: '1px solid black',
  },
  border1: {
    border: '1px solid black',
    padding: '5px',
  },
  border2: {
    border: '2px solid black',
    padding: '5px',
  },
  category: {
    transformText: 'uppercase',
    fontWeight: 'bold',
  },
});
export const TierRMSProposalTemplate = ({
  costItems,
  totalMonthlyCharges,
  bronzeTotal,
  silverTotal,
  goldTotal,
  updateProposalData,
  rmsProposal,
  printView = false,
}: TemplateTypeProps) => {
  // const TableCell = withStyles(styles)(TableCell);
  const classes = useStyles();
  const categoriesLength = costItems.length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h5" color="#FFFFFF">
            Recurring Monthly Cost
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              minWidth: 650,
            }}
            aria-label="monthy-cost-table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    borderLeft: '1px solid black',
                    borderBottom: '0px',
                    borderTop: '1px solid black',
                    width: '30%',
                  }}
                ></TableCell>
                {(!printView || (printView && rmsProposal.bronze)) && (
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ border: 0, textAlign: 'center', width: '23%' }}
                  >
                    {!printView ? (
                      <FormCheckbox
                        styles={{ margin: '0 auto' }}
                        name="bronze"
                        label="Bronze"
                        setFormData={updateProposalData}
                        formData={rmsProposal}
                      />
                    ) : (
                      <>
                        <Typography variant="body1" sx={{ display: 'block' }}>
                          Advanced Monitoring
                        </Typography>
                        <Typography variant="body1">(Bronze)</Typography>
                      </>
                    )}
                  </TableCell>
                )}
                {(!printView || (printView && rmsProposal.silver)) && (
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ border: 0, textAlign: 'center', width: '23%' }}
                  >
                    {!printView ? (
                      <FormCheckbox
                        name="silver"
                        label="Silver"
                        setFormData={updateProposalData}
                        formData={rmsProposal}
                      />
                    ) : (
                      <>
                        <Typography variant="body1" sx={{ display: 'block' }}>
                          Proactive Maintenance
                        </Typography>
                        <Typography variant="body1">(Silver)</Typography>
                      </>
                    )}
                  </TableCell>
                )}
                {(!printView || (printView && rmsProposal.gold)) && (
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ border: 0, textAlign: 'center', width: '23%' }}
                  >
                    {!printView ? (
                      <FormCheckbox
                        name="gold"
                        label="Gold"
                        setFormData={updateProposalData}
                        formData={rmsProposal}
                      />
                    ) : (
                      <>
                        <Typography variant="body1" sx={{ display: 'block' }}>
                          Availability Management
                        </Typography>
                        <Typography variant="body1">(Gold)</Typography>
                      </>
                    )}
                  </TableCell>
                )}
              </TableRow>

              <TableRow sx={{ borderBottom: '6px solid #000000' }}>
                <TableCell
                  sx={{ borderLeft: '1px solid black', borderTop: '0px' }}
                ></TableCell>
                {(!printView || (printView && rmsProposal.bronze)) && (
                  <>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Per Unit
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Total
                    </TableCell>
                  </>
                )}
                {(!printView || (printView && rmsProposal.silver)) && (
                  <>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Per Unit
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Total
                    </TableCell>
                  </>
                )}
                {(!printView || (printView && rmsProposal.gold)) && (
                  <>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Per Unit
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Qty
                    </TableCell>
                    <TableCell
                      className={classes.border1}
                      sx={{ textAlign: 'center' }}
                    >
                      Monthly Fee Total
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>

            <TableBody className={classes.border2}>
              <TableRow>
                <TableCell className={classes.border1} colSpan={10}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    MANAGED SERVICES
                  </Typography>
                </TableCell>
              </TableRow>
              {costItems.map((site, idx) => (
                <React.Fragment key={`${site.siteName}-${idx}`}>
                  {categoriesLength > 1 && (
                    <TableRow sx={{ background: '#D3D3D3' }}>
                      <TableCell
                        className={classes.siteHeader}
                        colSpan={10}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                          color="#FFFFFF"
                        >
                          {site.siteName}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {site.categories.map((category) => {
                    return (
                      <React.Fragment key={`Category-${category.category}`}>
                        <TableRow sx={{ background: '#D3D3D3' }}>
                          <TableCell
                            className={classes.border1}
                            colSpan={10}
                            align="left"
                          >
                            <Typography
                              variant="body1"
                              className={classes.category}
                            >
                              {category.category}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        {category.lineItems
                          .filter((i) => i.tier !== 'unknown')
                          .map((item, idx) => {
                            const bronze = item.tasks.find(
                              (task) => task.tier === 'Bronze'
                            );
                            const silver = item.tasks.find(
                              (task) => task.tier === 'Silver'
                            );
                            const gold = item.tasks.find(
                              (task) => task.tier === 'Gold'
                            );

                            return (
                              <TableRow key={`${item.tier}-${idx}`}>
                                <TableCell className={classes.border1}>
                                  {item.tier}
                                </TableCell>
                                {(!printView ||
                                  (printView && rmsProposal.bronze)) && (
                                  // eslint-disable-next-line react/jsx-no-useless-fragment
                                  <>
                                    {bronze ? (
                                      <>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(bronze.sellPrice)}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="center"
                                        >
                                          {bronze.quantity}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(
                                            bronze.totalSellPrice
                                          )}
                                        </TableCell>
                                      </>
                                    ) : (
                                      <TableCell
                                        className={classes.border1}
                                        align="center"
                                        colSpan={3}
                                      >
                                        Service Not Available
                                      </TableCell>
                                    )}
                                  </>
                                )}
                                {(!printView ||
                                  (printView && rmsProposal.silver)) && (
                                  // eslint-disable-next-line react/jsx-no-useless-fragment
                                  <>
                                    {silver ? (
                                      <>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(silver.sellPrice)}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="center"
                                        >
                                          {silver.quantity}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(
                                            silver.totalSellPrice
                                          )}
                                        </TableCell>
                                      </>
                                    ) : (
                                      <TableCell
                                        className={classes.border1}
                                        align="center"
                                        colSpan={3}
                                      >
                                        Service Not Available
                                      </TableCell>
                                    )}
                                  </>
                                )}
                                {(!printView ||
                                  (printView && rmsProposal.gold)) && (
                                  // eslint-disable-next-line react/jsx-no-useless-fragment
                                  <>
                                    {gold ? (
                                      <>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(gold.sellPrice)}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="center"
                                        >
                                          {gold.quantity}
                                        </TableCell>
                                        <TableCell
                                          className={classes.border1}
                                          align="right"
                                        >
                                          {formatCurrency(gold.totalSellPrice)}
                                        </TableCell>
                                      </>
                                    ) : (
                                      <TableCell
                                        className={classes.border1}
                                        align="center"
                                        colSpan={3}
                                      >
                                        Service Not Available
                                      </TableCell>
                                    )}
                                  </>
                                )}
                              </TableRow>
                            );
                          })}
                        {category.lineItems
                          .find((item) => item.tier === 'unknown')
                          ?.tasks?.map((item, idx) => (
                            <TableRow key={`${item.tier}-${idx}`}>
                              <TableCell className={classes.border1}>
                                {item.name}
                                <Typography
                                  variant="body1"
                                  sx={{ fontStyle: 'italic' }}
                                >
                                  {item.proposalNotes
                                    ? `(${item.proposalNotes})`
                                    : ''}
                                </Typography>
                                <Typography variant="body1">
                                  {item.proposalDescription
                                    ? `- ${item.proposalDescription}`
                                    : ''}
                                </Typography>
                              </TableCell>
                              {(!printView ||
                                (printView && rmsProposal.bronze)) && (
                                <TableCell
                                  className={classes.border1}
                                  align="center"
                                  colSpan={3}
                                >
                                  Service Not Available
                                </TableCell>
                              )}
                              {(!printView ||
                                (printView && rmsProposal.silver)) && (
                                <TableCell
                                  className={classes.border1}
                                  align="center"
                                  colSpan={3}
                                >
                                  Service Not Available
                                </TableCell>
                              )}
                              {(!printView ||
                                (printView && rmsProposal.gold)) && (
                                <TableCell
                                  className={classes.border1}
                                  align="center"
                                  colSpan={3}
                                >
                                  Service Not Available
                                </TableCell>
                              )}
                            </TableRow>
                          )) ?? null}
                      </React.Fragment>
                    );
                  })}
                  {categoriesLength > 1 && (
                    <TableRow>
                      <TableCell
                        className={classes.border1}
                        colSpan={7}
                        align="left"
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {`${site?.siteName ?? ''} - Total`}
                        </Typography>
                      </TableCell>
                      <TableCell
                        className={classes.border1}
                        align="right"
                        colSpan={3}
                      >
                        <Typography
                          variant="body1"
                          className={classes.category}
                        >
                          {formatCurrency(site?.totalSellPrice ?? 0)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              ))}
            </TableBody>

            <TableBody>
              {((printView && rmsProposal.includeMonthlySubtotals) ||
                !printView) && (
                <TableRow className={classes.border2}>
                  <TableCell className={classes.border1}>
                    {!printView ? (
                      <FormCheckbox
                        styles={{ margin: '0 auto' }}
                        name="includeMonthlySubtotals"
                        label="Monthly Cost Subtotal(s)"
                        setFormData={updateProposalData}
                        formData={rmsProposal}
                      />
                    ) : (
                      <Typography variant="h6">
                        Monthly Cost Subtotal(s)
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ textAlign: 'right' }}
                  >
                    {formatCurrency(bronzeTotal)}
                  </TableCell>
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ textAlign: 'right' }}
                  >
                    {formatCurrency(silverTotal)}
                  </TableCell>
                  <TableCell
                    className={classes.border1}
                    colSpan={3}
                    sx={{ textAlign: 'right' }}
                  >
                    {formatCurrency(goldTotal)}
                  </TableCell>
                </TableRow>
              )}
              {((printView && rmsProposal.includeMonthlyTotal) ||
                !printView) && (
                <TableRow className={classes.border2}>
                  <TableCell className={classes.border1}>
                    {!printView ? (
                      <FormCheckbox
                        styles={{ margin: '0 auto' }}
                        name="includeMonthlyTotal"
                        label="Total Monthly Cost"
                        setFormData={updateProposalData}
                        formData={rmsProposal}
                      />
                    ) : (
                      <Typography variant="h6">Total Monthly Cost</Typography>
                    )}
                  </TableCell>
                  <TableCell
                    className={classes.border1}
                    colSpan={9}
                    sx={{ textAlign: 'right' }}
                  >
                    {formatCurrency(totalMonthlyCharges)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      
    </Grid>
  );
};

export default TierRMSProposalTemplate;
