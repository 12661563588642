import { Task } from '@cdw-selline/common/types';

export const getCategoryIdByName = (
  category: string,
  tasks: Task[]
): string | null => {
  if (!category) return null;
  const taskWithCategory = tasks.find((task) => task.category === category);
  return taskWithCategory?.category ?? null;
};
