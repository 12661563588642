import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useOpenState } from '@cdw-selline/ui/hooks';
import { useMutation } from '@apollo/client';
import { UpdateProjectResponse } from '@cdw-selline/common/types';
import {
  GET_PROJECT_QUERY,
  REMOVE_PROJECT_TEAM_MEMBER_MUTATION,
  UPDATE_PROJECT_TEAM_MEMBER_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useApolloErrors } from '../useApolloErrors';
import { opportunityTeamTypes } from '@cdw-selline/ui/constants';
import { omit } from 'lodash';

const opportunityTeamTypesOptions = opportunityTeamTypes.map((type) => type);

const columns: GridColumns = [
  {
    field: 'name',
    headerName: 'Team Member',
    type: 'string',
    editable: false,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Role',
    type: 'singleSelect',
    valueOptions: opportunityTeamTypesOptions,
    editable: true,
    flex: 1,
  },
];

export const useUserAccess = (project) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const [
    removeProjectTeamMember,
    { loading: updateProjectLoading, error: updateProjectError },
  ] = useMutation<UpdateProjectResponse>(REMOVE_PROJECT_TEAM_MEMBER_MUTATION, {
    refetchQueries: [GET_PROJECT_QUERY],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project',
        });
      }
    },
  });

  const [
    updateProjectTeamMember,
    { loading: updateLoading, error: updateError },
  ] = useMutation<UpdateProjectResponse>(UPDATE_PROJECT_TEAM_MEMBER_MUTATION, {
    refetchQueries: [GET_PROJECT_QUERY],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Team',
        });
      }
    },
  });

  const rows = project?.projectTeam
    .filter((pt) => pt.email !== null)
    .map((p, i) => ({
      id: p.email,
      email: p.email,
      type: p.type,
      name: p.name || p.email,
      projectId: project.id,
    }));

  const {
    isOpen: userAccessFormOpen,
    handleClose: closeUserAccessForm,
    handleOpen: openUserAccessForm,
  } = useOpenState();

  const handleUserAccessFormClose = () => {
    closeUserAccessForm();
  };

  const handleRemoveUser = (rowData) => {
    removeProjectTeamMember({
      variables: {
        email: rowData.email,
        projectId: rowData.projectId,
      },
    });
  };

  const handleRowEditSave = (rowData) => {
    updateProjectTeamMember({
      variables: {
        projectId: rowData.projectId,
        projectTeam: omit(rowData, ['name', 'id', 'projectId']),
      },
    });
  };

  const handleSave = (projectTeam) => {
    updateProjectTeamMember({
      variables: {
        projectId: project.id,
        projectTeam: projectTeam,
      },
    });
  };

  return {
    columns,
    rows: rows ?? ([] as GridRowsProp),
    userAccessFormOpen,
    handleUserAccessFormClose,
    openUserAccessForm,
    handleRemoveUser,
    handleRowEditSave,
    handleSave,
  };
};
