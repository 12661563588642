import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '../styles.css';

import { ApolloProvider } from '@apollo/client/react';
import { client } from '@cdw-selline/ui/apollo-client';
import { ErrorFallback } from '@cdw-selline/ui/components';
import { BYPASS_AUTH } from '@cdw-selline/ui/constants';
import {
  AlertsOverlay,
  AppBaseContainer,
  LoginPageAdmin,
} from '@cdw-selline/ui/pages';
import {
  getStashedRoute,
  setStashedRoute,
  useUserState,
  userState,
} from '@cdw-selline/ui/state';
import { getMuiTheme } from '@cdw-selline/ui/theme';
import { useState } from '@hookstate/core';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FunctionComponent } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RouteProps } from 'react-router';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useLocation,
} from 'react-router-dom';

import { useApp } from './useApp';

export interface AppLayoutProps {
  title?: string;
  menu?: {
    icon?: (props: SvgIconProps) => JSX.Element;
    label?: string | number;
    path?: string;
  }[];
}
export const App: FunctionComponent<AppLayoutProps> = () => {
  const [theme, setTheme] = React.useState<'light' | 'dark'>(
    localStorage.darkMode === 'true' ? 'dark' : 'light'
  ); // TODO Attach to user state & browser settings
  const { routes, menu } = useApp();
  const loggedIn = useState(userState?.loggedIn);
  const isLoggedIn = BYPASS_AUTH || loggedIn?.value;

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
        window.location.reload();
      }}
    >
      <ThemeProvider theme={getMuiTheme(theme)}>
        <ApolloProvider client={client}>
          <CssBaseline />
          <BrowserRouter>
            <AppBaseContainer
              theme={theme}
              setTheme={setTheme}
              isLoggedIn={isLoggedIn}
              menus={menu}
            >
              <Switch>
                <Route
                  path="/sign-in"
                  exact
                  render={({ location }) =>
                    isLoggedIn ? (
                      <Redirect to={redirectTo(location)} />
                    ) : (
                      <LoginPageAdmin />
                    )
                  }
                />
                {routes.map(({ path, component: Component }, idx) => (
                  <LoggedInRoute
                    exact
                    path={path}
                    key={`route-${path}-${idx}`}
                    children={<Component />}
                  />
                ))}
              </Switch>
            </AppBaseContainer>
            <AlertsOverlay />
          </BrowserRouter>
        </ApolloProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default App;

const REDIRECT_ROUTES = new Map<string, string>([
  ['/sign-in', '/'],
  ['/sign-out', '/'],
  ['sign-in', '/'],
  ['sign-out', '/'],
  ['404', '/'],
]);

function redirectTo(location: RouteProps<never>['location']) {
  return (
    getStashedRoute(true) || {
      pathname: '/',
      state: { from: location },
    }
  );
}

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function LoggedInRoute({ children, ...rest }: RouteProps): JSX.Element {
  const userState = useUserState();
  const loggedIn = useState(userState?.loggedIn);
  const isLoggedIn = BYPASS_AUTH || loggedIn?.value;
  const { pathname, search, state, hash, key } = useLocation();

  if (!isLoggedIn) {
    const matchRoute = pathname.split('/')[1];
    if (REDIRECT_ROUTES.has(matchRoute)) {
      setStashedRoute({
        pathname: REDIRECT_ROUTES.get(matchRoute) || '/',
        search: '',
        hash: '',
        state,
      });
    }
    setStashedRoute({
      pathname,
      search,
      state,
      hash,
      key,
    });
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/sign-in',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}
