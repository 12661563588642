import { CiscoAnnuity } from '@cdw-selline/common/types';
import {
  CiscoAnnuityQuoteLine,
  CiscoAnnuityQuoteLineTotal,
  CiscoAnnuityQuoteSettings,
  CiscoAnnuityQuoteSummaryTable,
} from '@cdw-selline/ui/components';
import {
  useCiscoAnnuity,
  useCiscoAnnuityLines,
  useOpenState,
  useCiscoAnnuityLineState
} from '@cdw-selline/ui/hooks';
import {
  Backdrop,
  Grid,
  Paper,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  FormControlLabel,
  Switch,
  Checkbox,
  Button,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CDWFallback from '../../cdw-fallback/CDWFallback';
import { CiscoAnnuityQuoteInfo } from './CiscoAnnuityQuoteInfo';
import { CiscoAnnuityServiceFeesTable } from './CiscoAnnuityServiceFees';
import { CiscoAnnuityQuotePaymentSchedule } from './CiscoAnnuityQuotePaymentSchedule';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getCiscoAnnuityActiveQuote } from '@cdw-selline/ui/helpers';
import { useHistory } from 'react-router-dom';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { AdminCiscoAnnuityLines } from '@cdw-selline/ui/components';
import { round } from 'lodash';

enum AUDIO_CATEGORIES {
  'local_audio',
  'audio',
  'broadcast_audio',
  'additional_usage_fees'
}

const headerCaptions = {
  cost: 'COST',
  product_cost: 'ONE TIME COST',
  monthly_audio_service_fee: 'MONTHLY AUDIO SERVICE FEE',
  credit: 'CREDIT',
  totals: 'TOTALS',
  local_audio: 'LOCAL AUDIO',
  audio: 'AUDIO',
  broadcast_audio: 'BROADCAST AUDIO',
  cisco_calling_plan: 'CISCO CALLING PLAN TN RATES',
  webex_work_uncommitted_usage: 'WEBEX WORK UNCOMMITTED USAGE',
  webex_xm_data_upload_usage: 'WEBEX EXPERIENCE MANAGMENT DATA UPLOAD USAGE',
  additional_usage_fees: 'ADDITIONAL USAGE FEES',
  overage: 'OVERAGES, billed monthly unless otherwise noted',
  assist: 'WEBEX ASSIST (PER ASSIST)',
  included: 'INCLUDED ITEMS',
};

export interface CiscoAnnuityQuoteProps {
  id: string;
  projectItemId: string;
  ciscoAnnuity: CiscoAnnuity;
  loading?: boolean;
  isReadOnly?: boolean;
  userIsCloudFulfillment?: boolean;
}

export function CiscoAnnuityQuote({
  id,
  projectItemId,
  ciscoAnnuity,
  isReadOnly,
  userIsCloudFulfillment,
}: CiscoAnnuityQuoteProps) {
  const [isAdminView, setIsAdminView] = useState(false);
  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);
  let activeQuoteBillingCycle = activeQuote?.billingCycle ?? '';
  if ( activeQuoteBillingCycle.toLocaleLowerCase() == 'annual' && round(activeQuote.term, 2) < 12 ) {
    activeQuoteBillingCycle = 'Remaining Term';
  }
  const [allIncludeInQuote, setAllIncludeInQuote] = useState(true);
  const [allProductCostsIncludeInQuote, setAllProductCostsIncludeInQuote] = useState(true);
  const { data, loading, error, refetch } = useCiscoAnnuityLines({
    projectItemId,
  });
  const [lines, setLines] = useState(data.lines || []);
  const [lineToRemove, setLineToRemove] = useState('');
  const { handleRemoveCdwCredit } = useCiscoAnnuityLineState();

  useEffect(()=> {
    if (!loading) setLines(data.lines);
  }, [data.lines]);
  const {
    updateIncludeInQuoteById
  } = useCiscoAnnuity({
    projectItemId: projectItemId,
  });
  const router = useHistory();
  const currentUser = getCurrentUser();
  const notTitleCategories = ['cost', 'credit', 'totals', 'cdw-credit'];
  const filterCategoryItems = (category: string)=> {
    return lines
      .filter((l) => l.category === category)
      .sort((a, b)=>parseInt(a.line) > parseInt(b.line) ? 1 : -1)
      .sort((a, b)=>parseFloat(a.line) > parseFloat(b.line) ? 1 : -1)
      ;
  };

  const handleAdminView = (event) => {
    event.stopPropagation();

    if(isAdminView) {
      refetch();
    }

    setIsAdminView(!isAdminView);
  }

  const {
    isOpen: dialogConfirmOpen,
    handleClose: handleDialogConfirmClose,
    handleOpen: handleDialogConfirmOpen,
  } = useOpenState();

  const handleDialogRemoveLine = (id: string) => {
    setLineToRemove(id);
    handleDialogConfirmOpen();
  }

  const removeQuoteLine = () => {
    if (lineToRemove) {
      handleRemoveCdwCredit(lineToRemove);
    }
    handleDialogConfirmClose();
    setLineToRemove('');
  }

  const handleToggleIncludeInQuote = () => {
    const newAllIncludeInQuote = !allIncludeInQuote;

    updateIncludeInQuoteById({
      variables: {
        quoteId: activeQuote.id,
        includeInQuote: newAllIncludeInQuote
      },
    });

    setAllIncludeInQuote(newAllIncludeInQuote);
  }

  const handleToggleProductCostsIncludeInQuote = () => {
    const newAllIncludeInQuote = !allProductCostsIncludeInQuote;

    updateIncludeInQuoteById({
      variables: {
        quoteId: activeQuote.id,
        includeInQuote: newAllIncludeInQuote,
        category: 'product_cost'
      },
    });

    setAllProductCostsIncludeInQuote(newAllIncludeInQuote);
  }

  const handleAddCDWCredit = ()=> {
    setLines([
      ...lines,
      {
        includeInQuote: true,
        quoteId: activeQuote.id,
        quantity: 1,
        category: 'cdw-credit',
        partNumber: 'Credit',
        description: 'CDW Credit (Initial Term Only)',
      }]);
  }

  //TODO: Dont render header when totals are 0
  const getCategoryItems = (category: string) => {
    const filteredCategoryItems = filterCategoryItems(category);
    if (filteredCategoryItems.length > 0) {
      return (
        <>
          {!notTitleCategories.includes(category) && (
            <TableRow key={'header'}>
              <TableCell
                colSpan={15}
                sx={{
                  color: 'white',
                  backgroundColor: 'gray',
                  fontWeight: 'bold',
                }}
              >
                {category === 'product_cost' && (
                  <Checkbox
                    name="allIncludeInQuote"
                    checked={allProductCostsIncludeInQuote}
                    onChange={handleToggleProductCostsIncludeInQuote}
                    title="Update all One Time Costs."
                    tabIndex={-1}
                    disabled={isReadOnly}
                  />
                )}
                {headerCaptions[category]}
              </TableCell>
            </TableRow>
          )}

          {category !== 'totals'
            ? filterCategoryItems(category).map((ciscoAnnuityLine, index) => (
                <CiscoAnnuityQuoteLine
                  key={`${index}-${ciscoAnnuityLine.id ?? 0}`}
                  ciscoAnnuityLine={ciscoAnnuityLine}
                  isEstimate={activeQuote.isEstimate}
                  allowCdwDiscountEdit={false}
                  allowCustomerDiscountEdit={true}
                  showDiscount={
                    ![
                      'included',
                      'cdw-credit',
                      'monthly_audio_service_fee',
                    ].includes(category)
                  }
                  isReadOnly={isReadOnly}
                  handleDialogRemoveOpen={()=>handleDialogRemoveLine(ciscoAnnuityLine.id)}
                />
              ))
            : filterCategoryItems(category).map(
                (ciscoAnnuityLine, index) =>
                  ciscoAnnuityLine.customerPrice > 0 && (
                    <CiscoAnnuityQuoteLineTotal
                      key={`${index}-${ciscoAnnuityLine.id ?? 0}`}
                      ciscoAnnuityLine={ciscoAnnuityLine}
                    />
                  )
              )}
        </>
      );
    }
  };

  if (loading) {
    return (
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer - 1,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
        open
        data-testid="progressbar"
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Grid
            xs={12}
            item
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
            }}
          >
            <Typography> Loading Annuity Lines...</Typography>
          </Grid>
          <Grid item xs={12}>
            <CDWFallback />
          </Grid>
        </Stack>
      </Backdrop>
    );
  }

  if (error) return <Typography>Error loading Annuity Lines</Typography>;
  const customerTotalPrice =
    data.lines.find((l) => l.category === 'totals')?.customerTotalPrice ?? 0;

  const ciscoServiceFees = {
    customerPrice: customerTotalPrice ?? 0,
    customerPriceTermTotal: data.quoteSummary?.customerPriceInitial ?? 0,
    gp: data.quoteSummary?.gpRenewal ?? 0,
    gpTermTotal: data.quoteSummary?.gpInitial ?? 0,
  };
  return (
    <Grid container>
      <Grid item xs={12} mb={2}>
        <Grid container>
          <Grid item xs={6} mb={2}>
            <Grid container spacing={2}>
              <Grid item xs="auto" mb={2}>
                <CiscoAnnuityQuoteSettings
                  ciscoAnnuity={ciscoAnnuity}
                  isReadOnly={isReadOnly}
                  canRemoveBidAdders={
                    userIsCloudFulfillment || currentUser.admin
                  }
                />
              </Grid>
              <Grid item xs="auto" mb={2}>
                <CiscoAnnuityQuoteSummaryTable
                  summary={data.quoteSummary}
                  term={activeQuote.term}
                  autoRenew={activeQuote.autoRenew}
                  removeBidAdders={activeQuote.removeBidAdders}
                />
              </Grid>
              <Grid item xs={6} mb={2}>
                {activeQuote.billingCycle !== 'Prepaid' && data.serviceFeeSchedule.length> 0 && (
                  <CiscoAnnuityServiceFeesTable
                    serviceFeeSchedule={data.serviceFeeSchedule}
                    billingCycle={activeQuote.billingCycle}
                    loading={loading}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} mb={2}>
            <Grid container justifyContent="flex-end">
              <Grid item xs="auto" mb={2}>
                <CiscoAnnuityQuoteInfo
                  ciscoAnnuity={ciscoAnnuity}
                  isReadOnly={isReadOnly}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} mb={2}>
        {data.paymentSchedule && !activeQuote.hideMonthlyFeeBreakdown && (
          <CiscoAnnuityQuotePaymentSchedule
            paymentSchedule={data.paymentSchedule}
            terms={data.paymentSchedule[0].values.length}
          />
        )}
      </Grid>
      <Grid item xs={12} mb={2}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell colSpan={currentUser.admin ? 14 : 15} align="right">
                  <Button onClick={handleAddCDWCredit}>Add CDW Credit</Button>
                </TableCell>
                {currentUser.admin && (
                  <TableCell align="right">
                    <FormControlLabel
                      control={
                        <Switch
                          value={isAdminView}
                          onChange={(e) => handleAdminView(e)}
                        />
                      }
                      label="Admin View"
                    />
                  </TableCell>
                )}
              </TableRow>
              {!isAdminView && (
                <TableRow>
                  <TableCell>
                    <Checkbox
                      name="allIncludeInQuote"
                      checked={allIncludeInQuote}
                      onChange={handleToggleIncludeInQuote}
                      title="Update all lines."
                      tabIndex={-1}
                      disabled={isReadOnly}
                    />
                  </TableCell>
                  <TableCell>Line</TableCell>
                  <TableCell>Part Number</TableCell>
                  <TableCell>Cloud Services Description</TableCell>
                  <TableCell>Unit Quantity</TableCell>
                  <TableCell>Monthly List Price</TableCell>
                  <TableCell>{activeQuoteBillingCycle} Unit Fee</TableCell>
                  <TableCell>UOM</TableCell>
                  <TableCell>
                    {activeQuoteBillingCycle} Total Services Fee
                  </TableCell>
                  <TableCell>
                    Initial Subscription Term Total Services Fee
                  </TableCell>
                  <TableCell>{activeQuoteBillingCycle} Unit Cost</TableCell>
                  <TableCell>{activeQuoteBillingCycle} Total Cost</TableCell>
                  <TableCell>{activeQuote.billingCycle} GP</TableCell>
                  <TableCell align="right" width="5%">
                    Cust Disc(%)
                  </TableCell>
                  <TableCell align="right" width="5%">
                    CDW Disc(%)
                  </TableCell>
                </TableRow>
              )}
            </TableHead>
            {isAdminView && (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={13}>
                    <AdminCiscoAnnuityLines
                      quoteId={activeQuote.id}
                    ></AdminCiscoAnnuityLines>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
            {!isAdminView && (
              <TableBody>
                {getCategoryItems('cost')}
                {getCategoryItems('monthly_audio_service_fee')}
                {getCategoryItems('credit')}
                {getCategoryItems('cdw-credit')}
                {getCategoryItems('totals')}
                {getCategoryItems('product_cost')}
                {getCategoryItems('local_audio')}
                {getCategoryItems('audio')}
                {getCategoryItems('broadcast_audio')}
                {getCategoryItems('cisco_calling_plan')}
                {getCategoryItems('webex_work_uncommitted_usage')}
                {getCategoryItems('webex_xm_data_upload_usage')}
                {getCategoryItems('additional_usage_fees')}
                {getCategoryItems('overage')}
                {getCategoryItems('assist')}
                {!activeQuote.hideIncludedSkus && getCategoryItems('included')}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Grid>
      {dialogConfirmOpen && <DialogConfirm
        title="This action will permamently remove this quote line. Are you sure?"
        isOpen={dialogConfirmOpen}
        handleClose={handleDialogConfirmClose}
        handleYes={removeQuoteLine}
      ></DialogConfirm>}
    </Grid>
  );
}