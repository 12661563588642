/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { usePsaBreakdownSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  Grid,
  Skeleton,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro';
import { useEffect, useRef, useState } from 'react';

export const PsaBreakdownSummary = () => {
  const { estimateToPsaResponse, error, loading, columns } =
    usePsaBreakdownSummary();
  const theme = useTheme();
  const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) =>
    row.hierarchy;

  const [gridHeight, setGridHeight] = useState(0);
  const gridRef = useRef(null);
  const [groupingExpansionDepth, setGroupingExpansionDepth] = useState<number>(-1);

  useEffect(() => {
    if (gridRef.current) {
      const observer = new ResizeObserver(entries => {
        for (const entry of entries) {
          setGridHeight(entry.contentRect.height);
        }
      });
      observer.observe(gridRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [gridRef]);

  useEffect(() => {
    const savedDepth = localStorage.getItem('defaultGroupingExpansionDepth');
    const depth = isNaN(parseInt(savedDepth ?? '-1', 10)) ? -1 : parseInt(savedDepth, 10);
    setGroupingExpansionDepth(depth);
  }, []);

  const handleStateChange = (params) => {
    const newDepth = Object.values(params.visibleRowsLookup).every(v => v === true) ? -1 : 0;
    localStorage.setItem('defaultGroupingExpansionDepth', newDepth.toString());
  };

  return (
    <Grid item lg={6} sm={12} xl={6} xs={12}>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card>
              <CardHeader
                title="PSA Breakdown Summary"
                sx={{
                  backgroundColor: theme.palette.primary.dark,
                  padding: '5px 15px',
                }}
                titleTypographyProps={{
                  variant: 'h6',
                  color: theme.palette.common.white,
                }}
              />
              <Divider />
              <Box sx={{ width: '100%' }}>
                {loading && <Skeleton />}

                {!error?.message && !loading && (
                  <DataGridPro
                    treeData
                    hideFooterRowCount
                    hideFooterSelectedRowCount
                    defaultGroupingExpansionDepth={groupingExpansionDepth}
                    getTreeDataPath={getTreeDataPath}
                    rows={estimateToPsaResponse}
                    columns={columns}
                    getRowId={(row) => row.id}
                    groupingColDef={(params) => {
                      return {
                        headerName: 'Name',
                        hideDescendantCount: true,
                        flex: 2,
                      };
                    }}
                    autoHeight
                    onStateChange={handleStateChange}
                  />
                )}

                {!loading && error?.message && (
                  <Box sx={{ padding: '10px' }}>
                    {error.message.split('\n').map((error, index) => (
                      <Typography key={index} color="error" variant="h6">
                        {error}
                      </Typography>
                    ))}
                    <Typography color="error" variant="caption">
                      If you don't believe you should be getting these errors
                      and you are unsure how to resolve them, please click the
                      provide feedback below for assistance.
                    </Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
};
export default PsaBreakdownSummary;
