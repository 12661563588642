/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ROUTES } from '@cdw-selline/ui/constants';
import { useCustomers } from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getCurrentUser } from '@cdw-selline/ui/state';

export interface CustomerListProps {
  routeTo: (id: string) => void;
  routeToArbitraryRoute: (route: string) => void;
}
export const CustomerList: React.FC<CustomerListProps> = (props) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};

  const filter = {
    $or: [
      { projectTeam: { $elemMatch: { email: userEmail } } },
      { projectOwner: userEmail },
    ],
  };
  const { data, error, loading } = useCustomers({
    limit: 20,
    offset: 0,
    filters: filter,
  });

  return (
    <Card sx={{ height: 'calc(100vh - 10em)' }}>
      <CardHeader title="My Accounts" />
      <Divider />
      <PerfectScrollbar>
        <TableContainer sx={{ maxHeight: 'calc(92vh - 13em)' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer</TableCell>
                <TableCell
                  sx={{
                    width: '200',
                  }}
                >
                  SPS #
                </TableCell>
                <TableCell>Region</TableCell>
                <TableCell>Sector</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <Typography color="error" variant="h6">
                  {error}
                </Typography>
              )}
              {loading && (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!error &&
                !loading &&
                Array.isArray(data.customers) &&
                data.customers.map((d) => (
                  <TableRow hover key={d.id} style={{ cursor: 'pointer' }}>
                    <TableCell>{d.customerDescription}</TableCell>
                    <TableCell
                      sx={{
                        width: '200',
                      }}
                    >
                      {d.customerCode}
                    </TableCell>
                    <TableCell>{d.region}</TableCell>
                    <TableCell>{d.sector}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Button
          color="secondary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          onClick={() => props.routeToArbitraryRoute(ROUTES.CUSTOMERS)}
        >
          View all
        </Button>
      </Box>
    </Card>
  );
};

export default CustomerList;
