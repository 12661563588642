import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  SowSection,
} from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';
import {
  ADD_SOW_SECTION_MUTATION,
  GET_SOW_SECTIONS_QUERY,
  GET_SOW_SECTION_BY_ID_QUERY,
  REMOVE_ADMIN_SOW_SECTION_MUTATION,
  UPDATE_SOW_SECTION_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';
import { useSowSections } from './useSowSections';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'SowSection ID',
    width: 180,
    editable: false,
    hide: true,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'SowSection Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'createdOn',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(params?.value).format('MM/DD/YYYY'),
  },
];

export const useAdminSowSections = () => {
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [sortState, setSortState] = useState();
  const filterModel = JSON.parse(
    localStorage.getItem('SowSectionsCollectionPage-filter')
  );

  const { data, loading, error, refetch } = useSowSections({
    filters: getFilters('SowSectionsCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editSowSectionId, setEditSowSectionId] = useState(null);
  const {
    isOpen: sowSectionFormOpen,
    handleClose: closeSowSectionForm,
    handleOpen: openSowSectionForm,
  } = useOpenState();

  const [
    addSowSection,
    { loading: addSowSectionLoading, error: addSowSectionError },
  ] = useMutation<{ addSowSection: DefaultAddResponse }>(
    ADD_SOW_SECTION_MUTATION,
    {
      refetchQueries: [GET_SOW_SECTIONS_QUERY, GET_SOW_SECTION_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new sow section'),
      onCompleted: (data) => {
        if (data.addSowSection.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Sow Section',
          });
        }
      },
    }
  );

  const [
    removeSowSection,
    { loading: removeSowSectionLoading, error: removeSowSectionError },
  ] = useMutation<{ removeSowSection: DefaultMutationResponse }>(
    REMOVE_ADMIN_SOW_SECTION_MUTATION,
    {
      refetchQueries: [GET_SOW_SECTIONS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove sow section'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully removed sow section',
        });
      },
    }
  );

  const [
    updateSowSection,
    { loading: updateRoleLoading, error: updateRoleError },
  ] = useMutation<{ updateSowSection: DefaultMutationResponse }>(
    UPDATE_SOW_SECTION_MUTATION,
    {
      refetchQueries: [GET_SOW_SECTIONS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update sow section'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateSowSection.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated sow section',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openSowSectionForm();
  };

  const handleSowSectionFormClose = () => {
    setEditSowSectionId(null);
    closeSowSectionForm();
  };

  const handleEdit = (id: string) => {
    setEditSowSectionId(id);
    openSowSectionForm();
  };

  const handleDelete = (pid: string) =>
    removeSowSection({
      variables: { removeSowSectionId: pid },
    });

  const handleSowSectionFormSave = (sowSection: SowSection) => {
    if (!sowSection.id) {
      addSowSection({
        variables: {
          params: omitDeep(sowSection, ['__typename']),
        },
      });
    }

    if (sowSection.id) {
      updateSowSection({
        variables: {
          params: omitDeep(sowSection, ['__typename']),
        },
      });
    }
  };
  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: paginationState.offset,
      limit: paginationState.limit,
      filters: getFilters('SowSectionsCollectionPage'),
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('SowSectionsCollectionPage') });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
      filters: getFilters('SowSectionsCollectionPage'),
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data.sowSections || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageBack,
    onFilterModelChange,
    handlePageSizeChange,
    handleSort,
    filterModel: filterModel ?? {
      items: [{ columnField: 'name', operatorValue: 'contains', value: '' }],
    },
    sowSections: data.sowSections,
    editSowSectionId,
    addSowSectionLoading,
    addSowSectionError,
    removeSowSectionLoading,
    removeSowSectionError,
    loading: loading || addSowSectionLoading || removeSowSectionLoading,
    error,
    sowSectionFormOpen,
    handleSowSectionFormClose,
    handleSowSectionFormSave,
    rowCount: data.count,
    ...paginationState,
  };
};
