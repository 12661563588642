import { useEffect, useState } from 'react';

export const useCollectionPagination = (
  refetch: (options: { offset: number; limit: number }) => void
) => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageParams, setPageParams] = useState<{
    offset: number;
    limit: number;
  }>({
    offset: 0,
    limit: 100,
  });

  useEffect(() => {
    // TODO sessionStorage doesn't appear to be actually useful in existing tables, but including for sake of reflecting current behavior
    sessionStorage.page = currentPage;
    sessionStorage.offset = pageParams.offset;
    refetch(pageParams);
  }, [currentPage, pageParams, refetch]);

  const handlePageChange = (page: number) => {
    const newOffset =
      page > currentPage
        ? pageParams.offset + pageParams.limit
        : pageParams.offset - pageParams.limit;
    setPageParams((prev) => ({ ...prev, offset: newOffset }));
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size: number) => {
    setPageParams((prev) => ({ ...prev, limit: size }));
  };

  return {
    handlePageChange,
    handlePageSizeChange,
    page: currentPage,
    ...pageParams,
  };
};
