import { gql } from '@apollo/client';

export const GET_ESTIMATOR_CUSTOM_TASKS_BY_SITE_ID_QUERY = gql`
  query getEstimatorCustomTasksBySiteId($siteId: ID!) {
    getEstimatorCustomTasksBySiteId(siteId: $siteId) {
      id
      type
      costType
      name
      category
      hours
      quantity
      defaultCost
      practice
      managedServices
      isProjectManagement
      isTravelTime
      primaryRoleName
      primaryPercent
      primaryRoleRateOverride
      secondaryRoleName
      secondaryPercent
      secondaryRoleRateOverride
      overtime
      skill
      serviceItem
    }
  }
`;
