import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Divider,
  Grid,
  TextField,
  Typography,
  CircularProgress,
  Link,
  IconButton,
  Tooltip,
  MenuItem,
} from '@mui/material';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as EditorClass } from 'tinymce';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  FormText,
  FormCheckbox,
  RichTextEditorNoState,
  RMSProposalTable,
  FormSelect,
} from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import { useRmsProposal, useProjectItemEstimator } from '@cdw-selline/ui/hooks';
import PrintIcon from '@mui/icons-material/Print';
import { useProjects } from '@cdw-selline/ui/hooks';
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';

import { omit } from 'lodash';

const useStyles = makeStyles({
  sectionHeader: {
    backgroundColor: '#cc0000',
    padding: '5px',
    minHeight: '25px',
    borderTop: '10px solid #D3D3D3',
    borderBottom: '10px solid #D3D3D3',
    textTransform: 'uppercase',
  },
});


export interface RMSProposalTabProps {
  isReadOnly?: boolean;
}

export const RMSProposalTab = ({isReadOnly}: RMSProposalTabProps) => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();

  const { rmsProposal, rmsProposalLoading, updateRmsProposal } =
    useRmsProposal();
  const { data: estimator } = useProjectItemEstimator();

  const RMS_PROPOSAL_PRICING_NOTES = `
    <ol>
      <li contenteditable='false'>Please review the server and device types and quantities in the pricing detail section above. This is the list of servers, devices and/or infrastructure CDW assumes you are considering placing under CDW Managed Services. Please review this list for accuracy.</li>
      <li contenteditable='false'>The customer is responsible for the purchase and maintenance of manufacturer hardware and software support for servers and devices placed under CDW Managed Services. Delivery of certain aspects of CDW Managed Services assumes customer has a manufacturer maintenance contract.</li>
      <li contenteditable='false'>CDW’s Managed Services supports software versions that are supported by the manufacturer. If you have any servers or devices with software that is “End of Support” or “End of Life,” further discussion will be necessary and CDW support caveats may exist. Time and materials rates or other pricing modifications may apply.</li>
      <li contenteditable='false'>This is not a contract. Final pricing is subject to a technical review of your infrastructure and further discussion to validate your business, technical, financial and other requirements.</li>
      <li contenteditable='false'>Unit pricing assumes the quantities indicated in the pricing detail section of this document. Changes to unit pricing is subject to a signed change order by the customer.</li>
      <li contenteditable='false'>Pricing assumes a <span>${rmsProposal.term}</span>-month term.
      <li contenteditable='false'>Pricing is valid for 30 days from the date on the cover page.</li>
      <li contenteditable='false'>Billing is based on the quantity of units under service each month, subject to any agreed upon monthly minimum. If the customer does not meet the minimum quantity of users then seller reserves the right to adjust prices once annually and will be documented in a change order.</li>
    </ol>`;

  const { data: projectsData } = useProjects({ id });

  const setFormData = (updatedValue) => {
    updateRmsProposal({
      variables: {
        params: {
          rmsProposal: omit(updatedValue, ['__typename']),
          projectItemId: projectItemId,
        },
      },
    });
  };

  const [editorState, setEditorState] = useState(
    rmsProposal?.pricingNotes ?? '<ol><li></li></ol>'
  );

  useEffect(() => {
    setEditorState(rmsProposal?.pricingNotes ?? '<ol><li></li></ol>');
  }, [rmsProposal?.pricingNotes]);

  const handleEditorChange = (value) => {
    const rmsProposalWithNotes = { ...rmsProposal, ...value };
    setFormData(rmsProposalWithNotes);
  };

  const debouncedHandleTextInputChange = useCallback(
    debounce((e) => handleEditorChange(e), 1000),
    [rmsProposal]
  );

  const date = new Date();
  const formattedDate = date.toDateString();

  const handlePrintClick = () => {
    window.open(
      `${ROUTES.PROJECT}/${id}/estimator/${projectItemId}/proposalPrint`
    );
  };

  const termOptions = [
    { value: 12, label: '12 Months' },
    { value: 24, label: '24 Months' },
    { value: 36, label: '36 Months' },
    { value: 60, label: '60 Months' },
  ];

  if (rmsProposalLoading) return <CircularProgress />;

  return (
    <Box sx={{ mt: 2, p: 2 }}>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Typography variant="h6">Primary Contact</Typography>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="primaryName"
              label="Primary Name"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="primaryTitle"
              label="Primary Title"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="primaryOrg"
              label="Primary Org"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="primaryPhone"
              label="Primary Phone"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="primaryEmail"
              label="Primary Email"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="h6">Alternative Contact</Typography>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="altName"
              label="Alternative Name"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="altTitle"
              label="Alternative Title"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="altOrg"
              label="Alternative Org"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="altPhone"
              label="Alternative Phone"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <FormText
              name="altEmail"
              label="Alternative Email"
              setFormData={setFormData}
              formData={rmsProposal}
              disabled = {isReadOnly}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{ mt: 1 }}>
        <Grid item xs={6}>
          <FormCheckbox
            name="showReadinessVerbage"
            label="Show Readiness Assessment Verbiage"
            setFormData={setFormData}
            formData={rmsProposal}
            disabled = {isReadOnly}
          />
          <FormCheckbox
            name="showOnboardingVerbage"
            label="Show Onboarding Verbiage"
            setFormData={setFormData}
            formData={rmsProposal}
            disabled = {isReadOnly}
          />
          <Grid container>
            <Grid item xs={6}>
              <FormText
                type="number"
                name="priceOverride"
                label="Onboarding Price Override"
                setFormData={setFormData}
                formData={rmsProposal}
                sx={{ width: '50%' }}
                disabled = {isReadOnly}
              />
            </Grid>
            <Grid item xs={6}></Grid>
          </Grid>
        </Grid>
      </Grid>

      <Divider sx={{ mt: 2 }} />

      <Grid container spacing={3} justifyContent={'space-between'}>
        <Grid item xs={4}>
          <FormCheckbox
            name="isTierTemplate"
            label="Show Tier View"
            setFormData={setFormData}
            formData={rmsProposal}
            disabled = {isReadOnly}
          />
        </Grid>
        <Grid item xs={1} textAlign="right">
          <Tooltip title="Print" sx={{ float: 'right' }}>
            <IconButton
              sx={{ marginRight: '.25em' }}
              data-testid={`proposal-print-view`}
              onClick={() => handlePrintClick()}
              size="large"
            >
              <PrintIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Box sx={{ mt: 2, border: '1px solid #000000' }}>
        <Box
          sx={{
            backgroundColor: '#cc0000',
            padding: '5px',
            minHeight: '25px',
          }}
        />
        <Grid container spacing={3} sx={{ padding: '15px' }}>
          <Grid item xs={3}>
            {/* <span>left logo</span> */}
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Typography variant="h3">
              Managed Services Budgetary Quote
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {/* <span>right logo</span> */}
          </Grid>
        </Grid>
        <Grid container spacing={3} sx={{ padding: '15px' }}>
          <Grid item xs={8}>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                Date:
              </Typography>{' '}
              {formattedDate}
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                To:
              </Typography>{' '}
              {projectsData.customerName}
            </Box>

            <Box sx={{ mb: 1 }}>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                From:
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                Project:
              </Typography>{' '}
              {projectsData.name}
            </Box>
            <Box sx={{ mb: 1 }}>
              <Typography
                variant="body1"
                component="span"
                sx={{ fontWeight: 'bold' }}
              >
                CDW Office:
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {!rmsProposalLoading && (
        <RMSProposalTable
          isTierTemplate={rmsProposal.isTierTemplate}
          updateProposalData={setFormData}
          rmsProposal={rmsProposal}
        />
      )}
     
      <Box sx={{ mt: 1, mb: 1 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              Time and Materials Support - Managed Services Engineering and
              Consulting - $ 240.00 per Hour
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ border: '1px solid #000000' }}>
        <Box className={classes.sectionHeader}>
          <Typography variant="h6" color="#FFFFFF">
            Pricing Notes:
          </Typography>
        </Box>
        <Grid container spacing={3} sx={{ padding: '15px' }}>
          <Grid item xs={12}>
            <Editor
              apiKey={process.env.NX_TINY_MCE_API_KEY}
              init={{
                menubar: false,
                toolbar: false,
                plugins: 'autoresize',
                autoresize_bottom_margin: 0,
                autoresize_min_height: 100,
                autoresize_max_height: 500,
              }}
              value={RMS_PROPOSAL_PRICING_NOTES}
              disabled
            />
            <FormSelect
              name="term"
              label="Proposal Term"
              setFormData={setFormData}
              formData={rmsProposal}
              width="200px"
              disabled = {isReadOnly}
            >
              {termOptions.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  disabled={
                    option.value < estimator.managedServicesContractLength
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </FormSelect>
            <RichTextEditorNoState
              documentId="pricingNotes"
              editorState={editorState}
              setCurrentValue={setEditorState}
              handleChange={debouncedHandleTextInputChange}
              customEditorOptions={{
                plugins: ['autoresize'],
                autoresize_bottom_margin: 0,
                autoresize_min_height: 100,
                autoresize_max_height: 500,
              }}
              disabled = {isReadOnly}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RMSProposalTab;
