import '@cdw-selline/ui/theme';
import {
  DashboardCardsContainer,
  ProjectsKanban,
} from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import { useHealthCheck, useNavTabs } from '@cdw-selline/ui/hooks';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { ActivityTab, PipelinesTab } from '../..';
import LeadsTab from '../leads-tab/LeadsTab';
import CustomersTab from '../customers-tab/CustomersTab';
import type { Opportunity } from '@cdw-selline/common/types';
import { themeGlobals } from '@cdw-selline/ui/theme';

/* eslint-disable-next-line */
export interface DashboardProps {}

export const Dashboard = (props: DashboardProps) => {
  useHealthCheck();
  const {
    projectModalOpen,
    handleProjectClick,
    setProjectModalOpen,
    handleProjectSubmit,
    createProjectLoading,
    leadModalOpen,
    handleLeadClick,
    setLeadModalOpen,
    handleLeadSubmit,
    createLeadLoading,
  } = useNavTabs();

  const router = useHistory();

  const routeTo = (id: string) => {
    router.push(`/project/${id}`);
  };

  const routeToArbitraryRoute = (route: string) => {
    router.push(route);
  };

  return (
    <BrowserRouter>
      <Box sx={{ ...themeGlobals.containerMargins }}>
        <Switch>
          <Route exact path={ROUTES.HOME}>
            <DashboardCardsContainer
              routeTo={routeTo}
              routeToArbitraryRoute={routeToArbitraryRoute}
              createProject={handleProjectClick}
              projectModalOpen={projectModalOpen}
              setProjectModalOpen={setProjectModalOpen}
              handleProjectSubmit={handleProjectSubmit}
              createProjectLoading={createProjectLoading}
              createLead={handleLeadClick}
              leadModalOpen={leadModalOpen}
              setLeadModalOpen={setLeadModalOpen}
              handleLeadSubmit={handleLeadSubmit}
              createLeadLoading={createLeadLoading}
            />
          </Route>
          <Route exact path={ROUTES.DASHBOARD}>
            <DashboardCardsContainer
              routeTo={routeTo}
              routeToArbitraryRoute={routeToArbitraryRoute}
              createProject={handleProjectClick}
              projectModalOpen={projectModalOpen}
              setProjectModalOpen={setProjectModalOpen}
              handleProjectSubmit={handleProjectSubmit}
              createProjectLoading={createProjectLoading}
              createLead={handleLeadClick}
              leadModalOpen={leadModalOpen}
              setLeadModalOpen={setLeadModalOpen}
              handleLeadSubmit={handleLeadSubmit}
              createLeadLoading={createLeadLoading}
            />
          </Route>
          <Route exact path={ROUTES.PIPELINES}>
            <PipelinesTab routeTo={routeTo} />
          </Route>
          <Route exact path={ROUTES.LEADS}>
            <LeadsTab />
          </Route>
          <Route exact path={ROUTES.CUSTOMERS}>
            <CustomersTab />
          </Route>
        </Switch>
      </Box>
    </BrowserRouter>
  );
};

export default Dashboard;
