import React from 'react';
// import '../styles.css';
import { createStyles, makeStyles } from '@mui/styles';
import { Typography, Grid } from '@mui/material';
import { RmsProposal } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CdwSvgLogo, PreparedBy } from '@cdw-selline/ui/components';

interface CoverPageProps {
  date: string;
  customerName: string;
  rmsProposal: RmsProposal;
}
const useStyles = makeStyles(() =>
  createStyles({
    logo: {
      width: '130px',
      position: 'absolute', 
      top: 0
    },
  })
);

export const CoverPage = ({
  date,
  customerName,
  rmsProposal,
}: CoverPageProps) => {
  const classes =useStyles()
  return (
    <Grid container alignItems="center" style={{ height: '100vh' }}>
      <CdwSvgLogo className={classes.logo}/>
      <Grid style={{ width: '100%' }}>
        <Grid></Grid>
        <Grid style={{ textAlign: 'center' }}>
          <Typography
            variant="h2"
            sx={{ paddingBottom: '60px', fontWeight: 'bold' }}
          >
            CDW Managed Services Pricing Detail
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            Prepared For
          </Typography>
          <Typography variant="h5" sx={{ mt: 1, paddingBottom: '100px' }}>
            {customerName}
          </Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              style={{
                border: '#000000 solid 1px',
                padding: '8px',
                textAlign: 'center',
              }}
            >
              This document is a detail of the costs and an overview of terms of
              service for CDW Managed Services. For a description of the
              proposed services and all other non-cost detail, please refer to
              the 'Service Catalog,' 'Solution Detail' and/or other materials
              that may accompany this document.
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Prepared By</Typography>
              </Grid>
              <Grid item xs={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <PreparedBy
                      name={rmsProposal.primaryName}
                      title={rmsProposal.primaryTitle}
                      organization={rmsProposal.primaryOrg}
                      phone={rmsProposal.primaryPhone}
                      email={rmsProposal.primaryEmail}
                    />
                  </Grid>
                  <Grid item xs={12}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                <PreparedBy
                  name={rmsProposal.altName}
                  title={rmsProposal.altTitle}
                  organization={rmsProposal.altOrg}
                  phone={rmsProposal.altPhone}
                  email={rmsProposal.altEmail}
                />
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid item xs={9}>
                <Typography variant="body1">Date: {date}</Typography>
                <Typography variant="body1">
                  The pricing and terms in this document are valid for 30 days
                  from the above date
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ pageBreakAfter: 'always' }}></Grid>
    </Grid>
  );
};

export default CoverPage;
