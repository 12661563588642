import * as React from 'react';
import Logo from './logo.png';
interface AgiloftLogoProps {
    height?: string
    className?: string,
    onClick?: any
}
export function AgiloftSvgLogo(props: AgiloftLogoProps) {
    return <img src={Logo} {...props} />;
}
export default AgiloftSvgLogo;
