import { Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  estimatorStrings,
  labelStrings,
  titleStrings,
} from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useRestoreEstimatorTask } from '@cdw-selline/ui/hooks';
import {
  Button,
  CircularProgress,
  DialogActions,
  Grid,
  Typography,
  Box,
} from '@mui/material';
import React, {useState, Dispatch, SetStateAction } from 'react';

import DialogModal from '../../../../dialog-modal/DialogModal';

export interface TaskSyncDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  currentTask?: Task;
  siteId?: string;
  projectItemId?: string;
  setFullSiteLoad: Dispatch<SetStateAction<boolean>>;
}

//TODO Update backend to select latest task
export function TaskSyncDialog({
  isOpen,
  handleClose,
  currentTask,
  siteId,
  projectItemId,
  setFullSiteLoad,
}: TaskSyncDialogProps) {
  const [updateInProgress, setUpdateInProgress] = useState(false);

  const handleCloseAfterSave = () => {
    setUpdateInProgress(false);
    handleClose();
  }
  const { handleTaskGroupRestore, groupTaskLoading, groupTaskError } =
    useRestoreEstimatorTask(handleCloseAfterSave,null,null,setFullSiteLoad);

  const handleTaskSync = () => {
    setUpdateInProgress(true);
    if ((siteId && projectItemId) || currentTask.taskId) {
      handleTaskGroupRestore(siteId || currentTask.siteId, projectItemId);
    }
  };

  return (
    <DialogModal
      {...{ isOpen, handleClose, maxWidth: 'sm' }}
      title={titleStrings.TASK_VERSION}
    >
      {groupTaskLoading || updateInProgress ? (
          <Box 
              display="flex" 
              width={500} height={80} 
              alignItems="center"
              justifyContent="center"
            >
          <CircularProgress />
        </Box>
      ) : groupTaskError ? (
        <Typography>{estimatorStrings.UPDATE_TASK_ERROR}</Typography>
      ) : (
        <Grid container sx={{ paddingTop: '15px' }}>
          <Grid item xs={12}>
            <Typography align="center" gutterBottom variant="h6">
              {estimatorStrings.UPDATES_AVAILABLE}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography align="center" gutterBottom variant="h6">
              {estimatorStrings.APPLY_CHANGES_PROMPT}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <DialogActions>
              <Button
                variant="contained"
                onClick={handleTaskSync}
                aria-label="apply task changes"
              >
                {labelStrings.APPLY_CHANGES}
              </Button>
              <Button onClick={handleClose} aria-label="exit without changes">
                {labelStrings.EXIT_NO_CHANGES}
              </Button>
            </DialogActions>
          </Grid>
        </Grid>
      )}
    </DialogModal>
  );
}

export default TaskSyncDialog;
