import { ContentEditor } from "@cdw-selline/ui/components";
import { useSystemProposal } from "@cdw-selline/ui/hooks";
import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface ProposalTemplateOption {
  label: string;
  value: string;
}

export interface SystemPropsalViewProps {
  proposalTemplateId: string | ProposalTemplateOption[];
  projectItemId: string;
  projectId: string;
}

export const SystemProposalView = ({
  proposalTemplateId,
  projectItemId,
  projectId,
}: SystemPropsalViewProps) => {
  const [activeProposalTemplateId, setActiveProposalTemplateId] = useState('');
  useEffect(() => {
    if(typeof proposalTemplateId === "string") {
      setActiveProposalTemplateId(proposalTemplateId);
    }

    if(typeof proposalTemplateId === "object") {
      setActiveProposalTemplateId(proposalTemplateId[0].value);
    }
  }, [proposalTemplateId]);

  const handleProposalSelect = (event) => {
    setActiveProposalTemplateId(event.target.value);
  }

  const { proposal, error, loading } = useSystemProposal(
    activeProposalTemplateId,
    projectItemId,
    projectId
  );

  if (loading) {
    return <div>Loading...</div>;
  }

  const contentStyle = `
    .mce-item-table:not([border]), 
    .mce-item-table:not([border]) caption, 
    .mce-item-table:not([border]) td, 
    .mce-item-table:not([border]) th, 
    .mce-item-table[border="0"], 
    .mce-item-table[border="0"] caption, 
    .mce-item-table[border="0"] td, 
    .mce-item-table[border="0"] th, 
    table[style*="border-width: 0px"], 
    table[style*="border-width: 0px"] caption, 
    table[style*="border-width: 0px"] td, 
    table[style*="border-width: 0px"] th {
      border: unset;
    }
    
    body {
      font-family: sans-serif;
      font-size: 12px;
    }
  `;

  return (
    <>
     {typeof proposalTemplateId === 'object' && proposalTemplateId.length > 1 && (
        <Select
          value={activeProposalTemplateId}
          onChange={handleProposalSelect}
        >
          {proposalTemplateId.map((proposalTemplate) => (
            <MenuItem
              key={proposalTemplate.value}
              value={proposalTemplate.value}
            >
              {proposalTemplate.label}
            </MenuItem>
          ))}
        </Select>
      )}
      <ContentEditor
        documentId={`proposal-${proposalTemplateId}`}
        currentValue={proposal.content ?? 'Proposal template was not found. Contact your Admin.' }
        bodyClass="proposal"
        printable={true}
        disabled={false}
        contentStyle={contentStyle}
      />
    </>
  );
};