import { CiscoAnnuityLine } from '@cdw-selline/common/types';
import { formatCurrency, getDecimalCount } from '@cdw-selline/ui/helpers';
import { TableRow, TableCell, TextField, InputAdornment, Link, Checkbox, Typography, IconButton, Tooltip } from '@mui/material';
import React from 'react';
import { useCiscoAnnuityLineState } from '@cdw-selline/ui/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';

export interface CiscoAnnuityQuoteLinetProps {
  ciscoAnnuityLine: CiscoAnnuityLine;
  isEstimate: boolean;
  allowCdwDiscountEdit: boolean;
  allowCustomerDiscountEdit: boolean;
  showDiscount: boolean;
  isReadOnly: boolean;
  handleDialogRemoveOpen?: ()=>void;
}

export function CiscoAnnuityQuoteLine({
  ciscoAnnuityLine,
  isEstimate,
  allowCdwDiscountEdit,
  allowCustomerDiscountEdit,
  showDiscount,
  isReadOnly,
  handleDialogRemoveOpen,
}: CiscoAnnuityQuoteLinetProps) {
  const { handleChange } = useCiscoAnnuityLineState(ciscoAnnuityLine);

  const getDecimalPlaces = () => {
    if(ciscoAnnuityLine.decimalPlaces) {
      return ciscoAnnuityLine.decimalPlaces;
    }

    if (
      ciscoAnnuityLine.unitOfMeasure === 'GB/month' ||
      getDecimalCount(ciscoAnnuityLine.unitListPrice ?? 0) > 2
    ) {
      return 4;
    }

    return 2;
  };

  return (
    <TableRow>
      <TableCell>
        {ciscoAnnuityLine.category !== 'cdw-credit' && (
          <Checkbox
            name="includeInQuote"
            checked={ciscoAnnuityLine.includeInQuote}
            onChange={handleChange}
            title="Uncheck to remove from proposal. Will remove all related lines."
            tabIndex={-1}
            disabled={isReadOnly}
          />
        )}

        {ciscoAnnuityLine.category === 'cdw-credit' && (
          <IconButton onClick={handleDialogRemoveOpen}>
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {ciscoAnnuityLine.line}
      </TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {ciscoAnnuityLine.partNumber}
      </TableCell>
      <TableCell>        
        {ciscoAnnuityLine.description}{' '}
        {ciscoAnnuityLine.rateTableLink ? (
          <Link href={ciscoAnnuityLine.rateTableLink} target="_blank">
            {ciscoAnnuityLine.rateTableLink}
          </Link>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell>
        {ciscoAnnuityLine.category === 'credit'
          ? '--'
          : `${ciscoAnnuityLine.quantity}`}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ciscoAnnuityLine.rateTableName
          : formatCurrency(ciscoAnnuityLine.unitListPrice, getDecimalPlaces())}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ''
          : formatCurrency(ciscoAnnuityLine.customerPrice, getDecimalPlaces())}
      </TableCell>
      <TableCell>
        {ciscoAnnuityLine.isRateTable ? '' : ciscoAnnuityLine.unitOfMeasure}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ''
          : formatCurrency(ciscoAnnuityLine.customerTotalPrice, getDecimalPlaces())}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable ? (
            ''
          ) : ciscoAnnuityLine.category !== 'cdw-credit' ? (
            formatCurrency(ciscoAnnuityLine.customerTermTotal, getDecimalPlaces())
          ) : (
            <>
              <Tooltip
                sx={{ marginTop: '8px' }}
                title="CDW Credit entered is devived by the term to support calculations. As such if the dollar amount you enter changes it is because it was not evenly divisable by the term."
              >
                <InfoIcon />
              </Tooltip>
              <TextField
                key={ciscoAnnuityLine.customerTermTotal}
                name="creditAmount"
                defaultValue={ciscoAnnuityLine.customerTermTotal ?? 0}
                onBlur={handleChange}
                type="number"
                variant="standard"
                disabled={isReadOnly}
                onFocus={(event) => {
                  event.target.select();
                }}
                onWheel={(event) => (event.target as HTMLInputElement).blur()}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: {
                    style: { textAlign: 'right' },
                  },
                }}
                size="small"
              />
            </>
          )}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ''
          : formatCurrency(ciscoAnnuityLine.cdwPrice, getDecimalPlaces())}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ''
          : formatCurrency(ciscoAnnuityLine.cdwTotalPrice, getDecimalPlaces())}
      </TableCell>
      <TableCell align="right">
        {ciscoAnnuityLine.isRateTable
          ? ciscoAnnuityLine.rateTableName
          : formatCurrency(ciscoAnnuityLine.gp, getDecimalPlaces())}
      </TableCell>

      {allowCustomerDiscountEdit && showDiscount ? (
        <TableCell align="right">
          <TextField
            name="customerDiscount"
            defaultValue={ciscoAnnuityLine.customerDiscount ?? 0}
            onBlur={handleChange}
            type="number"
            variant="standard"
            disabled={ciscoAnnuityLine.cdwDiscount === 0 || isReadOnly}
            onFocus={(event) => {
              event.target.select();
            }}
            onWheel={(event) => (event.target as HTMLInputElement).blur()}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                style: { textAlign: 'right' },
              },
            }}
            size="small"
          />
        </TableCell>
      ) : (
        <TableCell align="right">
          {showDiscount ? ciscoAnnuityLine.customerDiscount : ''}
        </TableCell>
      )}

      {isEstimate && (
        <TableCell align="right">
          {showDiscount ? ciscoAnnuityLine.cdwDiscount?.toFixed(2) : ''}
        </TableCell>
      )}

      {!isEstimate && showDiscount && (
        <TableCell>
          <TextField
            name="cdwDiscount"
            defaultValue={ciscoAnnuityLine.cdwDiscount?.toFixed(2) ?? 0}
            onBlur={handleChange}
            type="number"
            variant="standard"
            onFocus={(event) => {
              event.target.select();
            }}
            onWheel={(event) => (event.target as HTMLInputElement).blur()}
            InputProps={{
              startAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                style: { textAlign: 'right' },
              },
            }}
            size="small"
            disabled={!allowCdwDiscountEdit || isReadOnly}
          />
        </TableCell>
      )}
    </TableRow>
  );
}
