// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useSiteListView, useTaskState, useIncludesSummaryTasks, useTaskWriteQueue } from '@cdw-selline/ui/hooks';
import { useParams } from 'react-router-dom';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Grid,
  useTheme,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
  TextField,
  CircularProgress,
} from '@mui/material';
import GradingIcon from '@mui/icons-material/Grading';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { Fragment, useState, useEffect } from 'react';
import { TASK_TYPES, TASK_TEXT_TYPES } from '@cdw-selline/ui/constants';
import { Task } from '@cdw-selline/common/types';
import { unionBy, cloneDeep, omit } from 'lodash';
import { SummaryCard } from '@cdw-selline/ui/components';

export interface SiteListViewProps {
  isReadOnly: boolean;
}

export function SiteListView({
  isReadOnly,
}: SiteListViewProps) {

    const theme = useTheme();
    const { projectItemId} = useParams<{ id: string; projectItemId: string }>();
    const { sitesWithTasks, sitesLoading, sitesError, handleSiteTaskUpdate } = useSiteListView(projectItemId);
    const [sitesWithTasksState, setSitesWithTasksState] = useState([]);
    const [updatedTask, setUpdateTask] = useState(null);

    useEffect(() => {
        if (!sitesLoading) {
            setSitesWithTasksState(unionBy(sitesWithTasks, sitesWithTasksState,'siteId'));
        }
    }, [sitesWithTasks]);

    const { getTargetValue } = useTaskState(null, null);

    const {
        includesSummaryTasks,
        includesSummaryTasksLoading,
        includesSummaryTasksError,
      } = useIncludesSummaryTasks(projectItemId);

    const firstSite = sitesWithTasks[0];

    const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          maxWidth: 300,
          fontSize: theme.typography.pxToRem(12),
          border: '1px solid #dadde9',
        },
      }));

    const getTextTaskTitle = (task: Task) => {
        if (!task.allowBlankText && task.text?.trim() === '') {
            return 'This content is required before creating your SOW,';
        }

        if (task.text?.trim() === '') {
            return 'Click to Add/Edit Text';
        }

        return '';
    };
    const createMarkup = (value: string, limit = 0) => {
        return {
          __html: `${
            limit > 0
              ? value.substring(0, limit) + ((value.length > limit && '...') || '')
              : value
          }`,
        };
      }

    const getHtmlDescription = (value: string, limit = 0) => {
        if (!value) return '';
        return (
          <div
            style={{ fontSize: 16 }}
            dangerouslySetInnerHTML={createMarkup(value, limit)}
          />
        );
      }
    const getTextTaskColor = (task: Task) => {
        if (!task.allowBlankText && task.text?.trim() === '') {
            return theme.palette.error.main;
        }

        return theme.palette.text.primary;
    };

    const handleTaskChange = (event, task: Task, siteId: string, stateUpdate = false) => {
        if (
          event.target.type === 'number' &&
          Number(event.target.value) === task[event.target.name]
        ) {
          return;
        }
    
        if (
          event.target.name === 'grossProfit' &&
          event.target.value > (100 - task.minGrossProfit - 1)
        ) {
          return 0;
        }

        let updatedStateTask;
        const copySitesWithTasks =  cloneDeep(sitesWithTasksState);
        copySitesWithTasks.forEach(site=> {
            if (site.siteId === siteId) {
                site?.tasks.forEach((t, ind) => {
                    if (t.id === task.id) {
                        updatedStateTask = {...omit(t, ['__typename']), [event.target.name]: getTargetValue(event.target)};
                        site!.tasks[ind] = updatedStateTask;
                        setUpdateTask(updatedStateTask);
                    }
                });
            }
        });
        setSitesWithTasksState(copySitesWithTasks);
        
        if (!stateUpdate) {
            handleSiteTaskUpdate(updatedStateTask);
        }
      };

    const handleTaskBlur = (event) => {
        if (updatedTask) {
            handleSiteTaskUpdate(updatedTask);
            setUpdateTask(null);
        }
    }
    const handleTaskFocus = (event) => {
        event.target.select();
    };
    const taskInput = (task: Task, siteId: string) => {
        switch(task.type) {
            case TASK_TYPES.LIST: {
                const listNamesArray = task.listNames.split(',');
                return <Select
                    size="small"
                    aria-label="select options"
                    value={task.dropDownIndex}
                    name="dropDownIndex"
                    sx={{ minWidth: '185px' }}
                    onChange={(e)=>handleTaskChange(e, task, siteId)}
                    disabled={task.disableQuantity || isReadOnly}
                >
                    <MenuItem key={`${task.id}-0`} value={0}>
                    -- Select --
                    </MenuItem>
                    {Array.isArray(listNamesArray) &&
                        listNamesArray.map((data, index) => (
                        <MenuItem key={`${task.id}-${data}-${index}`} value={index + 1}>
                            {data}
                        </MenuItem>
                    ))}
                </Select>};
           case TASK_TYPES.YES_NO:
                return <Checkbox
                    aria-label="task options"
                    onChange={(e)=>handleTaskChange(e, task, siteId)}
                    checked={task.quantity === 1}
                    name="quantity"
                    disabled={task.disableQuantity || isReadOnly}
                />;
            case TASK_TYPES.TEXT:
                return task.textType !== TASK_TEXT_TYPES.SINGLE_LINE ? <IconButton
                    title={getTextTaskTitle(task)}
                    //onClick={() => handleTextTaskEdit(task.id, null)}
                >
                    <HtmlTooltip title={getHtmlDescription(task?.text, 200)}>
                        <GradingIcon sx={{ color: getTextTaskColor(task) }} />
                    </HtmlTooltip>
                </IconButton> : <TextField
                        size="small"
                        value={task.text ?? 0}
                        disabled={isReadOnly}
                        //onChange={handleInputChange}
                        onChange={(e)=>handleTaskChange(e, task, siteId)}
                        inputProps={{ 'aria-label': 'text' }}
                        name="text"
                    />;
            default:
                return <TextField
                    sx={{ minWidth: 50 }}
                    size="small"
                    value={task.quantity ?? 0}
                    type="number"
                    onWheel={(event) => (event.target as HTMLInputElement).blur()}
                    disabled={task.disableQuantity || isReadOnly}
                    onFocus={handleTaskFocus}
                    onChange={(e)=>handleTaskChange(e, task, siteId, true)}
                    onBlur={handleTaskBlur}
                    inputProps={{ 'aria-label': 'task quantity' }}
                    name="quantity"
                />;
        };
    };

    const summarySite = {name: 'Summary', id:`summary-${projectItemId}`};

    if (sitesLoading || includesSummaryTasksLoading) {
        return (
            <CircularProgress />
        );
    }
  return (
    <Grid container sx={{ width: '100%', overflowX: 'scroll'}}>
        <Table size="small" data-testid="site-table"  sx={{ width: '100%', overflowX: 'scroll' }}>
            <TableBody>
                <TableRow
                    key={`${firstSite.siteId}-task-header`}
                    sx={{ backgroundColor: theme.palette.grey[400]}}
                    >
                    <TableCell key={`${firstSite.siteId}-site-header`}  sx={{ minWidth: 30 }}>
                        Site Name
                    </TableCell>
                    {firstSite.tasks?.map?.((task) => (
                        <TableCell key={`${firstSite.siteId}-${task.id}-header`}  sx={{ minWidth: 30 }}>
                            <Tooltip title={task.name}><span>{`${task.name.substring(0, 20)}...`}</span></Tooltip>
                        </TableCell>
                    ))}
                </TableRow>
                {sitesWithTasksState.map?.((site, ind) => (
                    <Fragment key={site.siteId}>
                        <TableRow
                            key={`${site.siteId}-${ind}-task-inputs`}
                            >
                            <TableCell key={`${site.siteId}-site-inputs`}  sx={{ minWidth: 30 }}>
                                {site.siteName}
                            </TableCell>
                            {site.tasks?.map?.((task) => (
                                <TableCell key={`${site.siteId}-${task.id}-inputs`} sx={{ minWidth: 30 }}>
                                    {taskInput(task, site.siteId)}
                                </TableCell>
                            ))}
                        </TableRow>
                    </Fragment>
                ))}
            </TableBody>
        </Table>
    </Grid>
  );
}

export default SiteListView;
