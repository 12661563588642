/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { ROUTES } from '@cdw-selline/ui/constants';
import { useOpportunities } from '@cdw-selline/ui/hooks';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  Paper,
  TableContainer,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import AddIcon from '@mui/icons-material/Add';
import React, { SetStateAction } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { ProjectForm } from '../add-project/ProjectForm';

export interface RecentPipelinesProps {
  routeTo: (id: string) => void;
  routeToArbitraryRoute: (route: string) => void;
  createProject: () => void;
  projectModalOpen?: boolean;
  setProjectModalOpen: React.Dispatch<SetStateAction<boolean>>;
  handleProjectSubmit: (formData: Record<string, string>) => void;
  createProjectLoading: boolean;
}

export const RecentPipelines: React.FC<RecentPipelinesProps> = (props) => {
  const currentUser = getCurrentUser();
  const userEmail = currentUser ? currentUser.email : {};
  const [userFilter, setUserFilter] = useState({
    $or: [
      { projectTeam: { $elemMatch: { email: userEmail } } },
      { projectOwner: userEmail },
    ],
  });

  const { loading, data, error, project } = useOpportunities({
    limit: 25,
    offset: 0,
    filters: userFilter,
    sort: { estClose: -1 },
  });

  return (
    <Card sx={{ height: 'calc(100vh - 10em)' }}>
      <CardHeader
        title="My Opportunities"
        action={
          <Button
            variant="text"
            size="small"
            color="secondary"
            style={{ marginRight: '1em', marginTop: '.5em' }}
            endIcon={<AddIcon color="secondary" />}
            onClick={props.createProject}
            data-testid="add-opportunity"
          >
            Add Opportunity
          </Button>
        }
      />
      <Divider />
      <PerfectScrollbar>
        <TableContainer sx={{ maxHeight: 'calc(92vh - 13em)' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Opportunity Name</TableCell>
                <TableCell>Customer</TableCell>
                <TableCell sortDirection="desc">
                  <Tooltip enterDelay={300} title="Sort">
                    <TableSortLabel active direction="desc">
                      Est. Close
                    </TableSortLabel>
                  </Tooltip>
                </TableCell>
                <TableCell>Probablity</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {error && (
                <Typography color="error" variant="h6">
                  {error.message}
                </Typography>
              )}
              {loading && (
                <TableRow>
                  <TableCell>
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!error &&
                !loading &&
                data &&
                data.length > 0 &&
                data.map((project) => (
                  <TableRow
                    hover
                    key={project.id}
                    onClick={() => props.routeTo(project.id)}
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{project.name}</TableCell>
                    <TableCell>{project.customerName}</TableCell>
                    <TableCell>
                      {new Date(project.estClose).toLocaleDateString()}
                    </TableCell>
                    <TableCell>{project.probability}%</TableCell>
                    <TableCell>
                      <Chip
                        color="primary"
                        label={project.status}
                        size="small"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          p: 2,
        }}
      >
        <Button
          color="secondary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
          style={{ textAlign: 'center' }}
          onClick={() => props.routeToArbitraryRoute(ROUTES.PIPELINES)}
          data-testid="view-all-opportunities"
        >
          View all
        </Button>
      </Box>
      <Paper>
        <ProjectForm
          isOpen={props.projectModalOpen}
          handleClose={() => props.setProjectModalOpen(false)}
          handleSubmit={props.handleProjectSubmit}
          projectId={null}
        />
      </Paper>
    </Card>
  );
};

export default RecentPipelines;
