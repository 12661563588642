import { gql } from '@apollo/client';

export const GET_ESTIMATOR_TOTALS_BY_ITEM_ID = gql`
  query GetEstimatorTotalsByItemId($itemId: ID!) {
    getEstimatorTotalsByItemId(itemId: $itemId) {
      pmHours
      serviceEstimate
      taskHours
      managedServices
      cdwCost
      grossProfit
      onetimeService
    }
  }
`;
