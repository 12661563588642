/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Button, DialogActions, Dialog, Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  useAddAgiloftSowContent,
  useBuildAgiloftFeeTable,
  useClearAgiloftFeeTable,
  useGenerateAgiloftPdfDoc,
  useGenerateAgiloftWordDoc,
  useUploadAgiloftDocuments,
  useValidateAgiloftDocuments,
  useValidateReadyForAgiloft,
  useUpdateAgiloftFeeSummary,
} from '@cdw-selline/ui/hooks';
import LinearProgress from '@mui/material/LinearProgress';

export interface AgiloftProgressModalProps {
  isOpen?: boolean;
  projectId?: string;
  projectItemId?: string;
  agiloftSOWId: string;
  handleClose: () => void;
}

export const AgiloftProgressModal = (props: AgiloftProgressModalProps) => {
  const [progress, setProgress] = useState(1);
  const [progressMessage, setProgressMessage] = useState(
    'Begin Agiloft Process..'
  );

  const { validateReadyForAgiloftRunning, validateReadyForAgiloftError } =
    useValidateReadyForAgiloft({
      progress,
      setProgress,
      startProgress: 1,
      endProgress: 5,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { addAgiloftSowContentRunning, addAgiloftSowContentError } =
    useAddAgiloftSowContent({
      progress,
      setProgress,
      startProgress: 5,
      endProgress: 15,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { clearAgiloftFeeTableRunning, clearAgiloftFeeTableError } =
    useClearAgiloftFeeTable({
      progress,
      setProgress,
      startProgress: 15,
      endProgress: 30,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { buildAgiloftFeeTableRunning, buildAgiloftFeeTableError } =
    useBuildAgiloftFeeTable({
      progress,
      setProgress,
      startProgress: 30,
      endProgress: 50,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { updateAgiloftFeeSummaryRunning, updateAgiloftFeeSummaryError } = 
    useUpdateAgiloftFeeSummary({
      progress,
      setProgress,
      startProgress: 50,
      endProgress: 60,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { validateAgiloftDocumentsRunning, validateAgiloftDocumentsError } = 
    useValidateAgiloftDocuments({
      progress,
      setProgress,
      startProgress: 60,
      endProgress: 70,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { generateAgiloftWordDocRunning, generateAgiloftWordDocError } = 
    useGenerateAgiloftWordDoc({
      progress,
      setProgress,
      startProgress: 70,
      endProgress: 80,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });    

  const { generateAgiloftPdfDocRunning, generateAgiloftPdfDocError } = 
    useGenerateAgiloftPdfDoc({
      progress,
      setProgress,
      startProgress: 80,
      endProgress: 90,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const { uploadAgiloftDocumentsRunning, uploadAgiloftDocumentsError } = 
    useUploadAgiloftDocuments({
      progress,
      setProgress,
      startProgress: 90,
      endProgress: 100,
      setProgressMessage,
      agiloftSOWId: props.agiloftSOWId,
      projectId: props.projectId,
      projectItemId: props.projectItemId,
    });

  const agiloftActive =
    validateReadyForAgiloftRunning ||
    addAgiloftSowContentRunning ||
    clearAgiloftFeeTableRunning ||
    buildAgiloftFeeTableRunning ||
    validateAgiloftDocumentsRunning ||
    generateAgiloftWordDocRunning ||
    generateAgiloftPdfDocRunning ||
    uploadAgiloftDocumentsRunning ||
    updateAgiloftFeeSummaryRunning;

  const contentError =
    validateReadyForAgiloftError ||
    addAgiloftSowContentError ||
    clearAgiloftFeeTableError ||
    buildAgiloftFeeTableError ||
    validateAgiloftDocumentsError ||
    generateAgiloftWordDocError ||
    generateAgiloftPdfDocError ||
    uploadAgiloftDocumentsError ||
    updateAgiloftFeeSummaryError;

  useEffect(() => {
    if (!agiloftActive && progress === 100) {
      setProgressMessage(
        contentError
          ? 'Agiloft Process failed with the following error: ' +
              `${contentError}`
          : 'Agiloft Process successfully completed!'
      );
    }
  }, [agiloftActive, clearAgiloftFeeTableRunning, contentError, progress]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={props.isOpen}
      data-testid="agiloft-modal-dialog"
    >
      <Box sx={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" value={progress} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            progress
          )}%`}</Typography>
        </Box>
      </Box>
      <Box sx={{ margin: '10px', display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{ __html: progressMessage }}
          ></Typography>
        </Box>
      </Box>
      <DialogActions>
        <Button
          variant="contained"
          onClick={props.handleClose}
          data-testid="agiloft-progress-ok"
          disabled={agiloftActive}
        >
          OK
        </Button>
        {contentError && (
          <Button
            variant="contained"
            onClick={props.handleClose}
            data-testid="agiloft-cancel"
          >
            Cancel
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AgiloftProgressModal;
