import { ApolloClient, useQuery } from '@apollo/client';
import {
  GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
  GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY,
  GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
} from '@cdw-selline/ui/queries';

import { useApolloErrors } from '../';
import { useTaskWriteQueue } from './useTaskWriteQueue';
import { useState } from 'react';
import { useParams } from 'react-router-dom';

const updateSummaryCalculation = async (client: ApolloClient<any>, projectItemId: string) => {
  await client.query({
    query: GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
    variables: {
      siteId: `summary-${projectItemId}`,
      firstRequest: true,
    },
    fetchPolicy: 'network-only',
  });

  await client.refetchQueries({
    include: [GET_ESTIMATOR_TOTALS_BY_ITEM_ID],
  });
}

export const useEstimatorTasks = (
  siteId: string,
  taskId = '',
  fullSiteLoad: boolean,
  setFullSiteLoad: (value: boolean) => void,
  tabView?: boolean,
  includesSummaryTasks?: boolean
) => {
  const {  projectItemId } = useParams<{ projectItemId: string }>();
  const { addTaskToWriteQueue, updateTaskLoading } = useTaskWriteQueue();
  const { handleErrorResponse } = useApolloErrors();
  const [tasks, setTasks] = useState([]);
  //TODO: This is in EstimateDetails as well - should be moved to a shared location
  const localStorageKey = `estimator-summary-site-tasks-${projectItemId}}-expanded`;
  const haveSummaryQuery = String(localStorage.getItem(localStorageKey) ?? 'true') === 'true';

  const { client } = useQuery(GET_ESTIMATOR_TASKS_BY_SITE_ID_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    skip: !siteId,
    variables: {
      siteId: siteId,
      firstRequest: fullSiteLoad || tasks.length === 0 ? true : false,
      taskId,
    },
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Estimator Tasks!'),
    onCompleted: (data) => {
      if (tasks.length === 0 || fullSiteLoad) {
        setTasks(data.getEstimatorTasksBySiteId);
      } else {
        const updatedTasks = data.getEstimatorTasksBySiteId;
        const mergedTasks = [...tasks];

        updatedTasks.forEach((updatedTask) => {
          const existingIndex = mergedTasks.findIndex(
            (task) => task.id === updatedTask.id
          );

          if (existingIndex !== -1) {
            mergedTasks[existingIndex] = updatedTask;
          } else {
            mergedTasks.push(updatedTask);
          }
        });

        setTasks(mergedTasks);
      }

      setFullSiteLoad(false);

      if(!includesSummaryTasks || !haveSummaryQuery) {
        client.refetchQueries({
          include: [GET_ESTIMATOR_TOTALS_BY_ITEM_ID],
        });
      }

      if(includesSummaryTasks && tabView) {
        updateSummaryCalculation(client, projectItemId);        
      }

      if(includesSummaryTasks && !tabView) {
        client.refetchQueries({
          include: [
            GET_ESTIMATOR_SUMMARY_TASKS_QUERY,
            GET_ESTIMATOR_TOTALS_BY_ITEM_ID,
          ],
        });
      } 
    },
  });

  return {
    estimatorTasks: tasks ?? [],
    addTaskToWriteQueue,
    updateTaskLoading,
  };
};
