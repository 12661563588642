/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useManagedServicesRenewal } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme,
  TextField,
  InputAdornment,
} from '@mui/material';
import * as React from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';
import { useParams } from 'react-router-dom';

export const ManagedServicesRenewal = () => {
  const {
    managedServicesRenewal,
    error,
    loading,
    updateManagedServicesRenewal,
  } = useManagedServicesRenewal();
  const theme = useTheme();
  const [managedServicesData, setManagedServicesData] = React.useState(
    managedServicesRenewal
  );
  const { projectItemId } = useParams<{ projectItemId: string }>();

  React.useEffect(() => {
    setManagedServicesData(managedServicesRenewal);
  }, [managedServicesRenewal]);

  const handleChange = (property, value) => {
    setManagedServicesData({ ...managedServicesData, [property]: value });
    updateManagedServicesRenewal({
      variables: {
        params: {
          [property]: value,
          projectItemId: projectItemId,
        },
      },
    });
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3} data-testid="managed-services-grid">
        <Grid item xs={12}>
          <Card>
            <CardHeader
              title="Managed Services Renewal"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <Box sx={{ minWidth: 400 }}>
              <Table size="small">
                <TableBody>
                  {error && (
                    <Typography color="error" variant="h6">
                      {error.message}
                    </Typography>
                  )}
                  {loading && (
                    <TableRow>
                      <TableCell>
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}
                  {!error && !loading && (
                    <>
                      <TableRow
                        hover
                        key={`current-price`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Current Price:</TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            onWheel={(event) => (event.target as HTMLInputElement).blur()}
                            name="currentPrice"
                            defaultValue={(
                              managedServicesRenewal.currentPrice ?? 0
                            ).toFixed(2)}
                            onBlur={(e) =>
                              handleChange(
                                e.target.name,
                                parseFloat(e.target.value)
                              )
                            }
                            data-testid="current-price"
                            variant="standard"
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`current-cost`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Current Cost:</TableCell>
                        <TableCell align="right">
                          <TextField
                            type="number"
                            name="currentCost"
                            onWheel={(event) => (event.target as HTMLInputElement).blur()}
                            defaultValue={(
                              managedServicesRenewal.currentCost ?? 0
                            ).toFixed(2)}
                            onBlur={(e) =>
                              handleChange(
                                e.target.name,
                                parseFloat(e.target.value)
                              )
                            }
                            data-testid="current-cost"
                            variant="standard"
                            size="small"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                            }}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`current-price-GP`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Current Price GP:</TableCell>
                        <TableCell align="right" data-testid="current-price-gp">
                          {`${managedServicesRenewal.currentPriceGP}%`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`proposed-GP`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Proposed GP:</TableCell>
                        <TableCell align="right" data-testid="proposed-gp">
                          {`${managedServicesRenewal.proposedGP}%`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`change-in-GP`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Change in GP:</TableCell>
                        <TableCell align="right" data-testid="change-in-gp">
                          {`${managedServicesRenewal.changeInGP}%`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`change-in-price`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>$ Change in Price:</TableCell>
                        <TableCell align="right" data-testid="change-in-price">
                          {formatCurrency(managedServicesRenewal.changeInPrice)}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`change-in-price-percent`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>% Change in Price:</TableCell>
                        <TableCell
                          align="right"
                          data-testid="change-in-price-percent"
                        >
                          {`${managedServicesRenewal.changeinPricePercent}%`}
                        </TableCell>
                      </TableRow>
                      <TableRow
                        hover
                        key={`change-reason`}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>Change Reason:</TableCell>
                        <TableCell align="right">
                          <TextField
                            name="changeReason"
                            defaultValue={managedServicesRenewal.changeReason}
                            onBlur={(e) =>
                              handleChange(e.target.name, e.target.value)
                            }
                            data-testid="change-reason"
                            size="small"
                            variant="standard"
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ManagedServicesRenewal;
