import { useMutation } from '@apollo/client';
import {
  DefaultMutationResponse,
  Site,
} from '@cdw-selline/common/types';
import { UPDATE_ESTIMATOR_SITE_CARDS_MUTATION, GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY } from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useEffect, useRef, useState } from 'react';
import { omit } from 'lodash';
import { useProjectSitesAndTasks } from '@cdw-selline/ui/hooks';

import { useApolloErrors } from '..';

export const useEditSiteCards = (projectItemId: string, setIsEditSites: (value: boolean) => void) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();

  const { sites, sitesLoading, sitesError } = useProjectSitesAndTasks(projectItemId);

  const tableColumns = useRef([
    'Id',
    'Order',
    'Quantity',
    'Name',
    'Address',
    'Floor',
  ]);

  const getTableRows = (sites: Site[]) => {
    return sites
      ? sites.map((site) => [
        site.id,
        site.order,
        site.quantity,
        site.name,
        site.address,
        site.floor,
      ])
      : [Array(tableColumns.current.length)];
  };

  const [tableData, setTableData] = useState(
    sites ? getTableRows(sites) : []
  );

  useEffect(() => {
    if (sites.length > 0) {
      setTableData(getTableRows(sites));
    }
  }, [sites]);

  const [updateSiteCards, { loading: updateLoading, error, client }] = useMutation<{ updateEstimatorSiteCards: DefaultMutationResponse }>(
    UPDATE_ESTIMATOR_SITE_CARDS_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update site cards'),
      onCompleted: (data) => {
        if (data.updateEstimatorSiteCards.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved site cards',
          });
          setIsEditSites(false);
        }
        client.refetchQueries({
          include: [
            GET_ESTIMATOR_SITES_BY_PROJECT_ITEM_ID_QUERY,
          ],
        });
      },
    }
  );

  const handleSitesSave = (sites: Site[]) => {
    updateSiteCards({
      variables: {
        projectItemId,
        sites: sites.map((site) => omit(site, ['__typename'])),
      },
    });
  };

  return {
    sites,
    sitesLoading,
    updateLoading,
    sitesError,
    tableData,
    tableColumns: tableColumns.current,
    handleSitesSave,
  };
};
