// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
    Button,
    DialogActions,
    Grid,
    Card,
    ListItem,
    TableContainer,
    Table,
    TableHead,
    Paper,
    TableRow,
    TableCell,
    TableBody,
    Typography,
  } from '@mui/material';
import React from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { useZuoraSubscription, useCiscoSubscriptionLines } from '@cdw-selline/ui/hooks';
import { format } from 'date-fns';
import { formatCurrency, formatPercent } from '@cdw-selline/ui/helpers';
import { orderBy } from 'lodash';
import { getActionButtons } from '../../formHelperFunctions';
  
  export interface CiscoSubscriptionViewProps {
    projectItemId: string;
    subscriptionId: string;
    isOpen: boolean;
    handleClose: ()=>void;
  }
  
  export const CiscoSubscriptionView = ({
    projectItemId,
    subscriptionId,
    isOpen,
    handleClose,
  }: CiscoSubscriptionViewProps) => {
    const { subscription, loading } = useZuoraSubscription(subscriptionId);
    const { lines, loading: linesLoading } = useCiscoSubscriptionLines(subscriptionId);

    const zuoraRatePlanCharges = orderBy(subscription?.ratePlans?.map(ratePlan=>ratePlan?.ratePlanCharges.map(ratePlanCharge=>ratePlanCharge)).flat(), ['externalProductID']);
    const poNumbers = zuoraRatePlanCharges.map(ratePlanCharge=>ratePlanCharge?.externalProductID);
    const filteredLines = orderBy(lines?.filter(line=>poNumbers.includes(line.description)), ['description']);

    const buildRatePlansSection = ()=> {
        if (!zuoraRatePlanCharges?.length) return;
        return <Card sx={{ width: '100%' }}>
            <h3 style={{ marginLeft: 10 }}>Zuora Rate Details:</h3>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>PO Number</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Unit Cost</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>TCV</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {zuoraRatePlanCharges.map((ratePlanCharge, ind)=>(
                        <TableRow key={ind}>
                            <TableCell>{ratePlanCharge.externalProductID}</TableCell>
                            <TableCell>{ratePlanCharge.name}</TableCell>
                            <TableCell>{ratePlanCharge.quantity}</TableCell>
                            <TableCell>{formatCurrency(Number(ratePlanCharge.unitCost))}</TableCell>
                            <TableCell>{formatCurrency(ratePlanCharge.mrr)}</TableCell>
                            <TableCell>{formatCurrency(ratePlanCharge.tcv)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>;
    }

    const buildSubscriptionAmountSection = ()=> {
        if (!filteredLines?.length) return;
        return <Card sx={{ width: '100%' }}>
            <h3 style={{ marginLeft: 10 }}>Subscription Details:</h3>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>PO Number</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Credit</TableCell>
                            <TableCell>List Price</TableCell>
                            <TableCell>Net Price</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filteredLines.map((line, ind)=>(
                        <TableRow key={ind}>
                            <TableCell>{line.description}</TableCell>
                            <TableCell>{line.hostedOffer}</TableCell>
                            <TableCell>{line.quantity}</TableCell>
                            <TableCell>{formatPercent(line.totalDiscount)}</TableCell>
                            <TableCell>{formatPercent(line.totalCredits)}</TableCell>
                            <TableCell>{formatCurrency(line.unitListPrice)}</TableCell>
                            <TableCell>{formatCurrency(line.unitNetPrice)}</TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>;
    }

    const buildSummarySection = () => {
        return <Card sx={{ width: '100%' }}>
            <h3 style={{ marginLeft: 10}}>Zuora Summary:</h3>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100' }}>
                <Grid item xs={6}>Account Name</Grid>
                <Grid item xs={6}>{subscription.accountName.toString()}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200' }}>
                <Grid item xs={6}>Owner Account Name</Grid>
                <Grid item xs={6}>{subscription.invoiceOwnerAccountName.toString()}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Subscription Number</Grid>
                <Grid item xs={6}>{subscription.subscriptionNumber.toString()}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Contract Effective Date</Grid>
                <Grid item xs={6}>{format(new Date(subscription.contractEffectiveDate), 'MM/dd/yyyy')}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Contracted Mrr</Grid>
                <Grid item xs={6}>{formatCurrency(subscription.contractedMrr)}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Current Term</Grid>
                <Grid item xs={6}>{subscription.currentTerm}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Term Periord Type</Grid>
                <Grid item xs={6}>{subscription.currentTermPeriodType}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Customer Acceptance Date</Grid>
                <Grid item xs={6}>{format(new Date(subscription.customerAcceptanceDate), 'MM/dd/yyyy')}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Payment Term</Grid>
                <Grid item xs={6}>{subscription.paymentTerm}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Payment Type</Grid>
                <Grid item xs={6}>{subscription.paymentType}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Status</Grid>
                <Grid item xs={6}>{subscription.status}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Subscription Number</Grid>
                <Grid item xs={6}>{subscription.subscriptionNumber}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Subscription Start Date</Grid>
                <Grid item xs={6}>{format(new Date(subscription.subscriptionStartDate), 'MM/dd/yyyy')}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200'}}>
                <Grid item xs={6}>Subscription End Date</Grid>
                <Grid item xs={6}>{format(new Date(subscription.subscriptionEndDate), 'MM/dd/yyyy')}</Grid>
            </ListItem>
            <ListItem sx={{ borderBottom: 1, borderColor: 'grey.200', backgroundColor: 'grey.100'}}>
                <Grid item xs={6}>Service Type</Grid>
                <Grid item xs={6}>{subscription.serviceType}</Grid>
            </ListItem>
        </Card>;
    }

    return (
        <DialogModal
            fullWidth={true}
            title={`Subscription ${subscriptionId}`}
            maxWidthProp={'lg'}
            isOpen={isOpen}
            handleClose={handleClose}
            action={getActionButtons(
                handleClose,
              )}
        >
            {!loading && !subscription && <Typography>Subscription record was not found.</Typography>}
            {!loading && subscription && <Grid container>
                {buildSummarySection()}
                {buildRatePlansSection()}
                {!linesLoading && lines && buildSubscriptionAmountSection()}
            </Grid>}
      </DialogModal>
    );
  };