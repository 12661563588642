/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError } from '@apollo/client';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useNonInitialEffect, useOpenState } from '@cdw-selline/ui/hooks';
import { Theme, createTheme } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import SearchIcon from '@mui/icons-material/Search';
import { makeStyles, createStyles } from '@mui/styles';
import {
  Typography,
  Grid,
  TextField,
  Button,
  IconButton,
  Box,
  useTheme,
} from '@mui/material';
import {
  DataGrid,
  GridApi,
  GridCallbackDetails,
  GridCellParams,
  GridColumnMenuProps,
  GridColumns,
  GridFilterModel,
  GridRowId,
  GridRowsProp,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  MuiEvent,
  useGridApiRef,
  getGridStringOperators,
  getGridDateOperators,
  getGridNumericOperators,
  getGridBooleanOperators,
  getGridSingleSelectOperators,
  GridFilterOperator,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import * as React from 'react';
import { DialogConfirm } from '../../dialog-confirm/DialogConfirm';
import { escapeRegExp } from '../../notifications-table/NotificationsTable';
import { ErrorOverlay } from '../ErrorOverlay';
import { NoRowsOverlay } from '../NoRowsOverlay';

export interface CollectionsDataTableProps {
  columns: GridColumns;
  rows: GridRowsProp;
  getRowId?: any;
  editMode?: 'cell' | 'row';
  handleSync?: () => unknown;
  handleAdd?: () => unknown;
  handleDelete?: (args) => unknown;
  handleDeleteRowDetail?: (args) => unknown;
  handleEdit?: (id: string) => unknown;
  handleEditAll?: () => unknown;
  handlePageChange?: (page: number) => unknown;
  onFilterModelChange?: (args) => unknown;
  handleColumnHeaderClick?: (args) => unknown;
  customRowActions?: any;
  filterModel?: GridFilterModel;
  handlePageSizeChange?: (size: number) => unknown;
  handleSort?: (args) => unknown;
  loading?: boolean;
  error?: ApolloError;
  onRowClick?: (args) => unknown;
  paginationMode?: 'server' | 'client';
  sortingMode?: 'server' | 'client';
  limit?: number;
  rowCount?: number;
  page?: number;
  handleSave?: (args) => unknown;
  width?: string | number;
  height?: string | number;
  gridMargin?: string | number;
  hideActions?: boolean;
  parent?: string;
  allowExport?: boolean;
  saveOnEditStop?: boolean;
  allowFilter?: boolean;
  density?: 'compact' | 'standard' | 'comfortable';
  dataTestId?: string;
  noDeleteConfirm?: boolean;
  isReadOnly?: boolean;
  allowAddNew?: boolean;
}

interface RowMenuProps {
  api: GridApi;
  id: GridRowId;
}

export interface CustomGridColumnMenuProps
  extends GridColumnMenuProps,
    CollectionsDataTableProps {
  value: string;
  clearSearch: () => void;
  onChange: (e) => void;
}

export const GridColumnMenu = React.forwardRef<
  HTMLUListElement,
  CustomGridColumnMenuProps
>(function GridColumnMenu(props: CustomGridColumnMenuProps, ref) {
  return (
    <GridToolbarContainer
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Grid>
        <GridToolbarColumnsButton />
        {props.allowFilter !== false && (
          <GridToolbarFilterButton {...({} as any)} />
        )}
        <GridToolbarDensitySelector />
        {props.allowExport && <GridToolbarExport {...({} as any)} />}
        {/* 
        //temporarily remove search feature from datagrid... commenting out so it can easily be brought back once working
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        /> */}
      </Grid>
      <Grid>
        {props.handleEditAll && !props.isReadOnly && (<Button
          startIcon={<EditIcon />}
          onClick={props.handleEditAll}
          data-testid={`editall-record-${props.parent}`}
        >
          Edit all records
        </Button>)}
        {props.handleAdd && (!props.isReadOnly || props.allowAddNew) && (<Button
          startIcon={<AddIcon />}
          onClick={props.handleAdd}
          data-testid={`add-record-${props.parent}`}
        >
          Add record
        </Button>)}
        {process.env.NX_PROJECT_ID === 'selline-dev' && props.handleSync && !props.isReadOnly && (<Button
          startIcon={<SyncIcon />}
          onClick={props.handleSync}
          data-testid={`sync-record-${props.parent}`}
        >
          Sync All Records
        </Button>)}
      </Grid>
    </GridToolbarContainer>
  );
});

export const CollectionsDataTable: React.FC<CollectionsDataTableProps> = (
  props
) => {
  const RowMenuCell = (rowProps: RowMenuProps) => {
    const { api, id } = rowProps;
    const isInEditMode = api.getRowMode(id) === 'edit' && !props.isReadOnly;

    const handleEditClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.setRowMode(id, 'edit');

      props.handleEdit && props.handleEdit(String(id));
    };

    const handleSaveClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.commitRowChange(id);
      props.handleSave(api.getRow(id));
      api.setRowMode(id, 'view');
      const row = api.getRow(id);
      api.updateRows([{ ...row, isNew: false }]);
    };

    const handleCancelClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      api.setRowMode(id, 'view');

      const row = api.getRow(id);
      if (row?.isNew) {
        api.updateRows([{ id, _action: 'delete' }]);
      }
    };

    const handleDeleteClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      if (props.noDeleteConfirm) {
        handleDialogDelete(event);
      } else {
        handleDeleteDialogConfirmOpen();
      }
    };

    const handleDialogDelete = (event) => {
      event.stopPropagation();
      if (props.handleDeleteRowDetail) {
        props.handleDeleteRowDetail(api.getRow(id));
      } else {
        props.handleDelete(id);
      }
      handleDeleteDialogConfirmClose();
    };

    const {
      isOpen: deleteDialogConfirmOpen,
      handleClose: handleDeleteDialogConfirmClose,
      handleOpen: handleDeleteDialogConfirmOpen,
    } = useOpenState();

    if (isInEditMode) {
      return (
        <div>
          <IconButton size="small" aria-label="save" onClick={handleSaveClick}>
            <SaveIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            aria-label="cancel"
            onClick={handleCancelClick}
          >
            <CancelIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        {!props.isReadOnly && props.handleSave && (
          <IconButton size="small" aria-label="edit" onClick={handleEditClick}>
            <EditIcon fontSize="small" />
          </IconButton>
        )}

        {(!props.isReadOnly && (props.handleDelete || props.handleDeleteRowDetail)) && (
          <IconButton
            size="small"
            aria-label="delete"
            onClick={handleDeleteClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}

        {props.customRowActions && props.customRowActions(id)}

        <DialogConfirm
          title="Delete?"
          isOpen={deleteDialogConfirmOpen}
          handleClose={handleDeleteDialogConfirmClose}
          handleYes={handleDialogDelete}
        >
          <Typography>Are you sure you want to delete this item?</Typography>
        </DialogConfirm>
      </div>
    );
  };

  const data = {
    rows: props.rows || [],
    columns: props.columns,
  };

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<GridRowsProp>(data.rows);
  const [colState, setColState] = React.useState<GridColumns>([]);
  const [hiddenCols, setHiddenCols] = React.useState([]);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>(
    props.filterModel ?? {
      items: [],
    }
  );
  React.useEffect(()=>{
    setFilterModel(props.filterModel??{items:[]})
  },[props.filterModel])
  const onColumnVisibilityChange = (col) => {
    if (!col.isVisible) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !hiddenCols.includes(col.field)
        ? setHiddenCols([...hiddenCols, col.field])
        : null;
    } else if (hiddenCols.includes(col.field)) {
      const newColumnState = hiddenCols.filter((val) => {
        return val !== col.field;
      });
      setHiddenCols(newColumnState);
    }
  };

  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row?.[field]?.toString?.());
      });
    });
    setRows(filteredRows);
  };

  const filterStringOperators = getGridStringOperators().filter(({ value }) =>
    ['equals', 'contains', 'startsWith', 'endsWith'].includes(value)
  );

  const filterNumericOperators = getGridNumericOperators().filter(({ value }) =>
    ['=', '>=', '<='].includes(value)
  );

  const filterDateOperators = getGridDateOperators().filter(({ value }) =>
    ['is', 'onOrAfter', 'onOrBefore'].includes(value)
  );

  const filterSelectOperators = getGridSingleSelectOperators().filter(
    ({ value }) => ['is', 'not'].includes(value)
  );

  const filterBooleanOperators = getGridBooleanOperators().filter(({ value }) =>
    ['equals'].includes(value)
  );

  const getFilterOperator = (
    type: string | undefined
  ): GridFilterOperator[] => {
    switch (type) {
      case 'number':
        return filterNumericOperators;
      case 'dateTime':
      case 'date':
        return filterDateOperators;
      case 'boolean':
        return filterBooleanOperators;
      case 'singleSelect':
        return filterSelectOperators;
      case 'string':
      default:
        return filterStringOperators;
    }
  };

  React.useEffect(() => {
    setRows(data.rows);
  }, [data.rows]);

  React.useEffect(() => {
    const hiddenColumns = JSON.parse(
      localStorage.getItem(`${props.parent}-hidden-columns`)
    );
    hiddenColumns ? setHiddenCols(hiddenColumns) : setHiddenCols(data.columns);
    const columns = data.columns.map((c) => {
      if (!Object.keys(c).some((k) => k === 'filterOperators')) {
        c = { ...c, filterOperators: getFilterOperator(c.type) };
      }
      return { ...c };
    });
    columns.map((col) => {
      col.hide = hiddenColumns?.includes(col.field) ? true : false;
      if (col.field === 'solutionArchitect' || col.field === 'salesRep') {
        col.hide = true;
      }
    });
    setColState(columns);
  }, [data.columns]);

  useNonInitialEffect(() => {
    localStorage.setItem(
      `${props.parent}-hidden-columns`,
      JSON.stringify(hiddenCols)
    );
  }, [hiddenCols]);

  const apiRef = useGridApiRef();
  if (
    props.columns &&
    !props.hideActions &&
    !props.columns.find((col) => col.field === 'actions')
  ) {
    props.columns.push({
      field: 'actions',
      headerName: '',
      renderCell: RowMenuCell,
      sortable: false,
      headerAlign: 'center',
      filterable: false,
      align: 'right',
      disableColumnMenu: true,
      disableReorder: true,
      hideSortIcons: true,
      hideable: false,
    });
  }
  const handleCellEditStop = (params, event) => {
    if (event.type === 'keydown') {
      return;
    }

    props.handleSave(params.row);
  };

  const handleCellKeyDown = (params: GridCellParams, event) => {
    if (event.key === 'Enter') {
      props.handleSave({ ...params.row, [params.field]: event.target.value });
    }
  };

  const handleFilterChange = (
    model: GridFilterModel,
    details: GridCallbackDetails
  ) => {
    if (model.items.length) {
      setFilterModel(model)
      localStorage.setItem(`${props.parent}-filter`, JSON.stringify(model));

      if (props.onFilterModelChange) {
        props.onFilterModelChange(model);
      }
    }
  };
  return (
    <Grid
      sx={{
        height: props?.height ?? 600,
        width: props?.width ?? '100%',
        margin: props?.gridMargin ?? '2em',
        maxHeight: '100%',
        minHeight: '200px',
      }}
    >
      <DataGrid
        getRowId={props?.getRowId}
        style={{ fontSize: '14px', padding: '2em' }}
        rows={rows}
        columns={colState}
        editMode={props?.editMode}
        components={{
          NoRowsOverlay,
          ErrorOverlay,
          Toolbar: GridColumnMenu,
        }}
        componentsProps={{
          columnsPanel: {
            sx: {
              '.MuiDataGrid-panelFooter': {
                display: 'none',
              },
            },
          },
          toolbar: {
            apiRef,
            ...props,
            value: searchText,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
              requestSearch(event.target.value),
            clearSearch: () => requestSearch(''),
          },
        }}
        loading={props.loading}
        // error={!!props.error}
        onColumnHeaderClick={props?.handleColumnHeaderClick}
        onSortModelChange={props?.handleSort}
        onRowClick={!props?.isReadOnly && props?.onRowClick}
        onRowEditStop={props.saveOnEditStop && handleCellEditStop}
        onCellEditStop={props.saveOnEditStop && handleCellEditStop}
        onCellKeyDown={props.saveOnEditStop && handleCellKeyDown}
        pagination
        paginationMode={props.paginationMode ?? 'client'}
        sortingMode={props.sortingMode ?? 'client'}
        onPageChange={props.handlePageChange}
        onPageSizeChange={props.handlePageSizeChange}
        page={props?.page}
        pageSize={props?.limit}
        rowCount={props?.rowCount}
        filterModel={filterModel}
        onFilterModelChange={handleFilterChange}
        filterMode="server"
        onColumnVisibilityChange={onColumnVisibilityChange}
        hideFooterSelectedRowCount={true}
        // rowsPerPageOptions={[25, 50]} // not working for some reason
        density={props.density ?? 'standard'}
      />
    </Grid>
  );
};
