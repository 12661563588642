import { useMutation, useQuery } from '@apollo/client';
import { UpdateProjectResponse, Task } from '@cdw-selline/common/types';
import {
  GET_PROJECT_SOW_QUERY,
  GET_PROJECT_TASK_GROUP_SCOPE_QUERY,
  UPDATE_PROJECT_SOW_MUTATION,
  GET_ESTIMATOR_TASK_GROUPS_BY_PROJECT_ITEM_ID,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

import { useApolloErrors } from './useApolloErrors';

export const useProjectTasksSowSections = (
  id: string,
  projectItemId: string
) => {
  const alertState = useAlertsState();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery(
    GET_PROJECT_TASK_GROUP_SCOPE_QUERY,
    {
      variables: { projectId: id },
      skip: !id,
      fetchPolicy: 'network-only', // Used for first execution
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch sow sections!'),
    }
  );

  const {
    data: projectSow,
    loading: projectSowLoading,
    error: projectSowError,
  } = useQuery(GET_PROJECT_SOW_QUERY, {
    variables: { projectId: id },
    fetchPolicy: 'network-only', // Used for first execution
    nextFetchPolicy: 'cache-first', // Used for subsequent executions
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch project sow!'),
  });

  const [
    updateProjectSow,
    { loading: updateProjectSowLoading, error: updateProjectSowError },
  ] = useMutation<UpdateProjectResponse>(UPDATE_PROJECT_SOW_MUTATION, {
    refetchQueries: [GET_PROJECT_TASK_GROUP_SCOPE_QUERY, GET_PROJECT_SOW_QUERY],
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project sow: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project Sow',
        });
      }
    },
  });

  const projectSowSections = [] as Task[];

  const {
    data: taskGroups,
    loading: taskGroupsLoading,
    error: taskGroupsError,
  } = useQuery(GET_ESTIMATOR_TASK_GROUPS_BY_PROJECT_ITEM_ID, {
    variables: { projectItemId: projectItemId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch task groups!'),
  });

  return {
    sowSections: projectSowSections ?? [],
    loading,
    error,
    taskGroupSow: data?.getProjectTaskGroupScope?.sow ?? '',
    taskGroupRawSow: data?.getProjectTaskGroupScope?.rawSowContent ?? '',
    taskGroupSowRefetch: refetch,
    taskGroups: taskGroups?.getEstimatorTaskGroupsByProjectItemId ?? [],
    taskGroupLoading: taskGroupsLoading,
    taskGroupError: taskGroupsError,
    projectSow: projectSow?.getSowByProjectId ?? {},
    updateProjectSow,
    projectSowLoading,
    projectSowError,
    updateProjectSowLoading,
    updateProjectSowError,
  };
};
