// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useProjectSitesAndTasksTotals,
  useManagedServices,
} from '@cdw-selline/ui/hooks';
import { Box, Grid, Typography } from '@mui/material';
import React, { CSSProperties, useEffect, useState } from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';

export interface EstimateTotalsProps {
  projectItemId: string;
  style?: CSSProperties;
  loading?: boolean;
}

export function EstimateTotals({
  projectItemId,
  loading: isLoading,
}: EstimateTotalsProps) {
  const { estimateTotals, loading: totalsLoading } =
    useProjectSitesAndTasksTotals(projectItemId);
  const { includesManagedServices } = useManagedServices(projectItemId);
  const {
    serviceEstimate,
    taskHours,
    pmHours,
    managedServices,
    grossProfit,
    onetimeService,
  } = estimateTotals;
  const loading = isLoading || totalsLoading;
  const [isSticky, setIsSticky] = useState(false);


  const handleScroll = () => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    setIsSticky(scrollTop >= 220);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Grid
      container
      id="stickyGrid"
      className={ isSticky ? "sticky" : ""}
      sx={{
        display: 'flex',
        marginTop: '5px',
        justifyContent: 'center',
        backgroundColor: '#fff',
        borderBottom: '1px solid #000',
        position: '',
      }}
    >
      <Grid item xs={1}>
        <Box>
          <Typography variant="subtitle2">Service Estimate</Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {formatCurrency(serviceEstimate)}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box>
          <Typography variant="subtitle2">Task Hours</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{taskHours}</Typography>
        </Box>
      </Grid>
      <Grid item xs={1}>
        <Box>
          <Typography variant="subtitle2">PM Hours</Typography>
        </Box>
        <Box>
          <Typography variant="body1">{pmHours}</Typography>
        </Box>
      </Grid>
      {includesManagedServices && (
        <>
          <Grid item xs={1}>
            <Box title="Managed Services Recurring Services Fees">
              <Typography variant="subtitle2">
                Recurring Services Fees
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {formatCurrency(managedServices)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box title="Managed Services One-Time Services Fees">
              <Typography variant="subtitle2">
                One-Time Services Fees
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {formatCurrency(onetimeService)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box title="Managed Services Gross Profit">
              <Typography variant="subtitle2">Gross Profit</Typography>
            </Box>
            <Box>
              <Typography variant="body1">
                {formatCurrency(grossProfit)}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default EstimateTotals;
