/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import {
  useOutcomeSow,
  useProjectSowsConnectedState,
} from '@cdw-selline/ui/hooks';
import { useProjectItem, useOpenState } from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  Paper,
  IconButton,
  Tooltip,
  TextField,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  ConnectedUsersList,
  RichTextEditor,
  RichTextEditorNoState,
} from '../..';
import DialogConfirm from '../dialog-confirm/DialogConfirm';
import { ProjectSowDialog } from './ProjectSowDialog';
import { AgiloftSowModal } from './agiloft-modal/AgiloftSowModal';
import { AgiloftProgressModal } from './agiloft-progress-modal/AgiloftProgressModal';
import { OutcomeSowModal } from '../..';
import EditIcon from '@mui/icons-material/Edit';
import { debounce } from 'lodash';
import { TaskGroup, ProjectSow } from '@cdw-selline/common/types';
import * as detailedSow from './detailed-sow.css';
import * as outcomeSow from './outcome-sow.css';
import { decode } from 'html-entities';
import { getCurrentUser } from '@cdw-selline/ui/state';

/* eslint-disable-next-line */
export interface ProjectSowProps {
  projectSow: ProjectSow;
  loading: boolean;
  taskGroups: any;
  taskGroupLoading: boolean;
  taskGroupError: any;
  taskGroupSow: string;
  taskGroupRawSow?: string;
  taskGroupSowRefetch: any;
  error: any;
  updateProjectSow: any;
  isReadOnly?: boolean;
}

export function ProjectSowComponent(props: ProjectSowProps) {
  const { id } = useParams<{ id: string }>();
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const [taskGroupId, setTaskGroupId] = useState('');
  const [sowGeneration, setSowGeneration] = useState(true);
  const [generatedScope, setGeneratedScope] = useState('');
  const [detectChange, setDetectChange] = useState(false);
  const [outcomeBased, setOutcomeBased] = useState(false);
  const [agiloftConfirm, setAgiloftConfirm] = useState(false);
  const [agiloftRemove, setAgiloftRemove] = useState(false);
  const [agiloftUpdate, setAgiloftUpdate] = useState(false);
  const [isAdminRawSow, setIsAdminRawSow] = useState(false);
  const [isOutcomeSowRegenerate, setIsOutcomeSowRegenerate] = useState(false);

  const {
    taskGroupSow,
    taskGroupRawSow,
    taskGroups,
    loading,
    error,
    taskGroupLoading,
    taskGroupError,
    projectSow,
    updateProjectSow,
    taskGroupSowRefetch,
  } = props;

  const currentUser = getCurrentUser();

  const [currentValue, setCurrentValue] = useState(projectSow?.content ?? '');
  const [agiloftSOWId, setAgiloftSOWId] = useState(
    projectSow?.agiloftSowId ?? ''
  );

  const {
    outcomeSowModalOpen,
    setOutcomeSowModalOpen,
    handleOutcomeSowSubmit,
  } = useOutcomeSow(id, projectItemId);

  const {
    data: projectItem,
    error: projectItemError,
    loading: projectItemLoading,
  } = useProjectItem(projectItemId);

  const {
    isOpen: Open,
    handleClose: handleDialogClose,
    handleOpen: handleDialogOpen,
  } = useOpenState();

  const {
    isOpen: progressOpen,
    handleClose: handleProgressClose,
    handleOpen: handleProgressOpen,
  } = useOpenState();

  const {
    isOpen: dialogConfirmOpen,
    handleClose: handleDialogConfirmClose,
    handleOpen: handleDialogConfirmOpen,
  } = useOpenState();

  useEffect(() => {
    setTaskGroupId(projectSow.taskGroup);
    setOutcomeBased(projectSow.outcomeBased);
    setAgiloftSOWId(projectSow?.agiloftSowId);
    let taskGroupExists = true;
    if (taskGroups.length === 1) {
      taskGroupExists = taskGroups.find(
        (t) => String(t.id) === projectSow.taskGroup
      );
      if (typeof projectSow?.content === 'undefined' || !taskGroupExists) {
        setTaskGroupId(taskGroups[0].id);
      }
    }
  }, [
    loading,
    taskGroupLoading,
    projectSow?.outcomeBased,
    projectSow?.taskGroup,
    projectSow?.agiloftSowId,
  ]);

  useEffect(() => {
    if (projectSow?.content?.length > 0) {
      setSowGeneration(false);
    }
    if (projectSow?.content && currentValue === '') {
      setCurrentValue(projectSow?.content);
    }
    if (projectSow.content === '') {
      setDetectChange(false);
      return;
    }

    if (cleanSowForComparison(taskGroupSow) !== cleanSowForComparison(projectSow.content)) {
      if (isOutcomeSowRegenerate) {
        regenerateSow();
        setIsOutcomeSowRegenerate(false)
      } else {
        setDetectChange(true);
      }
    } else {
      setDetectChange(false);
    }
  }, [taskGroupSow, projectSow?.content]);

  //Commenting this out for now. We will reimplement realtime database in the future.
  //Notes: projectSow?.content should be updated to doc?.content with RealTimeDB, updateProjectSow in handle change should become handleDocUpdates.
  // const { handleDocUpdates, doc } = useRealTimeDatabase(id, projectSow?.id);
  const { connectedUsers } = useProjectSowsConnectedState(id, projectSow?.id);

  const handleChange = (e, projectId) => {
    updateProjectSow({
      variables: {
        params: {
          id: projectId,
          content: e.sow,
        },
      },
    });
    e.sow !== '' ? setSowGeneration(false) : setSowGeneration(true);
  };

  const debouncedHandleTextInputChange = useCallback(
    debounce((e, projectId) => handleChange(e, projectId), 1000),
    []
  );

  const cleanSowForComparison = (sow: string) => {
    sow = decode(sow);
    return sow
      .replace(/&nbsp;/gm, '')
      .replace(/(<([^>]+)>)/gi, '')
      .replace(/\W/gm, '');
  };

  const pickTaskGroupSow = (e) => {
    const taskGroupSowId = e?.target?.value ?? e;
    setTaskGroupId(taskGroupSowId);
  };

  const getSowContent = (taskGroup) => {
    return taskGroupSow?.trim() ?? '<p>No Sow Sections</p>';
  };

  const openOutcomeSowModal = (e) => {
    e.preventDefault();
    setOutcomeSowModalOpen(true);
  };

  const regenerateSow = () => {
    updateProjectSow({
      variables: {
        params: {
          id: projectSow.id,
          taskGroup: taskGroupId,
        },
      },
    });
    const value = getSowContent(taskGroupId);

    setGeneratedScope('');
    setCurrentValue(value);
    handleDialogConfirmClose();
  };

  const generateSow = async (e) => {
    e.preventDefault();
    await taskGroupSowRefetch({projectItemId: projectItemId, projectId: id, taskGroupId})
    const value = getSowContent(taskGroupId);

    if (sowGeneration) {
      setCurrentValue(value);
      updateProjectSow({
        variables: {
          params: {
            id: projectSow.id,
            taskGroup: taskGroupId,
          },
        },
      });
    } else {
      setGeneratedScope(value);
    }
    handleDialogClose();
  };

  const generateRawSow = (e) => {
    setIsAdminRawSow(true);
    e.preventDefault();
    setGeneratedScope(taskGroupRawSow);
    handleDialogClose();
  };

  const handleToggleChange = (e) => {
    setOutcomeBased(e.target.checked);
    if (e.target.checked) {
      taskGroups.length > 1 ? handleDialogOpen() : setOutcomeSowModalOpen(true);
    }
    updateProjectSow({
      variables: {
        params: {
          id: projectSow.id,
          projectItemId,
          outcomeBased: e.target.checked,
        },
      },
    });
  };

  const verifyAgiloftById = () => {
    if (agiloftSOWId) {
      setAgiloftConfirm(true);
    }
  };

  const handleAgiloftSowConfirm = () => {
    if (agiloftSOWId) {
      handleProgressOpen();
    }
    setAgiloftConfirm(false);
    setAgiloftUpdate(false);
  };

  const handleAgiloftSowRemove = () => {
    if (agiloftSOWId) {
      updateProjectSow({
        variables: {
          params: {
            id: projectSow.id,
            agiloftSowId: '',
            agiloftSentStatus: '',
          },
        },
      });
      setAgiloftSOWId('');
      setAgiloftRemove(false);
    }
  };

  const handleCloseCompare = () => {
    setGeneratedScope('');
    setIsAdminRawSow(false);
  };

  const handleOutcomeSowSubmitRegenerate = (e) => {
    handleOutcomeSowSubmit(e);
    setIsOutcomeSowRegenerate(true);
  };

  const outcomeIncluded = () => {
    return Boolean(
      taskGroups.some((taskGroup: TaskGroup) => taskGroup.outcomeIncluded)
    );
  };

  const getSowContentStyle = () => {
    return outcomeBased ? outcomeSow[0][1] : detailedSow[0][1];
  };

  if (loading || taskGroupLoading || projectItemLoading)
    return <CircularProgress />;
  if (error || taskGroupError)
    return <Typography>{error || taskGroupError}</Typography>;

  return (
    <Stack
      sx={{
        width: '100%',
      }}
    >
      <Box>
        {generatedScope.length > 0 && !props.isReadOnly && (
          <Box
            sx={{ display: 'flex', paddingTop: '10px', paddingBottom: '10px' }}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={handleCloseCompare}
              sx={{ color: 'white' }}
            >
              Close SOW Compare
            </Button>
            {!isAdminRawSow && <Button
              variant="contained"
              color="error"
              onClick={handleDialogConfirmOpen}
              sx={{ marginLeft: '5px' }}
            >
              Regenerate SOW
            </Button>}
          </Box>
        )}
        {generatedScope.length < 1 && !props.isReadOnly && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            <Box sx={{ display: 'flex', ml: '1em' }}>
              {taskGroupSow?.trim() && (
                <>
                <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={
                      taskGroups.length > 1 ? handleDialogOpen : generateSow
                    }
                    sx={{ color: 'white' }}
                    data-testid="Generate_SOW"
                  >
                    {sowGeneration ? 'Generate SOW' : 'Compare SOW'}
                  </Button>

                  {detectChange === true ? (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        paddingLeft: '5px',
                        paddingTop: '5px',
                        color: 'red',
                      }}
                    >
                      Difference Detected!
                    </Typography>
                  ) : null}
                </Box>
                {currentUser.admin && <Box>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={generateRawSow}
                    sx={{ color: 'white', marginLeft: 3 }}
                    data-testid="raw-content-sow"
                  >
                    View Raw Content
                  </Button>
                </Box>}
                </>
              )}
              <Stack direction="row" sx={{ ml: '1em' }}>
                <ConnectedUsersList connectedUsers={connectedUsers} />
              </Stack>
            </Box>
            {outcomeIncluded() && (
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={outcomeBased}
                      onChange={handleToggleChange}
                      data-testid="outcome-based-toggle"
                    />
                  }
                  label="Outcome Based"
                />
                {outcomeBased && (
                  <Tooltip title="Edit Outcome Data">
                    <IconButton
                      size="medium"
                      aria-label="Edit Outcome Data"
                      onClick={() => setOutcomeSowModalOpen(true)}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Box>
            )}

            {(projectItem.servicesTotal > 0 ||
              projectItem.managedServiceTotal > 0) &&
              !sowGeneration && !props.isReadOnly && (
                <Box>
                  <TextField
                    variant="standard"
                    value={agiloftSOWId}
                    onChange={(e) => setAgiloftSOWId(e.target.value)}
                    placeholder="Enter Agiloft SOW Id"
                    disabled={projectSow.agiloftSentStatus === 'Sent'}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          size="small"
                          aria-label="Clear"
                          onClick={() => setAgiloftRemove(true)}
                          style={{
                            visibility: projectSow?.agiloftSowId
                              ? 'visible'
                              : 'hidden',
                          }}
                        >
                          <ClearIcon />
                        </IconButton>
                      ),
                    }}
                  />
                  {agiloftRemove && (
                    <DialogConfirm
                      title="Remove Project SOW Agiloft Connection?"
                      isOpen={agiloftRemove}
                      handleClose={() => setAgiloftRemove(false)}
                      handleYes={handleAgiloftSowRemove}
                    >
                      <Typography>
                        Are you sure you want to remove Agiloft Connection from
                        this Project SOW ?
                      </Typography>
                    </DialogConfirm>
                  )}
                  {projectSow.agiloftSowId && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setAgiloftUpdate(true)}
                      sx={{ color: 'white' }}
                      data-testid="send-agiloft-scope"
                    >
                      Update Agiloft SOW Content
                    </Button>
                  )}
                  {agiloftUpdate && (
                    <DialogConfirm
                      title="Update Project SOW Agiloft Connection?"
                      isOpen={agiloftUpdate}
                      handleClose={() => setAgiloftUpdate(false)}
                      handleYes={handleAgiloftSowConfirm}
                    >
                      <Typography>
                        Are you sure you want to update Agiloft Connection from
                        this Project SOW ?
                      </Typography>
                    </DialogConfirm>
                  )}
                  {!projectSow.agiloftSowId && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={verifyAgiloftById}
                      sx={{ color: 'white' }}
                      data-testid="verify-agiloft-id"
                    >
                      Verify Agiloft SOW ID
                    </Button>
                  )}
                </Box>
              )}
          </Box>
        )}
      </Box>
      <Stack
        direction="row"
        sx={{
          display: 'flex',
          flexWrap: 'nowrap',
          marginBottom: '50px',
        }}
      >
        {generatedScope.length > 0 && (
          <RichTextEditor
            customEditorOptions={{
              content_style: getSowContentStyle(),
              body_class: 'projectSowScope',
            }}
            height="calc(90vh - 14em)"
            width="50vw"
            documentId={'sowScope'}
            currentValue={isAdminRawSow ? taskGroupRawSow : taskGroupSow}
            disabled={true}
            handleChange={() => {
              return false;
            }}
          />
        )}
        <RichTextEditorNoState
          height="calc(90vh - 14em)"
          customEditorOptions={{
            content_style: getSowContentStyle(),
            body_class: 'projectSowScope',
          }}
          width={generatedScope.length > 0 ? '50vw' : '100vw'}
          documentId={'sow'}
          editorState={currentValue}
          setCurrentValue={setCurrentValue}
          handleChange={(e) => debouncedHandleTextInputChange(e, projectSow.id)}
          key={outcomeBased ? 1 : 0}
          disabled={props.isReadOnly}
        />
      </Stack>
      <ProjectSowDialog
        isOpen={Open}
        handleClose={handleDialogClose}
        generateSow={generateSow}
        taskGroups={taskGroups}
        pickTaskGroupSow={pickTaskGroupSow}
        sowGeneration={sowGeneration}
        taskGroupId={taskGroupId}
        outcomeSow={projectSow?.outcomeBased ?? false}
      />
      <Paper>
        <OutcomeSowModal
          taskGroupId={taskGroupId}
          taskGroup={taskGroups}
          outcomeSowModalOpen={outcomeSowModalOpen}
          setOutcomeSowModalOpen={setOutcomeSowModalOpen}
          handleSubmit={handleOutcomeSowSubmitRegenerate}
          handleClose={() => setOutcomeSowModalOpen(false)}
          updateOutcomeSowLoading={false}
          projectSow={projectSow}
        />
      </Paper>
      <DialogConfirm
        title="Regenerate SOW?"
        isOpen={dialogConfirmOpen}
        handleClose={handleDialogConfirmClose}
        handleYes={regenerateSow}
      ></DialogConfirm>
      {agiloftConfirm && (
        <Paper>
          <AgiloftSowModal
            isOpen={agiloftConfirm}
            handleCancel={() => setAgiloftConfirm(false)}
            handleConfirm={handleAgiloftSowConfirm}
            agiloftSOWId={agiloftSOWId}
          />
        </Paper>
      )}
      {progressOpen && (
        <Paper>
          <AgiloftProgressModal
            isOpen={progressOpen}
            handleClose={handleProgressClose}
            agiloftSOWId={agiloftSOWId}
            projectId={id}
            projectItemId={projectItemId}
          />
        </Paper>
      )}
    </Stack>
  );
}

export default ProjectSowComponent;
