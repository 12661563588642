import { gql } from '@apollo/client';

export const GET_TASKS_BY_ID_QUERY = gql`
  query getTaskByIdQuery($id: ID!) {
    getTaskById(id: $id) {
      allowMarkup
      allowBlankText
      alwaysShowOnProposal
      allowTotalHoursAdjustment
      category
      comment
      defaultCost
      cost
      costType
      defaultCost
      edc
      excludeToggle
      defaultGrossProfit
      disableQuantity
      disableHours
      disableQuantityFormula
      disableCost
      disableCostFormula
      disableHoursFormula
      dropDownIndex
      dropDownIndexFormula
      hideShowFormula
      quantityFormula
      hoursFormula
      costFormula
      volumePricing {
        minQuantity
        maxQuantity
        cost
      }
      volumePricingEnabled
      hide
      exclude
      grossProfit
      minGrossProfit
      hours
      id
      isProjectManagement
      instructionsText
      lastReviewedOn
      lastReviewedBy
      listNames
      listValues
      managedServices
      isManagedServicesCostCalculation
      managedServicesFteFormula
      managedServicesArcRrcFormula
      managedServicesTransitionFormula
      name
      noValue
      order
      overtime
      phases {
        taskPercent
        name
      }
      practice
      practiceId
      primaryPercent
      primaryRole
      primaryRoleId
      proposalNotes
      quantity
      quantityMin
      quantityMax
      rate
      secondaryPercent
      secondaryRole
      secondaryRoleId
      taskId
      tierId
      tier
      sku
      sowSectionId
      subType
      taskGroupId
      textDisabled
      textDisabledFormula
      textFormula
      text
      sowSection
      totalHours
      type
      units
      updatedAt
      version
      yesValue
      vendor
      enrollmentCost
      enrollmentCostFormula
      enrollmentHours
      enrollmentRole
      enrollmentHoursFormula
      sprint
      skill
      textType
      margin
      pmPercent
      totalSellPrice
      customServiceDivisorRate
      description
      customServiceCost
      customServiceSellPrice
      status
      originalTaskId
      inTestingTaskId
      totalHoursNoRounding
      hideHours
      estimatorLocation
      serviceItem
      isTravelTime
      primaryRoleRateOverride
      primaryRoleRateOverrideFormula
      allowModifyPrimaryRoleRate
      secondaryRoleRateOverride
      secondaryRoleRateOverride
      allowModifySecondaryRoleRate
      productType
    }
  }
`;
