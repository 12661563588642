import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  Tier,
} from '@cdw-selline/common/types';
import {
  ADD_TIER_MUTATION,
  GET_TIERS_QUERY,
  GET_TIER_BY_ID_QUERY,
  REMOVE_ADMIN_TIER_MUTATION,
  UPDATE_TIER_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useApolloErrors } from './useApolloErrors';
import { useTiers } from './useTiers';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import { getFilters } from '@cdw-selline/ui/helpers';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Tier ID',
    width: 180,
    editable: false,
    hide: true,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Tier Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(params?.value).format('MM/DD/YYYY'),
  },
];

export const useAdminTiers = () => {
  const [sortState, setSortState] = useState();
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const { data, loading, error, refetch } = useTiers({
    filters: getFilters('TiersCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const filterModel = JSON.parse(
    localStorage.getItem('TiersCollectionPage-filter')
  );
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editTierId, setEditTierId] = useState(null);
  const {
    isOpen: tierFormOpen,
    handleClose: closeTierForm,
    handleOpen: openTierForm,
  } = useOpenState();

  const [addTier, { loading: addTierLoading, error: addTierError }] =
    useMutation<{ addTier: DefaultAddResponse }>(ADD_TIER_MUTATION, {
      refetchQueries: [GET_TIERS_QUERY, GET_TIER_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new tier section'),
      onCompleted: (data) => {
        if (data.addTier.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added tier section',
          });
        }
      },
    });

  const [removeTier, { loading: removeTierLoading, error: removeTierError }] =
    useMutation<{ removeTier: DefaultMutationResponse }>(
      REMOVE_ADMIN_TIER_MUTATION,
      {
        refetchQueries: [GET_TIERS_QUERY],
        onError: (error) =>
          handleErrorResponse(error, 'Failed to remove tier section'),
        onQueryUpdated(observableQuery) {
          return observableQuery.refetch();
        },
        onCompleted: (data) => {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed tier section',
          });
        },
      }
    );

  const [updateTier, { loading: updateTierLoading, error: updateTierError }] =
    useMutation<{ updateTier: DefaultMutationResponse }>(UPDATE_TIER_MUTATION, {
      refetchQueries: [GET_TIERS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update tier section'),
      onCompleted: (data) => {
        if (data.updateTier.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated tier section',
          });
        }
      },
    });

  const handleAdd = () => {
    openTierForm();
  };

  const handleTierFormClose = () => {
    setEditTierId(null);
    closeTierForm();
  };

  const handleDelete = (pid: string) =>
    removeTier({
      variables: { removeTierId: pid },
    });
  const handleEdit = (id: string) => {
    setEditTierId(id);
    openTierForm();
  };

  const handleTierFormSave = (tier: Tier) => {
    if (!tier.id) {
      addTier({
        variables: {
          params: omitDeep(tier, ['__typename']),
        },
      });
    }

    if (tier.id) {
      updateTier({
        variables: {
          params: omitDeep(tier, ['__typename']),
        },
      });
    }
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('TiersCollectionPage') });
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('TiersCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('TiersCollectionPage'),
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data.tiers || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    tiers: data.tiers,
    editTierId,
    addTierLoading,
    addTierError,
    removeTierLoading,
    removeTierError,
    updateTierLoading,
    updateTierError,
    loading:
      loading || addTierLoading || removeTierLoading || updateTierLoading,
    error,
    tierFormOpen,
    handleTierFormClose,
    handleTierFormSave,
    rowCount: data.count,
    ...paginationState,
    addTier,
  };
};
