import '@cdw-selline/ui/theme';
import { CollectionsTable } from '@cdw-selline/ui/components';
import { ROUTES } from '@cdw-selline/ui/constants';
import { useHealthCheck, useUsers } from '@cdw-selline/ui/hooks';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  PhasesCollectionPage,
  RolesCollectionPage,
  CdwRatesCollectionPage,
  SowSectionsCollectionPage,
  TaskGroupsCollectionPage,
  TasksCollectionPage,
  TiersCollectionPage,
  ProposalTemplatesCollectionPage,
  AdminConfigSettingsCollectionPage,
  EmailTemplatesCollectionPage,
  ServicePracticesCollectionPage,
  ServiceItemsCollectionPage,
  ServiceSkillsCollectionPage
} from '../..';
import { PracticesCollectionPage } from '../collections/practices-collection-page/PracticesCollectionPage';
import ScopeGroupsCollectionPage from '../collections/scope-groups-collection-page/ScopeGroupsCollectionPage';
import ScopeItemsCollectionPage from '../collections/scope-items-collection-page/ScopeItemsCollectionPage';
import { CustomersCollectionPage } from '../collections/customers-collection-page/CustomersCollectionPage';
import { themeGlobals } from '@cdw-selline/ui/theme';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { CiscoAnnuityPartNumberCollectionPage } from '../collections/cisco-annuity-part-numbers/CiscoAnnuityPartNumbersCollectionPage';
import { User } from '@cdw-selline/common/types';

/* eslint-disable-next-line */
export interface AdminDashboardPageProps {}

export const AdminDashboardPage = (props: AdminDashboardPageProps) => {
  useHealthCheck();
  const currentUser = getCurrentUser();
  const { data, loading, error } = useUsers({ email: currentUser.email });

  const routes = [
    { 
      exact: true,
      path: ROUTES.HOME,
      Component: CollectionsTable,
      access: ['admin', 'contentAdmin'],
    },
    { 
      exact: true,
      path: ROUTES.ADMIN_DASHBOARD,
      Component: CollectionsTable,
      access: ['admin', 'contentAdmin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_CUSTOMERS,
      Component: CustomersCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_PRACTICES,
      Component: PracticesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_ROLES,
      Component: RolesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_CDW_RATES,
      Component: CdwRatesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SOW_SECTIONS,
      Component: SowSectionsCollectionPage,
      access: ['admin', 'contentAdmin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_PROPOSAL_TEMPLATES,
      Component: ProposalTemplatesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_TASKS,
      Component: TasksCollectionPage,
      access: ['admin', 'contentAdmin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_TASK_GROUPS,
      Component: TaskGroupsCollectionPage,
      access: ['admin', 'contentAdmin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_TIERS,
      Component: TiersCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_PHASES,
      Component: PhasesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SCOPE_GROUPS,
      Component: ScopeGroupsCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SCOPE_ITEMS,
      Component: ScopeItemsCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: `${ROUTES.ADMIN_TASKS}/:id`,
      Component: TasksCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: `${ROUTES.ADMIN_CISCO_ANNUITY_PART_NUMBERS}`,
      Component: CiscoAnnuityPartNumberCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_CONFIG_SETTINGS,
      Component: AdminConfigSettingsCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.EMAIL_TEMPLATES,
      Component: EmailTemplatesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SERVICE_PRACTICES,
      Component: ServicePracticesCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SERVICE_ITEMS,
      Component: ServiceItemsCollectionPage,
      access: ['admin'],
    },
    {
      exact: true,
      path: ROUTES.ADMIN_SERVICE_SKILLS,
      Component: ServiceSkillsCollectionPage,
      access: ['admin'],
    }
  ];

  const allowAccess = (access: string[], user: User) => {
    return (user?.admin && access.includes('admin')) || 
      (user?.contentAdmin && access.includes('contentAdmin'));
  };
  return (
    <Box sx={{ ...themeGlobals.containerMargins }}>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Switch>
          {routes.map(({ exact, path, Component, access }) => (
            <Route key={`admin-routes-${path}`} exact={exact} path={path}>
              {!loading &&
                (allowAccess(access, data[0]) ? (
                  <Component />
                ) : (
                  <Typography>
                    Admin user status required to access Admin Collections
                  </Typography>
                ))}
            </Route>
          ))}
        </Switch>
      </Stack>
    </Box>
  );
};

export default AdminDashboardPage;
