import { ApolloError } from '@apollo/client';
import {
  ProjectItemQuote,
  QuoteColumnFieldsAndHeaders,
  QuoteLineTypes,
} from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useOpenState, useQuotesTable } from '@cdw-selline/ui/hooks';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import React from 'react';
import DialogConfirm from '../../dialog-confirm/DialogConfirm';
import TableTemplate from '../handson-table/TableTemplate';

export interface QuotesTableProps {
  quoteData: ProjectItemQuote;
  loading: boolean;
  error: ApolloError;
}

export function QuotesTable({ quoteData, loading, error }: QuotesTableProps) {
  const QUOTE_FETCH_ERROR = 'Error fetching quote';
  const CONFIRM_REMOVE_ROWS = 'Remove selected rows?';

  const {
    tableRows,
    tableRef,
    handleAddEmptyLine,
    handleRemoveSelectedRows,
    handleQuoteLineChange,
    setSelections,
    loading: updateQuotesLoading,
    error: updateQuotesError,
  } = useQuotesTable(quoteData);

  const columnNames = Object.values(QuoteColumnFieldsAndHeaders);
  const getColumns = () => {
    const configureColumn = (colName: string) => {
      const isReadOnly =
        colName === QuoteColumnFieldsAndHeaders.TOTAL_COST ||
        colName === QuoteColumnFieldsAndHeaders.CUSTOMER_TOTAL_COST;
      if (isReadOnly) {
        return {
          readOnly: true,
        };
      }
      return colName === QuoteColumnFieldsAndHeaders.TYPE
        ? { type: 'dropdown', source: Object.values(QuoteLineTypes) }
        : {};
    };
    return columnNames.map(configureColumn);
  };

  const { isOpen, handleClose, handleOpen } = useOpenState();
  const handleConfirmRemoveRows = () => {
    handleRemoveSelectedRows();
    handleClose();
  };

  if (loading) return <CircularProgress />;
  if (error) return <Typography>{QUOTE_FETCH_ERROR}</Typography>;
  return (
    <Stack spacing={1} sx={{ overflowX: 'scroll' }}>
      <ButtonGroup>
        <Button
          variant="contained"
          onClick={handleAddEmptyLine}
          data-testid="add-quote-line"
        >
          Add Quote Line
        </Button>
        <Button
          variant="contained"
          onClick={handleOpen}
          data-testid="remove-quote-line"
        >
          Remove Selected Rows
        </Button>
      </ButtonGroup>
      <DialogConfirm
        {...{
          isOpen,
          handleClose,
          title: 'Remove Rows',
          handleYes: handleConfirmRemoveRows,
        }}
      >
        <Typography>{CONFIRM_REMOVE_ROWS}</Typography>
      </DialogConfirm>
      <TableTemplate
        {...{
          tableData: tableRows,
          tableRef: tableRef,
          handleCellDataChange: handleQuoteLineChange,
          handleCurrentSelection: setSelections,
          customSettings: {
            colHeaders: columnNames,
            columns: getColumns(),
            rowHeaders: true,
            hiddenColumns: { columns: [0] }, // hides the ID column
            selectionMode: 'multiple',
            outsideClickDeselects: false,
            width: '100%',
            stretchH: 'all',
          },
        }}
      />
    </Stack>
  );
}

export default QuotesTable;
