import { Editor as EditorClass } from 'tinymce';

export const proposalMaxWidth = '806px';

export interface TinyMCEEditorOptions {
  height?: string | number;
  width?: string | number;
  content_style?: string;
  content_css?: string;
  menubar?: string;
  valid_elements?: string;
  extended_valid_elements?: string;
  valid_children?: string;
  verify_html?: boolean;
  custom_elements?: string;
  console?: boolean;
  validate?: boolean;
  toolbar?: string;
  statusbar?: boolean;
  plugins?: string[];
  editor_selector?: string;
  init_instance_callback?: (editor: EditorClass) => void;
  setup?: (editor: EditorClass) => void;
  body_class?: string;
  fontsize_formats?: string;
  paste_data_images?: boolean;
  paste_as_text?: boolean;
  images_dataimg_filter?: (img: HTMLImageElement) => boolean;
  on_submit?: (e: Event) => void;
  on_save?: (e: Event) => void;
  save_onsavecallback?: () => void;
  forced_root_block?: string;
  browser_spellcheck?: boolean;
  contextmenu?: string;
  autoresize_bottom_margin?: number;
  autoresize_max_height?: number;
  autoresize_min_height?: number | string;
  table_class_list?: { title: string; value: string }[];
  showNbspSpaceHandlerButtons?: boolean;
  force_br_newlines?: boolean;
  force_p_newlines?: boolean;
  invalid_elements?: string;
}

export const defaultEditorOptions: TinyMCEEditorOptions = {
  custom_elements: 'replace',
  extended_valid_elements: 'replace[*]',
  valid_children: '+p[replace],+div[replace]',
  toolbar:
    'print add-sections add-global-sections preview copy | ' +
    'undo redo | ' +
    'formatselect fontselect fontsizeselect | ' +
    'bold italic | ' +
    'alignleft aligncenter ' +
    'alignright alignjustify | ' +
    'bullist numlist outdent indent | ' +
    'image | forecolor backcolor | paste |' +
    'fullscreen | nonbreaking',
    plugins: [
      'print',
      'advlist',
      'code',
      'lists',
      'link',
      'image',
      'charmap',
      'pagebreak',
      'paste',
      'visualblocks',
      'insertdatetime',
      'help',
      'wordcount',
      'save',
      'table',
      'fullscreen',
      'nonbreaking',
      'noneditable',
    ],
};