import { gql } from '@apollo/client';

export const PROJECT_ESTIMATES_FRAGMENT = gql`
  fragment projectEstimates on ProjectEstimates {
    pmHours
    projectId
    serviceEstimate
    taskHours
    sites {
      site
      tasks {
        allowMarkup
        category
        comment
        cost
        costType
        defaultCost
        defaultGrossProfit
        edc
        excludeToggle
        exclude
        grossProfit
        minGrossProfit
        hours
        id
        lastReviewedOn
        listNames
        listValues
        name
        noValue
        yesValue
        order
        overtime
        phases {
          name
          taskPercent
        }
        practice
        practiceId
        primaryPercent
        listValues
        primaryRole
        primaryRoleId
        projectId
        proposalNotes
        quantity
        rate
        roles
        secondaryPercent
        secondaryRole
        secondaryRoleId
        tiers
        subType
        taskGroupId
        totalHours
        type
        units
      }
    }
  }
`;
