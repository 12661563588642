/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  FormAutocomplete,
  FormAutocompleteOption,
  FormTextControlled,
} from '../formHelperFunctions';
import { Grid, TextField } from '@mui/material';
import { RoleAndRate } from '@cdw-selline/common/types';
import { useProjectItemEstimator, useSearchServiceItems } from '@cdw-selline/ui/hooks';
import { round } from 'lodash';
import FormFieldAutoComplete from '../autocomplete-form-field/FormFieldAutoComplete';

export interface CustomServiceTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  roleOptions: RoleAndRate[];
  errorsArray: any;
  practiceOptions: FormAutocompleteOption[];
}

export function CustomServiceTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  roleOptions,
  errorsArray,
  practiceOptions,
}: CustomServiceTaskFormProps) {
  const { data: projectEstimator } = useProjectItemEstimator();

  useEffect(() => {
    if (!taskFormData?.id) {
      setTaskFormData((prevState) => ({
        ...prevState,
        custom: true,
        disableQuantity: true,
        disableHours: true,
        units: 'Hours',
        margin: prevState?.margin ?? 25,
        pmPercent: prevState?.pmPercent ?? 15,
        isProjectManagement: true,
        customServiceDivisorRate: prevState?.customServiceDivisorRate ?? 250,
        customServiceCost: prevState?.customServiceCost ?? 0,
        primaryRole: projectEstimator?.pmRole ?? '',
      }));
    }
  }, [projectEstimator?.pmRole]);

  const [customServiceSellPrice, setCustomServiceSellPrice] = useState<number>(
    taskFormData?.customServiceSellPrice ?? 0
  );
  const [quantity, setQuantity] = useState<number>(taskFormData?.quantity ?? 0);

  useEffect(() => {
    if (
      taskFormData?.customServiceCost &&
      taskFormData?.margin &&
      taskFormData?.customServiceDivisorRate &&
      taskFormData?.pmPercent
    ) {
      const sellPrice = round(
        taskFormData.customServiceCost / (1 - taskFormData.margin / 100),
        2
      );
      const quantity = Math.ceil(
        (taskFormData.customServiceCost /
          taskFormData.customServiceDivisorRate) *
          (taskFormData.pmPercent / 100)
      );
      setTaskFormData((prevState) => ({
        ...prevState,
        customServiceSellPrice: sellPrice,
        quantity: quantity,
      }));

      setCustomServiceSellPrice(sellPrice);
      setQuantity(quantity);
    } 

    if (!taskFormData?.customServiceCost) {
      setCustomServiceSellPrice(0);
    }
  }, [
    taskFormData.customServiceCost,
    taskFormData.customServiceDivisorRate,
    taskFormData.pmPercent,
    taskFormData.margin,
  ]);

  const pmRoles =
    roleOptions
      .filter((role: RoleAndRate) => role.projectManager)
      .map((role: RoleAndRate) => {
        return {
          id: role.id,
          label: `${role.name}  (${role.practiceName})`,
        };
      }) ?? [];

  return (
    <>
      <FormTextControlled
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      <FormAutocomplete
        name="vendor"
        label="Vendor"
        options={vendorOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        freeSolo={true}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.vendor}
      />
      <FormTextControlled
        name="customServiceCost"
        label="Cost"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.customServiceCost}
      />
      <FormTextControlled
        name="margin"
        label="Margin"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        inputProps={{ min: 15 }}
      />
      <FormTextControlled
        name="pmPercent"
        label="PM Percent"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
      />
      <FormTextControlled
        name="customServiceDivisorRate"
        label="Divisor Rate"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
      />
      <FormAutocomplete
        name="primaryRole"
        label="PM Role"
        options={pmRoles}
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.primaryRole}
      />
      <Grid item xs={12} sx={{ width: '100%' }}>
        <TextField
          name={'quantity'}
          id={'quantity'}
          value={quantity}
          label="PM Hours"
          disabled={true}
          type="number"
          sx={{ width: '100%' }}
        />
      </Grid>
      <Grid item xs={12} sx={{ width: '100%' }}>
        <TextField
          name={'customServiceSellPrice'}
          id={'customServiceSellPrice'}
          value={customServiceSellPrice}
          label={'Total Sell Price'}
          disabled={true}
          type="number"
          sx={{ width: '100%' }}
        />
      </Grid>
      <FormTextControlled
        name="description"
        label="Description"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormFieldAutoComplete
        name="serviceItem"
        label="Service Item"
        setTaskFormData={setTaskFormData}
        taskFormData={taskFormData}
        useSearchHook={useSearchServiceItems}
        idColumnName="serviceItemId"
        isUpdateIdAndName={true}
      />
    </>
  );
}
