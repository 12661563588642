import {
  ProposalControls,
  RichTextEditor,
  RichTextEditorNoState,
} from '@cdw-selline/ui/components';
import {
  useOpenState,
  useProjectProposal,
  useProjectProposalsConnectedState,
  useGetProjectProposalsByEstimatorId,
  useProjectItemEstimator,
} from '@cdw-selline/ui/hooks';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getEstimatorProposalEditorOptions, proposalMaxWidth } from '@cdw-selline/ui/helpers';
import { debounce } from 'lodash';
import { ROUTES } from '@cdw-selline/ui/constants';
import { Editor } from 'tinymce';
import { defaultEditorOptions } from '@cdw-selline/ui/helpers';

/* eslint-disable-next-line */
export interface ProposalTabProps {
  isReadOnly?: boolean;
}
export function ProposalTab(props: ProposalTabProps) {
  const infoStrings = {
    ERROR: 'Error loading proposal',
  };

  const params = useParams<{ id: string; projectItemId: string }>();

  //TODO: update to get only the current proposal. Prevent refresh of other data
  const {
    data: estimator,
    updateProjectItemEstimator,
    getProjectItemEstimatorLoading,
  } = useProjectItemEstimator();

  const {
    isOpen: editNameOpen,
    handleClose: handleEditNameClose,
    handleOpen: handleEditNameOpen,
  } = useOpenState();

  const {
    data: projectProposals,
    loading: proposalsLoading,
    error: proposalsError,
  } = useGetProjectProposalsByEstimatorId(params.id, params.projectItemId);

  const [selectedProposalId, setSelectedProposalId] = useState<string>('');

  const handleSelectValueChange = (newProposalId: string) => {
    setSelectedProposalId(newProposalId);

    updateProjectItemEstimator({
      variables: {
        params: {
          currentProposalId: newProposalId,
          projectItemId: params.projectItemId,
        },
      },
    });
  };

  const {
    currentProposal,
    doc,
    handleEditProposalContent,
    handleContentChange,
    handleNameChange,
    loading,
    error,
  } = useProjectProposal(selectedProposalId, handleEditNameClose);
  const [currentValue, setCurrentValue] = useState(currentProposal?.content ?? '');

  useEffect(() => {
    if(currentProposal?.content && currentValue === '') {
      setCurrentValue(currentProposal?.content);
    }
  }, [currentProposal, currentValue]);

  const { connectedUsers } = useProjectProposalsConnectedState({
    projectId: params?.id,
    docId: params?.projectItemId,
  });

  const debouncedHandleEditorChange = debounce(handleEditProposalContent, 1000);

  const customEditorOptions = getEstimatorProposalEditorOptions();

  useEffect(() => {
    setSelectedProposalId(estimator.currentProposalId || selectedProposalId);
  }, [projectProposals, estimator, selectedProposalId]);

  const printRoute =`${ROUTES.PROJECT}/${params?.id}/estimator/${params?.projectItemId}/print/${currentProposal?.id}`;

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'calc(90vh - 13em)',
      }}
    >
      <Stack
        alignContent={'center'}
        sx={{
          width: `${proposalMaxWidth}`,
          paddingTop: '10px',
          paddingBottom: '10px',
        }}
        data-testid="proposal-view"
        height="calc(90vh - 13em)"
      >
        <ProposalControls
          {...{
            projectId: params.id,
            projectItemId: params.projectItemId,
            estimatorId: params.projectItemId,
            currentProposal,
            proposalSelect: {
              selectedProposalId,
              handleSelectValueChange,
            },
            proposalEditState: {
              editNameOpen,
              handleEditNameClose,
              handleEditNameOpen,
              handleNameChange,
            },
            projectProposals,
            isReadOnly: props.isReadOnly,
            connectedUsers,
          }}
        />
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography>{infoStrings.ERROR}</Typography>
        ) : currentProposal ? (
          <RichTextEditorNoState
            documentId="proposal"
            editorState={currentValue}
            setCurrentValue={setCurrentValue}
            handleChange={debouncedHandleEditorChange}
            customEditorOptions={{
              ...defaultEditorOptions,
              content_css: '/proposal.css',
              width: '100%',
            }}
            height="calc(90vh - 14em)"
            disabled={props.isReadOnly}
          />
        ) : null}
      </Stack>
    </Box>
  );
}

export default ProposalTab;
