// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CollectionsDataTable } from '@cdw-selline/ui/components';
import { useAdminServiceItems } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import LoadingOverlay from 'libs/ui/components/src/lib/LoadingOverlay';
import React from 'react';
  
  export const ServiceItemsCollectionPage = () => {
    const dataTableProps = useAdminServiceItems();
    
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <CollectionsDataTable
          {...dataTableProps}
          height="calc(100vh - 13em)"
          parent="ServiceItemsCollectionPage"
          paginationMode="server"
          handleSync={dataTableProps.syncServiceItems}
        />
      </Box>
    );
  };
  
  export default ServiceItemsCollectionPage;