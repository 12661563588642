import { RoleAndRate, Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { estimatorStrings, TASK_COST_TYPES, TASK_TYPES, TASK_TEXT_TYPES } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useTaskState } from '@cdw-selline/ui/hooks';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import CheckIcon from '@mui/icons-material/Check';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import CommentIcon from '@mui/icons-material/Comment';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import GradingIcon from '@mui/icons-material/Grading';
import DoNotDisturbOnIcon from '@mui/icons-material/DoNotDisturbOn';
import Engineering from '@mui/icons-material/Engineering';
import ManageAccounts from '@mui/icons-material/ManageAccounts';
import TextSnippet from '@mui/icons-material/TextSnippet';
import AttachMoney from '@mui/icons-material/AttachMoney';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import PriceCheck from '@mui/icons-material/PriceCheck';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

import {
  Checkbox,
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
  Badge,
  Skeleton,
  useTheme,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import TaskRoleSelect from './TaskRoleSelect';
import { formatCurrency, formatNumber } from '@cdw-selline/ui/helpers';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { round } from 'lodash';

export interface TaskItemProps {
  task: Task;
  taskIndex: number;
  projectItemId: string;
  expanded?: boolean;
  hideZeroQuantity?: boolean;
  handleTaskEdit: (taskId: string, siteId: string) => void;
  handleVersionSyncOpen: (task: Task) => void;
  handleNotesOpen: (currentTask: Task) => void;
  currentRolesAndRates: RoleAndRate[];
  addTaskToWriteQueue: (task: Task) => void;
  handleRemoveCustomTask: (taskId: string) => void;
  handleTextTaskEdit: (taskId: string, siteId: string) => void;
  handleProposalDescriptionDialogOpen: (task: Task) => void;
  isReadOnly: boolean;
}

export function TaskItem({
  task,
  taskIndex,
  hideZeroQuantity,
  handleTaskEdit,
  handleVersionSyncOpen,
  handleNotesOpen,
  handleProposalDescriptionDialogOpen,
  currentRolesAndRates,
  addTaskToWriteQueue,
  handleRemoveCustomTask,
  handleTextTaskEdit,
  isReadOnly,
}: TaskItemProps) {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [editPrimaryRole, setEditPrimaryRole] = useState(false);
  const [editSecondaryRole, setEditSecondaryRole] = useState(false);
  const handleOnWheel = (event)=>{
    event.target.blur()
  }
  const {
    handleChange: handleTaskChange,
    taskState,
    setTaskState,
    handleFocus,
  } = useTaskState(task, addTaskToWriteQueue);

  const handleInputChange = (event) => {
    setTaskState({ ...taskState, [event.target.name]: event.target.value });
  };

  const maxAdditionalGP = 100 - task.minGrossProfit - 1;

  const handleChange = (event) => {
    if (
      event.target.type === 'number' &&
      Number(event.target.value) === taskState[event.target.name]
    ) {
      return;
    }

    if (
      event.target.name === 'grossProfit' &&
      event.target.value > maxAdditionalGP
    ) {
      return 0;
    }

    handleTaskChange(event);
  };

  const handleTaskBlur = () => {
    setEditSecondaryRole(false);
    setEditPrimaryRole(false);
  };

  const handleRoleChange = (event) => {
    handleTaskBlur();
    handleChange(event);
  };

  const handleRoleClear = () => {
    handleTaskBlur();
    handleChange({
      target: {
        type: 'autocomplete',
        name: 'secondaryRole',
        value: null,
      },
    });
  };

  const isDropDown = taskState.type === 'List';
  const isCheckbox = taskState.type === 'Yes/No';
  const isButton = taskState.type === 'Text';
  const isHidden =
    task.hide ||
    (hideZeroQuantity &&
      (([
        TASK_TYPES.YES_NO,
        TASK_TYPES.HOURS,
        TASK_TYPES.COST,
        TASK_TYPES.PRODUCT,
      ].includes(taskState.type as TASK_TYPES) &&
        !taskState.quantity) ||
        (isDropDown && !taskState.dropDownIndex)));

  //TODO Move to backend
  const listNames = taskState.listNames;
  let listNamesArray = [];
  if (isDropDown) {
    if (listNames) {
      listNamesArray = listNames.split(',');
    }
  }

  const isLatest = task.latestVersion === task.version;
  const isTextMultiLine = taskState.textType !== TASK_TEXT_TYPES.SINGLE_LINE;

  function createMarkup(value: string, limit = 0) {
    return {
      __html: `${
        limit > 0
          ? value.substring(0, limit) + ((value.length > limit && '...') || '')
          : value
      }`,
    };
  }

  function getHtmlDescription(value: string, limit = 0) {
    if (!value) return '';
    return (
      <div
        style={{ fontSize: 16 }}
        dangerouslySetInnerHTML={createMarkup(value, limit)}
      />
    );
  }

  const handleTaskFocus = (event) => {
    event.target.select();
    handleFocus();
  };

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 300,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const getSellPrice = () => {
    if (typeof taskState.sellPrice === 'number') {
      return taskState.sellPrice.toFixed(2);
    }

    if (typeof taskState.sellPrice === 'string') {
      return taskState.sellPrice;
    }

    return '0.00';
  };

  const getTextTaskColor = () => {
    if (!taskState.allowBlankText && taskState.text.trim() === '') {
      return theme.palette.error.main;
    }

    return theme.palette.text.primary;
  };

  const getTextTaskTitle = () => {
    if (!taskState.allowBlankText && taskState.text.trim() === '') {
      return 'This content is required before creating your SOW,';
    }

    if (taskState.text.trim() === '') {
      return 'Click to Add/Edit Text';
    }

    return '';
  };

  const getTextTaskField = () => {
      return isTextMultiLine ? <IconButton
        title={getTextTaskTitle()}
        onClick={() => handleTextTaskEdit(task.id, null)}
      >
        <HtmlTooltip title={getHtmlDescription(task?.text, 200)}>
          <GradingIcon sx={{ color: getTextTaskColor() }} />
        </HtmlTooltip>
      </IconButton> :
      <Tooltip title={<Typography fontSize={16} color={'white'} fontWeight={'bold'}>{taskState.text}</Typography>}>
          <TextField
            size="small"
            value={taskState.text ?? 0}
            disabled={taskState.textDisabled || isReadOnly }
            onFocus={handleTaskFocus}
            onChange={handleInputChange}
            onBlur={handleChange}
            inputProps={{ 'aria-label': 'text' }}
            name="text"
          />
      </Tooltip>
  }

  const getTaskIcon = () => {
    if (taskState.isProjectManagement) {
      return <Tooltip title='Project Management' sx={{ marginRight: 2 }}><ManageAccounts /></Tooltip>;;
    }

    switch (taskState.type) {
      case TASK_TYPES.HOURS:
      case TASK_TYPES.LIST:
      case TASK_TYPES.YES_NO:
        return <Tooltip title='Engineering Hours' sx={{ marginRight: 2 }}><Engineering /></Tooltip>;
      case TASK_TYPES.TEXT: 
        return <Tooltip title='Sow Text' sx={{ marginRight: 2 }}><TextSnippet /></Tooltip>;
      case TASK_TYPES.COST:
        if (taskState.costType === TASK_COST_TYPES.ONE_TIME) {
          return <Tooltip title='One Time Costs' sx={{ marginRight: 2 }}><AttachMoney /></Tooltip>;;
        } else if (taskState.costType === TASK_COST_TYPES.RECURRING) {
          return <Tooltip title='Recurring Costs' sx={{ marginRight: 2 }}><CurrencyExchange /></Tooltip>;;
        } else if (taskState.costType === TASK_COST_TYPES.FUNDING) {
          return <Tooltip title='Price Uplift' sx={{ marginRight: 2 }}><PriceCheck /></Tooltip>;;
        }
        return;
      case TASK_TYPES.PRODUCT:
        return <Tooltip title='Product' sx={{ marginRight: 2 }}><ShoppingBasketIcon /></Tooltip>;
    }
  };

  const displayTaskPrimaryRole= () => {
    if (task.isTravelTime && task.allowModifyPrimaryRoleRate) {
      return <TextField
        size="small"
        value={taskState.primaryRoleRateOverride}
        sx={{
          width: '80px',
        }}
        type="number"
        title="Primary Role Rate Override"
        onWheel={handleOnWheel}
        onChange={handleInputChange}
        onBlur={handleChange}
        disabled={isReadOnly}
        label="Rate Override"
        name="primaryRoleRateOverride"
        onFocus={handleTaskFocus}
      />;
    } else {
      return <div>
        {editPrimaryRole ? (
          <TaskRoleSelect
            currentRolesAndRates={currentRolesAndRates}
            value={taskState.primaryRole}
            name="primaryRole"
            onChange={handleRoleChange}
            onBlur={handleTaskBlur}
            label="Primary Role"
          />
        ) : (
          <>
            {taskState.primaryRole && currentRolesAndRates
              ? currentRolesAndRates.find(
                  (o) => o.id === taskState.primaryRole
                )?.name
              : (!isReadOnly && 'Select Role') || ''}
            {!isReadOnly && (
              <IconButton
                title="Click to Edit Task"
                onClick={() => setEditPrimaryRole(true)}
              >
                <EditIcon />
              </IconButton>
            )}
          </>
        )}
      </div>;
    }
  }

  const displayTaskSecondaryRole= () => {
    if (task.isTravelTime && task.allowModifySecondaryRoleRate) {
      return <TextField
        size="small"
        value={taskState.secondaryRoleRateOverride}
        sx={{
          width: '80px',
        }}
        type="number"
        title="Secondary Role Rate Override"
        onWheel={handleOnWheel}
        onChange={handleInputChange}
        onBlur={handleChange}
        disabled={isReadOnly}
        label="Rate Override"
        name="secondaryRoleRateOverride"
        onFocus={handleTaskFocus}
      />;
    } else {
      return <div>
        {editSecondaryRole ? (
          <TaskRoleSelect
            currentRolesAndRates={currentRolesAndRates}
            value={taskState.secondaryRole}
            name="secondaryRole"
            onChange={handleRoleChange}
            onBlur={handleTaskBlur}
            label="Secondary Role"
          />
        ) : (
          <>
            {taskState.secondaryRole && currentRolesAndRates
              ? currentRolesAndRates.find(
                  (o) => o.id === taskState.secondaryRole
                )?.name
              : (!isReadOnly && 'Select Role') || ''}
            {!isReadOnly && (
              <IconButton
                title="Click to Edit Task"
                onClick={() => setEditSecondaryRole(true)}
              >
                <EditIcon />
              </IconButton>
            )}
            {!isReadOnly && taskState.secondaryRole && (
              <IconButton
                title="Click to Clear"
                onClick={handleRoleClear}
              >
                <DeleteIcon />
              </IconButton>
            )}
          </>
        )}
      </div>;
    }
  }

  useEffect(() => {
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <TableRow
        style={{
          background: theme.palette.grey[200],
          height: 53,
          borderBottom: '2px inset',
        }}
        // key={task.id}
      >
        <TableCell></TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
        <TableCell>
          <Skeleton />
        </TableCell>
      </TableRow>
    );
  }

  const isManagedService = task.managedServices;
  return (
    <TableRow
      sx={{
        background: theme.palette.grey[200],
        display: isHidden ? 'none' : 'table-row',
        borderBottom: '2px inset',
      }}
      data-testid={`${task.category}-${taskIndex}`}
    >
      <TableCell aria-label="task version" sx={{ whiteSpace: 'nowrap' }}>
        {getTaskIcon()}
        {isLatest || isReadOnly || task.custom ? (
          <Tooltip title={estimatorStrings.UP_TO_DATE}>
            <CheckIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip
            title={
              task.version > 0 && task.latestVersion
                ? estimatorStrings.OUT_OF_DATE
                : estimatorStrings.TASK_WAS_UPDATED
            }
          >
            {task.latestVersion ? (
              <IconButton
                sx={{ cursor: 'pointer' }}
                onClick={() => handleVersionSyncOpen(task)}
              >
                <MoreHorizIcon color="warning" />
              </IconButton>
            ) : (
              <IconButton sx={{ cursor: 'pointer' }}>
                <DoNotDisturbOnIcon color="error" />
              </IconButton>
            )}
          </Tooltip>
        )}
      </TableCell>

      <TableCell aria-label="quantity or selection">
        {isDropDown ? (
          <Select
            size="small"
            aria-label="select options"
            value={taskState.dropDownIndex}
            name="dropDownIndex"
            sx={{ minWidth: '185px' }}
            onChange={handleChange}
            disabled={task.disableQuantity || isReadOnly}
          >
            <MenuItem key={`${task.id}-0`} value={0}>
              -- Select --
            </MenuItem>
            {Array.isArray(listNamesArray) &&
              listNamesArray.map((data, index) => (
                <MenuItem key={`${task.id}-${data}-${index}`} value={index + 1}>
                  {data}
                </MenuItem>
              ))}
          </Select>
        ) : isCheckbox ? (
          <Checkbox
            aria-label="task options"
            onChange={handleChange}
            checked={taskState.quantity === 1}
            name="quantity"
            disabled={task.disableQuantity || isReadOnly}
          />
        ) : isButton && !isReadOnly ? (
          getTextTaskField()
        ) : (
          <TextField
            size="small"
            value={taskState.quantity ?? 0}
            type="number"
            onWheel={handleOnWheel}
            disabled={task.disableQuantity || isReadOnly}
            onFocus={handleTaskFocus}
            onChange={handleInputChange}
            onBlur={handleChange}
            inputProps={{ 'aria-label': 'task quantity' }}
            name="quantity"
          />
        )}
      </TableCell>

      <TableCell aria-label="task notes" sx={{ whiteSpace: 'nowrap' }}>
        <IconButton
          onClick={() => handleNotesOpen(task)}
          aria-label={
            task.noteCount > 0
              ? `${task.noteCount} task notes`
              : 'add task note'
          }
        >
          <Badge badgeContent={task.noteCount} color="primary">
            <CommentIcon />
          </Badge>
        </IconButton>
        {isManagedService && !isReadOnly && (
          <>
            <IconButton
              title={
                taskState.proposalDescription ??
                'Click to Edit Task Proposal Description'
              }
              onClick={() => handleProposalDescriptionDialogOpen(task)}
            >
              <NoteAltIcon />
            </IconButton>
            <Checkbox
              aria-label="always show on proposal?"
              title="Always show on proposal?"
              checked={taskState.alwaysShowOnProposal ?? false}
              onChange={handleChange}
              name="alwaysShowOnProposal"
            />
          </>
        )}
        {taskState.comment && (
          <IconButton>
            <Tooltip
              title={
                getHtmlDescription(task?.comment, task?.comment.length) ?? ''
              }
            >
              <InfoIcon></InfoIcon>
            </Tooltip>
          </IconButton>
        )}
        {!isReadOnly && (task.allowAdminEdit || task.custom) && (
          <IconButton
            title="Click to Edit Task"
            onClick={() => handleTaskEdit(task.id, task.siteId)}
          >
            <EditIcon />
          </IconButton>
        )}
        {!isReadOnly && task.custom && (
          <IconButton
            title="Delete Task"
            onClick={() => handleRemoveCustomTask(task.id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </TableCell>

      <TableCell
        aria-label="task name"
        sx={{ width: '20%' }}
        title={'Task Group: ' + task.taskGroupName}
      >
        {task.allowAdminEdit && `(${task.taskId} - ${task.order}) `}

        {task.name}
      </TableCell>

      <TableCell aria-label="task units">{task.units}</TableCell>
      {!task.exclude ? (
        <>
          <TableCell aria-label="primary role">
            {![TASK_TYPES.COST, TASK_TYPES.TEXT, TASK_TYPES.PRODUCT].includes(
              task.type as TASK_TYPES
            ) && (
              displayTaskPrimaryRole()
            )}
          </TableCell>
          <TableCell aria-label="secondary role">
            {![TASK_TYPES.COST, TASK_TYPES.TEXT, TASK_TYPES.PRODUCT].includes(
              task.type as TASK_TYPES
            ) && (
              displayTaskSecondaryRole()
            )}
          </TableCell>
          {task.type !== 'Cost' && task.type !== 'Product' ? (
            <TableCell aria-label="overtime">
              {![TASK_TYPES.TEXT].includes(task.type as TASK_TYPES) && (
                <Checkbox
                  checked={taskState.overtime}
                  onChange={handleChange}
                  name="overtime"
                  disabled={isReadOnly}
                />
              )}
            </TableCell>
          ) : (
            <TableCell aria-label="GP">
              {![TASK_TYPES.TEXT].includes(task.type as TASK_TYPES) &&
                task.allowMarkup && (
                  <Tooltip
                    title={`Please enter a value between 0-${maxAdditionalGP}`}
                  >
                    <TextField
                      size="small"
                      value={round(taskState.grossProfit, 2)}
                      type="number"
                      onWheel={handleOnWheel}
                      onChange={handleInputChange}
                      onBlur={handleChange}
                      disabled={isReadOnly}
                      onFocus={handleTaskFocus}
                      label="GP"
                      name="grossProfit"
                      error={round(taskState.grossProfit, 2) > maxAdditionalGP}
                      helperText={
                        round(taskState.grossProfit, 2) > maxAdditionalGP
                          ? `Please enter a value between 0-${maxAdditionalGP}`
                          : null
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                      }}
                    />
                  </Tooltip>
                )}
            </TableCell>
          )}
          <TableCell aria-label="task hours">
            {![TASK_TYPES.TEXT].includes(task.type as TASK_TYPES) && (
              <div>
                {task.type === 'Cost' || task.type === 'Product' ? (
                  <TextField
                    size="small"
                    type="number"
                    value={getSellPrice()}
                    onWheel={handleOnWheel}
                    onChange={handleInputChange}
                    onBlur={handleChange}
                    onFocus={handleTaskFocus}
                    disabled={taskState.disableCost || isReadOnly}
                    label="MSRP"
                    name="sellPrice"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <>
                    {!task.hideHours && (
                      <TextField
                        size="small"
                        value={taskState.hours ? taskState.hours : 0}
                        sx={{
                          width: `${
                            task.allowTotalHoursAdjustment ? '80px' : 'auto'
                          }`,
                        }}
                        type="number"
                        onWheel={handleOnWheel}
                        onChange={handleInputChange}
                        onBlur={handleChange}
                        disabled={taskState.disableHours || isReadOnly}
                        label="Hours"
                        name="hours"
                        onFocus={handleTaskFocus}
                      />
                    )}

                    {task.allowTotalHoursAdjustment && (
                      <TextField
                        size="small"
                        value={taskState.totalHoursAdjustment}
                        sx={{
                          width: `${task.hideHours ? 'auto' : '80px'}`,
                          marginLeft: `${task.hideHours ? '0' : '5px'}`,
                        }}
                        type="number"
                        title="Adjust total hours by +/- value"
                        onWheel={handleOnWheel}
                        onChange={handleInputChange}
                        onBlur={handleChange}
                        disabled={isReadOnly}
                        label="Hrs Adj +/-"
                        name="totalHoursAdjustment"
                        onFocus={handleTaskFocus}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </TableCell>
          <TableCell aria-label="total hours">
            {![TASK_TYPES.TEXT].includes(task.type as TASK_TYPES) && (
              <div>
                <Tooltip title={task.totalHours} arrow>
                  <Typography>
                    {task.type === TASK_TYPES.COST || task.type === TASK_TYPES.PRODUCT
                      ? formatCurrency(task.totalSellPrice ?? 0)
                      : formatNumber(task.totalHours ?? 0, 2)}
                  </Typography>
                </Tooltip>
              </div>
            )}
          </TableCell>
        </>
      ) : (
        <>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </>
      )}
    </TableRow>
  );
}

export default TaskItem;

export const MemorizedTask = React.memo(TaskItem);
