import {
  ProjectItemQuote,
  GlobalQuoteSettingsFields,
} from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import React from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useGlobalSettingsTable } from '@cdw-selline/ui/hooks';
import { TableCell, TableRow, Input } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import OutputTable from '../output-table/OutputTable';

export interface GlobalQuoteSettingsTableProps {
  quoteData: ProjectItemQuote;
  tableData: { tableName: string; rowName: string; value: string }[];
  setting: GlobalQuoteSettingsFields;
}

export function GlobalQuoteSettingsTable({
  quoteData,
  tableData,
  setting,
}: GlobalQuoteSettingsTableProps) {
  const { handleGlobalSettingsChange, loading, error } =
    useGlobalSettingsTable(quoteData);

  return (
    <OutputTable
      title={tableData?.[0]?.tableName}
      testId={`global-settings-table-${setting}`}
    >
      {tableData.map((row, idx) => (
        <TableRow key={row.rowName + idx}>
          <TableCell>{row.rowName}</TableCell>
          <TableCell padding="none">
            <Input
              defaultValue={row.value}
              onChange={(e) =>
                handleGlobalSettingsChange(setting, row.rowName, e.target.value)
              }
            />
          </TableCell>
        </TableRow>
      ))}
    </OutputTable>
  );
}

export default GlobalQuoteSettingsTable;
