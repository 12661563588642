import { Opportunity, Opportunity as Project } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import React, { Dispatch, SetStateAction } from 'react';
import { FormSelect, FormDateField } from '../formHelperFunctions';
import { Grid, MenuItem, FormLabel } from '@mui/material';
import { probabilityOfClose } from '@cdw-selline/ui/constants';
import { ProjectComments } from 'libs/ui/pages/src/lib/project-page/activity/ProjectComments';

export interface KanbanDetailsFormProps {
  project: Project;
  formData: Opportunity;
  setFormData: Dispatch<SetStateAction<Opportunity>>;
}

export const KanbanDetailsForm = (props: KanbanDetailsFormProps) => {
  const { id } = props.project;

  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="center"
      direction="row"
      spacing={1}
      mt={1}
      sx={{ minHeight: '40vh', width: '800px' }}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="center"
        direction="column"
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Grid container mt={2} mb={2} justifyContent="flex-end">
          <FormSelect
            name="probability"
            label="Probability of Close"
            setFormData={props.setFormData}
            formData={props.formData}
          >
            {probabilityOfClose.map((probability) => (
              <MenuItem
                key={`probability-${probability.value}`}
                value={probability.value}
              >
                {probability.label}
              </MenuItem>
            ))}
          </FormSelect>
        </Grid>
        <Grid container mt={1} mb={2} justifyContent="flex-end">
          <FormDateField
            name="estClose"
            label="Est. Close"
            setFormData={props.setFormData}
            formData={props.formData}
          />
        </Grid>
        <FormLabel>Chatter</FormLabel>
        <ProjectComments projectId={id} />
      </Grid>
    </Grid>
  );
};
