import { useQuery } from '@apollo/client';
import { GET_CUSTOMERS_QUERY } from '@cdw-selline/ui/queries';
import { GetCustomersResponse } from '@cdw-selline/common/types';

import { useApolloErrors } from './useApolloErrors';

export const useCustomers = ({ limit = 20, offset = 0, filters = {}, sort = undefined }) => {
  const params = {
    limit,
    offset,
    filters,
    sort,
  };
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data, refetch } = useQuery<{
    getCustomers: GetCustomersResponse;
  }>(GET_CUSTOMERS_QUERY, {
    variables: params,
    onError: (error) =>
      handleErrorResponse(error, 'Failed to fetch Customers!'),
  });

  return {
    data: data?.getCustomers ?? null,
    error,
    loading,
    refetch,
  };
};

export default useCustomers;
