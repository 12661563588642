import { useMutation } from '@apollo/client';
import {
  GET_ADMIN_SERVICE_SKILLS,
  SYNC_SERVICE_SKILLS_MUTATION,
} from '@cdw-selline/ui/queries';
import { useState } from 'react';
import { useApolloErrors } from '..';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getFilters } from '@cdw-selline/ui/helpers';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useServiceSkills } from '@cdw-selline/ui/hooks';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 400,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Service Skill Name',
    width: 900,
  },
];

export const useAdminServiceSkills = () => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [sortState, setSortState] = useState();

  const { data, loading, error, refetch } = useServiceSkills({
    filters: getFilters('ServiceSkillsCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });

  const [
    syncServiceSkills,
    { loading: syncServiceSkillsLoading, error: syncServiceSkillsError },
  ] = useMutation(SYNC_SERVICE_SKILLS_MUTATION, {
    refetchQueries: [GET_ADMIN_SERVICE_SKILLS],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to sync Service Skills!'),
    onCompleted: (data) => {
      if (data.syncServiceSkills.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully synced Service Skills!',
        });
      }
    },
  });

  const filterModel = JSON.parse(
    localStorage.getItem('ServiceSkillsCollectionPage-filter')
  );

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('ServiceSkillsCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('ServiceSkillsCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('ServiceSkillsCollectionPage') });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data?.serviceSkills || ([] as GridRowsProp),
    handlePageChange,
    handlePageBack,
    handlePageSizeChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    serviceSkills: data?.serviceSkills,
    rowCount: data?.count || 0,
    loading: loading || syncServiceSkillsLoading,
    error: error || syncServiceSkillsError,
    syncServiceSkills,
    ...paginationState,
  };
};
