import React, {
  Dispatch,
  SetStateAction,
  useState,
  useEffect,
  useRef,
} from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  Button,
  DialogActions,
  Typography,
  Box,
  Grid,
  FormControl as Form,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { TaskGroup, ProjectSow } from '@cdw-selline/common/types';
import { RichTextEditor } from '../../rich-text-editor/RichTextEditor';
import { ContentEditor } from '../../content-editor/ContentEditor';
import { getDisplayValue } from '@cdw-selline/ui/helpers';

import * as outcomeSow from '../outcome-sow.css';

export interface OutcomeSowModalProps {
  outcomeSowModalOpen?: boolean;
  setOutcomeSowModalOpen: Dispatch<SetStateAction<boolean>>;
  handleSubmit: (e) => unknown;
  handleClose: () => void;
  updateOutcomeSowLoading: boolean;
  taskGroup: TaskGroup[];
  taskGroupId?: string;
  projectSow: ProjectSow;
}

export function OutcomeSowModal(props: OutcomeSowModalProps) {
  const [state, setState] = useState({});
  const [reset, setReset] = useState(false);
  const [emptyInputError, setEmptyInputError] = useState(false);
  const errorMessageRef = useRef<HTMLDivElement>();

  let taskGroup = Array.isArray(props.taskGroup)
    ? props.taskGroup?.find((t) => String(t.id) === props.taskGroupId) ?? {}
    : {};

  if (Object.keys(taskGroup).length === 0 && props.taskGroup.length === 1) {
    taskGroup = props.taskGroup[0];
  }

  useEffect(() => {
    const startingState = {};
    if (taskGroup.executiveSummaryDriversIncluded) {
      startingState['executiveSummaryDrivers'] = props.projectSow.executiveSummaryDrivers ?? taskGroup.executiveSummaryDrivers;
    }
    if (taskGroup.executiveSummaryOverviewIncluded) {
      startingState['executiveSummaryOverview'] = props.projectSow.executiveSummaryOverview ?? taskGroup.executiveSummaryOverview;
    }
    if (taskGroup.businessOutcomeIncluded) {
      startingState['businessOutcome'] = props.projectSow.businessOutcome ?? taskGroup.businessOutcome;
    }
    if (taskGroup.technicalOutcomeIncluded) {
      startingState['technicalOutcome'] = props.projectSow.technicalOutcome ?? taskGroup.technicalOutcome;
    }
    if (taskGroup.outcomeProjectScopePhasesIncluded) {
      startingState['outcomeProjectScopePhases'] = props.projectSow.outcomeProjectScopePhases ?? taskGroup.outcomeProjectScopePhases;
    }
    setState(startingState);
  }, []);

  useEffect(() => {
    if (emptyInputError === true) {
      errorMessageRef.current?.scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [emptyInputError]);

  if (Object.keys(taskGroup).length > 0) {
    const handleEditorChange = (params) => {
      setState((s) => ({
        ...s,
        ...params,
      }));
    };

    const getCurrentValue = (savedValue, adminValue) => {
      if (reset) {
        return getDisplayValue(adminValue);
      }
      return getDisplayValue(savedValue ? savedValue : adminValue);
    };

    const handleFormSubmit = (e: React.FormEvent) => {
      e.preventDefault();

      const formData = { ...state, id: props.projectSow.id };
      const hasEmptyFields = Object.values(state).includes('');

      if (hasEmptyFields) {
        setEmptyInputError(true);
      } else {
        setEmptyInputError(false);
        props.handleSubmit(formData);
        setReset(false);
      }
    };

    const handleFormClose = () => {
      props.handleClose();
      setReset(false);
    };

    const contentStyle = outcomeSow[0] ? outcomeSow[0][1] || '' : '';

    const buildOutcomeBaseSection = (
      label: string,
      property: string,
      helpProperty: string,
      includeProperty: string) => {
      if (!taskGroup[includeProperty]) return;
      return (
        <>
          <Box sx={{ marginTop: 2, marginBottom: 1 }}>
            <Typography variant="h6">{label}</Typography>
          </Box>
          {taskGroup[helpProperty] && <ContentEditor
            documentId={helpProperty}
            contentStyle={contentStyle}
            currentValue={getCurrentValue(props.projectSow[helpProperty], taskGroup[helpProperty])}
          />}
          <br />
          <RichTextEditor
            width="100%"
            documentId={property}
            customEditorOptions={{
              content_style: contentStyle,
              body_class: 'projectSowScope',
            }}
            currentValue={getCurrentValue(props.projectSow[property], taskGroup[property])}
            handleChange={handleEditorChange}
            height="300px"
          />
        </>
      );
    };

    return (
      <DialogModal
        isOpen={props.outcomeSowModalOpen}
        handleClose={handleFormClose}
        title={'Outcome Sow Fields'}
        maxWidthProp="lg"
      >
        <Form>
          <Box sx={{ width: '100%' }}>
            {emptyInputError && (
              <Box sx={{ padding: '15px 0' }} ref={errorMessageRef}>
                <Typography color="error" variant="h6">
                  Please fill out missing field(s) below
                </Typography>
              </Box>
            )}
            {buildOutcomeBaseSection(
              'Executive Summary Overview',
              'executiveSummaryOverview',
              'executiveSummaryOverviewHelp',
              'executiveSummaryOverviewIncluded')}
            {buildOutcomeBaseSection(
              'Executive Summary Drivers',
              'executiveSummaryDrivers',
              'executiveSummaryDriversHelp',
              'executiveSummaryDriversIncluded')}
            {buildOutcomeBaseSection(
              'ICARE Business Outcome',
              'businessOutcome',
              'businessOutcomeHelp',
              'businessOutcomeIncluded')}
            {buildOutcomeBaseSection(
              'ICARE Technical Outcome',
              'technicalOutcome',
              'technicalOutcomeHelp',
              'technicalOutcomeIncluded')}
            {buildOutcomeBaseSection(
              'Project Scope Phases',
              'outcomeProjectScopePhases',
              'outcomeProjectScopePhasesHelp',
              'outcomeProjectScopePhasesIncluded')}
            <DialogActions>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <Button color="secondary" onClick={() => setReset(true)}>
                    Reset to Default Values
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    color="secondary"
                    onClick={handleFormClose}
                    data-testid="update-outcome-sow-cancel-button"
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    color="primary"
                    type="submit"
                    loading={!!props.updateOutcomeSowLoading}
                    variant="contained"
                    data-testid="update-outcome-sow-submit-button"
                    onClick={handleFormSubmit}
                  >
                    Save
                  </LoadingButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Box>
        </Form>
      </DialogModal>
    );
  }
  return null;
}

export default OutcomeSowModal;
