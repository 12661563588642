// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CiscoAnnuityPartNumbersList,
} from '@cdw-selline/ui/components';
import { useCiscoAnnuityPartNumbers } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';

export const CiscoAnnuityPartNumberCollectionPage = () => {
  const dataTableProps = useCiscoAnnuityPartNumbers();

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <CiscoAnnuityPartNumbersList
        categories={dataTableProps.rows}
        tableData={dataTableProps.tableData}
        categoryList={dataTableProps.categoryList}
        handleCategorySave={dataTableProps.handleSaveAll}
        handleDelete={dataTableProps.handleDelete}
      />
    </Box>
  );
};

export default CiscoAnnuityPartNumberCollectionPage;
