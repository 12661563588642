import { useMutation } from '@apollo/client';
import {
  GET_ESTIMATOR_SOW_QUERY,
  UPDATE_AGILOFT_FEE_SUMMARY_MUTATION,
} from '@cdw-selline/ui/queries';
import { DefaultMutationResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '../../useApolloErrors';

export interface useUpdateAgiloftFeeSummary {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useUpdateAgiloftFeeSummary = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useUpdateAgiloftFeeSummary) => {
  const { handleErrorResponse } = useApolloErrors();

  const [updateAgiloftFeeSummaryRunning, setUpdateAgiloftFeeSummaryRunning] =
    useState(false);

  const [
    updateAgiloftFeeSummary,
    { error: updateAgiloftFeeSummaryError, client },
  ] = useMutation<{
    updateAgiloftFeeSummary: DefaultMutationResponse;
  }>(UPDATE_AGILOFT_FEE_SUMMARY_MUTATION, {
    onError: (error) => {
      setProgress(100);
      setUpdateAgiloftFeeSummaryRunning(false);
      handleErrorResponse(error, 'Failed to update agiloft fee clauses!');
    },
    onCompleted: (data) => {
      setProgress(endProgress);
      setUpdateAgiloftFeeSummaryRunning(false);
      client.refetchQueries({
        include: [GET_ESTIMATOR_SOW_QUERY],
      });
    },
  });

  useEffect(() => {
    if (progress === startProgress) {
      setProgressMessage('Updating agiloft fee clauses ..');
      setUpdateAgiloftFeeSummaryRunning(true);
      updateAgiloftFeeSummary({
        variables: {
          params: {
            id: agiloftSOWId,
            projectItemId: projectItemId,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    updateAgiloftFeeSummary,
    agiloftSOWId,
    projectId,
    projectItemId,
  ]);

  useEffect(() => {
    if (progress < 100 && updateAgiloftFeeSummaryRunning) {
      const timer = setTimeout(() => {
        if (updateAgiloftFeeSummaryRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [
    updateAgiloftFeeSummary,
    updateAgiloftFeeSummaryRunning,
    endProgress,
    progress,
    setProgress,
  ]);

  return {
    updateAgiloftFeeSummaryRunning,
    updateAgiloftFeeSummaryError,
  };
};
