import { useMutation } from '@apollo/client';
import { GET_ADMIN_SERVICE_PRACTICES, SYNC_SERVICE_PRACTICES_MUTATION } from '@cdw-selline/ui/queries';
import { useState } from 'react';
import { useApolloErrors } from '..';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getFilters } from '@cdw-selline/ui/helpers';
import {
    ALERT_SEVERITY,
    ALERT_TYPE,
    useAlertsState,
  } from '@cdw-selline/ui/state';
  import { useServicePractices } from '@cdw-selline/ui/hooks';

const columns: GridColumns = [
    {
        field: 'id',
        headerName: 'ID',
        width: 400,
        editable: false,
    },
    { 
        field: 'name',
        headerName: 'Service Practice Name',
        width: 900
    },
];

export const useAdminServicePractices = () => {
    const { handleErrorResponse } = useApolloErrors();
    const alertState = useAlertsState();
    const [paginationState, setPaginationState] = useState({
        offset: 0,
        limit: 100,
        page: 0,
      });
      const [sortState, setSortState] = useState();

      const { data, loading, error, refetch } = useServicePractices({
        filters: getFilters('ServicePracticesCollectionPage'),
        offset: paginationState.offset,
        limit: paginationState.limit,
        sort: sortState,
      });

      const [
        syncServicePractices,
        {
          loading: syncServicePracticesLoading,
          error: syncServicePracticesError,
        },
      ] = useMutation(SYNC_SERVICE_PRACTICES_MUTATION, {
        refetchQueries: [GET_ADMIN_SERVICE_PRACTICES],
        onError: (error) =>
          handleErrorResponse(error, 'Failed to sync Service Practices!'),
        onCompleted: (data) => {
          if (data.syncServicePractices.success) {
            alertState.setAlert({
              type: ALERT_TYPE.SNACKBAR,
              severity: ALERT_SEVERITY.SUCCESS,
              message: 'Successfully synced Service Practices!',
            });
          }
        },
      });
    
      const filterModel = JSON.parse(
        localStorage.getItem('ServicePracticesCollectionPage-filter')
      );

      const handlePageChange = (page: number) => {
        if (page < paginationState.page) return handlePageBack(page);
        sessionStorage.offset = paginationState.offset + paginationState.limit;
        sessionStorage.page = paginationState.page + 1;
        setPaginationState((p) => ({
          ...p,
          offset: Number(sessionStorage.offset),
          page: Number(sessionStorage.page),
        }));
        refetch({
          filters: getFilters('ServicePracticesCollectionPage'),
          offset: paginationState.offset,
          limit: paginationState.limit,
        });
      };
    
      const handlePageBack = (page: number) => {
        sessionStorage.offset = paginationState.offset - paginationState.limit;
        sessionStorage.page = paginationState.page - 1;
        setPaginationState((p) => ({
          ...p,
          offset: Number(sessionStorage.offset),
          page: Number(sessionStorage.page),
        }));
        refetch({
          filters: getFilters('ServicePracticesCollectionPage'),
          offset: paginationState.offset,
          limit: paginationState.limit,
        });
      };
    
      const handlePageSizeChange = (size: number) => {
        setPaginationState((p) => ({
          ...p,
          limit: size,
        }));
        refetch({ offset: paginationState.offset, limit: paginationState.limit });
      };
    
      const onFilterModelChange = (filterModel) => {
        refetch({ filters: getFilters('ServicePracticesCollectionPage') });
      };

      const handleSort = (args) => {
        let newSort;
        if (args.length) newSort = { [args[0].field]: args[0].sort };
        setSortState(newSort);
      };


  return {
    columns,
    rows: data?.servicePractices || ([] as GridRowsProp),
    handlePageChange,
    handlePageBack,
    handlePageSizeChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    servicePractices: data?.servicePractices,
    rowCount: data?.count || 0,
    loading: loading,
    syncServicePractices,
    ...paginationState,
  };
};