import { CircularProgress, Grid, Typography } from '@mui/material';
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useNotFoundPage } from './useNotFoundPage';

/* eslint-disable-next-line */
export interface NotFoundPageProps {}

export const NotFoundPage = (props: NotFoundPageProps) => {
  useNotFoundPage();
  const defaultTitle = 'Ultron';
  const pageTitle = '404 Page Not Found';

  return (
    <HelmetProvider>
      <title>{`${defaultTitle} - ${pageTitle}`}</title>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Grid item xs={12} sx={{ display: 'flex', marginTop: '1em' }}>
          <Typography data-test-id="appTitle" variant="h1">
            {pageTitle}
          </Typography>
        </Grid>
        <Grid item sx={{ display: 'flex' }} xs={12}>
          <CircularProgress />
          Redirecting to login in 5 seconds...
        </Grid>
      </Grid>
    </HelmetProvider>
  );
};

export default NotFoundPage;
