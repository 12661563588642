import { Customer } from '@cdw-selline/common/types';
import React, { useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import { Grid, Typography, Box } from '@mui/material';
import {
  FormText,
  getActionButtons,
} from '../../formHelperFunctions';

export interface CustomerFormProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (customer: Customer) => void;
  customer?: Customer | null;
  customerCodes?: string[];
  apiErrorMessage?: string;
}

const customerDefaults: Customer = {
  name: '',
  customerCode: '',
};

export function CustomerForm({
  isOpen,
  handleClose,
  handleSubmit,
  customer,
  apiErrorMessage,
  customerCodes,
}: CustomerFormProps) {

  const [formData, setFormData] = useState(customer ?? customerDefaults);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(()=>{
    if(apiErrorMessage){
      setErrorMessage(apiErrorMessage);
      setError(true);
    }
  },[apiErrorMessage])
  useEffect(() => {
    if (customer?.id) {
        setFormData(customer);
    }
  }, [customer]);

  const handleFormClose = () => {
    setFormData(customerDefaults);
    setError(false);
    handleClose();
  };

  const handleSaveClick = () => {
    if (!formData?.name || !formData?.customerCode) {
        setErrorMessage("Please add all required field values !");
        setError(true);
        return;
    }
    handleSubmit(formData);
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={formData?.id && 'Edit Customer' || 'Add Customer'}
      action={getActionButtons(handleFormClose, handleSaveClick)}
      maxWidthProp={'lg'}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '50vh' }}
        data-testid="customer-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">{errorMessage}</Typography>
            </Box>
          )}
          <FormText
            name="name"
            label="Customer Name"
            required={true}
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="customerCode"
            label="Code"
            disabled={!!customer.customerCode}
            required={true}
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="customerDescription"
            label="Description"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="addressLine1"
            label="Street 1"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="addressLine2"
            label="Street 2"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="city"
            label="City"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="stateCode"
            label="State"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="zipCode"
            label="ZipCode"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="onedriveId"
            label="One Drive Id"
            setFormData={setFormData}
            formData={formData}
          />
          <FormText
            name="onedriveLink"
            label="One Drive Link"
            setFormData={setFormData}
            formData={formData}
          />
        </Grid>
      </Grid>
    </DialogModal>
  );
}
