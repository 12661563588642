import React, { useState } from 'react';
import { Editor } from 'tinymce';
import { useOpenState } from '@cdw-selline/ui/hooks';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormRichTextEditor,
  FormAutocompleteOption,
} from '../formHelperFunctions';
import { debounce } from 'lodash';
import { MenuItem, Grid } from '@mui/material';
import { TASK_TEXT_TYPES } from '@cdw-selline/ui/constants';
import * as detailedSow from '../project-sow/detailed-sow.css';
import { TaskModal } from '@cdw-selline/ui/components';

export interface TextTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  practiceOptions: FormAutocompleteOption[];
  taskGroupOptions: FormAutocompleteOption[];
  vendorOptions: FormAutocompleteOption[];
  isCustom: boolean;
  errorsArray: any;
}

export function TextTaskForm({
  taskFormData,
  setTaskFormData,
  practiceOptions,
  taskGroupOptions,
  vendorOptions,
  isCustom,
  errorsArray
}: TextTaskFormProps) {
  const [editor, setEditor] = React.useState(null);
  const [taskSub, setTaskSub] = React.useState(false);
  const contentStyle = detailedSow[0] ? detailedSow[0][1] || '' : '';
  const {
    isOpen: taskOpen,
    handleClose: handleTaskClose,
    handleOpen: handleTaskOpen,
  } = useOpenState();

  const taskModal = async (editor, taskSub: boolean) => {
    setEditor(editor);
    setTaskSub(taskSub);
    handleTaskOpen();
  };

  const setup = (editor: Editor) => {
    editor.ui.registry.addButton('task', {
      text: 'Task',
      onAction: () => {
        taskModal(editor, false);
      },
    });

    editor.ui.registry.addButton('tasksub', {
      text: 'TaskSub',
      onAction: () => {
        taskModal(editor, true);
      },
    });
  };

  const handleEditorChange = (params) => {
    setTaskFormData((s) => ({
      ...s,
      ...params,
    }));
  };

  const debouncedHandleEditorChange = debounce(handleEditorChange, 1000);

  return (
    <>
      <FormSelect
        name="textType"
        label="Text Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.textType}
      >
        {Object.keys(TASK_TEXT_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_TEXT_TYPES[type]}>
            {TASK_TEXT_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      <FormTextControlled
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      {!isCustom && (
        <FormSelect
          name="estimatorLocation"
          label="Estimator Location"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.estimatorLocation}
        >
          <MenuItem value="site">Site</MenuItem>
          <MenuItem value="summary">Summary</MenuItem>
        </FormSelect>
      )}
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {!isCustom && taskFormData.practiceId && (
        <>
          <FormAutocomplete
            name="taskGroupId"
            label="Task Group"
            options={taskGroupOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskGroupId}
          />
          <FormText
            name="taskId"
            label="Task Id"
            setFormData={setTaskFormData}
            formData={taskFormData}
            data-testid="task-id"
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskId}
          />
        </>
      )}
      <>
        <FormText
          name="category"
          label="Category"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormText
          name="sprint"
          label="Sprint"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        <FormAutocomplete
          name="vendor"
          label="Vendor"
          options={vendorOptions}
          setFormData={setTaskFormData}
          formData={taskFormData}
          freeSolo={true}
        />
        <FormTextControlled
          name="comment"
          label="Comment"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
        {!isCustom && (
          <>
            <Grid item xs={12} sx={{ width: '100%' }}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <FormText
                    name="sku"
                    label="SKU"
                    setFormData={setTaskFormData}
                    formData={taskFormData}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="edc"
                    label="EDC"
                    setFormData={setTaskFormData}
                    formData={taskFormData}
                  />
                </Grid>
              </Grid>
            </Grid>
            <FormCheckbox
              name="hide"
              label="Hide task?"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            {!taskFormData.hide && (
              <FormText
                name="hideShowFormula"
                label="Hide/Show Formula"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
            )}
            <FormCheckbox
              name="allowBlankText"
              label="Allow Blank Text?"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            <FormCheckbox
              name="textDisabled"
              label="Disable Text?"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            <FormText
              name="textFormula"
              label="Text Formula"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            <FormText
              name="textDisabledFormula"
              label="Text Disabled Formula"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
            <FormRichTextEditor
              name="instructionsText"
              label="Intsructions Text"
              customEditorOptions={{
                content_style: contentStyle,
                body_class: 'projectSowScope',
                forced_root_block: '',
                toolbar:
                  'task tasksub | undo redo | ' +
                  'formatselect fontselect fontsizeselect | ' +
                  'bold italic | ' +
                  'alignleft aligncenter ' +
                  'alignright alignjustify | ' +
                  'bullist numlist outdent indent | ' +
                  'forecolor backcolor | paste ',
                setup,
              }}
              value={taskFormData.instructionsText}
              handleEditorChange={debouncedHandleEditorChange}
            />
            <FormRichTextEditor
              name="text"
              label="Text"
              customEditorOptions={{
                content_style: contentStyle,
                body_class: 'projectSowScope',
                forced_root_block: '',
                toolbar:
                  'task tasksub | undo redo | ' +
                  'formatselect fontselect fontsizeselect | ' +
                  'bold italic | ' +
                  'alignleft aligncenter ' +
                  'alignright alignjustify | ' +
                  'bullist numlist outdent indent | ' +
                  'forecolor backcolor | paste ',
                setup,
              }}
              value={taskFormData.text}
              handleEditorChange={debouncedHandleEditorChange}
            />
            {taskOpen && (
              <TaskModal
                {...{
                  isOpen: taskOpen,
                  handleClose: handleTaskClose,
                  handleOpen: handleTaskOpen,
                  editor: editor,
                  taskSub: taskSub,
                }}
              />
            )}
          </>
        )}
      </>
    </>
  );
}
