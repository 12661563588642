import { useState } from 'react';

export const datagridPagination = (refetch, filter, sort?: any) => {
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [sortState, setSortState] = useState<any>(sort || {});
  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack();
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Math.max(Number(sessionStorage.offset), 0),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Math.max(Number(sessionStorage.offset), 0),
      limit: paginationState.limit,
      filters: filter,
      sort: sortState,
    });
  };

  const handlePageBack = () => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Math.max(Number(sessionStorage.offset), 0),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Math.max(Number(sessionStorage.offset), 0),
      limit: paginationState.limit,
      filters: filter,
      sort: sortState,
    });
  };

  const handlePageSizeChange = (size: number) => {
    const newOffset = (paginationState.offset / paginationState.limit) * size;
    setPaginationState((p) => ({
      ...p,
      offset: newOffset,
      limit: size,
    }));
    refetch({
      offset: newOffset,
      limit: size,
      filters: filter,
      sort: sortState,
    });
  };

  const handleSort = (args) => {
    let newSort = sort ||{};
    if(args.length)
    newSort = {
      [args[0].field]: args[0].sort === "asc" ? 1 : -1,
    };
    setSortState(newSort);
    refetch({
      offset: paginationState.offset,
      limit: paginationState.limit,
      filters: filter,
      sort: newSort,
    });
  };

  return {
    handlePageBack,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    paginationState,
    setPaginationState,
  };
};
