import { useMutation } from '@apollo/client';
import {
    Customer,
    DefaultAddResponse,
    DefaultMutationResponse,
} from '@cdw-selline/common/types';
import {
  ADD_CUSTOMER_MUTATION,
  GET_CUSTOMERS_QUERY,
  GET_CUSTOMER_BY_ID_QUERY,
  UPDATE_CUSTOMER_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import moment from 'moment';

import { useApolloErrors } from './useApolloErrors';
import { useCustomers } from './useCustomers';
import { useState } from 'react';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import { getFilters } from '@cdw-selline/ui/helpers';
import { uniq } from 'lodash';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Customer ID',
    width: 200,
    editable: false,
    flex: 1,
  },
  { field: 'name', headerName: 'Customer Name', width: 250 },
  {
    field: 'customerCode',
    headerName: 'Code',
    width: 200,
    editable: false,
    flex: 1,
  },
  {
    field: 'updatedAt',
    headerName: 'Last Modified',
    type: 'dateTime',
    width: 220,
    editable: false,
    flex: 1,
    valueFormatter: (params) =>
      params?.value && moment(parseInt(params?.value)).format('MM/DD/YYYY'),
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(parseInt(params?.value)).format('MM/DD/YYYY'),
  },
];

export const useAdminCustomers = () => {
  const [sortState, setSortState] = useState();
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [apiErrorMessage,setApiErrorMessage] = useState("")
  const { data, loading, error, refetch } = useCustomers({
    filters: getFilters('CustomersCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const filterModel = JSON.parse(
    localStorage.getItem('CustomersCollectionPage-filter')
  );
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [editCustomerId, setEditCustomerId] = useState(null);
  const {
    isOpen: customerFormOpen,
    handleClose: closeCustomerForm,
    handleOpen: openCustomerForm,
  } = useOpenState();

  const [
    addCustomer,
    { loading: addCustomerLoading, error: addCustomerError },
  ] = useMutation<{ addCustomer: DefaultAddResponse }>(ADD_CUSTOMER_MUTATION, {
    refetchQueries: [GET_CUSTOMERS_QUERY],
    onError: (error) =>
      {
        if(error.message.includes("dup key: { customerCode:")){
          setApiErrorMessage("Customer code has to be unique !")
        }else{
          handleErrorResponse(error, 'Failed to create new customer')
        }
        
      },
    onCompleted: (data) => {
      if (data.addCustomer.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully added customer',
        });
        handleCustomerFormClose();
      }
    },
  });

  const [
    updateCustomer,
    { loading: updateCustomerLoading, error: updateCustomerError },
  ] = useMutation<{ updateCustomer: DefaultMutationResponse }>(
    UPDATE_CUSTOMER_MUTATION,
    {
      refetchQueries: [GET_CUSTOMERS_QUERY, GET_CUSTOMER_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update customer'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateCustomer.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated customer',
          });
          handleCustomerFormClose();
        }
      },
    }
  );

  const handleAdd = () => {
    openCustomerForm();
  };
  const handleCustomerFormClose = () => {
    setEditCustomerId(null);
    closeCustomerForm();
    setApiErrorMessage("")
  };
  const handleEdit = (id: string) => {
    setEditCustomerId(id);
    openCustomerForm();
  };

  const handleCustomerFormSave = (customer: Customer) => {
    if (!customer.customerDescription) {
      customer.customerDescription = customer.name;
    }
    
    if (!customer.id) {
      addCustomer({
        variables: {
          params: omitDeep(customer, ['__typename']),
        },
      });
    } else {
        updateCustomer({
            variables: {
              params: omitDeep(customer, ['__typename']),
            },
        });
    }
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('CustomersCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('CustomersCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const onFilterModelChange = (filterModel) => {
    
    refetch({ filters: getFilters('CustomersCollectionPage') });
  };

  const customerCodes = uniq<string>(
    data?.customers
      .filter((customer) => !!customer.customerCode)
      .map((customer) => customer.customerCode)
  );

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data?.customers || ([] as GridRowsProp),
    customerCodes,
    handleAdd,
    handleEdit,
    handleSort,
    handlePageChange,
    handlePageBack,
    handlePageSizeChange,
    onFilterModelChange,
    filterModel,
    editCustomerId,
    addCustomerLoading,
    addCustomerError,
    customerFormOpen,
    handleCustomerFormClose,
    handleCustomerFormSave,
    rowCount: data?.count,
    apiErrorMessage,
    loading: loading || addCustomerLoading,
  };
};
