import { gql } from '@apollo/client';

export const GET_TASK_GROUPS_QUERY = gql`
  query getTaskGroupsQuery(
    $limit: Int
    $offset: Int
    $filters: MongoFilter
    $sort: MongoFilter
  ) {
    getTaskGroups {
      count
      taskGroups(
        limit: $limit
        offset: $offset
        filters: $filters
        sort: $sort
      ) {
        name
        order
        practice
        published
        riskPercent
        sowScopeFooter
        sowScopeHeader
        lastReviewedOn
        lastReviewedBy
        id
        fixedFee
        createdAt
        createdBy
        outcomeIncluded
        outcomeSowHeader
        outcomeSowFooter
        executiveSummaryOverview
        executiveSummaryOverviewHelp
        executiveSummaryDrivers
        executiveSummaryDriversHelp
        businessOutcome
        businessOutcomeHelp
        technicalOutcome
        technicalOutcomeHelp
        outcomeProjectScope
        outcomeProjectScopePhases
        outcomeProjectScopePhasesHelp
        agiloftServiceCategory
        agiloftScopeType
        agiloftOutcomeScopeType
        agiloftDefaultPrintTemplate
        agiloftPrintTemplateTypeOptions
        agiloftManagedScopeType
        agiloftBlendedScopeType
        agiloftRecurringServicesFees
        agiloftClauses
        bidAssuranceUrl
        bidAssuranceGroup
        taskGroupTeam {
          email
        }
        itemsProvidedToCustomers {
          item
          description
          format
          id
          taskId
          operator
          value
        }
        version
      }
    }
  }
`;

export default GET_TASK_GROUPS_QUERY;
