import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  CdwRate,
} from '@cdw-selline/common/types';
import {
  ADD_CDW_RATE_MUTATION,
  GET_CDW_RATES_QUERY,
  GET_CDW_RATE_BY_ID_QUERY,
  REMOVE_CDW_RATE_MUTATION,
  SYNC_CDW_RATES_MUTATION,
  UPDATE_CDW_RATE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import {
  GridColumns,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';
import { useCDWRates } from './useCDWRates';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import { getFilters } from '@cdw-selline/ui/helpers';
import { formatCurrency } from '@cdw-selline/ui/helpers';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    editable: false,
    hide: true,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'practiceName',
    headerName: 'Practice',
    flex: 1,
  },
  {
    field: 'cost',
    headerName: 'Cost',
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams) => {
      return `${formatCurrency(params?.value) ?? 0.0}`;
    },
    type: 'number',
  },
];

export const useAdminCdwRates = () => {
  const [sortState, setSortState] = useState();
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });

  const filterModel = JSON.parse(
    localStorage.getItem('CdwRatesCollectionPage-filter')
  );

  const { data, loading, error, refetch } = useCDWRates({
    filters: getFilters('CdwRatesCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editCdwRateId, setEditCdwRateId] = useState(null);
  const {
    isOpen: cdwRateFormOpen,
    handleClose: closeCdwRateForm,
    handleOpen: openCdwRateForm,
  } = useOpenState();

  const [addCdwRate, { loading: addCdwRateLoading, error: addCdwRateError }] =
    useMutation<{ addCdwRate: DefaultAddResponse }>(ADD_CDW_RATE_MUTATION, {
      refetchQueries: [GET_CDW_RATES_QUERY, GET_CDW_RATE_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new cdwRate'),
      onCompleted: (data) => {
        if (data.addCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added cdwRate',
          });
        }
      },
    });

  const [
    removeCdwRate,
    { loading: removeCdwRateLoading, error: removeCdwRateError },
  ] = useMutation<{ removeCdwRate: DefaultMutationResponse }>(
    REMOVE_CDW_RATE_MUTATION,
    {
      refetchQueries: [GET_CDW_RATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove cdwRate'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed cdwRate',
          });
        }
      },
    }
  );

  const [
    updateCdwRate,
    { loading: updateCdwRateLoading, error: updateCdwRateError },
  ] = useMutation<{ updateCdwRate: DefaultMutationResponse }>(
    UPDATE_CDW_RATE_MUTATION,
    {
      refetchQueries: [GET_CDW_RATES_QUERY, GET_CDW_RATE_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update cdwRate'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateCdwRate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated cdwRate',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openCdwRateForm();
  };

  const handleCdwRateFormClose = () => {
    setEditCdwRateId(null);
    closeCdwRateForm();
  };

  const handleDelete = (pid: string) =>
    removeCdwRate({
      variables: { removeCdwRateId: pid },
    });
  const handleEdit = (id: string) => {
    setEditCdwRateId(id);
    openCdwRateForm();
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: paginationState.offset,
      limit: paginationState.limit,
      filters: getFilters('CdwRatesCollectionPage'),
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('CdwRatesCollectionPage') });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
      filters: getFilters('CdwRatesCollectionPage'),
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleCdwRateFormSave = (cdwRate: CdwRate) => {
    if (!cdwRate.id) {
      addCdwRate({
        variables: {
          params: omitDeep(cdwRate, ['__typename']),
        },
      });
    }

    if (cdwRate.id) {
      updateCdwRate({
        variables: {
          params: omitDeep(cdwRate, ['__typename']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const [
    syncCdwRates,
    {
      loading: syncCdwRatesLoading,
      error: syncCdwRatesError,
    },
  ] = useMutation(SYNC_CDW_RATES_MUTATION, {
    refetchQueries: [GET_CDW_RATES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to sync CDW rates!'),
    onCompleted: (data) => {
      if (data.syncCdwRates.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully synced CDW rates!',
        });
      }
    },
  });

  return {
    columns,
    rows: data.rates || ([] as GridRowsProp),
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageSizeChange,
    handlePageBack,
    onFilterModelChange,
    handleSort,
    filterModel: filterModel ?? {
      items: [{ columnField: 'name', operatorValue: 'contains', value: '' }],
    },
    cdwRates: data.rates,
    editCdwRateId,
    addCdwRateLoading,
    addCdwRateError,
    removeCdwRateLoading,
    removeCdwRateError,
    loading: loading || addCdwRateLoading || removeCdwRateLoading,
    error,
    cdwRateFormOpen,
    handleCdwRateFormClose,
    handleCdwRateFormSave,
    rowCount: data.count,
    ...paginationState,
    syncCdwRates
  };
};
