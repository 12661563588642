// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ciscoAnnuityStatusActiveStep,
  ciscoAnnuityStatusSteps,
  ciscoAnnuityStatusActionLabel,
} from '@cdw-selline/ui/constants';
import {
  Button,
  Grid,
  IconButton,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useCiscoAnnuityUpdate } from '@cdw-selline/ui/hooks';
import { CiscoAnnuity, CiscoAnnuityContactType, Opportunity } from '@cdw-selline/common/types';
import ClearIcon from '@mui/icons-material/Clear';
import { ALERT_SEVERITY, ALERT_TYPE, useAlertsState } from '@cdw-selline/ui/state';

export interface CiscoAnnuityStatusStepperProps {
  ciscoAnnuity: CiscoAnnuity;
  project: Opportunity;
  projectItemId: string;
  userIsCloudFulfillment: boolean;
}

export const CiscoAnnuityStatusStepper = ({
  ciscoAnnuity,
  project,
  projectItemId,
  userIsCloudFulfillment,
}: CiscoAnnuityStatusStepperProps) => {
  const theme = useTheme();
  const { handleCiscoAnnuityUpdate, handleRequestWordCofById } =
    useCiscoAnnuityUpdate();
  const [currentStatus, setCurrentStatus] = useState(ciscoAnnuity.status);
  const [denyMessageOpen, setDenyMessageOpen] = useState(false);
  const [denyMessage, setDenyMessage] = useState('');
  const [denyMessageError, setDenyMessageError] = useState('');
  const alertState = useAlertsState();

  useEffect(() => {
    setCurrentStatus(ciscoAnnuity.status);
  }, [ciscoAnnuity]);

  const handleCompletePhase = () => {
    const newStatus =
      ciscoAnnuityStatusSteps[ciscoAnnuityStatusActiveStep[currentStatus]].name;

    if(!validateStatusChange(newStatus)) {
      return false;
    }

    if (newStatus) {
      handleCiscoAnnuityUpdate(
        {
          id: ciscoAnnuity.id,
          previousStatus: ciscoAnnuity.status,
          status: newStatus,
        },
        projectItemId
      );
    }
  };

  const validateStatusChange = (newStatus: string): boolean => {
    if(newStatus === 'COF Requested') {
      const billingContact = ciscoAnnuity.contacts?.filter(
        (contact) => contact.type === CiscoAnnuityContactType.CustomerBilling
      )[0];

      if(!billingContact || !billingContact.name || !billingContact.email || !billingContact.phone) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Billing Contact is required for requesting COF',
        });
        
        return false;
      }

      const cdwContact = ciscoAnnuity.contacts?.filter(
        (contact) => contact.type === CiscoAnnuityContactType.Cdw
      )[0];

      if(!cdwContact || !cdwContact.name || !cdwContact.email || !cdwContact.phone) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'CDW Contact is required for requesting COF',
        });

        return false;
      }

      const sellerRoles = [
        'Primary Account Owner',
        'Secondary Account Owner',
      ]

      const primarySeller = project.projectTeam?.some(
        (contact) => ['Primary Account Owner'].includes(contact.type)
      )

      const secondarySeller = project.projectTeam?.some(
        (contact) => ['Secondary Account Owner'].includes(contact.type)
      )

      if(!primarySeller || !secondarySeller) {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Please add a Primary and Secondary Account Owner to the project team.',
        });
      }
    }

    return true;
  };

  const handleStepChange = (index: number) => () => {
    const currentIndex = ciscoAnnuityStatusActiveStep[currentStatus];
    const clickedIndex = index + 1;

    if(clickedIndex > currentIndex) {
      //forward
      if(clickedIndex - currentIndex > 1) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Skipping steps is not supported.',
        });

        return;
      }
    }

    if(clickedIndex < ciscoAnnuityStatusActiveStep[currentStatus]) {
      //backward
      if(currentStatus === "COF Signed") {
        alertState.setAlert({
          type: ALERT_TYPE.MODAL,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Ordering request has already been made. Please reach out to cloud fullfillment directly!',
        })

        return;
      }

      if(currentIndex - clickedIndex > 1 ) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.WARNING,
          message: 'Unable to undo multiple steps.',
        });

        return;
      }
    }
    
    const newStatus = ciscoAnnuityStatusSteps[index].name;

    if(!validateStatusChange(newStatus)) {
      return;
    }

    if (newStatus) {
      handleCiscoAnnuityUpdate(
        {
          id: ciscoAnnuity.id,
          previousStatus: ciscoAnnuity.status,
          status: newStatus,
        },
        projectItemId
      );
    }
  };

  const handleDenyCof = () => {
    if(denyMessageOpen && !denyMessage) {
      setDenyMessageError("Deny message is required");
      return;
    }

    if(denyMessageOpen && denyMessage) {
      setDenyMessage("");
      setDenyMessageError("");
      setDenyMessageOpen(!denyMessageOpen);

      handleCiscoAnnuityUpdate(
        {
          id: ciscoAnnuity.id,
          previousStatus: ciscoAnnuity.status,
          status: 'Quoted',
          cofDeniedReason: denyMessage
        },
        projectItemId
      );

      return;
    }

    setDenyMessage("");
    setDenyMessageError("");
    setDenyMessageOpen(!denyMessageOpen);
  }

  const handleDenyOrder = () => {
    if(denyMessageOpen && !denyMessage) {
      setDenyMessageError("Deny message is required");
      return;
    }

    if(denyMessageOpen && denyMessage) {
      setDenyMessage("");
      setDenyMessageError("");
      setDenyMessageOpen(!denyMessageOpen);

      handleCiscoAnnuityUpdate(
        {
          id: ciscoAnnuity.id,
          previousStatus: ciscoAnnuity.status,
          status: 'COF Delivered',
          orderDeniedReason: denyMessage
        },
        projectItemId
      );

      return;
    }

    setDenyMessage("");
    setDenyMessageError("");
    setDenyMessageOpen(!denyMessageOpen);
  }

  const handleCancelCofRequest = () => {
    handleCiscoAnnuityUpdate(
      {
        id: ciscoAnnuity.id,
        previousStatus: ciscoAnnuity.status,
        status: 'Quoted',
      },
      projectItemId
    );
  }

  const handleRequestWordCof = () => {
    handleRequestWordCofById(ciscoAnnuity.id);
  }

  const getStepDisabled = (step) => {
    if(!step.cloudFulfillmentOnly) {
      return false;
    }

    if(step.cloudFulfillmentOnly && userIsCloudFulfillment) {
      return false;
    }

    return true
  }

  const AdvanceStepButton = () => {
    return (
      <Grid item>
        <Button
          variant="outlined"
          color="success"
          onClick={handleCompletePhase}
        >
          {ciscoAnnuityStatusActionLabel[currentStatus]}
        </Button>
      </Grid>
    )
  }

  const getActionButtons = () => {
    const currentIndex = ciscoAnnuityStatusActiveStep[currentStatus];
    switch (currentIndex) {
      case 1: //Created
        return <AdvanceStepButton />;
      case 2: //Quoted
        return (
          <>
            <AdvanceStepButton />
            <Grid item>
              <Button
                variant="outlined"
                color="success"
                onClick={handleRequestWordCof}
              >
                Request Word COF
              </Button>
            </Grid>
          </>
        );
      case 3: //COF Delivered
        return (
          <>
            <AdvanceStepButton />
            <Grid item>
              <Button
                variant="outlined"
                color="success"
                onClick={handleRequestWordCof}
              >
                Request Word COF
              </Button>
            </Grid>
          </>
        );
      case 4: //COF Signed
        return userIsCloudFulfillment ? (
          <>
            <AdvanceStepButton />
            <Grid item sx={{ position: 'relative' }}>
              <Button
                variant="outlined"
                color="success"
                onClick={handleDenyOrder}
              >
                {denyMessageOpen ? 'Send Order Deny' : 'Deny Order Request'}
              </Button>
              {denyMessageOpen && (
                <TextField
                  autoFocus={true}
                  label="Deny Message"
                  variant="filled"
                  value={denyMessage}
                  onChange={(e) => setDenyMessage(e.target.value)}
                  onKeyUp={(event) => {
                    if (event.key === 'Enter') {
                      handleDenyOrder();
                    }
                  }}
                  helperText={denyMessageError}
                  error={!!denyMessageError}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        title="Cancel"
                        onClick={(e) => {
                          setDenyMessageOpen(false);
                          setDenyMessage('');
                          setDenyMessageError('');
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    ),
                  }}
                  rows={4}
                  sx={{
                    width: '800px',
                    position: 'absolute !important',
                    top: 50,
                    right: 0,
                    zIndex: 1000,
                  }}
                />
              )}
            </Grid>
          </>
        ) : null;
      case 6: //Ordered
      default:
        return;
    }
  }

  const getStepLabel = (step, index) => {
    const labelProps: {
      optional?: React.ReactNode;
      error?: boolean;
    } = {};

    if (ciscoAnnuity.orderDeniedReason && step.name === 'Ordered') {
      labelProps.optional = (
        <Typography variant="caption" color="error">
          {ciscoAnnuity.orderDeniedReason}
        </Typography>
      );
      labelProps.error = true;

      return <StepLabel {...labelProps}>{step.name}</StepLabel>
    }

    if (ciscoAnnuity.orderDeniedReason && step.name === 'COF Signed') {
      return (
        <StepLabel {...labelProps} onClick={handleStepChange(index)} sx={{cursor:"pointer"}}>
          Request Order
        </StepLabel>
      );
    }

    if (ciscoAnnuity.cofDeniedReason && step.name === 'COF Created') {
      labelProps.optional = (
        <Typography variant="caption" color="error">
          {ciscoAnnuity.cofDeniedReason}
        </Typography>
      );
      labelProps.error = true;

      return <StepLabel {...labelProps}>{step.name}</StepLabel>
    }

    if (ciscoAnnuity.status === 'COF Created' && step.name === 'COF Signed') {
      labelProps.optional = (
        <Typography variant="caption" color="error">
          Be sure to upload the signed COF to the OneDrive folder!
        </Typography>
      );
      labelProps.error = false;

      return (
        <StepLabel {...labelProps} onClick={handleStepChange(index)} sx={{cursor:"pointer"}}>
          {step.name}
        </StepLabel>
      );
    }

    return <StepButton
        color="inherit"
        onClick={handleStepChange(index)}
        disabled={getStepDisabled(step)}
      >
        {step.name}
      </StepButton>
  }

  if (!ciscoAnnuity) return <>No cisco annuity provided</>;

  return (
    <Grid container columnSpacing={0} mt={4} mb={4} alignItems="center">
      <Grid item xs={10}>
        <Stepper
          style={{ backgroundColor: `${theme.palette.secondary}` }}
          activeStep={ciscoAnnuityStatusActiveStep[currentStatus]}
        >
          {ciscoAnnuityStatusSteps.map((step, index) => (
            <Step
              style={{
                backgroundColor: `${theme.palette.secondary}`,
              }}
              key={step.name}
            >
              {getStepLabel(step, index)}
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={2}>
        <Grid container justifyContent="center" spacing={1}>
          {getActionButtons()}
        </Grid>
      </Grid>
    </Grid>
  );
};
