import {
  Button,
  DialogActions,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Box,
  Typography,
  FormControl as Form,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React, { Dispatch, SetStateAction } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  taskGroupConstants,
  SOW_SUB_SECTIONS,
} from '@cdw-selline/ui/constants';
import { Editor } from 'tinymce';

export interface TaskModalProps {
  isOpen?: boolean;
  handleOpen: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  editor?: Editor;
  taskSub: boolean;
  isSowSection?: boolean;
}

export function TaskModal (props: TaskModalProps) {
  const [taskId, setTaskID] = React.useState('');
  const [sowSectionId, setSowSectionId] = React.useState('');
  const [sowSubSection, setSowSubSection] = React.useState('');
  const [operator, setOperator] = React.useState('');
  const [value, setValue] = React.useState('');
  const [taskField, setTaskField] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleFormSubmit = () => {
    const timeNow = new Date().getTime();

    if (props.taskSub && !(taskField && taskId)) {
      setErrorMessage('Fill out required fields!');
      return;
    } else {
      setErrorMessage('');
    };

    if (props.taskSub) {
      props.editor.insertContent(
        `{{ tasksub field="${taskField}" id="${taskId}" }}`
      );
    } else if (props.isSowSection) {
      props.editor.insertContent(
        `{{ sowsection sowSubSection="${sowSubSection}" id="${sowSectionId}" }}`
      );
    } else {
      props.editor.insertContent(
        `{{ task operator="${operator}" value="${value}" id="${taskId}-${timeNow}" }}{{ endtask id="${taskId}-${timeNow}" }}`
      );
    }

    props.handleClose();
    setTaskID('');
    setOperator('');
    setValue('');
    setTaskField('');
    setSowSectionId('');
    setSowSubSection('');
  };

  return (
    <>
      {!props.isSowSection && (
        <DialogModal
          isOpen={props.isOpen}
          handleClose={props.handleClose}
          title={props.taskSub ? 'New Task Sub' : 'New Task'}
          maxWidth="sm"
        >
          <Form data-testid="task-modal-container">
            {errorMessage && <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                <Typography color='error'>{errorMessage}</Typography>
            </Box>}
            <FormControl sx={{ width: '100%' }}>
              <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                <Typography>Task ID</Typography>
              </Box>
              <TextField
                required={true}
                data-testid="taskid"
                sx={{ marginBottom: 2 }}
                value={taskId}
                onChange={(e) => setTaskID(e.target.value)}
              />
            </FormControl>
            {props.taskSub ? (
              <FormControl sx={{ width: '100%' }}>
                <Box sx={{ marginBottom: 1 }}>
                  <Typography>Task Field</Typography>
                </Box>
                <Select
                  required={true}
                  sx={{ marginBottom: 2 }}
                  value={taskField}
                  onChange={(e) => setTaskField(e.target.value)}
                  data-testid="taskField"
                >
                  <MenuItem sx={{ height: '30px' }} value={0}></MenuItem>
                  {taskGroupConstants.TASK_FIELD.map((tt) => (
                    <MenuItem value={tt.value} key={`task-field-${tt.value}`}>
                      {tt.field}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <>
                <FormControl sx={{ width: '100%' }}>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography>Operator</Typography>
                  </Box>
                  <Select
                    sx={{ marginBottom: 2 }}
                    value={operator}
                    onChange={(e) => setOperator(e.target.value)}
                    data-testid="taskOperator"
                  >
                    <MenuItem sx={{ height: '30px' }} value={0}></MenuItem>
                    {taskGroupConstants.OPERATORS.map((op) => (
                      <MenuItem value={op.value} key={`operators-${op.value}`}>
                        {op.operator}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                  <Box sx={{ marginBottom: 1 }}>
                    <Typography>Value</Typography>
                  </Box>
                  <TextField
                    sx={{ marginBottom: 2 }}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    data-testid="taskValue"
                  />
                </FormControl>
              </>
            )}
            <DialogActions>
              <Button
                color="secondary"
                onClick={props.handleClose}
                data-testid="new-Task-cancel-button"
              >
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                data-testid="new-Task-submit-button"
                onClick={handleFormSubmit}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        </DialogModal>
      )}

      {props.isSowSection && (
        <DialogModal
          isOpen={props.isOpen}
          handleClose={props.handleClose}
          title={'SOW Section'}
          maxWidth="sm"
        >
          <Form data-testid="task-modal-container">
            <FormControl sx={{ width: '100%' }}>
              <Box sx={{ marginTop: 2, marginBottom: 1 }}>
                <Typography>SOW Section ID</Typography>
              </Box>
              <TextField
                data-testid="sowsectionid"
                sx={{ marginBottom: 2 }}
                value={sowSectionId}
                onChange={(e) => setSowSectionId(e.target.value)}
              />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
              <Box sx={{ marginBottom: 1 }}>
                <Typography>Sub-Section</Typography>
              </Box>
              <Select
                sx={{ marginBottom: 2 }}
                value={sowSubSection}
                onChange={(e) => setSowSubSection(e.target.value)}
                data-testid="sowSubSection"
              >
                <MenuItem sx={{ height: '30px' }} value={0}></MenuItem>
                {SOW_SUB_SECTIONS.map((subSection) => (
                  <MenuItem
                    value={subSection.value}
                    key={`subSection-${subSection.label}`}
                  >
                    {subSection.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <DialogActions>
              <Button
                color="secondary"
                onClick={props.handleClose}
                data-testid="new-Task-cancel-button"
              >
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                type="submit"
                variant="contained"
                data-testid="new-Task-submit-button"
                onClick={handleFormSubmit}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Form>
        </DialogModal>
      )}
    </>
  );
};

export default TaskModal;
