import * as React from 'react';
import Logo from './logo.png';
interface SalesforceLogoProps{
  height?:string
  className?:string,
  onClick?: any
}
export function SalesforceSvgLogo(props: SalesforceLogoProps) {
  return <img src={Logo} {...props} />;
}
export default SalesforceSvgLogo;
