import { cloneDeep } from 'lodash';
import moment from 'moment';

const numericColumns = ['probability'];

const columnReplacements = {
  CustomersCollectionPage: {
    customerCode: 'cdwCustomerAccountNumber',
    customerDescription: 'customerName',
    region: 'customerRegion',
    sector: 'customerSector',
    area: 'customerArea',
  },
};

export const getFilters = (page, userFilter?) => {
  const filters = cloneDeep(userFilter ? userFilter : {});
  const filterData = JSON.parse(localStorage.getItem(`${page}-filter`));

  if (filterData) {
    let filterColumn = filterData.items[0]?.columnField;
    if (page in columnReplacements) {
      if (page !== 'CustomersCollectionPage' || Object.keys(filters).length > 0)
        //TODO: this fix is temporary, we need a better solution for filters
        filterColumn = columnReplacements[page][filterColumn];
    }
    let filterValue = filterData.items[0]?.value;
    const filterOperator = filterData.items[0]?.operatorValue;

    if (filterValue && Boolean(filterValue)) {
      let filterOperation = {};
      filterValue = filterValue.replace(/\\/g, '\\\\');
      filterValue = numericColumns.includes(filterColumn)
        ? parseInt(filterValue)
        : filterValue;

      switch (filterOperator) {
        case 'contains':
          filterOperation = { $regex: `${filterValue}`, $options: `i` };
          break;
        case '=':
        case 'is':
          {
            filterOperation = { $eq: filterValue };
            const filterDate = new Date(filterValue);
            const isValidDate = !isNaN(filterDate.valueOf());
            if (isValidDate) {
              const startDate = moment(filterValue).format('L');
              const endDate = moment(filterValue).add(1, 'days').format('L');
              filterOperation = { 
                    $gte : startDate,
                    $lt : endDate
                }   
              } 
          } 
          break;
        case 'equals':
          filterOperation = { $eq: filterValue };
          break;
        case 'onOrAfter':
        case '>=':
          filterOperation = { $gte: filterValue };
          break;
        case 'onOrBefore':
          {
            filterOperation = { $lte: filterValue };
            const filterDate = new Date(filterValue);
            const isValidDate = !isNaN(filterDate.valueOf());
            if (isValidDate) {
              filterValue =  moment(filterValue).add(1, 'days').format('L');
              filterOperation = { $lt: filterValue };
            }
          }
          break;
        case '<=':
          filterOperation = { $lte: filterValue };
          break;
        case 'not':
          filterOperation = { $ne: filterValue };
          break;
        case 'startsWith':
          filterOperation = { $regex: `^${filterValue}`, $options: `i` };
          break;
        case 'endsWith':
          filterOperation = { $regex: `${filterValue}$`, $options: `i` };
          break;
      }
      filters[filterColumn] = filterOperation;

      if (filterColumn === 'solutionArchitect' || filterColumn === 'salesRep') {
        return projectTeamFilters(filterColumn, filterOperation);
      }
    }
  }
  return filters;
};

const projectTeamFilters = (filterColumn, filterOperation) => {
  const type =
    filterColumn === 'salesRep' ? 'Sales Manager' : 'Solution Architect';

  const filter = {
    projectTeam: {
      $elemMatch: {
        type: type,
        name: filterOperation,
      },
    },
  };
  return filter;
};
