import { Practice, RoleAndRate } from '@cdw-selline/common/types';
import { GridColumns, GridValueFormatterParams } from '@mui/x-data-grid';
import { useProjectPracticesAndRatesMutation } from '..';

interface RoleRow {
  id: string;
  name: string;
  practice: string;
  rate: string;
  otCost: string;
  gp: string;
}

interface RoleWithPracticeName extends RoleAndRate {
  practiceName: string;
}

export const useRolesAndRatesTable = (
  projectId: string,
  currentPractices: Practice[],
  currentRolesAndRates: RoleAndRate[],
  isReadOnly: boolean
) => {
  const columns: GridColumns = [
    {
      field: 'name',
      headerName: 'Name',
      description: 'Role Name',
      editable: !isReadOnly,
      width: 380,
      flex: 1,
    },
    {
      field: 'practice',
      headerName: 'Practice',
      description: 'Role Practice',
      width: 300,
      flex: 1,
    },
    {
      field: 'rate',
      headerName: 'Rate',
      description: 'Role Rate',
      editable: !isReadOnly,
      width: 150,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return `$${Number(params?.value).toFixed(2) ?? 0.0}`;
      },
    },
    {
      field: 'otCost',
      headerName: 'OT Rate',
      description: 'Role OT Rate',
      width: 180,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams) => {
        return `$${Number(params?.value).toFixed(2) ?? 0.0}`;
      },
    },
    {
      field: 'gp',
      headerName: 'GP %',
      description: 'GP %',
      width: 150,
      flex: 1,
    },
  ];

  const rolesWithPractices: RoleWithPracticeName[] = currentRolesAndRates.map(
    (role) => {
      const matchingPractice = currentPractices.find(
        (i) => i.id === role.practice
      );
      return { ...role, practiceName: matchingPractice?.name };
    }
  );

  const { handleUpdateRole, loading } = useProjectPracticesAndRatesMutation(
    projectId,
    currentPractices,
    currentRolesAndRates
  );

  const handleRowEditSave = ({ id, rate, name }: RoleRow) => {
    const targetRole = currentRolesAndRates.find((role) => role.id === id);
    const updatedRole = { ...targetRole, rate, name };
    handleUpdateRole(updatedRole);
  };

  const rows = rolesWithPractices.map(
    ({ id, name, rate, cost, otCost, practiceName }) => ({
      id,
      name,
      practice: practiceName,
      rate,
      otCost,
      gp: rate > 0 ? `${Math.round(((rate - cost) / rate) * 100)} %` : '0%',
    })
  );

  return {
    columns,
    rows,
    handleRowEditSave,
    loading,
  };
};
