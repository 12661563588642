import { Practice, Site } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  estimatorStrings,
  labelStrings,
  titleStrings,
} from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useAddProjectTaskGroup,
  useTaskGroupsByPractice,
} from '@cdw-selline/ui/hooks';
import AddIcon from '@mui/icons-material/Add';
import {
  Box,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';

import DrawerRight from '../../../drawer-right/DrawerRight';

export interface TaskDrawerProps {
  isOpen: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  projectItemId: string;
  practices: Practice[];
  handleNewSiteDialogOpen: () => void;
  sites: Site[];
  clickedSiteId?: string;
}

export const TaskDrawer = React.memo(
  ({
    isOpen,
    handleDrawerOpen,
    handleDrawerClose,
    practices,
    projectItemId,
    handleNewSiteDialogOpen,
    sites,
    clickedSiteId,
  }: TaskDrawerProps) => {
    const { SEARCH, SEARCH_TASK_GROUPS, ADD_TASK_GROUP } = labelStrings;
    const { TASK_GROUPS_SELECT_PRACTICE } = estimatorStrings;
    const site = sites.find((site) => site.id === clickedSiteId);

    const [searchValue, setSearchValue] = useState('');

    useEffect(() => {
      if (isOpen && practices?.length > 0) {
        refetch();
      }
    }, [isOpen]);

    const { taskGroupsByPractice, error: taskGroupsError, refetch } =
      useTaskGroupsByPractice(practices, {}, 500, { name: 1 });
    const displayedResults = searchValue
      ? taskGroupsByPractice.filter((item) => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase());
      })
      : taskGroupsByPractice;

    const theme = useTheme();

    const {
      handleAddTaskGroup,
      loading: addGroupLoading,
      error: addGroupError,
    } = useAddProjectTaskGroup();

    const handleSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
      setSearchValue(e.target.value);
    };

    const handleAddTaskGroupEvent = (input) => {
      setSearchValue('');
      handleAddTaskGroup(input);
    };

    const loading = addGroupLoading;

    const error = addGroupError || taskGroupsError;

    if (!isOpen) return null;
    return (
      <DrawerRight
        {...{
          isOpen,
          handleDrawerOpen,
          handleDrawerClose,
          loading,
          title: 'Add Task Groups',
        }}
      >
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          SITE: {site.name}
        </Typography>

        <FormControl fullWidth sx={{ margin: '.5em 0' }}>
          <TextField
            type="search"
            label={SEARCH}
            placeholder={SEARCH_TASK_GROUPS}
            onChange={handleSearchInput}
            data-testid="task-drawer-search-input"
            sx={{ width: '95%', marginLeft: 'auto', marginRight: 'auto' }}
          />
        </FormControl>
        {error && (
          <Typography color="error">Error Fetching Task Groups</Typography>
        )}
        <List>
          {!error && sites?.length > 0 && displayedResults?.length > 0 ? (
            displayedResults?.map((group, i) => {
              return (
                <Box key={group.id}>
                  <ListSubheader>
                    <Typography noWrap>
                      <Tooltip title={ADD_TASK_GROUP}>
                        <IconButton
                          sx={{ marginRight: '.25em' }}
                          data-testid={`task-group-${i}`}
                          onClick={() =>
                            handleAddTaskGroupEvent({
                              itemId: projectItemId,
                              siteId: site.id,
                              taskGroupId: group.id,
                            })
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={group.name}>
                        <span
                          style={{
                            color: !group.published
                              ? theme.palette.grey[600]
                              : '',
                          }}
                        >
                          {group.name}
                        </span>
                      </Tooltip>
                    </Typography>
                  </ListSubheader>
                </Box>
              );
            })
          ) : (
            <ListItem>
              <ListItemText primary={TASK_GROUPS_SELECT_PRACTICE} />
            </ListItem>
          )}
        </List>
      </DrawerRight>
    );
  }
);

export default TaskDrawer;
