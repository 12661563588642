
import { useQuery } from '@apollo/client';
import {GET_ADMIN_CONFIG_SETTINGS} from '@cdw-selline/ui/queries';
import {GetAdminConfigSettingsResponse} from '@cdw-selline/common/types';
import { useApolloErrors } from '.';

export const useProjectConfigSettings = ({filters = {}}) => {
    const { handleErrorResponse } = useApolloErrors();
    const { loading, error, data, refetch } = useQuery<{
        getAdminConfigSettings: GetAdminConfigSettingsResponse;
    }>(GET_ADMIN_CONFIG_SETTINGS, {
        fetchPolicy: 'network-only', // Used for first execution
        nextFetchPolicy: 'cache-first', // Used for subsequent executions
        variables: { filters: filters ?? {} },
        onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Project Config Settings!'),
    });

    return {
        data: data?.getAdminConfigSettings ?? {count: 0, adminConfigSettings: []},
        error,
        loading,
        refetch,
      };
};