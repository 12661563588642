import { Box, Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import DialogModal from '../../dialog-modal/DialogModal';
import React from 'react';
import {
  DataGridPro,
  GridRenderCellParams,
  GridRowModel,
  GridRowOrderChangeParams,
} from '@mui/x-data-grid-pro';
import { SITE_TABLE_COLUMN_VALUES } from '@cdw-selline/ui/constants';
import DeleteIcon from '@mui/icons-material/Delete';
import { Editor } from 'tinymce';
import { getActionButtons } from '../../formHelperFunctions';

export interface SiteTableModalProps {
  isOpen: boolean;
  handleClose: () => void;
  editor?: Editor;
}

interface CustomRowModel extends GridRowModel {
  id: number;
  columnName: string;
  type: string;
  value: string;
}

export function SiteTableModal({
  isOpen,
  handleClose,
  editor
}: SiteTableModalProps) {
  const [errorMessage, setErrorMessage] = React.useState('');
  const [rows, setRows] = React.useState<CustomRowModel[]>([
    { id: 1, columnName: '', value: '', type: '' },
  ]);

  const handleFormSubmit = () => {
    // Validate that required fields are filled
    const hasEmptyFields = rows.some(
      (row) =>
        !row.columnName?.trim() || !row.type || (row.type === 'Task Id' && !row.value?.trim())
    );

    if (hasEmptyFields) {
      setErrorMessage('Fill out all required fields!');
      return;
    } else {
      setErrorMessage('');
    }

    const valueMap = {
      'Site Name': 'site-name',
      'Site Location': 'site-location',
      'Site Address': 'site-address',
      'Site Floor': 'site-floor',
      'Site Quantity': 'site-quantity',
    };
    const shortcode = rows
      .map((row) => {
        if (row.columnName && row.type) {
          return `{{ site-table-column
                      name="${row.columnName.trim()}"
                      value="${row.type === 'Task Id' ? row.value.trim() : valueMap[row.type]}"
                  }}`;
        }
      }).join(' ');

    if (shortcode.length > 0) {
      const fullShortcode = `<span class="mceNonEditable">{{ site-table }} ${shortcode} {{ end-site-table }}</span>`;
      editor.insertContent(fullShortcode);
    }
    setRows([{ id: 1, columnName: '', value: '', type: '' }]);
    handleClose();
  };

  const handleAddRow = () => {
    const newRow = {
      id: rows.length + 1,
      columnName: '',
      value: '',
      type: '',
    };
    setRows([...rows, newRow]);
  };

  const handleDeleteRow = (id) => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    const newRows = updateRowPosition(
      params.oldIndex,
      params.targetIndex,
      rows
    );
    setRows(newRows);
  };

  const updateRowPosition = (
    initialIndex: number,
    newIndex: number,
    rows: Array<CustomRowModel>
  ) => {
    const rowsClone = [...rows];
    const row = rowsClone.splice(initialIndex, 1)[0];
    rowsClone.splice(newIndex, 0, row);
    return rowsClone;
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRows = rows.map((row) =>
      (row as CustomRowModel).id === (newRow as CustomRowModel).id
        ? { ...row, ...newRow }
        : row
    );
    setRows(updatedRows);
    return newRow;
  };

  const renderColumnNameCell = (params: GridRenderCellParams<any, any>) => {
    return (
      <TextField
        type="text"
        required={true}
        data-testid="columnNameCell"
        sx={{ width: '100%', marginTop: '2px' }}
        value={params.value as string}
        onChange={(e) => {
          const newValue = e.target.value;
          params.api.setEditCellValue({
            id: params.id,
            field: 'columnName',
            value: newValue,
          });
        }}
      />
    );
  };

  const renderTypeCell = (params: GridRenderCellParams<any, any>) => {
    return (
      <Select
        value={params.row.type || ''}
        sx={{ width: '100%' }}
        onChange={(e) => {
          const updatedRows = rows.map((row) =>
            row.id === params.row.id ? { ...row, type: e.target.value } : row
          );
          setRows(updatedRows);
        }}
        data-testid="typeCell"
      >
        {SITE_TABLE_COLUMN_VALUES.map((obj) => (
          <MenuItem value={obj.value} key={obj.value}>
            {obj.value}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const renderValueCell = (params: GridRenderCellParams<any, any>) => {
    if (params.row.type === 'Task Id') {
      return (
        <TextField
          type="text"
          sx={{ width: '100%' }}
          data-testid="valueCell"
          value={params.row.value}
          onChange={(e) => {
            const updatedRows = rows.map((row) =>
              row.id === params.row.id ? { ...row, value: e.target.value } : row
            );
            setRows(updatedRows);
          }}
        />
      );
    }    
  };

  const columns = [
    {
      field: 'columnName',
      headerName: 'Column Name',
      width: 200,
      editable: true,
      renderCell: renderColumnNameCell,
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 200,
      editable: true,
      renderCell: renderTypeCell,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 200,
      renderCell: renderValueCell,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        <DeleteIcon
          sx={{ marginTop: 2, marginLeft: 1 }}
          onClick={() => handleDeleteRow(params.row.id)}
        />
      ),
    },
  ];

  return (
    <DialogModal
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Site Table'}
      maxWidth="100%"
      maxWidthProp={'lg'}
      action={getActionButtons(
        handleClose,
        handleFormSubmit,
      )}
    >
      <Stack
        direction="row"
        justifyContent="end"
        alignItems="center"
        padding="2px"
        spacing={1}
      >
          <Button size="large" onClick={handleAddRow}>
            + ADD
          </Button>
      </Stack>
      <Box>
        {errorMessage && (
          <Box sx={{ color: 'red', marginBottom: 2 }}>{errorMessage}</Box>
        )}
        <DataGridPro
          rows={rows}
          columns={columns}
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          processRowUpdate={processRowUpdate}
          rowHeight={60}
          hideFooter={true}
          sx={{ minHeight: '200px' }}
        />
      </Box>
    </DialogModal>
  );
}

export default SiteTableModal;

