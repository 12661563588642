import * as React from 'react';
import { useHistory } from 'react-router-dom';
import {
  SvgIconTypeMap,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  styled,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

const drawerWidth = 240;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

export type MenuItem = {
  label: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  path: string;
};

/* eslint-disable-next-line */
export interface AppDrawerProps {
  open?: boolean;
  menuItems: MenuItem[];
  topMenuItems: MenuItem[];
  toggleDrawer: (forceClose?: boolean) => void;
}

export const AppDrawer = (props: AppDrawerProps) => {
  const router = useHistory();

  const handleClick = (path: string) => {
    props.toggleDrawer(true);
    router.push(path);
  };

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="left"
      open={props.open}
    >
      <DrawerHeader />
      <Divider />
      <List>
        {props.topMenuItems?.map?.(({ label, icon: MenuIcon, path }, index) => (
          <ListItem
            button
            key={`${label}-${index}`}
            onClick={() => handleClick(path)}
            sx={{ marginLeft: '.5em' }}
            data-testid={`app-drawer-${label}`}
          >
            <ListItemIcon>
              <MenuIcon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {props.menuItems?.map?.(({ label, icon: Icon, path }, index) => (
          <ListItem
            sx={{ marginLeft: '.5em' }}
            button
            key={`${label}-index`}
            onClick={() => handleClick(path)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default AppDrawer;
