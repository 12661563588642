import { useMutation } from '@apollo/client';
import { ADD_AGILOFT_SOW_CONTENT_MUTATION, GET_ESTIMATOR_SOW_QUERY } from '@cdw-selline/ui/queries';
import { DefaultMutationResponse } from '@cdw-selline/common/types';
import { useEffect, useState } from 'react';
import { useApolloErrors } from '../../useApolloErrors';

export interface useAddAgiloftSowContent {
  progress: number;
  setProgress: (progress: number) => void;
  startProgress: number;
  endProgress: number;
  setProgressMessage: (message: string) => void;
  agiloftSOWId: string;
  projectId: string;
  projectItemId: string;
}

export const useAddAgiloftSowContent = ({
  progress,
  setProgress,
  startProgress,
  endProgress,
  setProgressMessage,
  agiloftSOWId,
  projectId,
  projectItemId,
}: useAddAgiloftSowContent) => {
  const { handleErrorResponse } = useApolloErrors();

  const [addAgiloftSowContentRunning, setAddAgiloftSowContentRunning] =
    useState(false);

  const [addAgiloftSowContent, { error: addAgiloftSowContentError, client }] =
    useMutation<{
      addAgiloftSowContent: DefaultMutationResponse;
    }>(ADD_AGILOFT_SOW_CONTENT_MUTATION, {
      onError: (error) => {
        setProgress(100);
        setAddAgiloftSowContentRunning(false);
        handleErrorResponse(error, 'Failed to add agiloft sow content');
      },
      onCompleted: (data) => {
        setProgress(endProgress);
        setAddAgiloftSowContentRunning(false);
        client.refetchQueries({
          include: [GET_ESTIMATOR_SOW_QUERY],
        });
      },
    });

  useEffect(() => {
    if (progress === startProgress) {
      setProgressMessage('Adding Agiloft Sow Content ..');
      setAddAgiloftSowContentRunning(true);
      addAgiloftSowContent({
        variables: {
          params: {
            id: agiloftSOWId,
            projectId: projectId,
            projectItemId: projectItemId,
          },
        },
      });
    }
  }, [
    progress,
    startProgress,
    setProgressMessage,
    addAgiloftSowContent,
    agiloftSOWId,
    projectId,
    projectItemId,
  ]);

  useEffect(() => {
    if (progress < 100 && addAgiloftSowContentRunning) {
      const timer = setTimeout(() => {
        if (addAgiloftSowContentRunning && progress < endProgress - 1) {
          setProgress(progress + 1);
        }
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [
    addAgiloftSowContent,
    addAgiloftSowContentRunning,
    endProgress,
    progress,
    setProgress,
  ]);

  return {
    addAgiloftSowContentRunning,
    addAgiloftSowContentError,
  };
};
