import { useQuery } from '@apollo/client';
import { GetTotalSalesResponse } from '@cdw-selline/common/types';
import { GET_TOTAL_SALES_QUERY } from '@cdw-selline/ui/queries';

import { useApolloErrors } from './useApolloErrors';

export const useTotalXass = () => {
  const data = { getTotalXassSales: 0 };

  return {
    data: data ?? null,
    loading: false,
  };
};

export default useTotalXass;
