import { ProjectProposal } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useRemoveProjectProposal,
  useOpenState,
  useReloadProjectProposal,
} from '@cdw-selline/ui/hooks';
import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
  Box,
  Tooltip,
  Grid,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import React from 'react';
import {
  NewProposalDialog,
  EditProposalNameDialog,
  DialogConfirm,
  ConnectedUsersList,
} from '@cdw-selline/ui/components';

export interface ProposalControlsProps {
  projectId: string;
  projectItemId: string;
  estimatorId: string;
  currentProposal: ProjectProposal;
  proposalSelect: {
    selectedProposalId: string;
    handleSelectValueChange: (newValue: string) => void;
  };
  proposalEditState: {
    editNameOpen: boolean;
    handleEditNameClose: () => void;
    handleEditNameOpen: () => void;
    handleNameChange: (newValue: string) => void;
  };
  projectProposals?: ProjectProposal[];
  isReadOnly?: boolean;
  connectedUsers?: any[];
}

export function ProposalControls({
  projectId,
  estimatorId,
  projectItemId,
  currentProposal,
  proposalSelect,
  proposalEditState,
  projectProposals,
  isReadOnly,
  connectedUsers,
}: ProposalControlsProps) {
  const controlStrings = {
    CREATE_PROPOSAL: 'Click to create a new one.',
    ERROR: 'Could not load proposals',
    EXISTING_PROPOSALS: 'Existing Proposals',
    NEW_PROPOSAL: 'New Proposal',
    EDIT_NAME: 'Edit Current Proposal Name',
    DELETE_PROPOSAL: 'Delete Current Proposal',
    RELOAD_PROPOSAL: 'Reload Current Proposal',
  };

  const {
    handleRemoveProjectProposal,
    loading: removeProposalLoading,
    error: removeProposalError,
  } = useRemoveProjectProposal(() =>
    proposalSelect.handleSelectValueChange('')
  );

  const { handleReloadProjectProposal, loading, error } =
    useReloadProjectProposal(currentProposal?.id || '');


  const handleSelectChangeEvent = (e: SelectChangeEvent) => {
    proposalSelect.handleSelectValueChange(e.target.value);
  };

  const {
    isOpen: newProposalOpen,
    handleClose: handleNewProposalClose,
    handleOpen: handleNewProposalOpen,
  } = useOpenState();

  const {
    isOpen: isConfirmOpen,
    handleClose: handleConfirmClose,
    handleOpen: handleConfirmOpen,
  } = useOpenState();

  const {
    isOpen: isReloadConfirmOpen,
    handleClose: handleReloadConfirmClose,
    handleOpen: handleReloadConfirmOpen,
  } = useOpenState();


  if (removeProposalLoading) return <CircularProgress />;
  if (removeProposalError)
    return <Typography>{controlStrings.ERROR}</Typography>;

  return (
    <>
      <Box sx={{ width: '100%', paddingTop: '10px', paddingBottom: '10px' }}>
        <Grid container spacing={1}>
          {projectProposals.length > 0 && (
            <Grid item>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                <Select
                  sx={{ minWidth: 200, height: '35px' }}
                  labelId="proposal-select-label"
                  label={controlStrings.EXISTING_PROPOSALS}
                  value={
                    proposalSelect.selectedProposalId ||
                    projectProposals[projectProposals?.length - 1]?.id
                  }
                  onChange={handleSelectChangeEvent}
                  data-testid="proposal-select"
                >
                  {projectProposals?.map((proposal, idx) => (
                    <MenuItem
                      value={proposal.id}
                      key={proposal.id}
                      data-testid={`proposal-option-${idx}`}
                    >
                      {proposal.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
          {!isReadOnly && (
            <>
              <Grid item>
                <IconButton
                  onClick={handleNewProposalOpen}
                  title={controlStrings.CREATE_PROPOSAL}
                  data-testid="new-proposal-button"
                >
                  <AddIcon />
                </IconButton>
              </Grid>
              {currentProposal?.id && (
                <>
                  <Grid item>
                    <IconButton
                      onClick={handleReloadConfirmOpen}
                      title={controlStrings.RELOAD_PROPOSAL}
                      data-testid="reload-proposal-button"
                    >
                      <AutorenewIcon />
                    </IconButton>
                    <IconButton
                      onClick={proposalEditState.handleEditNameOpen}
                      title={controlStrings.EDIT_NAME}
                      data-testid="edit-proposal-name-button"
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={handleConfirmOpen}
                      title={controlStrings.DELETE_PROPOSAL}
                      data-testid="delete-proposal-button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Grid>
                </>
              )}
              {connectedUsers?.length > 0 && (
                <Grid item>
                  <Stack direction="row">
                    <ConnectedUsersList connectedUsers={connectedUsers} />
                  </Stack>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Box>
      <NewProposalDialog
        {...{
          isOpen: newProposalOpen,
          handleClose: handleNewProposalClose,
          projectId,
          projectItemId,
          estimatorId,
          handleNewProposalId: proposalSelect.handleSelectValueChange,
        }}
      />
      <EditProposalNameDialog
        {...{
          isOpen: proposalEditState.editNameOpen,
          handleClose: proposalEditState.handleEditNameClose,
          currentProposalName: currentProposal?.name || '',
          handleNameChange: proposalEditState.handleNameChange,
        }}
      />
      <DialogConfirm
        {...{
          isOpen: isReloadConfirmOpen,
          handleClose: handleReloadConfirmClose,
          title: 'Reload Proposal',
          handleYes: () => {
            handleReloadConfirmClose();
            handleReloadProjectProposal(proposalSelect.selectedProposalId);
          },
        }}
      >
        <Typography>
            Are you sure you want to reload selected Proposal?<br /><br />
            This will remove any changes you have made.<br /><br />
            This will reload the proposal based on the estimator
              configuration.
        </Typography>
      </DialogConfirm>
      <DialogConfirm
        {...{
          isOpen: isConfirmOpen,
          handleClose: handleConfirmClose,
          title: 'Proposal',
          handleYes: () => {
            handleConfirmClose();
            handleRemoveProjectProposal(proposalSelect.selectedProposalId);
          },
        }}
      >
        <Typography>
          Are you sure you want to delete selected Proposal?
        </Typography>
      </DialogConfirm>
    </>
  );
}

export default ProposalControls;
