import { ROUTES } from '@cdw-selline/ui/constants';
import { useAdminMenu } from '@cdw-selline/ui/hooks';
import {
  AdminDashboardPage,
  LoginPageAdmin,
  NotFoundPage,
  AdminListTasksPage,
} from '@cdw-selline/ui/pages';

export const useApp = () => {
  const menu = useAdminMenu();
  const routes = [
    {
      path: ROUTES.HOME,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: false,
    },
    {
      path: ROUTES.SIGN_IN,
      component: LoginPageAdmin,
      exact: true,
      isPrivate: false,
    },
    {
      path: ROUTES.ADMIN_DASHBOARD,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_CUSTOMERS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_PRACTICES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_PRACTICES}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_TIERS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_PHASES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_TIERS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_ROLES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_ROLES}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_CDW_RATES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SOW_SECTIONS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_SOW_SECTIONS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_PROPOSAL_TEMPLATES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_PROPOSAL_TEMPLATES}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_TASKS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_TASKS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_TASK_GROUPS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_TASK_GROUPS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SCOPE_GROUPS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_SCOPE_GROUPS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SCOPE_ITEMS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_SCOPE_ITEMS}/:id`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      exact: false,
      path: `${ROUTES.ADMIN_LIST_TASKS}/:taskGroupId`,
      component: AdminListTasksPage,
      isPrivate: true,
    },
    {
      path: `${ROUTES.ADMIN_CISCO_ANNUITY_PART_NUMBERS}`,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_CONFIG_SETTINGS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.EMAIL_TEMPLATES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SERVICE_PRACTICES,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SERVICE_ITEMS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    {
      path: ROUTES.ADMIN_SERVICE_SKILLS,
      component: AdminDashboardPage,
      exact: true,
      isPrivate: true,
    },
    /* 404 page must be last */
    {
      path: '*',
      component: NotFoundPage,
      isPrivate: false,
    },
  ];

  return {
    routes,
    menu,
    isLoggedIn: true, // TODO: add better auth check
  };
};
