import { gql } from '@apollo/client';

export const GET_PSA_BREAKDOWN_QUERY = gql`
  query getPsaBreakdownQuery($id: ID!) {
    getPsaBreakdownByProjectId(projectId: $id) {  
     inputs {
      wrapperestimate {
        estimate {
          ffscpq__Start_Date__c
          ffscpq__End_Date__c
          ffscpq__Region__c
          ffscpq__Approval_Status__c
          Name
          ffscpq__Opportunity__c
          Project_Type__c
          ffscpq__Billing_Type__c
          ffscpq__Is_Template__c
          ffscpq__Stage_Name__c
          ffscpq__Is_Primary__c
          ffscpq__Total_Hours__c
          source__c
          action__c
        }
        estimateProducts{
          Name
          contractType
          external_id
          Contingency_amount
          contingency_percentage
          travel_bill_rate
          travel_hours    
        }
        estimateLines{
          name
          currencyCode
          createMilestone
          endDate
          estimateProduct
          fixedPriceMarginPercent
          mileStone
          netBillAmount
          order
          startDate
          lineSetType
          percentDiscount
          servicesItem
          quantity
          subcontractorAccount
          unitCost
          practice
          unitPrice
          contractType
        }
        estimateRoleRequests{
          estimateProduct
          estimateProductId
          estimateLineName
          startDate
          endDate
          practice
          role
          hours
          role_Nickname
          bill_Rate_Override
          externalId
          skills {
            name
            minimumRating
            externalId
            estimateRoleSkillRequestName
          }
        }
      }
    }
  }
}
`
