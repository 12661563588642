import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  ScopeGroup,
} from '@cdw-selline/common/types';
import {
  ADD_SCOPE_GROUP_MUTATION,
  GET_SCOPE_GROUPS_QUERY,
  GET_SCOPE_GROUP_BY_ID_QUERY,
  REMOVE_SCOPE_GROUP_MUTATION,
  UPDATE_SCOPE_GROUP_MUTATION,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import { useOpenState, useScopeGroups } from '@cdw-selline/ui/hooks';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import omitDeep from 'omit-deep-lodash';
import { useState } from 'react';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { getFilters } from '@cdw-selline/ui/helpers';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 180,
    editable: false,
    hide: true,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Scope Group Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'practiceId',
    headerName: 'Practice',
    width: 220,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(params?.value).format('MM/DD/YYYY'),
  },
];

export const useAdminScopeGroups = () => {
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [sortState, setSortState] = useState();
  const { data, loading, error, refetch } = useScopeGroups({
    filters: getFilters('ScopeGroupsCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editScopeGroupId, setEditScopeGroupId] = useState(null);
  const {
    isOpen: scopeGroupFormOpen,
    handleClose: closeScopeGroupForm,
    handleOpen: openScopeGroupForm,
  } = useOpenState();

  const [
    addScopeGroup,
    { loading: addScopeGroupLoading, error: addScopeGroupError },
  ] = useMutation<{ addScopeGroup: DefaultAddResponse }>(
    ADD_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY, GET_SCOPE_GROUP_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new scope group!'),
      onCompleted: (data) => {
        if (data.addScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Scope Group',
          });
        }
      },
    }
  );

  const [
    removeScopeGroup,
    { loading: removeScopeGroupLoading, error: removeScopeGroupError },
  ] = useMutation<{ removeScopeGroup: DefaultMutationResponse }>(
    REMOVE_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove scope group'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed scope group',
          });
        }
      },
    }
  );

  const [
    updateScopeGroup,
    { loading: updateScopeGroupLoading, error: updateScopeGroupError },
  ] = useMutation<{ updateScopeGroup: DefaultMutationResponse }>(
    UPDATE_SCOPE_GROUP_MUTATION,
    {
      refetchQueries: [GET_SCOPE_GROUPS_QUERY, GET_SCOPE_GROUP_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update scope group'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateScopeGroup.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated scope group',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openScopeGroupForm();
  };

  const handleScopeGroupFormClose = () => {
    setEditScopeGroupId(null);
    closeScopeGroupForm();
  };

  const handleDelete = (pid: string) =>
    removeScopeGroup({
      variables: { removeScopeGroupId: pid },
    });
  const handleEdit = (id: string) => {
    setEditScopeGroupId(id);
    openScopeGroupForm();
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('ScopeGroupsCollectionPage') });
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('ScopeGroupsCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
    });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('ScopeGroupsCollectionPage'),
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleScopeGroupFormSave = (scopeGroup: ScopeGroup) => {
    if (!scopeGroup.id) {
      addScopeGroup({
        variables: {
          params: omitDeep(scopeGroup, ['__typename']),
        },
      });
    }

    if (scopeGroup.id) {
      updateScopeGroup({
        variables: {
          id: scopeGroup.id,
          modifiedScopeGroup: omitDeep(scopeGroup, ['__typename', 'id']),
        },
      });
    }
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };
  return {
    columns,
    rows: data.scopeGroups || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    onFilterModelChange,
    scopeGroups: data.scopeGroups,
    editScopeGroupId,
    addScopeGroupLoading,
    addScopeGroupError,
    removeScopeGroupLoading,
    removeScopeGroupError,
    updateScopeGroupLoading,
    updateScopeGroupError,
    loading:
      loading ||
      addScopeGroupLoading ||
      removeScopeGroupLoading ||
      updateScopeGroupLoading,
    error,
    scopeGroupFormOpen,
    handleScopeGroupFormClose,
    handleScopeGroupFormSave,
    rowCount: data.count,
    ...paginationState,
  };
};
