import { camelCase } from 'lodash';

export enum SUBGRAPHS {
  accounts = 'accounts',
  admin = 'admin',
  jsonforms = 'jsonforms',
  projects = 'projects',
  notifications = 'notifications',
  customers = 'customers',
  ciscoAnnuity = 'cisco-annuity',
  sowSections = 'sow-sections',
  leads = 'leads',
  tasks = 'tasks',
  projectItemQuotes = 'project-item-quotes',
  scope = 'scope',
  proposals = 'proposals',
  /* NX_GENERATOR_APPEND_SUBGRAPH */
}

export enum DOC_ID {
  PROJECT = 'project-detail',
  PROFILE = 'user-profile',
  PROFILE_NOTIFICATIONS = 'user-notifications',
  NEW_PROJECT = 'new-project',
  NEW_LEAD = 'new-lead',
  PRACTICES = 'practices',
  TASKS = 'tasks',
  TASK_GROUPS = 'task-groups',
  ROLES = 'roles',
  CUSTOM_TASKS = 'custom-tasks',
  SOW_SECTIONS = 'sow-sections',
  NEW_PROJECT_ITEM = 'project-items',
  TIERS = 'tiers',
  PROJECT_ITEM_ESTIMATOR = 'project-item-estimator',
  PROPOSAL_TEMPLATES = 'proposal-templates',
  PROPOSALS = 'proposals',
}

export type TOPIC = 'ingest-trigger' | 'ingest-transform-topic';

export interface QuoteSettingsTableRow {
  tableName: string;
  rowName: string;
  value: string;
}
export type QuoteTableData = QuoteSettingsTableRow[];
export interface GlobalQuoteSettingsTableData {
  addlCosts: QuoteTableData;
  hardware: QuoteTableData;
  software: QuoteTableData;
  services: QuoteTableData;
  support: QuoteTableData;
}

export enum GlobalQuoteSettingsFields {
  TAX_SETTINGS = 'globalQuoteSettings',
  HARDWARE_SETTINGS = 'globalQuoteHardwareSettings',
  SOFTWARE_SETTINGS = 'globalQuoteSoftwareSettings',
  SERVICES_SETTINGS = 'globalQuoteServiceSettings',
  SUPPORT_SETTINGS = 'globalQuoteSupportSettings',
}

export enum GlobalQuoteSettingsTableTypes {
  HARDWARE = 'Hardware',
  SOFTWARE = 'Software',
  SERVICES = 'Services',
  SUPPORT = 'Support',
  TAXES = 'Taxes',
}

export enum QuoteColumnFieldsAndHeaders {
  ID = 'ID',
  TYPE = 'Type',
  PART_NUMBER = 'Part Number',
  DESCRIPTION = 'Description',
  LIST_UNIT_COST = 'Unit Sell',
  QUANTITY = 'Quantity',
  DISCOUNT = 'Discount',
  TOTAL_COST = 'CDW Total',
  CUSTOMER_TOTAL_COST = 'Extended Sell',
  MARKUP = 'Markup',
  MARGIN = 'Margin',
}

export type QuoteColumnField = keyof typeof QuoteColumnFieldsAndHeaders;

export const columnFields = Object.keys(QuoteColumnFieldsAndHeaders).map(
  (field) => camelCase(field)
);

export enum QuoteLineTypes {
  HARDWARE = 'hardware',
  SOFTWARE = 'software',
  SERVICE = 'service',
  SUPPORT = 'support',
}
export interface QuoteTableRow {
  id: string;
  type: QuoteLineTypes;
  partNumber: string;
  description: string;
  listUnitCost: string;
  quantity: number;
  discount: number;
  totalCost: string;
  customerTotalCost: string;
  markup: string;
  margin: string;
}

export type HandsonTableSelectionData = [
  startRow: number,
  startCol: number,
  endRow: number,
  endCol: number
];

export enum ScopeItemQuestionTypes {
  LIST = 'List',
  MULTI_LIST = 'Multi List',
  DATE = 'Date',
  NUMBER = 'Number',
  ONE_LINE_TEXT = 'One line Text',
  MULTI_LINE_TEXT = 'Multi line Text',
}

export type EmailTemplate = {
  name:string;
  subject: string;
  body: string;
  id?:string;
}
