// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import type { Customer } from '@cdw-selline/common/types';
import {
  Card,
  CardHeader,
  Divider,
  FormControlLabel,
  Switch,
  Button,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import {
  GridColumnMenuProps,
  GridFilterModel,
  GridColDef,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  useGridApiRef,
} from '@mui/x-data-grid';
import React, { SetStateAction } from 'react';
import { CollectionsDataTable } from '../..';
import { escapeRegExp } from '../notifications-table/NotificationsTable';

export interface CustomerTableProps {
  customers: Customer[]; // TODO: generate types from graphql codegen
  setUserFilter?: React.Dispatch<SetStateAction<Record<string, unknown>>>;
  userState?: boolean;
  userEmail?: string;
  paginationMode?: 'server' | 'client';
  limit?: number;
  rowCount?: number;
  page?: number;
}

export interface CustomersColumn extends GridColDef {
  field: keyof Customer;
}

const columns: CustomersColumn[] = [
  {
    field: 'customerCode',
    headerName: 'Code',
    width: 200,
  },
  {
    field: 'customerDescription',
    headerName: 'Name',
    width: 400,
  },
  {
    field: 'region',
    headerName: 'Region',
    width: 200,
    editable: true,
  },
  {
    field: 'sector',
    headerName: 'Sector',
    width: 200,
  },
  {
    field: 'area',
    headerName: 'Area',
    width: 300,
  },
  {
    field: 'id',
    headerName: 'Actions',
    width: 400,
    renderCell: () => (
      <strong>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{ marginLeft: 0 }}
        >
          Create Project
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          size="small"
          style={{ marginLeft: 5 }}
        >
          Create Lead
        </Button>
      </strong>
    ),
  },
];

export interface CustomerGridColumnMenuProps
  extends GridColumnMenuProps,
    CustomerTableProps {
  value: string;
  clearSearch: () => void;
  onChange: (e) => void;
}

export const GridCustomerColumnMenu = React.forwardRef<
  HTMLUListElement,
  CustomerGridColumnMenuProps
>(function GridCustomerColumnMenu(props: CustomerGridColumnMenuProps, ref) {
  return (
    <GridToolbarContainer
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        color: 'black',
      }}
    >
      <Grid>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton variant="standard" {...({} as any)} />
        <GridToolbarDensitySelector />
        <TextField
          variant="standard"
          value={props.value}
          onChange={props.onChange}
          placeholder="Search…"
          InputProps={{
            startAdornment: <SearchIcon fontSize="small" />,
            endAdornment: (
              <IconButton
                title="Clear"
                aria-label="Clear"
                size="small"
                style={{ visibility: props.value ? 'visible' : 'hidden' }}
                onClick={props.clearSearch}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Grid>
    </GridToolbarContainer>
  );
});

export const CustomerTable = (props: CustomerTableProps) => {
  const data = {
    rows: props.customers || [],
    columns,
  };

  const [searchText, setSearchText] = React.useState('');
  const [rows, setRows] = React.useState<Customer[]>(data.rows);
  const [filterModel, setFilterModel] = React.useState<GridFilterModel>({
    items: [],
  });
  const apiRef = useGridApiRef();
  const requestSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i');
    const filteredRows = data.rows.filter((row) => {
      return Object.keys(row).some((field) => {
        return searchRegex.test(row?.[field]?.toString?.());
      });
    });
    setRows(filteredRows);
  };

  React.useEffect(() => {
    setRows(data.rows);
    const filterData = JSON.parse(
      localStorage.getItem('customer-pipeline-filters')
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    filterData ? setFilterModel(filterData) : null;
  }, [data.rows]);

  const handleToggleChange = (e) => {
    e.target.checked
      ? props.setUserFilter({})
      : props.setUserFilter({
          $or: [
            { projectTeam: { $elemMatch: { email: props.userEmail } } },
            { projectOwner: props.userEmail },
          ],
        });
  };

  const onFilterModelChange = (filterModel) => {
    setFilterModel(filterModel);
    localStorage.setItem(
      'customer-pipeline-filters',
      JSON.stringify(filterModel)
    );
  };

  return (
    <Card
      style={{
        width: '100%',
        background: '#ffffff',
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <CardHeader
        title="Accounts"
        action={
          <FormControlLabel
            control={
              <Switch checked={props.userState} onChange={handleToggleChange} />
            }
            label="View your accounts"
          />
        }
        sx={{ width: '100%' }}
      />
      <Divider />

      <CollectionsDataTable
        columns={columns}
        rows={rows}
        editMode="row"
        gridMargin={0}
        parent="CustomerView"
        saveOnEditStop={true}
        onFilterModelChange={onFilterModelChange}
        filterModel={filterModel}
      />
    </Card>
  );
};
