import '@cdw-selline/ui/theme';
import { useOpportunities } from './useOpportunities';
import { getFilters } from '@cdw-selline/ui/helpers';
import { pick } from 'lodash';
import { pruneEmpty } from '@cdw-selline/ui/common';
import { datagridPagination } from '@cdw-selline/ui/helpers';
import { useEffect, useState } from 'react';

export const useDashboard = (userFilter) => {
  const [filterModel, setFilterModel] = useState(
    JSON.parse(localStorage.getItem('OpportunityView-filter'))
  );

  const { loading, data, error, project, refetch, count } = useOpportunities({
    offset: 0,
    limit: 100,
    filters: getFilters('OpportunityView', userFilter),
    sort: { estClose: 1 },
  });

  const {
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    paginationState,
  } = datagridPagination(refetch, getFilters('OpportunityView', userFilter), { estClose: 1 });

  const handleProjectUpdate = (params) => {
    project.updateProject({
      variables: {
        updateProjectParams: pick(pruneEmpty(params), [
          'name',
          'id',
          'estClose',
          'probability',
        ]),
      },
    });
  };

  const onFilterModelChange = () => {
    refetch({ filters: getFilters('OpportunityView', userFilter) });
  };


  return {
    error,
    loading,
    rows: data ? data : [],
    handleSave: handleProjectUpdate,
    onFilterModelChange,
    filterModel,
    rowCount: count,
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    ...paginationState,
  };
};
