import { TASK_COST_TYPES } from '@cdw-selline/ui/constants';
import { MenuItem } from '@mui/material';
import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormAutocompleteOption,
} from '../formHelperFunctions';

export interface CustomCostTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  errorsArray: any;
  practiceOptions: FormAutocompleteOption[];
}

export function CustomCostTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  errorsArray,
  practiceOptions,
}: CustomCostTaskFormProps) {
  return (
    <>
      <FormSelect
        name="costType"
        label="Cost Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.costType}
      >
        {Object.keys(TASK_COST_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_COST_TYPES[type]}>
            {TASK_COST_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      {taskFormData.costType !== TASK_COST_TYPES.FUNDING && (
        <FormCheckbox
          name="managedServices"
          label="Managed Services?"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
      )}
      <FormText
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      <FormText
        name="units"
        label="Units"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="category"
        label="Category"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormAutocomplete
        name="vendor"
        label="Vendor"
        options={vendorOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        freeSolo={true}
      />
      <FormTextControlled
        name="comment"
        label="Comment"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {taskFormData.managedServices && (
        <>
          <FormCheckbox
            name="alwaysShowOnProposal"
            label="Always Show on Proposal?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="proposalNotes"
            label="Proposal Notes"
            setFormData={setTaskFormData}
            formData={taskFormData}
            multiline={true}
          />
        </>
      )}
      <FormText
        name="quantity"
        label="Quantity"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="cost"
        label="Cost"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.cost}
      />
      <FormText
        name="grossProfit"
        label="Default Gross Profit"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
      />
    </>
  );
}
