import { useMutation } from '@apollo/client';
import {
  RELOAD_PROJECT_PROPOSAL_MUTATION,
  GET_PROJECT_PROPOSAL_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import { useApolloErrors } from '../useApolloErrors';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';

export const useReloadProjectProposal = (completeCallback: (data) => void) => {
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [reloadProjectProposal, { loading, error }] = useMutation(
    RELOAD_PROJECT_PROPOSAL_MUTATION,
    {
      refetchQueries: [GET_PROJECT_PROPOSAL_BY_ID_QUERY],
      onError: (error) => {
        handleErrorResponse(error, 'Failed to reload Project Proposal!')
      },
      onCompleted: (data) => {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Reloaded Project Proposal!',
        });
      },
    }
  );

  const handleReloadProjectProposal = async (projectProposalId: string) => {
    const addProposalResponse = await reloadProjectProposal({
      variables: {
        id: projectProposalId,
      },
    });
    return addProposalResponse;
  };

  return {
    handleReloadProjectProposal,
    loading,
    error,
  };
};
