// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  statusActiveStep,
  statusSteps,
  closeStatus,
} from '@cdw-selline/ui/constants';
import {
  Button,
  DialogActions,
  Grid,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Step,
  StepLabel,
  Stepper,
  TextField,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useProjects } from '@cdw-selline/ui/hooks';
import { useProjectStatus } from '@cdw-selline/ui/hooks';
import DialogModal from '../dialog-modal/DialogModal';
import { LoadingButton } from '@mui/lab';

export const ProjectStatusStepper = (props) => {
  const { updateProject } = useProjects({});

  const theme = useTheme();

  const [closedStatus, setClosedStatus] = useState(
    props.project?.closedStatus ?? null
  );

  const [previousStatus, setPreviousStatus] = useState(
    props.project?.status ?? 'Discovery'
  );

  const [closedReason, setClosedReason] = useState(
    props.project?.closedReason ?? null
  );

  const id = props.project?.id;
  const { updateProjectStatus } = useProjectStatus({ id });

  const handleCloseCompleteForm = () => {
    updateProjectStatus({
      variables: {
        updateProjectStatusParams: {
          id,
          status: previousStatus,
          closedReason: '',
          closedStatus: '',
        },
      },
    });
    setClosedReason('');
    setClosedStatus('');
  };
  const handleStatusChange = (e: SelectChangeEvent) => {
    setClosedStatus(e.target.value);
  };

  const handleReasonChange = (value) => {
    setClosedReason(value);
  };

  const handlePreviousStatus = (value) => {
    setPreviousStatus(value);
  };

  const handleCompleteForm = (): void => {
    if (closedStatus !== 'ClosedWon' && closedReason === '') return;

    const id = props.project?.id;

    updateProject({
      variables: {
        updateProjectParams: {
          closedStatus: closedStatus,
          closedReason: closedReason,
          id,
        },
      },
    });
    setClosedReason(closedReason);
    setClosedStatus(closedStatus);
  };

  const handleCompletePhase = (status = null) => {
    if (typeof status !== 'string') {
      status = props.project?.status;
    }
    const clickedStatusIndex = parseInt(statusActiveStep[status]);
    const projectStatusIndex = parseInt(
      statusActiveStep[props.project?.status]
    );

    let forwardStep = 0;
    if (clickedStatusIndex === projectStatusIndex) {
      forwardStep = 1;
    }

    const statusInt = parseInt(statusActiveStep[status] + forwardStep);

    const newStatus = statusSteps[statusInt];

    handlePreviousStatus(props.project?.status);
    if (newStatus) {
      updateProjectStatus({
        variables: {
          updateProjectStatusParams: {
            id,
            status: newStatus,
            closedReason: '',
            closedStatus: '',
          },
        },
      });
    }
  };

  if (!props.project) return <>No project provided</>;

  return (
    <Grid container columnSpacing={0} sx={{ ml: 0, mr: 0 }}>
      <Grid item xs={10}>
        <Stepper
          style={{ backgroundColor: `${theme.palette.secondary}` }}
          activeStep={
            props.project?.closedStatus
              ? 4
              : Number(statusActiveStep[props.project.status])
          }
          alternativeLabel
        >
          {statusSteps.map((label, key) => (
            <Step
              onClick={() => handleCompletePhase(label)}
              style={{
                backgroundColor: `${theme.palette.secondary}`,
                cursor: `pointer`,
              }}
              key={label}
            >
              <StepLabel
                style={{
                  backgroundColor: `${theme.palette.secondary}`,
                }}
              >
                {label}{' '}
                {label === 'Close' && props.project.closedStatus
                  ? ' - ' + closeStatus[props.project.closedStatus]
                  : ''}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Paper>
        <DialogModal
          isOpen={
            props.project.status === 'Close' && !props.project.closedStatus
          }
          handleClose={props.handleClose}
          title={'Close This Opportunity'}
        >
          <Select
            labelId="closedStatus"
            label={'Stage'}
            value={closedStatus || '-1'}
            onChange={handleStatusChange}
            sx={{ margin: '1em 0', width: '40em' }}
          >
            <MenuItem value={'-1'} key={'-1'}>
              Select Option
            </MenuItem>

            <MenuItem value={'ClosedWon'} key={'ClosedWon'}>
              Closed Won
            </MenuItem>

            <MenuItem value={'ClosedLost'} key={'ClosedLost'}>
              Closed Lost
            </MenuItem>
            <MenuItem
              value={'ClosedDidNotPurchase'}
              key={'ClosedDidNotPurchase'}
            >
              Closed Did Not Purchase
            </MenuItem>
          </Select>
          {closedStatus !== 'ClosedWon' && (
            <TextField
              label={'Close Reason'}
              type="text"
              sx={{ width: '40em' }}
              data-testid={`close-project-${props?.label}-input`}
              required={true}
              onChange={(e) => handleReasonChange(e.target.value)}
            />
          )}
          <DialogActions>
            <Button
              color="secondary"
              onClick={handleCloseCompleteForm}
              data-testid="new-Lead-cancel-button"
            >
              Cancel
            </Button>
            <LoadingButton
              color="primary"
              type="submit"
              onClick={handleCompleteForm}
              loading={!!props.createLeadLoading}
              variant="contained"
              data-testid="new-closed-project-submit-button"
            >
              Save
            </LoadingButton>
          </DialogActions>
        </DialogModal>
      </Paper>
      <Grid item xs={2}>
        <Button
          variant="outlined"
          color={props.project.status === 'Archive' ? 'secondary' : 'success'}
          onClick={handleCompletePhase}
        >
          {props.project.status === 'Archive'
            ? 'Project Archived'
            : 'Mark Stage as Complete'}
        </Button>
      </Grid>
    </Grid>
  );
};
