import { TASK_COST_TYPES, TASK_TIER_LEVELS } from '@cdw-selline/ui/constants';
import { MenuItem, Grid } from '@mui/material';
import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormAutocompleteOption,
} from '../formHelperFunctions';
import { VolumePricingTable } from '@cdw-selline/ui/components';

export interface CostTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  vendorOptions: FormAutocompleteOption[];
  practiceOptions: FormAutocompleteOption[];
  taskGroupOptions: FormAutocompleteOption[];
  tierOptions: FormAutocompleteOption[];
  tierError: boolean;
  roleOptions: FormAutocompleteOption[];
  sowSectionOptions: FormAutocompleteOption[];
  siteId: string;
  errorsArray: any;
}

export function CostTaskForm({
  taskFormData,
  setTaskFormData,
  vendorOptions,
  practiceOptions,
  taskGroupOptions,
  tierOptions,
  tierError,
  roleOptions,
  sowSectionOptions,
  siteId,
  errorsArray,
}: CostTaskFormProps) {
  return (
    <>
      <FormSelect
        name="costType"
        label="Cost Type"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.costType}
      >
        {Object.keys(TASK_COST_TYPES).map((type) => (
          <MenuItem key={type} value={TASK_COST_TYPES[type]}>
            {TASK_COST_TYPES[type]}
          </MenuItem>
        ))}
      </FormSelect>
      <FormText
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      <FormSelect
        name="estimatorLocation"
        label="Estimator Location"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.estimatorLocation}
      >
        <MenuItem value="site">Site</MenuItem>
        <MenuItem value="summary">Summary</MenuItem>
      </FormSelect>
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {taskFormData.practiceId && (
        <>
          <FormAutocomplete
            name="taskGroupId"
            label="Task Group"
            options={taskGroupOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskGroupId}
          />
          <FormTextControlled
            name="taskId"
            label="Task Id"
            setFormData={setTaskFormData}
            formData={taskFormData}
            data-testid="task-id"
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskId}
          />
        </>
      )}
      {taskFormData.taskGroupId && (
        <>
          <FormCheckbox
            name="isProjectManagement"
            label={'Enrollment Project Management?'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {taskFormData.costType !== TASK_COST_TYPES.FUNDING && (
            <FormCheckbox
              name="managedServices"
              label="Managed Services?"
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
          )}
          {taskFormData.managedServices && (
            <>
              <FormCheckbox
                name="isManagedServicesCostCalculation"
                label={'Is MS Cost Calc?'}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormCheckbox
                name="managedServicesFteFormula"
                label={'managedServicesFteFormula'}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormCheckbox
                name="managedServicesArcRrcFormula"
                label={'managedServicesArcRrcFormula'}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormCheckbox
                name="managedServicesTransitionFormula"
                label={'managedServicesTransitionFormula'}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
            </>
          )}
          <FormText
            name="units"
            label="Units"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="category"
            label="Category"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormAutocomplete
            name="vendor"
            label="Vendor"
            options={vendorOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            freeSolo={true}
          />
          <FormTextControlled
            name="comment"
            label="Comment"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {taskFormData.managedServices && (
            <>
              <FormCheckbox
                name="alwaysShowOnProposal"
                label="Always Show on Proposal?"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormText
                name="proposalNotes"
                label="Proposal Notes"
                setFormData={setTaskFormData}
                formData={taskFormData}
                multiline={true}
              />
            </>
          )}
          <Grid item xs={12} sx={{ width: '100%' }}>
            <Grid container direction="row" spacing={1}>
              {taskFormData.managedServices && (
                <>
                  <Grid item xs={6}>
                    <FormText
                      name="enrollmentCost"
                      label="Enrollment Cost"
                      setFormData={setTaskFormData}
                      formData={taskFormData}
                      type="number"
                      disabled={
                        taskFormData.enrollmentHours ||
                        taskFormData.enrollmentCostFormula
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormText
                      name="enrollmentCostFormula"
                      label="Enrollment Cost Formula"
                      setFormData={setTaskFormData}
                      formData={taskFormData}
                      disabled={
                        taskFormData.enrollmentHours ||
                        taskFormData.enrollmentCost
                          ? true
                          : false
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormText
                      name="enrollmentHours"
                      label="Enrollment Hours"
                      setFormData={setTaskFormData}
                      formData={taskFormData}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormAutocomplete
                      name="enrollmentRole"
                      label="Enrollment Role"
                      options={roleOptions}
                      setFormData={setTaskFormData}
                      formData={taskFormData}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormText
                      name="enrollmentHoursFormula"
                      label="Enrollment Hours Formula"
                      setFormData={setTaskFormData}
                      formData={taskFormData}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                <FormText
                  name="sku"
                  label="SKU"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
              </Grid>
              <Grid item xs={6}>
                <FormText
                  name="edc"
                  label="EDC"
                  setFormData={setTaskFormData}
                  formData={taskFormData}
                />
              </Grid>
            </Grid>
          </Grid>
          {taskFormData.managedServices && (
            <FormAutocomplete
              name="tierId"
              label="Tier"
              options={tierOptions}
              setFormData={setTaskFormData}
              formData={taskFormData}
              freeSolo={true}
            />
          )}
          {taskFormData.tierId && (
            <FormSelect
              name="tier"
              label="Tier - Level"
              error={tierError}
              setFormData={setTaskFormData}
              formData={taskFormData}
            >
              {Object.keys(TASK_TIER_LEVELS).map((type) => (
                <MenuItem key={type} value={TASK_TIER_LEVELS[type]}>
                  {TASK_TIER_LEVELS[type]}
                </MenuItem>
              ))}
            </FormSelect>
          )}
        </>
      )}
      <FormText
        name="quantity"
        label="Quantity"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormCheckbox
        name="disableQuantity"
        label="Disable Quantity?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {!taskFormData.disableQuantity && (
        <FormText
          name="disableQuantityFormula"
          label="Disable Quantity Formula"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
      )}
      <FormText
        name="quantityFormula"
        label="Quantity Formula"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="quantityMin"
        label="Quantity Min"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="quantityMax"
        label="Quantity Max"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {siteId || (taskFormData.allowAdminEdit && taskFormData.defaultCost) ? (
        <FormText
          name="defaultCost"
          label="Cost"
          setFormData={setTaskFormData}
          formData={taskFormData}
          type="number"
        />
      ) : (
        <FormText
          name="cost"
          label="Cost"
          setFormData={setTaskFormData}
          formData={taskFormData}
          type="number"
          required={true}
          error={errorsArray.length > 0 && !taskFormData.cost}
        />
      )}
      <FormCheckbox
        name="disableCost"
        label="Disable Cost?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {!taskFormData.disableCost && (
        <FormText
          name="disableCostFormula"
          label="Disable Cost Formula"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
      )}
      <FormText
        name="costFormula"
        label="Cost Formula"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="minGrossProfit"
        label="Min Gross Profit"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
      />
      <FormCheckbox
        name="allowMarkup"
        label="Allow Markup?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      <FormText
        name="grossProfit"
        label="Default Gross Profit"
        setFormData={setTaskFormData}
        formData={taskFormData}
        type="number"
      />
      <FormCheckbox
        name="alwaysShowOnProposal"
        label="Always Show on Proposal?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {taskFormData.costType !== TASK_COST_TYPES.FUNDING && (
        <>
          <FormCheckbox
            name="volumePricingEnabled"
            label="Volume Pricing Enabled"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {taskFormData.volumePricingEnabled && (
            <VolumePricingTable
              volumePricingData={taskFormData?.volumePricing ?? []}
              setFormData={setTaskFormData}
              formData={taskFormData}
            />
          )}
        </>
      )}
      <FormCheckbox
        name="hide"
        label="Hide task?"
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
      {!taskFormData.hide && (
        <FormText
          name="hideShowFormula"
          label="Hide/Show Formula"
          setFormData={setTaskFormData}
          formData={taskFormData}
        />
      )}
      <FormAutocomplete
        name="sowSectionId"
        label="SOW Section"
        options={sowSectionOptions}
        freeSolo={false}
        setFormData={setTaskFormData}
        formData={taskFormData}
      />
    </>
  );
}

export default CostTaskForm;
