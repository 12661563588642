import {
  useTaskInitialData,
  useOpenState,
  useValidateTask,
  useGetNextTaskId,
} from '@cdw-selline/ui/hooks';
import { Task, Practice } from '@cdw-selline/common/types';
import {
  TASK_TYPES,
  TASK_COST_TYPES,
  TASK_TEXT_TYPES,
  TASK_STATUS,
} from '@cdw-selline/ui/constants';
import { Grid, Typography, MenuItem, Box } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import DialogModal from '../dialog-modal/DialogModal';
import {
  FormSelect,
  getActionButtons,
} from '../formHelperFunctions';
import { ApolloError } from '@apollo/client';
import { DialogConfirm } from '@cdw-selline/ui/components';
import { ALERT_SEVERITY, ALERT_TYPE, getCurrentUser, useAlertsState } from '@cdw-selline/ui/state';
import { cloneDeep, isEqual, omit, startCase } from 'lodash';
import {
  CustomServiceTaskForm,
  CustomCostTaskForm,
  CostTaskForm,
  TaskFormHeader,
  TextTaskForm,
  ListTaskForm,
  HoursTaskForm,
  YesNoTaskForm,
  ProductTaskForm,
} from '.';
import LoadingOverlay from '../LoadingOverlay';
export interface TaskFormProps {
  isOpen;
  handleClose;
  handleSubmit;
  handleTestVersion?: (taskId: string) => void;
  task?: any | null;
  siteId?: string | null;
  taskLoading?: boolean;
  taskError?: ApolloError;
  projectItemId?: string;
  currentPractices?: Practice[];
  handleDelete?: (taskId: string) => void;
}

const newTaskDefaults: Task = {
  hours: 0,
  quantity: 0,
  primaryPercent: 100,
  secondaryPercent: 0,
  vendor: 'CDW',
  estimatorLocation: 'site',
  textType: TASK_TEXT_TYPES.MULTI_LINE,
  status: TASK_STATUS.IN_TESTING,
};

export function TaskForm({
  isOpen,
  handleClose,
  handleSubmit,
  task,
  siteId,
  taskLoading,
  taskError,
  projectItemId,
  currentPractices,
  handleTestVersion,
  handleDelete,
}: TaskFormProps) {
  const [taskFormData, setTaskFormData] = useState(task?.id ?? newTaskDefaults);
  const [tierError, setTierError] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState(task.version);
  const currentUser = getCurrentUser();
  const taskTypes = Object.keys(TASK_TYPES);
  const practiceId = currentPractices ? currentPractices[0]?.id : null;
  const { validate } = useValidateTask();
  const [errorArray, setErrorArray] = useState([]);
  const taskFormRef = useRef(null);
  const alertState = useAlertsState();

  const {
    currentTask,
    vendorOptions,
    practiceOptions,
    taskGroupOptions,
    taskGroups,
    roleOptions,
    roles,
    getFirstRole,
    currentTaskVersion,
    versionList,
    sowSectionOptions,
    tierOptions,
    practiceLoading,
    practiceError,
    rolesLoading,
    taskDependencies,
  } = useTaskInitialData(
    task,
    currentPractices,
    selectedVersion,
    taskFormData.sowSectionName,
    taskFormData.practiceId || task?.practiceId
  );

  const isCustomTask = (!task?.id && siteId) || task?.custom;

  const { nextTaskId } = useGetNextTaskId(
    !siteId && !task.id && !task.custom? taskFormData.taskGroupId : null
  );

  const {
    isOpen: confirmTestVersion,
    handleClose: handleTestVersionConfirmClose,
    handleOpen: handleTestVersionConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmRestore,
    handleClose: handleRestoreConfirmClose,
    handleOpen: handleRestoreConfirmOpen,
  } = useOpenState();

  const {
    isOpen: confirmDelete,
    handleClose: handleDeleteConfirmClose,
    handleOpen: handleDeleteConfirmOpen,
  } = useOpenState();

  useEffect(() => {
    if (
      currentTaskVersion?.id &&
      currentTaskVersion?.version !== task.version
    ) {
      setTaskFormData({
        ...currentTaskVersion,
      });
    } else {
      setTaskFormData({
        ...task,
      });
    }
  }, [currentTaskVersion.id]);

  useEffect(() => {
    currentTask.status =
      currentTask.status ||
      (currentTask.originalTaskId
        ? TASK_STATUS.IN_TESTING
        : TASK_STATUS.PUBLISHED);
    const selectedTask =
      currentTask.status === taskFormData.status
        ? cloneDeep(currentTask)
        : task;
    if (selectedTask.id) {
      setTaskFormData(selectedTask);
    }
  }, [taskFormData.status]);

  useEffect(() => {
    const createNewCustomTask = () => {
      const primaryRoleId = getFirstRole(practiceId, roles) ?? '';
      setTaskFormData((taskFormData) => ({
        ...taskFormData,
        siteId: siteId,
        projectItemId: projectItemId,
        practiceId: practiceId,
        practice: currentPractices.find(practice=>practice.id === practiceId)?.name,
        custom: true,
        order: 1000,
        type: 'Hours',
        category: 'Other',
        primaryRoleId: primaryRoleId,
        primaryRole: primaryRoleId,
        primaryRoleName: roles.roles.find(role=>role.id===primaryRoleId)?.name,
        primaryPercent: 100,
        secondaryPercent: 0,
        units: 'Hours',
        hours: 1,
        quantity: 1,
        exclude: false,
        costType: TASK_COST_TYPES.RECURRING,
        vendor: 'CDW',
        estimatorLocation: 'site',
        textType: TASK_TEXT_TYPES.MULTI_LINE,
        allowMarkup: true,
        isProjectManagement: false,
        managedServices: false,
        overtime: false,
      }));
    };

    if (!task.id) {
      if (siteId) {
        createNewCustomTask();
      }
    } else {
      setTaskFormData({
        ...task,
        estimatorLocation: task.estimatorLocation ?? 'site',
        status: task.status || (task.originalTaskId ? TASK_STATUS.IN_TESTING : TASK_STATUS.PUBLISHED)
      });
    }
  }, [task.id, siteId, projectItemId, currentPractices, rolesLoading]);

  useEffect(() => {
    let setEmpty = true;
    if (!taskFormData.custom && taskGroupOptions.length > 0 && taskFormData.taskGroupId) {
      taskGroupOptions.forEach((option) => {
        if (Object.values(option).includes(taskFormData.taskGroupId)) {
          setEmpty = false;
        }
      });
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      setEmpty ? setTaskFormData({ ...taskFormData, taskGroupId: '' }) : null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taskGroups, taskFormData.practiceId]);

  useEffect(() => {
    if (!taskFormData.custom && nextTaskId && !taskFormData.taskId) {
      setTaskFormData({
        ...taskFormData,
        taskId: nextTaskId,
      });
    }
  }, [nextTaskId, taskFormData]);

  useEffect(() => {
    if (
      [TASK_TYPES.COST, TASK_TYPES.TEXT].includes(taskFormData.type) ||
      taskFormData.primaryRole ||
      !taskFormData.custom
    ) {
      return;
    }

    const firstRole = getFirstRole(taskFormData.practiceId, roles);

    if (!firstRole) return;

    setTaskFormData({ ...taskFormData, primaryRole: firstRole });
  }, [taskFormData.practiceId, taskFormData.type, taskFormData.primaryRole]);

  const handleFormClose = () => {
    setTaskFormData(newTaskDefaults);
    handleClose();
  };

  const handleTestVersionConfirm = () => {
    handleTestVersion(taskFormData.id);
    handleClose();
  };

  const handleSaveClick = async () => {
    if (isEqual(taskFormData, task)) {
      alertState.setAlert({
        type: ALERT_TYPE.MODAL,
        severity: ALERT_SEVERITY.INFO,
        message: "No changes to save!",
      });
      return;
    }

    const validationMessages = await validate(taskFormData);
    if (validationMessages?.length > 0) {
      setErrorArray(validationMessages);
      taskFormRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      return;
    }

    if (
      isCustomTask &&
      taskFormData.type === TASK_TYPES.COST &&
      !taskFormData.cost
    ) {
      // setErrorMessage('Cost is required field !');
      return;
    }
    if (isCustomTask) {
      taskFormData.practice = currentPractices.find(practice=>practice.id === taskFormData.practiceId)?.name;
      taskFormData.primaryRoleId = taskFormData.primaryRole;
      taskFormData.primaryRoleName= roles.roles.find(role=>role.id===taskFormData.primaryRole)?.name;
    }
    // setErrorMessage('');
    setTierError(false);
    handleSubmit(taskFormData);
  };

  const handleDuplicateClick = async () => {
    const validationMessages = await validate(taskFormData);

    if (validationMessages?.length > 0) {
      setErrorArray(validationMessages);
      return;
    }
    // setErrorMessage('');
    setTierError(false);
    handleSubmit({
      ...omit(taskFormData, ['id', 'taskId']),
      name: `${taskFormData.name} (Copy)`,
    });
  };

  if (taskLoading || practiceLoading || !taskFormData)
    return <LoadingOverlay />;

  if (taskError || practiceError)
    return <Typography>Error loading form</Typography>;

  const handleToggleChange = (e) => {
    setTaskFormData({
      ...taskFormData,
      status: e.target.checked ? TASK_STATUS.IN_TESTING : TASK_STATUS.PUBLISHED,
    });
  };

  const handleChangeVersion = (event) => {
    setSelectedVersion(event.target.value);
  };

  const getTitle = () => {
    if (taskFormData?.custom && taskFormData?.id) {
      return `Edit Custom Task`;
    }

    if (taskFormData?.custom && !taskFormData?.id) {
      return `New Custom Task`;
    }

    if (taskFormData?.version) {
      return (
        <TaskFormHeader
          taskFormData={taskFormData}
          task={task}
          versionList={versionList}
          selectedVersion={selectedVersion}
          handleToggleChange={handleToggleChange}
          handleChangeVersion={handleChangeVersion}
          handleRestoreConfirmOpen={handleRestoreConfirmOpen}
        />
      );

    } else {
      return 'Add Task';
    }
  };

  const allowTestVersion = () => {
    if (!siteId) {
      if (
        taskFormData.status === TASK_STATUS.PUBLISHED &&
        !taskFormData?.inTestingTaskId
      ) {
        return handleTestVersionConfirmOpen;
      }
    }
  };

  const allowDuplicate = () => {
    if (!siteId) {
      if (
        taskFormData?.status === TASK_STATUS.PUBLISHED ||
        (taskFormData?.status === TASK_STATUS.IN_TESTING &&
          !taskFormData?.originalTaskId)
      ) {
        return handleDuplicateClick;
      }
    }
  };

  const handleDeleteTask = async () => {
    handleDelete(task.id);
    handleDeleteConfirmClose();
    handleClose();
  }

  const allowDelete = () => {
    if (task.id && !siteId) {
      return handleDeleteConfirmOpen;
    }
  };

  const handleRestoreVersion = () => {
    handleSubmit({
      ...taskFormData,
      id: task.id,
      version: task.version,
    });
    handleClose();
  };

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={getTitle()}
      action={getActionButtons(
        handleFormClose,
        handleSaveClick,
        allowDuplicate(),
        allowTestVersion(),
        allowDelete(),
      )}
      maxWidthProp={'lg'}
      disableEnforceFocus={true}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '90vh' }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
          ref={taskFormRef}
        >
          {(errorArray || tierError) && (
            <Box sx={{ padding: '5px' }}>
              {errorArray.map((error) => {
                return (
                  <Typography key={`${error}`} color="error" variant="h6">
                    {error ?? 'Please fill out missing field(s)'}
                  </Typography>
                );
              })}
            </Box>
          )}
          <FormSelect
            name="type"
            label="Type"
            setFormData={setTaskFormData}
            formData={taskFormData}
          >
            {taskTypes
              .filter((type) => isCustomTask || type !== 'CUSTOM_SERVICE')
              .map((type) => (
                <MenuItem key={type} value={TASK_TYPES[type]}>
                  {TASK_TYPES[type]}
                </MenuItem>
              ))}
          </FormSelect>

          {/* Switch for task type forms  */}

          {taskFormData.type === TASK_TYPES.TEXT && (
            <TextTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              practiceOptions={practiceOptions}
              taskGroupOptions={taskGroupOptions}
              vendorOptions={vendorOptions}
              isCustom={isCustomTask}
              errorsArray={errorArray}
            />
          )}
          {taskFormData.type === TASK_TYPES.LIST && (
            <ListTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              practiceOptions={practiceOptions}
              taskGroupOptions={taskGroupOptions}
              vendorOptions={vendorOptions}
              sowSectionOptions={sowSectionOptions}
              roleOptions={roleOptions}
              currentUser={currentUser}
              isCustom={isCustomTask}
              errorsArray={errorArray}
            />
          )}
          {taskFormData.type === TASK_TYPES.HOURS && (
            <HoursTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              practiceOptions={practiceOptions}
              taskGroupOptions={taskGroupOptions}
              vendorOptions={vendorOptions}
              sowSectionOptions={sowSectionOptions}
              roleOptions={roleOptions}
              currentUser={currentUser}
              isCustom={isCustomTask}
              errorsArray={errorArray}
            />
          )}
          {taskFormData.type === TASK_TYPES.YES_NO && (
            <YesNoTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              practiceOptions={practiceOptions}
              taskGroupOptions={taskGroupOptions}
              vendorOptions={vendorOptions}
              sowSectionOptions={sowSectionOptions}
              roleOptions={roleOptions}
              currentUser={currentUser}
              isCustom={isCustomTask}
              errorsArray={errorArray}
            />
          )}
          {taskFormData.type === TASK_TYPES.CUSTOM_SERVICE && (
            <CustomServiceTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              practiceOptions={practiceOptions}
              vendorOptions={vendorOptions}
              roleOptions={roles.roles}
              errorsArray={errorArray}
            />
          )}
          {taskFormData.type === TASK_TYPES.COST && (
            <>
              {isCustomTask && (
                <CustomCostTaskForm
                  taskFormData={taskFormData}
                  practiceOptions={practiceOptions}
                  setTaskFormData={setTaskFormData}
                  vendorOptions={vendorOptions}
                  errorsArray={errorArray}
                />
              )}
              {!isCustomTask && (
                <CostTaskForm
                  taskFormData={taskFormData}
                  setTaskFormData={setTaskFormData}
                  vendorOptions={vendorOptions}
                  practiceOptions={practiceOptions}
                  taskGroupOptions={taskGroupOptions}
                  tierOptions={tierOptions}
                  tierError={tierError}
                  roleOptions={roleOptions}
                  sowSectionOptions={sowSectionOptions}
                  siteId={siteId}
                  errorsArray={errorArray}
                />
              )}
            </>
          )}
          {taskFormData.type === TASK_TYPES.PRODUCT && (
            <ProductTaskForm
              taskFormData={taskFormData}
              setTaskFormData={setTaskFormData}
              vendorOptions={vendorOptions}
              practiceOptions={practiceOptions}
              taskGroupOptions={taskGroupOptions}
              isCustom={isCustomTask}
              errorsArray={errorArray}
            />
          )}
        </Grid>
      </Grid>
      {confirmTestVersion && (
        <DialogConfirm
          title="Create Test Version?"
          isOpen={confirmTestVersion}
          handleClose={handleTestVersionConfirmClose}
          handleYes={handleTestVersionConfirm}
        >
          <Typography>
            This action will create test version of this task including all task
            group and sow sections. Are you sure ?
          </Typography>
        </DialogConfirm>
      )}
      {confirmRestore && (
        <DialogConfirm
          title="Restore?"
          isOpen={confirmRestore}
          handleClose={handleRestoreConfirmClose}
          handleYes={handleRestoreVersion}
        >
          <Typography variant="caption" sx={{ ml: 1 }}>
            Would you like to restore this task version?
          </Typography>
        </DialogConfirm>
      )}
      {confirmDelete && (
        <DialogConfirm
          title="Delete this Task"
          isOpen={confirmDelete}
          handleClose={handleDeleteConfirmClose}
          handleYes={handleDeleteTask}
        >
          <Typography variant="h5">
            Would you like to delete this task?
            {taskDependencies.length>0 &&
              <Typography sx={{ whiteSpace: 'break-spaces', mt: 1}}>
                BE AWARE THIS TASK IS REFERNECED BY OTHER FOLLOWING TASKS:
                <p>{taskDependencies.join('\n')}</p>
              </Typography>}
          </Typography>
        </DialogConfirm>
      )}
    </DialogModal>
  );
}

export default TaskForm;
