import { RoleAndRate, Site, Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { estimatorStrings, labelStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import SummaryTaskList from '../task-list/SummaryTaskList';

export interface SummaryCardProps {
  site: Site;
  projectItemId: string;
  handleTaskEdit: (taskId: string, siteId: string) => void;
  handleVersionSyncOpen: (task: Task) => void;
  handleNotesOpen: (currentTask: Task) => void;
  currentRolesAndRates: RoleAndRate[];
  handleAddTaskGroup: (siteId: string) => void;
  handleSyncTaskGroup: (siteId: string) => void;
  handleRemoveCustomTask: (taskId: string) => void;
  activeTaskId: string;
  fullSiteLoad: boolean;
  setFullSiteLoad: (value: boolean) => void;
  handleTextTaskEdit: (taskId: string, siteId: string) => void;
  handleProposalDescriptionDialogOpen: (task: Task) => void;
  isReadOnly: boolean;
  tabView?: boolean;
}

export function SummaryCard({
  site,
  projectItemId,
  handleTaskEdit,
  handleVersionSyncOpen,
  handleNotesOpen,
  currentRolesAndRates,
  handleAddTaskGroup,
  handleSyncTaskGroup,
  handleRemoveCustomTask,
  handleProposalDescriptionDialogOpen,
  activeTaskId,
  fullSiteLoad,
  setFullSiteLoad,
  handleTextTaskEdit,
  isReadOnly,
  tabView,
}: SummaryCardProps) {

  const localStorageKey = `estimator-summary-site-tasks-${projectItemId}}-expanded`;
  const [expanded, setToggleExpanded] = React.useState(
    String(localStorage.getItem(localStorageKey) ?? 'true') === 'true'
  );

  const handleToggleAccordion = ()=> {
    localStorage.setItem(
      localStorageKey,
      String(!expanded)
    );
    setToggleExpanded(!expanded);
  };
  
  if (!tabView) {
    return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'space-between',
        padding: '0',
      }}
    >
      <AccordionSummary
        style={{ cursor: 'default' }}
        aria-controls="summary site task list"
        expandIcon={
          <ExpandMoreIcon
            data-testid="summary-expand-site"
            onClick={handleToggleAccordion}
            style={{ cursor: 'pointer' }}
          />
        }
      >
        <Grid container sx={{ justifyContent: 'space-between' }}>
          <Grid
            item
            component="form"
            sx={{
              display: 'flex',
              alignItems: 'space-between',
            }}
            noValidate
            autoComplete="off"
            xs={12}
            lg={7}
          >
            <Typography variant="h3">Summary</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              padding: '0',
            }}
          >
            <Grid
              item
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                ml: '2em',
              }}
            >
              <Typography color="primary" onClick={handleToggleAccordion}>
                {expanded ? 'Collapse' : 'Expand'} Summary
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0',
        }}
      >
        <SummaryTaskList
          projectItemId={projectItemId}
          site={site}
          expanded={expanded}
          hideZeroQuantity={site.hideZeroQuantity}
          handleTaskEdit={handleTaskEdit}
          handleVersionSyncOpen={handleVersionSyncOpen}
          handleNotesOpen={handleNotesOpen}
          currentRolesAndRates={currentRolesAndRates}
          handleRemoveCustomTask={handleRemoveCustomTask}
          activeTaskId={activeTaskId}
          fullSiteLoad={fullSiteLoad}
          setFullSiteLoad={setFullSiteLoad}
          handleTextTaskEdit={handleTextTaskEdit}
          handleProposalDescriptionDialogOpen={
            handleProposalDescriptionDialogOpen
          }
          isReadOnly={isReadOnly}
        />
      </AccordionDetails>
    </Accordion>
  )};

  if(tabView) {
    return (
      <SummaryTaskList
      projectItemId={projectItemId}
      site={site}
      expanded={true}
      hideZeroQuantity={site.hideZeroQuantity}
      handleTaskEdit={handleTaskEdit}
      handleVersionSyncOpen={handleVersionSyncOpen}
      handleNotesOpen={handleNotesOpen}
      currentRolesAndRates={currentRolesAndRates}
      handleRemoveCustomTask={handleRemoveCustomTask}
      activeTaskId={activeTaskId}
      fullSiteLoad={fullSiteLoad}
      setFullSiteLoad={setFullSiteLoad}
      handleTextTaskEdit={handleTextTaskEdit}
      handleProposalDescriptionDialogOpen={
        handleProposalDescriptionDialogOpen
      }
      isReadOnly={isReadOnly}
    />
  )}
}

export default SummaryCard;
