import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  ProjectItem,
} from '@cdw-selline/common/types';
import { ROUTES } from '@cdw-selline/ui/constants';
import {
  ADD_PROJECT_ITEM_MUTATION,
  GET_PROJECT_ITEMS_QUERY,
  GET_PROJECT_ITEM_BY_ID,
  REMOVE_PROJECT_ITEM_BY_ID,
  GET_PROJECT_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';
import { useProjectItems } from '.';
import { useProjectItemVendors } from './useProjectItemVendors';
import { useProjectItemSolutions } from './useProjectItemSolutions';

export const useProjectItemsTable = () => {
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const { data, error, loading, refetch } = useProjectItems();
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [addPrjectItem, { loading: addRoleLoading, error: addRoleError }] =
    useMutation<{ addProjectItem: DefaultAddResponse }>(
      ADD_PROJECT_ITEM_MUTATION,
      {
        refetchQueries: [
          GET_PROJECT_ITEMS_QUERY,
          GET_PROJECT_ITEM_BY_ID,
          GET_PROJECT_QUERY,
        ],
        onError: (error) =>
          handleErrorResponse(error, 'Failed to create new project item'),
        onCompleted: (data) => {
          //@todo handle add complete
          return null;
        },
      }
    );

  const [
    removeProjectItem,
    { loading: removeRoleLoading, error: removeRoleError },
  ] = useMutation<{ removeProjectItemById: DefaultMutationResponse }>(
    REMOVE_PROJECT_ITEM_BY_ID,
    {
      refetchQueries: [GET_PROJECT_ITEMS_QUERY, GET_PROJECT_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove project item'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeProjectItemById.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed project item',
          });
        }
      },
    }
  );

  const handleAddPrjectItem = () => {
    addPrjectItem({ variables: {} });
  };

  const handleRemoveProjectItem = (pid: string) =>
    removeProjectItem({
      variables: { removeProjectItemByIdId: pid },
    });

  const { vendors } = useProjectItemVendors();
  const { solutions } = useProjectItemSolutions();

  return {
    rows: data || ([] as GridRowsProp),
    loading: loading,
    error,
    handleAddPrjectItem,
    handleRemoveProjectItem,
    vendors,
    solutions,
  };
};
