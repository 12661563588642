import { GridColDef, GridRowsProp } from '@mui/x-data-grid';

import { useCustomers } from './useCustomers';
import type { Customer } from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';
import { datagridPagination } from '@cdw-selline/ui/helpers';

export interface CustomersColumn extends GridColDef {
  field: keyof Customer;
}

const columns: CustomersColumn[] = [
  {
    field: 'customerCode',
    headerName: 'SPS Number',
    flex: 1,
  },
  {
    field: 'customerDescription',
    headerName: 'Name',
    flex: 1,
  },
  {
    field: 'region',
    headerName: 'Region',
    flex: 1,
    editable: true,
  },
  {
    field: 'sector',
    headerName: 'Sector',
    flex: 1,
  },
  {
    field: 'area',
    headerName: 'Area',
    flex: 1,
  },
];

export const useDashboardCustomers = (userFilter) => {
  const { data, loading, error, refetch } = useCustomers({
    offset: 0,
    limit: 100,
    filters: getFilters('CustomersCollectionPage', userFilter),
  });

  const filterModel = JSON.parse(
    localStorage.getItem('CustomersCollectionPage-filter')
  );

  const {
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    paginationState,
    setPaginationState,
  } = datagridPagination(refetch, {});

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('CustomersCollectionPage', userFilter) });
  };

  return {
    columns,
    rows: data ? (data?.customers as GridRowsProp) : [],
    handlePageChange,
    handlePageSizeChange,
    handleSort,
    customers: data ? data?.customers : {},
    loading: loading,
    error,
    rowCount: data ? data?.count : 0,
    onFilterModelChange,
    filterModel,
    paginationState,
    setPaginationState,
  };
};
