/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Output } from '@cdw-selline/ui/components';
import {
  useProjects,
  useProjectItemEstimator,
  useManagedServices,
} from '@cdw-selline/ui/hooks';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { FixedFeeMilestone } from '@cdw-selline/common/types';

export interface OutputTabProps {
  isReadOnly?: boolean
}

export const OutputTab: React.FC<OutputTabProps> = ({
  isReadOnly,
}: OutputTabProps) => {
  const { projectItemId } = useParams<{ projectItemId: string }>();
  const {
    data: projectItemEstimator,
    updateProjectItemEstimator,
    getProjectItemEstimatorLoading,
    getProjectItemEstimatorError,
  } = useProjectItemEstimator();
  const { includesManagedServices } = useManagedServices(projectItemId);
  const { id } = useParams<{ id: string }>();
  const { data: project, updateProject, error, loading } = useProjects({ id });


  const handleUpdate = (
    key: string,
    value: Array<FixedFeeMilestone> | boolean
  ) => {
    if (key) {
      updateProjectItemEstimator({
        variables: {
          params: { [key]: value, projectItemId },
        },
      });
    }
  };

  const handleProjectUpdate = (e: any) => {
    if (e.target.value) {
      updateProject({
        variables: {
          updateProjectParams: { [e.target.value]: e.target.checked, id },
        },
      });
    }
  };

  return (
    <Output
      error={getProjectItemEstimatorError}
      loading={getProjectItemEstimatorLoading}
      project={project}
      projectItemEstimator={projectItemEstimator}
      includesManagedServices={includesManagedServices}
      handleUpdate={handleUpdate}
      isReadOnly={isReadOnly}
    />
  );
};

export default OutputTab;
