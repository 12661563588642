import React, { useState, useEffect } from 'react';
import { ApolloError } from '@apollo/client';
import { ProposalTemplate } from '@cdw-selline/common/types';
import { Grid, Box, Typography, Checkbox, Stack } from '@mui/material';
import DialogModal from '../../dialog-modal/DialogModal';
import {
  FormCheckbox,
  FormTextControlled,
  FormRichTextEditor,
  getActionButtons,
} from '../../formHelperFunctions';
import { debounce } from 'lodash';
import { getCustomEditorOptions, proposalMaxWidth } from '@cdw-selline/ui/helpers';
import { DataCollectionTable } from './ProposalDataCollection';
import { cloneDeep } from 'lodash';
import { omit } from 'lodash';
import omitDeep from 'omit-deep-lodash';

export interface ProposalTemplateFormProps {
  proposalTemplate: ProposalTemplate;
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (proposalTemplate: ProposalTemplate) => void;
  proposalTemplateId?: string;
  proposalTemplateLoading?: boolean;
  proposalTemplateError?: ApolloError;
}

const newProposalTemplateDefaults: ProposalTemplate = {
  id: '',
  name: '',
  content: '',
  dataCollection: [],
};

export function ProposalTemplateForm({
  proposalTemplate,
  isOpen,
  handleClose,
  handleSubmit,
}: ProposalTemplateFormProps) {
  const [proposalTemplateFormData, setProposalTemplateFormData] = useState(
    proposalTemplate ?? newProposalTemplateDefaults
  );
  const [customEditorOptions, setCustomEditorOptions] = useState(null);

  useEffect(() => {
    setProposalTemplateFormData(
      proposalTemplate ?? newProposalTemplateDefaults
    );
    setCustomEditorOptions(
      getCustomEditorOptions(
        null,
        handleEditorChange,
        (proposalTemplate ?? newProposalTemplateDefaults).dataCollection
      )
    );
  }, [proposalTemplate.id]);

  const [error, setError] = useState(false);
  const [errorDataCollection, setErrorDataCollection] = useState('');
  const handleEditorChange = (params) => {
    setProposalTemplateFormData((s) => ({
      ...s,
      ...params,
    }));
  };

  const handleFormClose = () => {
    setProposalTemplateFormData(null);
    setError(false);
    handleClose();
  };
  const deleteDataCollectionItem = (rowKey) => {
    setErrorDataCollection('');
    const dataCollection = cloneDeep(
      proposalTemplateFormData.dataCollection || []
    );
    const updatedDataCollection = dataCollection.filter(
      (row) => row.key !== rowKey
    );
    setProposalTemplateFormData((s) => ({
      ...s,
      dataCollection: updatedDataCollection,
    }));
  };
  const saveDataCollectionItem = (row) => {
    const dataCollection = cloneDeep(
      proposalTemplateFormData.dataCollection || []
    );
    const existing = dataCollection.find((r) => r.id === row.id);
    if (existing && row.isNew) {
      setErrorDataCollection('Data Collection key has to be unique!');
      return;
    }

    if (existing) {
      existing.key = row.key;
      existing.name = row.name;
    } else {
      dataCollection.push(omit(row, ['isNew', 'isEdit']));
    }

    setProposalTemplateFormData((s) => ({
      ...s,
      dataCollection,
    }));
  };

  const handleSaveClick = () => {
    if (proposalTemplateFormData.name && !errorDataCollection) {
      handleSubmit(proposalTemplateFormData);
      handleClose();
    } else {
      setError(true);
    }
  };

  const handleDuplicateClick = () => {
    if (proposalTemplateFormData.name && !errorDataCollection) {

      let dataCollection;
      if (proposalTemplateFormData.dataCollection) {
        dataCollection = proposalTemplateFormData.dataCollection.map(data=> omitDeep(data, ['__typename']));
      }
      handleSubmit({...omit(proposalTemplateFormData, ["id"]), name: proposalTemplateFormData.name + ' (Copy)', dataCollection});
      handleClose();
    } else {
      setError(true);
    }
  };

  const debouncedHandleEditorChange = debounce(handleEditorChange, 1000);

  return (
    <DialogModal
      fullWidth={true}
      isOpen={isOpen}
      handleClose={handleClose}
      title={'Proposal Template'}
      action={getActionButtons(
        handleFormClose,
        handleSaveClick,
        handleDuplicateClick
      )}
      maxWidthProp={'lg'}
      disableEnforceFocus={true}
    >
      <Grid
        container
        alignItems="flex-start"
        justifyContent="flex-start"
        direction="row"
        spacing={1}
        mt={1}
        sx={{ height: '90vh' }}
        data-testid="proposal-template-grid"
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="flex-start"
          direction="column"
          spacing={1}
          sx={{ width: '100%' }}
        >
          {error && (
            <Box sx={{ padding: '15px' }}>
              <Typography color="error" variant="h6">
                Please add all required field values
              </Typography>
            </Box>
          )}
          <FormTextControlled
            name="name"
            label="Proposal Template Name"
            required={true}
            value={proposalTemplateFormData.name}
            setFormData={setProposalTemplateFormData}
            formData={proposalTemplateFormData}
          />
          <FormCheckbox
            name="isSystem"
            label="System Template?"
            setFormData={setProposalTemplateFormData}
            formData={proposalTemplateFormData}
          />
          <FormCheckbox
            name="isDataCollectionIncluded"
            label="Does Template include data collection?"
            setFormData={setProposalTemplateFormData}
            formData={proposalTemplateFormData}
          />
          {proposalTemplateFormData.isDataCollectionIncluded && (
            <Grid container spacing={1}>
              {errorDataCollection && (
                <Box sx={{ paddingTop: '5px', paddingLeft: '15px' }}>
                  <Typography color="error" variant="h6">
                    {errorDataCollection}
                  </Typography>
                </Box>
              )}
              <DataCollectionTable
                currentProposalTemplate={proposalTemplateFormData}
                handleDelete={deleteDataCollectionItem}
                handleSave={saveDataCollectionItem}
              ></DataCollectionTable>
            </Grid>
          )}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(90vh - 13em)',
              width: '100%',
            }}
          >
            <Stack
              alignContent={'center'}
              sx={{
                width: `${proposalMaxWidth}`,
                paddingTop: '10px',
                paddingBottom: '10px',
              }}
              height="calc(90vh - 13em)"
            >
              <FormRichTextEditor
                name="content"
                label="Content"
                value={proposalTemplateFormData.content}
                handleEditorChange={debouncedHandleEditorChange}
                customEditorOptions={{
                  ...customEditorOptions,
                  content_css: '/proposal.css',
                  height: "calc(90vh - 14em)"
                }}
              />
            </Stack>
          </Box>
        </Grid>
      </Grid>
    </DialogModal>
  );
}
