/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useMansEnrollmentSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import { formatCurrency } from '@cdw-selline/ui/helpers';

export const EnrollmentSummary = () => {
  const { enrollmentSummary, error, loading } = useMansEnrollmentSummary();
  const enrollmentTotal = enrollmentSummary?.roles?.find(
    (role) => role.id === 'total'
  );

  const theme = useTheme();

  if (enrollmentTotal?.unit) {
    return (
      <Grid item lg={6} sm={12} xl={6} xs={12}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardHeader
                  title="Enrollment Summary"
                  sx={{
                    backgroundColor: theme.palette.primary.dark,
                    padding: '5px 15px',
                  }}
                  titleTypographyProps={{
                    variant: 'h6',
                    color: theme.palette.common.white,
                  }}
                />
                <Divider />
                <Box sx={{ minWidth: 400 }}>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Role</TableCell>
                        <TableCell align="right">Units</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {error && (
                        <Typography color="error" variant="h6">
                          {error.message}
                        </Typography>
                      )}
                      {loading && (
                        <TableRow>
                          <TableCell>
                            <CircularProgress />
                          </TableCell>
                        </TableRow>
                      )}
                      {!error &&
                        !loading &&
                        Array.isArray(enrollmentSummary.roles) &&
                        enrollmentSummary.roles
                          .filter(
                            (summaryRow) =>
                              summaryRow.unit > 0 &&
                              summaryRow.type !== 'total' &&
                              summaryRow.type !== 'thirdPartyService' &&
                              summaryRow.type !== 'risk' &&
                              summaryRow.name
                          )
                          .map((summaryRow, i) => (
                            <TableRow
                              hover
                              key={`${summaryRow.name}-${i}`}
                              sx={{
                                '&:last-child td, &:last-child th': {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell>
                                {summaryRow.name !== null
                                  ? summaryRow.name
                                  : summaryRow.type === 'pmRole'
                                  ? 'PM Role (No PM Selected)'
                                  : ''}
                                {summaryRow.type === 'otRole'
                                  ? ' - OT per hour'
                                  : ' - per hour'}
                              </TableCell>
                              <TableCell align="right">
                                {summaryRow.unit}
                              </TableCell>
                            </TableRow>
                          ))}
                      {!error &&
                        !loading &&
                        Array.isArray(enrollmentSummary.roles) &&
                        enrollmentSummary.roles
                          .filter((data) => data.type === 'total')
                          .map((data, i) => (
                            <TableRow hover key={`${data.name}-${i}`}>
                              <TableCell>
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  Enrollment Total
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Typography
                                  sx={{
                                    color: theme.palette.primary.dark,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {data.unit}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                    </TableBody>
                  </Table>
                </Box>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Grid>
    );
  } else {
    return null;
  }
};

export default EnrollmentSummary;
