import { useMutation } from '@apollo/client';
import {
  HandledMutationResponse,
  ProposalTemplate,
} from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';
import {
  ADD_PROPOSAL_TEMPLATE_MUTATION,
  REMOVE_PROPOSAL_TEMPLATE_MUTATION,
  UPDATE_PROPOSAL_TEMPLATE_MUTATION,
  GET_PROPOSAL_TEMPLATES_QUERY,
  GET_PROPOSAL_TEMPLATE_BY_ID_QUERY,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import { useApolloErrors } from './useApolloErrors';
import { useProposalTemplates } from './proposals/useProposalTemplates';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import { omit, pick } from 'lodash';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Template ID',
    width: 180,
    editable: false,
    hide: true,
    flex: 1,
  },
  {
    field: 'name',
    headerName: 'Template Name',
    width: 180,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(parseInt(params?.value)).format('MM/DD/YYYY'),
  },
];

export const useAdminProposalTemplates = () => {
  const [sortState, setSortState] = useState();
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });

  const filterModel = JSON.parse(
    localStorage.getItem('ProposalTemplatesCollectionPage-filter')
  );

  const { data, loading, error, refetch } = useProposalTemplates({
    filters: getFilters('ProposalTemplatesCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();
  const [editProposalTemplateId, setEditProposalTemplateId] = useState(null);
  const {
    isOpen: proposalTemplateFormOpen,
    handleClose: closeProposalTemplateForm,
    handleOpen: openProposalTemplateForm,
  } = useOpenState();

  const [
    addProposalTemplate,
    { loading: addProposalTemplateLoading, error: addProposalTemplateError },
  ] = useMutation<{ addProposalTemplate: HandledMutationResponse }>(
    ADD_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [
        GET_PROPOSAL_TEMPLATES_QUERY,
        GET_PROPOSAL_TEMPLATE_BY_ID_QUERY,
      ],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new proposal template'),
      onCompleted: (data) => {
        if (data.addProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully added Proposal Template',
          });
        }
      },
    }
  );

  const [
    removeProposalTemplate,
    {
      loading: removeProposalTemplateLoading,
      error: removeProposalTemplateError,
    },
  ] = useMutation<{ removeProposalTemplate: HandledMutationResponse }>(
    REMOVE_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [GET_PROPOSAL_TEMPLATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove sproposal template'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.removeProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed proposal template',
          });
        }
      },
    }
  );

  const [
    updateProposalTemplate,
    {
      loading: updateProposalTemplateLoading,
      error: updateProposalTemplateError,
    },
  ] = useMutation<{ updateProposalTemplate: HandledMutationResponse }>(
    UPDATE_PROPOSAL_TEMPLATE_MUTATION,
    {
      refetchQueries: [GET_PROPOSAL_TEMPLATES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update proposal template'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updateProposalTemplate.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated proposal template',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openProposalTemplateForm();
  };

  const handleProposalTemplateFormClose = () => {
    setEditProposalTemplateId(null);
    closeProposalTemplateForm();
  };

  const handleEdit = (id: string) => {
    setEditProposalTemplateId(id);
    openProposalTemplateForm();
  };

  const handleDelete = (pid: string) =>
    removeProposalTemplate({
      variables: { id: pid },
    });

  const handleProposalTemplateFormSave = (
    proposalTemplate: ProposalTemplate
  ) => {
    const filteredDatacollection = proposalTemplate?.dataCollection?.map(
      (row) => omit(row, '__typename')
    );

    const filteredProposalTemplate = {
      ...proposalTemplate,
      dataCollection: filteredDatacollection,
    };

    if (!filteredProposalTemplate.id) {
      addProposalTemplate({
        variables: {
          params: pick(filteredProposalTemplate, [
            'content',
            'dataCollection',
            'isDataCollectionIncluded',
            'isSystem',
            'name'
          ]),
        },
      });
    } else {
      updateProposalTemplate({
        variables: {
          params: pick(filteredProposalTemplate, [
            'id',
            'content',
            'dataCollection',
            'isDataCollectionIncluded',
            'isSystem',
            'name'
          ]),
        },
      });
    }
  };
  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: paginationState.offset,
      limit: paginationState.limit,
      filters: getFilters('ProposalTemplatesCollectionPage'),
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('ProposalTemplatesCollectionPage') });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
      filters: getFilters('ProposalTemplatesCollectionPage'),
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data?.proposalTemplates || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageBack,
    onFilterModelChange,
    handlePageSizeChange,
    handleSort,
    filterModel: filterModel ?? {
      items: [{ columnField: 'name', operatorValue: 'contains', value: '' }],
    },
    editProposalTemplateId,
    addProposalTemplateLoading,
    addProposalTemplateError,
    removeProposalTemplateLoading,
    removeProposalTemplateError,
    updateProposalTemplateLoading,
    updateProposalTemplateError,
    loading:
      loading ||
      addProposalTemplateLoading ||
      removeProposalTemplateLoading ||
      updateProposalTemplateLoading,
    error,
    proposalTemplateFormOpen,
    handleProposalTemplateFormClose,
    handleProposalTemplateFormSave,
    rowCount: data?.count,
    ...paginationState,
  };
};
