import {
  DefaultMutationResponseWithId,
  ProposalTemplate,
} from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { labelStrings } from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useAddProjectProposal,
  useEstimatorProposalTemplates,
} from '@cdw-selline/ui/hooks';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  Button,
  DialogActions,
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import DialogModal from '../../dialog-modal/DialogModal';

export interface NewProposalDialogProps {
  isOpen: boolean;
  handleClose: () => void;
  projectId: string;
  projectItemId: string;
  estimatorId: string;
  handleNewProposalId: (newProposalId: string) => void;
}

export function NewProposalDialog({
  isOpen,
  handleClose,
  projectId,
  projectItemId,
  estimatorId,
  handleNewProposalId,
}: NewProposalDialogProps) {
  const [proposalNameInput, setProposalNameInput] = useState<string>('');

  const dialogStrings = {
    CREATE_PROPOSAL: 'Create Proposal',
    TEMPLATE: 'Template',
    PROPOSAL_NAME: 'Proposal Name',
    NAME_HELPER: 'Please enter a proposal name',
    TEMPLATE_HELPER: 'Please select a proposal template',
  };

  const {
    data,
    loading: templatesLoading,
    error: templatesError,
  } = useEstimatorProposalTemplates(projectItemId);

  const templates = data.proposalTemplates?.map((t) => {
    return {
      id: t.id,
      label: t.name,
    };
  });
  const [selectedTemplate, setSelectedTemplate] = useState<ProposalTemplate>(
    templates[0]
  );
  const [searchTerm, setSearchTerm] = useState(templates[0]?.label ?? '');

  useEffect(() => {
    if (templates.length) {
      setSelectedTemplate(templates[0]);
      setSearchTerm(templates[0]?.label)
    }
  }, [templatesLoading]);

  const handleTemplateChange = (newValue) => {
    setSelectedTemplate(newValue);
  };

  const [inputError, setInputError] = useState<boolean>(false);
  const handleNameInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setProposalNameInput(e.target.value);
  };
  const handleResetForm = () => {
    setSelectedTemplate(templates[0]);
    setSearchTerm(templates[0]?.label ?? '');
    setProposalNameInput('');
  };
  const addProjectCallback = (data: {
    addProjectProposal: DefaultMutationResponseWithId;
  }) => {
    handleNewProposalId(data.addProjectProposal.id);
    handleResetForm();
    handleClose();
  };
  const {
    handleAddProjectProposal,
    loading: addProposalLoading,
    error: addProposalError,
  } = useAddProjectProposal(addProjectCallback);

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!proposalNameInput) {
      setInputError(true);
      return;
    }
    if (!selectedTemplate?.id) {
      setInputError(true);
      return;
    }
    setInputError(false);
    handleAddProjectProposal({
      name: proposalNameInput,
      templateId: selectedTemplate?.id,
      projectId,
      projectItemId,
      estimatorId,
    });
  };

  return (
    !templatesLoading &&
    !templatesError && (
      <DialogModal
        title={dialogStrings.CREATE_PROPOSAL}
        maxWidth={'lg'}
        {...{ isOpen, handleClose }}
      >
        <form onSubmit={handleFormSubmit}>
          <FormControl sx={{ margin: '1em' }}>
            <Autocomplete
              tabIndex={2}
              id="template-select"
              options={templates}
              sx={{ width: '100%' }}
              noOptionsText={`Type Template Name`}
              value={searchTerm}
              onInputChange={(event, newValue) => {
                setSearchTerm(newValue);
              }}
              onChange={(event, newValue) => {
                handleTemplateChange(newValue);
              }}
              data-testid={`template-select-input`}
              renderInput={(params) => (
                <TextField {...params} label="Template"
                  error={inputError && !selectedTemplate?.id}
                  helperText={inputError && !selectedTemplate?.id && dialogStrings.TEMPLATE_HELPER}/>
              )}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={props.id}>
                    {option?.label}
                  </li>
                );
              }}
              
            />
            <TextField
              fullWidth
              label={dialogStrings.PROPOSAL_NAME}
              value={proposalNameInput}
              onChange={handleNameInputChange}
              data-testid="proposal-name-input"
              error={inputError && !proposalNameInput}
              helperText={inputError && !proposalNameInput && dialogStrings.NAME_HELPER}
              sx={{ marginTop: '1em', marginBottom: '1em' }}
            />
          </FormControl>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleClose}
              data-testid="add-proposal-cancel-button"
            >
              {labelStrings.CANCEL}
            </Button>
            <LoadingButton
              type="submit"
              aria-label="add new proposal"
              data-testid="add-proposal-submit-button"
              variant="contained"
              loading={addProposalLoading}
            >
              {labelStrings.SUBMIT}
            </LoadingButton>
          </DialogActions>
        </form>
      </DialogModal>
    )
  );
}

export default NewProposalDialog;
