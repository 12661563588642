import omitDeep from 'omit-deep-lodash';

import {
  useGetProjectProposalById,
  useRealTimeDatabase,
  useUpdateProjectProposal,
} from '..';

export const useProjectProposal = (
  proposalId: string,
  handleEditNameClose?: () => void
) => {
  const {
    data: currentProposal,
    loading: currentProposalLoading,
    error: currentProposalError,
  } = useGetProjectProposalById(proposalId);

  const { handleDocUpdates, doc } = useRealTimeDatabase(
    currentProposal?.projectId,
    currentProposal?.id,
    `/project-proposals/${currentProposal?.projectId}/${currentProposal?.id}`
  );

  const {
    handleUpdateProjectProposal,
    loading: updateProposalLoading,
    error: updateProposalError,
  } = useUpdateProjectProposal(handleEditNameClose);

  const handleEditCurrentProposal = (
    property: 'name' | 'content',
    modifiedValue: string,
    reload: boolean
  ) => {
    const proposal = omitDeep(currentProposal, [
      '__typename',
      'previewContent',
    ]);
    const modifiedProposal = { ...proposal, [property]: modifiedValue };
    handleUpdateProjectProposal(modifiedProposal, reload);
  };

  const handleEditProposalContent = (document: { [key: string]: string }) => {
    const content = document['proposal'];
    handleDocUpdates({ content });
    handleEditCurrentProposal('content', content, false);
    currentProposal.content = content;
  };

  const handleNameChange = (newName: string) => {
    handleEditCurrentProposal('name', newName, true);
  };

  const handleContentChange = (newContent: string) => {
    handleEditCurrentProposal('content', newContent, true);
  };

  const loading = updateProposalLoading || currentProposalLoading;
  const error = updateProposalError || currentProposalError;

  return {
    currentProposal,
    doc: doc ?? currentProposal,
    handleEditProposalContent,
    handleContentChange,
    handleNameChange,
    loading,
    error,
  };
};
