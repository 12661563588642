import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import * as React from 'react';
import Logo from './logo.png';
interface CdwLogoProps{
  height?:string
  className?:string
}
export function CdwSvgLogo(props: CdwLogoProps) {
  return <img src={Logo} {...props} />;
}
export default CdwSvgLogo;
