import { ApolloError } from '@apollo/client';
import { Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  NOTE_TYPES,
  labelStrings,
  estimatorStrings,
} from '@cdw-selline/ui/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useTaskNoteForm } from '@cdw-selline/ui/hooks';
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { FormEvent } from 'react';

export interface NewNoteFormProps {
  addNoteLoading: boolean;
  addNoteError: ApolloError;
  noteType: string;
  handleNoteTypeChange: (e: SelectChangeEvent<string>) => void;
  noteInput: string;
  handleNoteInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  emptyNote: boolean;
}

export function NewNoteForm({
  addNoteLoading,
  addNoteError,
  noteType,
  handleNoteTypeChange,
  noteInput,
  handleNoteInput,
  emptyNote,
}: NewNoteFormProps) {
  if (addNoteLoading) return <CircularProgress />;
  if (addNoteError)
    return <Typography>{estimatorStrings.ADD_NOTE_ERROR}</Typography>;

  return (
    <Grid container>
      <form aria-label="new note form" style={{ width: '100%' }}>
        <Grid item xs={12} sx={{ margin: '1em 0' }}>
          <FormControl
            fullWidth
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <InputLabel shrink>{labelStrings.NOTE_TYPE}</InputLabel>
            <Select
              value={noteType}
              onChange={handleNoteTypeChange}
              label={labelStrings.NOTE_TYPE}
              aria-label="note types"
              fullWidth
              sx={{ width: '100%' }}
            >
              {NOTE_TYPES.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sx={{ margin: '1em 0' }}>
          <FormControl
            fullWidth
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <InputLabel shrink>{labelStrings.NOTE}</InputLabel>
            {emptyNote && (
              <Box sx={{ padding: '15px' }}>
                <Typography color="error" variant="h6">
                  Note can not be empty
                </Typography>
              </Box>
            )}
            <TextField
              multiline
              required={true}
              rows={3}
              InputLabelProps={{ shrink: true }}
              label={labelStrings.NOTE}
              value={noteInput}
              onChange={handleNoteInput}
              aria-label="note input"
              fullWidth
              sx={{ width: '100%' }}
            />
          </FormControl>
        </Grid>
      </form>
    </Grid>
  );
}

export default NewNoteForm;
