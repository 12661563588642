import React, { Dispatch, SetStateAction, useState } from 'react';
import { FormControl, Grid, TextField, Select, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import omitDeep from 'omit-deep-lodash';
import { cloneDeep } from 'lodash';
import { taskGroupConstants } from '@cdw-selline/ui/constants';
import { ItemsProvidedToCustomers } from '@cdw-selline/common/types';

/* eslint-disable-next-line */
export interface ItemsProvidedLineItemsProps {
  lineItem: ItemsProvidedToCustomers;
  removeRow: (int) => void;
  handleChange: (params) => void;
  lineItemsArray: ItemsProvidedToCustomers[];
  setLineItems: Dispatch<SetStateAction<ItemsProvidedToCustomers[]>>;
}

export function ItemsProvidedLineItems(props: ItemsProvidedLineItemsProps) {
  const { id } = props?.lineItem;
  const index = props?.lineItemsArray.findIndex((i) => i.id === id);
  const [item, setItem] = React.useState(props.lineItem.item);
  const [description, setDescription] = React.useState(
    props.lineItem.description
  );
  const [format, setFormat] = React.useState(props.lineItem.format);
  const [taskId, setTaskID] = React.useState(props.lineItem.taskId);
  const [operator, setOperator] = React.useState(props.lineItem.operator);
  const [value, setValue] = React.useState(props.lineItem.value);

  React.useEffect(() => {
    setItem(props.lineItem.item);
    setDescription(props.lineItem.description);
    setFormat(props.lineItem.format);
    setTaskID(props.lineItem.taskId);
    setOperator(props.lineItem.operator);
    setValue(props.lineItem.value);
  }, [props.lineItem]);

  const handleItemChange = (e, type) => {
    const itemsCopy = cloneDeep(props.lineItemsArray);
    switch (type) {
      case 'item':
        itemsCopy[index].item = e.target.value;
        setItem(e.target.value);
        break;
      case 'description':
        itemsCopy[index].description = e.target.value;
        setDescription(e.target.value);
        break;
      case 'format':
        itemsCopy[index].format = e.target.value;
        setFormat(e.target.value);
        break;
      case 'taskId':
        itemsCopy[index].taskId = e.target.value;
        setTaskID(e.target.value);
        break;
      case 'operator':
        itemsCopy[index].operator = e.target.value;
        setOperator(e.target.value);
        break;
      case 'value':
        itemsCopy[index].value = e.target.value;
        setValue(e.target.value);
        break;
    }
    props.setLineItems([...itemsCopy]);
    props.handleChange({
      itemsProvidedToCustomers: omitDeep([...itemsCopy], ['__typename']),
    });
  };

  return (
    <Grid item xl={12} sx={{ marginBottom: 2 }} key={`items-provided-${id}`}>
      <FormControl sx={{ width: '25%' }}>
        <TextField
          sx={{ marginRight: 2 }}
          value={item ?? ''}
          onChange={(e) => handleItemChange(e, 'item')}
          label="Item"
        />
      </FormControl>
      <FormControl sx={{ width: '35%' }}>
        <TextField
          sx={{ marginRight: 2 }}
          value={description ?? ''}
          onChange={(e) => handleItemChange(e, 'description')}
          label="Description"
        />
      </FormControl>
      <FormControl sx={{ width: '12%' }}>
        <TextField
          sx={{ marginRight: 2 }}
          label="Format"
          value={format ?? ''}
          onChange={(e) => handleItemChange(e, 'format')}
        />
      </FormControl>
      <FormControl sx={{ width: '8%' }}>
        <TextField
          sx={{ marginRight: 2 }}
          label="Task ID"
          value={taskId ?? ''}
          onChange={(e) => handleItemChange(e, 'taskId')}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <FormControl sx={{ width: '8%' }}>
        <Select
          sx={{ marginRight: 2 }}
          value={operator ?? ''}
          onChange={(e) => handleItemChange(e, 'operator')}
        >
          <MenuItem sx={{ height: '30px' }} value={0}></MenuItem>
          {taskGroupConstants.OPERATORS.map((op) => (
            <MenuItem value={op.value} key={`operators-${op.value}`}>
              {op.operator}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl sx={{ width: '8%' }}>
        <TextField
          label="Value"
          value={value ?? ''}
          onChange={(e) => handleItemChange(e, 'value')}
          InputLabelProps={{ shrink: true }}
        />
      </FormControl>
      <DeleteIcon
        sx={{ marginTop: 2, marginLeft: 1 }}
        onClick={() => props.removeRow(id)}
      />
    </Grid>
  );
}

export default ItemsProvidedLineItems;
