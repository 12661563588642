import { gql } from '@apollo/client';

export const GET_ADMIN_LIST_TASKS_QUERY = gql`
  query GetTasks($filters: MongoFilter, $limit: Int, $offset: Int, $sort: MongoFilter) {
    getTasks {
      count(filters: $filters)
      tasks(limit: $limit, offset: $offset, filters: $filters, sort: $sort) {
        order

        id
        type
        practiceId

        taskId
        name
        estimatorLocation

        units
        category
        comment
        
        quantity
        quantityFormula
        quantityMin
        quantityMax
        disableQuantity
        disableQuantityFormula

        hours
        hoursFormula
        disableHours
        disableHoursFormula
        allowTotalHoursAdjustment
        totalHoursNoRounding
        hideHours

        listNames
        listValues
        dropDownIndex
        dropDownIndexFormula

        noValue
        yesValue

        textType    
        text
        textFormula
        instructionsText
        textDisabled
        textDisabledFormula
        allowBlankText

        managedServices        
        isManagedServicesCostCalculation
        managedServicesFteFormula
        managedServicesArcRrcFormula
        managedServicesTransitionFormula

        costType
        productType
        cost
        costFormula
        minGrossProfit
        grossProfit
        defaultGrossProfit
        disableCost
        disableCostFormula
        allowMarkup
        alwaysShowOnProposal
        volumePricingEnabled
        volumePricing {
          minQuantity
          maxQuantity
          cost
        }

        proposalShowEmpty
        proposalNotes

        version
        
        tierId
        tier
        
        sowSectionId

        overtime
        isProjectManagement

        #primaryRoleName
        primaryRoleId
        primaryRole
        primaryPercent

        #secondaryRoleName
        secondaryRoleId
        secondaryRole
        secondaryPercent

        hide
        hideShowFormula

        exclude

        vendor

        enrollmentCost
        enrollmentCostFormula
        enrollmentHours
        enrollmentRole
        enrollmentHoursFormula 

        sprint

        skill
        skillId
        serviceItem
        serviceItemId

        sku
        edc
      }
    }
  }
`;

export default GET_ADMIN_LIST_TASKS_QUERY;
