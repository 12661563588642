import { TinyMCEEditorOptions } from '@cdw-selline/ui/helpers';
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { Editor as EditorClass } from 'tinymce';

export interface RichTextEditorNoStateProps {
  documentId: string;
  handleChange: (document: { [key: string]: string }) => void;
  currentValue?: string;
  disabled?: boolean;
  height?: string | number;
  width?: string | number;
  customEditorOptions?: TinyMCEEditorOptions;
  editorState?: string;
  setCurrentValue?: (value: string) => void;
}

export function RichTextEditorNoState({
  documentId,
  handleChange,
  currentValue,
  editorState,
  setCurrentValue: setEditorState,
  disabled,
  height = 500,
  width = 'calc(80vw - 5em)',
  customEditorOptions,
}: RichTextEditorNoStateProps) {
  const handleEditorChange = (value: unknown, editor: EditorClass) => {
    if (typeof value !== 'string') {
      return;
    }

    setEditorState(value);
    handleChange({ [documentId]: value });
  };

  const defaultEditorOptions: TinyMCEEditorOptions = {
    width: '100%',
    height,
    menubar: 'edit insert view format table tools',
    fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt',
    toolbar:
      'save copy | ' +
      'undo redo | ' +
      'formatselect fontselect fontsizeselect | ' +
      'bold italic | ' +
      'alignleft aligncenter ' +
      'alignright alignjustify | ' +
      'bullist numlist outdent indent | ' +
      'image | forecolor backcolor | paste |' +
      'fullscreen |',
    statusbar: false,
    plugins: [
      'advlist',
      'code',
      'lists',
      'link',
      'image',
      'charmap',
      'pagebreak',
      'paste',
      'print',
      'visualblocks',
      'insertdatetime',
      'help',
      'wordcount',
      'save',
      'table',
      'fullscreen',
      'noneditable',
    ],
    table_class_list: [
      {title: 'None', value: ' '},
      {title: 'No Blank Rows', value: 'table-no-blank-rows'},
      {title: 'No Cleanup', value: 'table-no-cleanup'},
    ],
    browser_spellcheck: true,
    contextmenu: '',
    paste_data_images: true,
    paste_as_text: true,
    images_dataimg_filter: function (img) {
      return img.hasAttribute('internal-blob');
    },
    save_onsavecallback: function () {
      return false;
    },
    editor_selector: 'textarea#sow',
    init_instance_callback: (editor: EditorClass) => {
      editor.on('ExecCommand', (e) => {
        if (
          e.command === 'mceFullScreen' &&
          e.target.plugins.fullscreen.isFullscreen()
        ) {
          editor.notificationManager.open({
            text: `Press CMD + SHIFT + F to exit fullscreen mode`,
            type: 'info',
            timeout: 5000,
            closeButton: true,
          });
        }
      });
    },
  };
  const elId = `rich-text-editor-${documentId}`;

  const editorOptions: TinyMCEEditorOptions = {
    ...defaultEditorOptions,
    ...customEditorOptions,
  };

  return (
    <Editor
      apiKey={process.env.NX_TINY_MCE_API_KEY}
      init={editorOptions}
      onEditorChange={handleEditorChange}
      onSaveContent={(value, editor) => handleEditorChange(value, editor)}
      value={editorState}
      disabled={!!disabled}
      id={elId}
      data-testid={elId}
    />
  );
}

export default RichTextEditorNoState;
