import { Dispatch, SetStateAction } from 'react';
import { ProjectItemEstimators } from '@cdw-selline/common/types';

const agiloftPrintTemplateTypes= {
    detailed: [
        'CSOW',
        'Services Proposal',
      ],
    outcomeBase: [
        'Outcome Based Services Proposal',
        'Outcome Based CSOW',
      ]
};

const agiloftExpenseTypes= {
    travelTimeAndDirectExpense: [
        'Cook County Expenses',
        'Expenses_Travel Time and Direct Expenses_CSOW',
        'Medtronic Expenses',
        'Patch Management travel and expenses',
        'Takeda Expenses',
      ],
    directExpensesOnly: [
        'Expenses_Direct Expenses Only_CSOW',
        'Penske Expenses',
      ],
    notBilledClauses: [
        'Expenses_Not Billed',
        'ManS Expenses Travel Notice Requirement No Travel',
      ],
    notBilledRemoteServices: [
        'Expenses_Not Billed_Remote Services',
        'Managed Services - General - Expenses (Incl. Recurring Services Fees And Price Protection)',
      ],
    travelTimeOnly: ['Expenses_Travel Time Only_CSOW']
};

const agiloftTravelTypes = {
    twoWeekNoticeNoPenalty: [ "Travel Notice_2 Wks Penalty NA"],
    twoWeekNoticePenaltyVariable: ["Travel Notice_2 Wks Penalty Variable"],
    twoWeekNoticePenaltyFixed: ["Travel Notice_2 Wks Penalty Fixed"],
    noTravel: [
        "Travel Notice_No Travel Remote Only",
        "Cook County Travel Notice",
        "Incident Response Travel Notice",
      ],
    travelNotBilled: ["Travel Notice_Not Billed"]
};

const managedServicesCustomerTypeOptions = ['New', 'Existing'];
const managedServicesDealTypeOptions = ['New', 'Modify', 'Renewal'];
const managedServicesTransitionFeeTypeOptions = [
  'One-time Transition Fee (OTF) only',
  'Monthly Transition Fee (MTF) only',
  'Both OTF and MTF (default)',
  'Base Monthly Recurring Fee (Assumes no OTF and no MTF)',
];

export const useAgiloftEstimatorVariables = (
    setExpenseClauses: Dispatch<SetStateAction<string[]>>,
    setShowExpenseMiles: Dispatch<SetStateAction<boolean>>,
    setTravelClauses: Dispatch<SetStateAction<string[]>>,
    setShowTravelPenaltyAmount: Dispatch<SetStateAction<boolean>>,
    setFormData: (value)=>void) => {

const updatePrintTemplateType = (
    agiloftPrintTemplateType: string,
    estimator: ProjectItemEstimators,
    outcomeBase: boolean
    ) => {

        switch (agiloftPrintTemplateType) {
            case 'CSOW':
            case 'Services Proposal':
                if (outcomeBase) {
                    setFormData({
                        id: estimator.id,
                        projectItemId: estimator.projectItemId,
                        agiloftPrintTemplateType: 'Outcome Based Services Proposal',
                    });
                }
            break;
            case 'Outcome Based Services Proposal':
            case 'Outcome Based CSOW':
                if (!outcomeBase) {
                    setFormData({
                        id: estimator.id,
                        projectItemId: estimator.projectItemId,
                        agiloftPrintTemplateType: 'Services Proposal',
                    });
                }
            break;
        };
};

const updateTravelClauses = (agiloftTravelType: string, estimator: ProjectItemEstimators) => {

    switch (agiloftTravelType) {
        case '2 Week Notice - No Penalty':
            if(!agiloftTravelTypes.twoWeekNoticeNoPenalty.includes(estimator.agiloftTravelClause)) {
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftTravelType: agiloftTravelType,
                    agiloftTravelClause: agiloftTravelTypes.twoWeekNoticeNoPenalty[0],
                });
            }
            setTravelClauses(agiloftTravelTypes.twoWeekNoticeNoPenalty);
            break;
        case '2 Week Notice - Penalty Variable':
            if(!agiloftTravelTypes.twoWeekNoticePenaltyVariable.includes(estimator.agiloftTravelClause)) {
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftTravelType: agiloftTravelType,
                    agiloftTravelClause: agiloftTravelTypes.twoWeekNoticePenaltyVariable[0],
                });
            }
            setTravelClauses(agiloftTravelTypes.twoWeekNoticePenaltyVariable);
            break;
        case '2 Week Notice - Penalty Fixed':
            if(!agiloftTravelTypes.twoWeekNoticePenaltyFixed.includes(estimator.agiloftTravelClause)) {
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftTravelType: agiloftTravelType,
                    agiloftTravelClause: agiloftTravelTypes.twoWeekNoticePenaltyFixed[0],
                });
            }
            setTravelClauses(agiloftTravelTypes.twoWeekNoticePenaltyFixed);
            break;
        case 'No Travel':
            if(!agiloftTravelTypes.noTravel.includes(estimator.agiloftTravelClause)) {
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftTravelType: agiloftTravelType,
                    agiloftTravelClause: agiloftTravelTypes.noTravel[0],
                });
            }
            setTravelClauses(agiloftTravelTypes.noTravel);
            break;
        case 'Travel Not Billed':
            if(!agiloftTravelTypes.travelNotBilled.includes(estimator.agiloftTravelClause)) {
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftTravelType: agiloftTravelType,
                    agiloftTravelClause: agiloftTravelTypes.travelNotBilled[0],
                });
            }
            setTravelClauses(agiloftTravelTypes.travelNotBilled);
            break;
        }
    
    setShowTravelPenaltyAmount((agiloftTravelType === '2 Week Notice - Penalty Fixed'));
};

const updateExpenseClauses = (
    agiloftExpenseType: string,
    estimator: ProjectItemEstimators,
    includesManagedServices: boolean) => {

    let expenseClause = '';
    const agiloftExpenseClause = estimator.agiloftExpenseClause;
    switch (agiloftExpenseType) {
        case 'Travel Time and Direct Expenses':
            if(!agiloftExpenseTypes.travelTimeAndDirectExpense.includes(agiloftExpenseClause)) {
                expenseClause = agiloftExpenseTypes.travelTimeAndDirectExpense[1];
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftExpenseType: agiloftExpenseType,
                    agiloftExpenseClause: expenseClause,
                });
            }
            setExpenseClauses(agiloftExpenseTypes.travelTimeAndDirectExpense);
            setShowExpenseMiles(true);
            break;
        case 'Direct Expenses Only':
            if(!agiloftExpenseTypes.directExpensesOnly.includes(estimator.agiloftExpenseClause)) {
                expenseClause = agiloftExpenseTypes.directExpensesOnly[0];
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftExpenseType: agiloftExpenseType,
                    agiloftExpenseClause: expenseClause,
                });
            }
            setExpenseClauses(agiloftExpenseTypes.directExpensesOnly);
            setShowExpenseMiles(false);
            break;
        case 'Not Billed': {
            if(!agiloftExpenseTypes.notBilledClauses.includes(estimator.agiloftExpenseClause)) {
                expenseClause = includesManagedServices ? agiloftExpenseTypes.notBilledClauses[1] : agiloftExpenseTypes.notBilledClauses[0];
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftExpenseType: agiloftExpenseType,
                    agiloftExpenseClause: expenseClause
                });
            }
            setExpenseClauses(agiloftExpenseTypes.notBilledClauses);
            setShowExpenseMiles(false);
            break;
            }
        case 'Not Billed Remote Services': {
            if(!agiloftExpenseTypes.notBilledRemoteServices.includes(estimator.agiloftExpenseClause)) {
                expenseClause = includesManagedServices ? agiloftExpenseTypes.notBilledRemoteServices[1] : agiloftExpenseTypes.notBilledRemoteServices[0];
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftExpenseType: agiloftExpenseType,
                    agiloftExpenseClause: expenseClause
                });
            }
            setExpenseClauses(agiloftExpenseTypes.notBilledRemoteServices);
            setShowExpenseMiles(false);
            break;
            }
        case 'Travel Time Only':
            if(!agiloftExpenseTypes.travelTimeOnly.includes(estimator.agiloftExpenseClause)) {
                expenseClause = agiloftExpenseTypes.travelTimeOnly[0];
                setFormData({
                    id: estimator.id,
                    projectItemId: estimator.projectItemId,
                    agiloftExpenseType: agiloftExpenseType,
                    agiloftExpenseClause: expenseClause,
                });
            }
            setExpenseClauses(agiloftExpenseTypes.travelTimeOnly);
            setShowExpenseMiles(true);
            break;
        }
  };

  return { 
    updateExpenseClauses,
    updateTravelClauses,
    updatePrintTemplateType,
    managedServicesCustomerTypeOptions, 
    managedServicesDealTypeOptions, 
    managedServicesTransitionFeeTypeOptions
 };
};