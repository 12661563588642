export const getEstimatorProposalEditorOptions = () => {
  return {
    custom_elements: 'replace',
    extended_valid_elements: 'replace[*]',
    valid_children: '+p[replace],+div[replace]',
    // forced_root_block: 'p',
    toolbar:
      'print | add-sections add-global-sections | ' +
      'undo redo | ' +
      'formatselect fontselect fontsizeselect | ' +
      'bold italic | ' +
      'alignleft aligncenter ' +
      'alignright alignjustify | ' +
      'bullist numlist outdent indent | ' +
      'image | forecolor backcolor | paste |' +
      'fullscreen |',
      plugins: [
        'print',
        'advlist',
        'code',
        'lists',
        'link',
        'image',
        'charmap',
        'pagebreak',
        'paste',
        'visualblocks',
        'insertdatetime',
        'help',
        'wordcount',
        'save',
        'table',
        'fullscreen',
        'nonbreaking',
        'noneditable',
      ],
  };
};
