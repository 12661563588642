import { ROUTES } from '@cdw-selline/ui/constants';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const useNotFoundPage = () => {
  const router = useHistory();

  useEffect(() => {
    setTimeout(() => {
      router.push(ROUTES.SIGN_IN);
    }, 5000);
  });
};
