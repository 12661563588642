// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CollectionsDataTable } from '@cdw-selline/ui/components';
import { useAdminServicePractices } from '@cdw-selline/ui/hooks';
import { Box } from '@mui/material';
import React from 'react';
  
  export const ServicePracticesCollectionPage = () => {
    const dataTableProps = useAdminServicePractices();
  
    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
        <CollectionsDataTable
          {...dataTableProps}
          height="calc(100vh - 13em)"
          parent="ServicePracticesCollectionPage"
          paginationMode="server"
          handleSync={dataTableProps.syncServicePractices}
        />
      </Box>
    );
  };
  
  export default ServicePracticesCollectionPage;