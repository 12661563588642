import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponseWithId,
  Lead,
  UpdateLeadResponse,
} from '@cdw-selline/common/types';
import {
  CREATE_LEAD_MUTATION,
  GET_LEADS_QUERY,
  GET_LEAD_QUERY,
  UPDATE_LEAD_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseLeads {
  id?: string;
}

export const useLead = ({ id }: UseLeads) => {
  const alertState = useAlertsState();
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{ getLead: Lead }>(
    GET_LEADS_QUERY,
    {
      skip: !id,
      variables: { getLeadId: id },
      onError: (error) => handleErrorResponse(error, 'Failed to fetch Lead!'),
    }
  );

  const [createLead, { loading: createLeadLoading, error: createLeadError }] =
    useMutation<{ createLead: DefaultMutationResponseWithId }>(
      CREATE_LEAD_MUTATION,
      {
        refetchQueries: [GET_LEADS_QUERY],
        onError: (error) =>
          handleErrorResponse(error, 'Failed to create new lead'),
        onCompleted: (data) => {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully Created New Lead',
          });
        },
      }
    );

  const [updateLead, { loading: updateLeadLoading, error: updateLeadError }] =
    useMutation<UpdateLeadResponse>(UPDATE_LEAD_MUTATION, {
      refetchQueries: [GET_LEADS_QUERY, GET_LEAD_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(
          error,
          `Failed to update project: ${error.message}`,
          ALERT_TYPE.MODAL
        ),
      onCompleted: (data) => {
        if (data.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully Updated Lead',
          });
        }
      },
    });

  return {
    data: data?.getLead ?? null,
    error,
    loading,
    createLead,
    createLeadLoading,
    createLeadError,
    updateLead,
    updateLeadLoading,
    updateLeadError,
  };
};

export default useLead;
