/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useSkillSummary } from '@cdw-selline/ui/hooks';
import {
  Box,
  Card,
  CardHeader,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import * as React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatCurrency } from '@cdw-selline/ui/helpers';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SkillSummaryProps {}

export const SkillSummary: React.FC<SkillSummaryProps> = (props) => {
  const { skillSummary, error, loading } = useSkillSummary();
  const theme = useTheme();

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card {...props}>
            <CardHeader
              title="Skill Summary"
              sx={{
                backgroundColor: theme.palette.primary.dark,
                padding: '5px 15px',
              }}
              titleTypographyProps={{
                variant: 'h6',
                color: theme.palette.common.white,
              }}
            />
            <Divider />
            <PerfectScrollbar>
              <Box sx={{ minWidth: 400 }}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Skill</TableCell>
                      <TableCell align="right">Hours</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {error && (
                      <Typography color="error" variant="h6">
                        {error.message}
                      </Typography>
                    )}
                    {loading && (
                      <TableRow>
                        <TableCell>
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}
                    {!loading &&
                      skillSummary.skillSummaryRows.map((skillSummaryRow) => (
                        <TableRow hover key={skillSummaryRow.skill}>
                          <TableCell>
                            {skillSummaryRow.skill || 'No Skill Assigned'}
                          </TableCell>
                          <TableCell align="right">
                            {skillSummaryRow.totalHours}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
export default SkillSummary;
