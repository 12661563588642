/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useTotalXass } from '@cdw-selline/ui/hooks';
import {
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import * as React from 'react';

export const TotalCustomers = (props) => {
  const { data, loading } = useTotalXass();

  return (
    <Card {...props}>
      <CardContent>
        <Grid container spacing={3} sx={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color="textSecondary" gutterBottom variant="button">
              TOTAL XaaS TCV
            </Typography>
            <Typography color="textPrimary" variant="h6">
              {loading ? (
                <CircularProgress />
              ) : (
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(data?.getTotalXassSales)
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar
              sx={{
                backgroundColor: green[600],
                height: 46,
                width: 46,
              }}
            >
              <AttachMoneyIcon />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TotalCustomers;
