import { CiscoAnnuity, CiscoQuote } from '@cdw-selline/common/types';
import {  MenuItem } from '@mui/material';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import React, { useState } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useCiscoAnnuityQuote } from '@cdw-selline/ui/hooks';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  getCiscoAnnuityActiveQuote,
} from '@cdw-selline/ui/helpers';
import { FormSelect, FormText, FormCheckbox } from '../../formHelperFunctions';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CISCO_ANNUITY_TERM_LENGTHS } from '@cdw-selline/ui/constants';
import { round } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    labelRoot: {
      right: 0,
    },
    shrink: {
      transformOrigin: 'top right',
    },
  })
);

export interface CiscoAnnuityQuoteSettingsProps {
  ciscoAnnuity: CiscoAnnuity;
  isReadOnly: boolean;
  canRemoveBidAdders?: boolean;
}

export function CiscoAnnuityQuoteSettings({
  ciscoAnnuity,
  isReadOnly,
  canRemoveBidAdders,
}: CiscoAnnuityQuoteSettingsProps) {
  const activeQuote = getCiscoAnnuityActiveQuote(ciscoAnnuity);
  const theme = useTheme();
  const classes = useStyles();
  const { handleCiscoAnnuityQuoteUpdate } = useCiscoAnnuityQuote();
  const termValues = CISCO_ANNUITY_TERM_LENGTHS.map((term) => term.value);
  const setFormData = (updatedFormData) => {
    handleUpdate(updatedFormData);
  };

  const handleUpdate = (updatedFormData: Partial<CiscoQuote>): void => {
    handleCiscoAnnuityQuoteUpdate(
      ciscoAnnuity.id,
      ciscoAnnuity.activeCiscoQuote,
      updatedFormData
    );
  };

  return (
    <>
      <FormSelect
        name="billingCycle"
        label="Billing Cycle"
        setFormData={setFormData}
        formData={activeQuote}
        width="250px"
        disabled={isReadOnly}
      >
        <MenuItem value="Monthly">Monthly</MenuItem>
        <MenuItem value="Annual">Annual</MenuItem>
        <MenuItem value="Prepaid">Prepaid</MenuItem>
      </FormSelect>
      {termValues.includes(activeQuote.term) ? (
        <FormSelect
          name="term"
          label="Term"
          setFormData={setFormData}
          formData={activeQuote}
          title={activeQuote.term}
          width="250px"
          sx={{ marginTop: 2}}
          disabled={isReadOnly}
        >
          {CISCO_ANNUITY_TERM_LENGTHS.map((term, i) => (
            <MenuItem key={i} value={term.value}>
              {term.label}
            </MenuItem>
          ))}
        </FormSelect>
      ) :(
        <FormText
          name="term"
          label="Term"
          type="number"
          setFormData={setFormData}
          formData={{...activeQuote, term: round(activeQuote.term, 2)}}
          title={activeQuote.term}
          width="250px"
          sx={{ marginTop: 2}}
          disabled={isReadOnly}
        />
      )}
      <FormText
            name="committedAudioMarkup"
            label="Committed Audio Markup"
            type="number"
            setFormData={setFormData}
            formData={activeQuote}
            width="250px"
            sx={{ marginTop: 2}}
            disabled={isReadOnly}
          />
      {canRemoveBidAdders && (
        <FormCheckbox
          name="removeBidAdders"
          label="Remove Bid Adders"
          setFormData={setFormData}
          formData={activeQuote}
          disabled={isReadOnly}
        />
      )}
      <FormCheckbox
        name="autoRenew"
        label="Auto-Renewal"
        setFormData={setFormData}
        formData={activeQuote}
        disabled={isReadOnly}
      />
      <FormCheckbox
        name="hideIncludedSkus"
        label="Hide $0.00 SKUs"
        setFormData={setFormData}
        formData={activeQuote}
        disabled={isReadOnly}
      />
      <FormCheckbox
        name="hideCustomerViewTotal"
        label="Hide TCV from Quote"
        setFormData={setFormData}
        formData={activeQuote}
        disabled={isReadOnly}
      />
      <FormCheckbox
        name="hideMonthlyFeeBreakdown"
        label="Hide Monthly Fee Breakdown"
        setFormData={setFormData}
        formData={activeQuote}
        disabled={isReadOnly}
      />
    </>
  );
}
