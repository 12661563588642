import { ApolloError } from '@apollo/client';
import { Practice, RoleAndRate, ProjectItem } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  usePractices,
  useProjectPracticesAndRatesMutation,
  useRolesAndRatesTable,
  useOpenState,
} from '@cdw-selline/ui/hooks';
import {
  Autocomplete,
  Checkbox,
  Grid,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import React, { useState } from 'react';
import { CollectionsDataTable } from '../../../tables/collections-data-table/CollectionsDataTable';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { inPractices } from '@cdw-selline/ui/helpers';
import omitDeep from 'omit-deep-lodash';
import { DialogConfirm } from '../../../dialog-confirm/DialogConfirm';

export interface RolesAndRatesTableProps {
  projectItemId: string;
  currentPractices: Practice[];
  currentRolesAndRates: RoleAndRate[];
  practicesAndRatesLoading: boolean;
  practicesAndRatesError: ApolloError;
  projectItem: ProjectItem;
  isReadOnly: boolean;
}

export function RolesAndRatesTable({
  projectItemId,
  currentPractices,
  currentRolesAndRates,
  practicesAndRatesLoading,
  practicesAndRatesError,
  projectItem,
  isReadOnly,
}: RolesAndRatesTableProps) {
  const { columns, rows, handleRowEditSave } = useRolesAndRatesTable(
    projectItemId,
    currentPractices,
    currentRolesAndRates,
    isReadOnly
  );
  const PRACTICES_ROLES_RATES_TITLE = 'Roles and Rates - Practice(s):';

  const { handleAddPractice, handleRemovePractice } =
    useProjectPracticesAndRatesMutation(
      projectItemId,
      currentPractices,
      currentRolesAndRates
    );
  const currentPracticeValues = currentPractices.map((practice) => ({
    ...practice,
    value: practice.id,
    label: practice.name,
  }));

  const [deleteValue, setDeleteValue] = useState(null);

  const {
    data: allPractices,
    loading: practicesLoading,
    error: practicesError,
  } = usePractices({});

  const {
    isOpen: deleteDialogConfirmOpen,
    handleClose: handleDeleteDialogConfirmClose,
    handleOpen: handleDeleteDialogConfirmOpen,
  } = useOpenState();

  const handleDialogDelete = (e) => {
    e.stopPropagation();
    currentPractices.forEach((practice) => {
      if (!inPractices(practice.id, deleteValue)) {
        handleRemovePractice(omitDeep(practice, ['value', 'label']));
      }
    });
    setDeleteValue(null);
    handleDeleteDialogConfirmClose();
  };

  const practiceOptions = allPractices.practices.map((practice) => ({
    ...practice,
    value: practice.id,
    label: practice.name,
  }));

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handlePracticeClick = (event, newValue, reason) => {
    if (reason === 'selectOption') {
      newValue.forEach((practice) => {
        if (!inPractices(practice.id, currentPractices)) {
          handleAddPractice(omitDeep(practice, ['value', 'label']));
        }
      });
    }

    if (reason === 'removeOption') {
      currentPractices.forEach((practice) => {
        if (projectItem.servicesTotal > 0) {
          setDeleteValue(newValue);
          handleDeleteDialogConfirmOpen();
        } else {
          if (!inPractices(practice.id, newValue)) {
            handleRemovePractice(omitDeep(practice, ['value', 'label']));
          }
        }
      });
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography color="primary" style={{ color: '#444' }} variant="h6">
          {PRACTICES_ROLES_RATES_TITLE}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          multiple
          data-testid="add-practice-button"
          limitTags={2}
          options={practiceOptions}
          disableCloseOnSelect
          noOptionsText="No practices found"
          onChange={handlePracticeClick}
          value={currentPracticeValues}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <li {...props} key={option.id}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
                data-testid={`practice-item-${option.label
                  ?.split(' ')
                  ?.join('')}`}
              />
              {option.label}
            </li>
          )}
          style={{ width: '100%' }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Practices"
              placeholder="Practices"
            />
          )}
          disabled={isReadOnly}
        />
        <DialogConfirm
          title="Delete Practice?"
          isOpen={deleteDialogConfirmOpen}
          handleClose={handleDeleteDialogConfirmClose}
          handleYes={handleDialogDelete}
        >
          <Box>
            To avoid errors, it is recommended to not remove a Practice when an
            estimator is in progress. Are you sure you want to continue?
          </Box>
        </DialogConfirm>
      </Grid>

      <Grid item xs={12}>
        <CollectionsDataTable
          columns={columns}
          rows={rows}
          editMode="row"
          handleSave={handleRowEditSave}
          loading={practicesAndRatesLoading}
          error={practicesAndRatesError}
          gridMargin={0}
          parent="RolesAndRatesTable"
          saveOnEditStop={true}
          isReadOnly={isReadOnly}
        />
      </Grid>
    </Grid>
  );
}

export default RolesAndRatesTable;
