import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
  Practice,
} from '@cdw-selline/common/types';
import {
  ADD_PRACTICE_MUTATION,
  GET_PRACTICES_QUERY,
  GET_PRACTICE_BY_ID_QUERY,
  REMOVE_ADMIN_PRACTICE_MUTATION,
  UPDATE_PRACTICE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';

import { useApolloErrors } from './useApolloErrors';
import { usePractices } from './usePractices';
import { useState } from 'react';
import { useOpenState } from '@cdw-selline/ui/hooks';
import omitDeep from 'omit-deep-lodash';
import { getFilters } from '@cdw-selline/ui/helpers';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'Practice ID',
    width: 200,
    editable: false,
    flex: 1,
  },
  { field: 'name', headerName: 'Practice Name', width: 250 },
  {
    field: 'managedSvc',
    headerName: 'Is Managed SVC?',
    type: 'boolean',
    width: 200,
    editable: false,
    flex: 1,
  },
  {
    field: 'lastModified',
    headerName: 'Last Modified',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(params?.value).format('MM/DD/YYYY'),
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(parseInt(params?.value)).format('MM/DD/YYYY'),
  },
];

export const useAdminPractices = () => {
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });
  const [sortState, setSortState] = useState();

  const { data, loading, error, refetch } = usePractices({
    filters: getFilters('PracticesCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const filterModel = JSON.parse(
    localStorage.getItem('PracticesCollectionPage-filter')
  );
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const [editPracticeId, setEditPracticeId] = useState(null);
  const {
    isOpen: practiceFormOpen,
    handleClose: closePracticeForm,
    handleOpen: openPracticeForm,
  } = useOpenState();

  const [
    addPractice,
    { loading: addPracticeLoading, error: addPracticeError },
  ] = useMutation<{ addPractice: DefaultAddResponse }>(ADD_PRACTICE_MUTATION, {
    refetchQueries: [GET_PRACTICES_QUERY],
    onError: (error) =>
      handleErrorResponse(error, 'Failed to create new practice'),
    onCompleted: (data) => {
      if (data.addPractice.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully added practice',
        });
      }
    },
  });

  const [
    removePractice,
    { loading: removePracticeLoading, error: removePracticeError },
  ] = useMutation<{ removePractice: DefaultMutationResponse }>(
    REMOVE_ADMIN_PRACTICE_MUTATION,
    {
      refetchQueries: [GET_PRACTICES_QUERY, GET_PRACTICE_BY_ID_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to remove practice'),
      onCompleted: (data) => {
        if (data.removePractice.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully removed practice',
          });
        }
      },
    }
  );

  const [
    updatePractice,
    { loading: updatePracticeLoading, error: updatePracticeError },
  ] = useMutation<{ updatePractice: DefaultMutationResponse }>(
    UPDATE_PRACTICE_MUTATION,
    {
      refetchQueries: [GET_PRACTICES_QUERY, GET_PRACTICE_BY_ID_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to update practice'),
      onQueryUpdated(observableQuery) {
        return observableQuery.refetch();
      },
      onCompleted: (data) => {
        if (data.updatePractice.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully updated practice',
          });
        }
      },
    }
  );

  const handleAdd = () => {
    openPracticeForm();
  };

  const handlePracticeFormClose = () => {
    setEditPracticeId(null);
    closePracticeForm();
  };

  const handleDelete = (pid: string) =>
    removePractice({
      variables: { removePracticeId: pid },
    });
  const handleEdit = (id: string) => {
    setEditPracticeId(id);
    openPracticeForm();
  };

  const handlePracticeFormSave = (practice: Practice) => {
    if (!practice.id) {
      addPractice({
        variables: {
          params: omitDeep(practice, ['__typename']),
        },
      });
    }

    if (practice.id) {
      updatePractice({
        variables: {
          params: omitDeep(practice, ['__typename']),
        },
      });
    }
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('PracticesCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
      sort: { createdAt: 'desc' },
    });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      filters: getFilters('PracticesCollectionPage'),
      offset: paginationState.offset,
      limit: paginationState.limit,
      sort: { createdAt: 'desc' },
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('PracticesCollectionPage') });
  };
  const handleSort = (args) => {
    let newSort;
    if (args.length) newSort = { [args[0].field]: args[0].sort };
    setSortState(newSort);
  };

  const rows = data.practices.map((p) => ({
    ...p,
    id: p.id ?? p.practiceId,
  }));

  return {
    columns,
    rows: rows || ([] as GridRowsProp),
    handleAdd,
    handleDelete,
    handleEdit,
    handlePageChange,
    handlePageBack,
    handlePageSizeChange,
    handleSort,
    onFilterModelChange,
    filterModel,
    practices: data.practices,
    editPracticeId,
    addPracticeLoading,
    addPracticeError,
    removePracticeLoading,
    removePracticeError,
    practiceFormOpen,
    handlePracticeFormClose,
    handlePracticeFormSave,
    rowCount: data.count,
    loading: loading || addPracticeLoading || removePracticeLoading,
  };
};
