import React from 'react';
import {
  FormAutocomplete,
  FormText,
  FormCheckbox,
  FormSelect,
  FormTextControlled,
  FormAutocompleteOption,
} from '../formHelperFunctions';
import { MenuItem, Grid } from '@mui/material';
import FormFieldAutoComplete from '../autocomplete-form-field/FormFieldAutoComplete';
import { useSearchServiceItems, useSearchServiceSkills } from '@cdw-selline/ui/hooks';
import { PrimaryRoleOverrideInclude } from './PrimaryRoleOverrideInclude';
import { SecondaryRoleOverrideInclude } from './SecondaryRoleOverrideInclude';

export interface ListTaskFormProps {
  taskFormData: any;
  setTaskFormData: (value) => void;
  practiceOptions: FormAutocompleteOption[];
  taskGroupOptions: FormAutocompleteOption[];
  vendorOptions: FormAutocompleteOption[];
  sowSectionOptions: FormAutocompleteOption[];
  roleOptions: FormAutocompleteOption[];
  currentUser: any;
  isCustom: boolean;
  errorsArray: any;
}

export function ListTaskForm({
  taskFormData,
  setTaskFormData,
  practiceOptions,
  taskGroupOptions,
  vendorOptions,
  sowSectionOptions,
  roleOptions,
  currentUser,
  isCustom,
  errorsArray,
}: ListTaskFormProps) {
  return (
    <>
      <FormTextControlled
        name="name"
        label="Name"
        setFormData={setTaskFormData}
        formData={taskFormData}
        required={true}
        error={errorsArray.length > 0 && !taskFormData.name}
      />
      {!isCustom && (
        <FormSelect
          name="estimatorLocation"
          label="Estimator Location"
          setFormData={setTaskFormData}
          formData={taskFormData}
          required={true}
          error={errorsArray.length > 0 && !taskFormData.estimatorLocation}
        >
          <MenuItem value="site">Site</MenuItem>
          <MenuItem value="summary">Summary</MenuItem>
        </FormSelect>
      )}
      <FormAutocomplete
        name="practiceId"
        label="Practice"
        options={practiceOptions}
        setFormData={setTaskFormData}
        formData={taskFormData}
        data-testid="task-practices"
        required={true}
        error={errorsArray.length > 0 && !taskFormData.practiceId}
      />
      {!isCustom && taskFormData.practiceId && (
        <>
          <FormAutocomplete
            name="taskGroupId"
            label="Task Group"
            options={taskGroupOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskGroupId}
          />
          <FormTextControlled
            name="taskId"
            label="Task Id"
            setFormData={setTaskFormData}
            formData={taskFormData}
            data-testid="task-id"
            required={true}
            error={errorsArray.length > 0 && !taskFormData.taskId}
          />
        </>
      )}
        <>
          <FormCheckbox
            name="isProjectManagement"
            label={'Project Management?'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormCheckbox
            name="isTravelTime"
            label={'Is Travel Time ?'}
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="units"
            label="Units"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="category"
            label="Category"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormText
            name="sprint"
            label="Sprint"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          <FormAutocomplete
            name="vendor"
            label="Vendor"
            options={vendorOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            freeSolo={true}
          />
          <FormTextControlled
            name="comment"
            label="Comment"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {!isCustom && (
            <Grid item xs={12} sx={{ width: '100%' }}>
              <Grid container direction="row" spacing={1}>
                <Grid item xs={6}>
                  <FormText
                    name="sku"
                    label="SKU"
                    setFormData={setTaskFormData}
                    formData={taskFormData}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormText
                    name="edc"
                    label="EDC"
                    setFormData={setTaskFormData}
                    formData={taskFormData}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          <FormText
            name="listNames"
            label="List Names"
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.listNames}
          />
          <FormText
            name="listValues"
            label="List Values"
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.listValues}
          />
          {!isCustom && (
            <>
              <FormText
                name="dropDownIndexFormula"
                label="List Formula"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormText
                name="hoursFormula"
                label="Hours Formula"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormCheckbox
                name="exclude"
                label="Exclude task from total?"
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
              <FormAutocomplete
                name="sowSectionId"
                label="SOW Section"
                options={sowSectionOptions}
                freeSolo={false}
                setFormData={setTaskFormData}
                formData={taskFormData}
              />
            </>
          )}
          <FormCheckbox
            name="overtime"
            label="Use overtime rates?"
            setFormData={setTaskFormData}
            formData={taskFormData}
          />
          {currentUser.admin && (
            <>
              <FormFieldAutoComplete
                name="skill"
                label="Skill"
                setTaskFormData={setTaskFormData}
                taskFormData={taskFormData}
                useSearchHook={useSearchServiceSkills}
                idColumnName="skillId"
                isUpdateIdAndName={true}
              />
              <FormFieldAutoComplete
                name="serviceItem"
                label="Service Item"
                setTaskFormData={setTaskFormData}
                taskFormData={taskFormData}
                useSearchHook={useSearchServiceItems}
                idColumnName="serviceItemId"
                isUpdateIdAndName={true}
              />
            </>
          )}
          <FormAutocomplete
            name="primaryRole"
            label="Primary Role"
            options={roleOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.primaryRole}
          />
          <FormText
            name="primaryPercent"
            label="Primary Hours %"
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.primaryPercent}
          />
          <PrimaryRoleOverrideInclude
            setFormData={setTaskFormData}
            formData={taskFormData}
            isCustom={isCustom}
            />
          <FormAutocomplete
            name="secondaryRole"
            label="Secondary Role"
            options={roleOptions}
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.secondaryRole}
          />
          <FormText
            name="secondaryPercent"
            label="Secondary Hours %"
            setFormData={setTaskFormData}
            formData={taskFormData}
            required={true}
            error={errorsArray.length > 0 && !taskFormData.secondaryPercent}
          />
          <SecondaryRoleOverrideInclude
            setFormData={setTaskFormData}
            formData={taskFormData}
            isCustom={isCustom}
            />
        </>
    </>
  );
}
