import { Editor } from 'tinymce';

export const useCustomEditorOptions = (
  taskModal: (editor: Editor, taskSub: boolean, isSowSection: boolean) => void,
  siteModal?: (editor: Editor) => void,
) => {
  const menuStrings = {
    GLOBAL_SECTION: 'Select Global Section',
    BUSINESS_OUTCOME: `Business Outcome`,
    TECHNICAL_OUTCOME: `Technical Outcome`,
    OUTCOME_PROJECT_SCOPE_PHASES: `Outcome Project Scope Phases`,
    EXECUTIVE_SUMMARY_OVERVIEW: `Executive Summary Overview`,
    EXECTUIVE_SUMMARY_DRIVERS: `Exectuive Summary Drivers`,
    CUSTOMER: `Customer`,
    ITEMS_PROVIDED_TO_CUSOMTERS: `Items Provided to Customers`,
    HIGH_LEVEL_SCOPE: 'High Level Scope',
    CUSTOMER_RESPONSIBILITIES: `Customer Responsibilities`,
    OUT_OF_SCOPE: `Out of Scope`,
    ASSUMPTION: `Assumption`,
    RMS_RECURRING_TABLE: `RMS Recurring Table`,
    RMS_ONETIME_TABLE: `RMS One Time Table`,
  };

  const shortCodes = {
    BUSINESS_OUTCOME: `{{ outcome-global type="business_outcome" }}`,
    TECHNICAL_OUTCOME: `{{ outcome-global type="technical_outcome" }}`,
    OUTCOME_PROJECT_SCOPE_PHASES: `{{ outcome-global type="outcome_project_scope_phases" }}`,
    EXECUTIVE_SUMMARY_OVERVIEW: `{{ outcome-global type="executive_summary_overview" }}`,
    EXECTUIVE_SUMMARY_DRIVERS: `{{ outcome-global type="executive_summary_drivers" }}`,
    CUSTOMER: `{{ global type="customer" }}`,
    ITEMS_PROVIDED_TO_CUSOMTERS: `{{ global type="items_provided_to_customer" }} `,
    HIGH_LEVEL_SCOPE: `{{ global type="high_level_scope"}}`,
    CUSTOMER_RESPONSIBILITIES: `{{ global type="customer_responsibility"}}`,
    OUT_OF_SCOPE: `{{ global type="outofscope"}}`,
    ASSUMPTION: `{{ global type="assumption"}}`,
    RMS_RECURRING_TABLE: `{{ global type="rms_recurring_table" }}`,
    RMS_ONETIME_TABLE: `{{ global type="rms_onetime_table" }}`,
  };

  const customEditorOptions = {
    toolbar:
      'add-global-sections task tasksub sowsection sitetable copy | ' +
      'undo redo | ' +
      'formatselect fontselect fontsizeselect | ' +
      'bold italic | ' +
      'alignleft aligncenter ' +
      'alignright alignjustify | ' +
      'bullist numlist outdent indent | ' +
      'image | forecolor backcolor | paste |' +
      'fullscreen |',
    editor_selector: 'textarea#admin',
    setup: (editor: Editor) => {
      editor.ui.registry.addButton('task', {
        text: 'Task',
        onAction: () => {
          taskModal(editor, false, false);
        },
      });

      editor.ui.registry.addButton('tasksub', {
        text: 'TaskSub',
        onAction: () => {
          taskModal(editor, true, false);
        },
      });

      editor.ui.registry.addButton('sowsection', {
        text: 'SOW Section',
        onAction: () => {
          taskModal(editor, false, true);
        },
      });

      if (siteModal) {
        editor.ui.registry.addButton('sitetable', {
          text: 'Site Table',
          onAction: () => {
            siteModal(editor);
          },
        });
      }


      editor.ui.registry.addMenuButton('add-global-sections', {
        text: menuStrings.GLOBAL_SECTION,
        fetch: (callback) => {
          const menuItems = [
            {
              type: 'menuitem',
              text: menuStrings.BUSINESS_OUTCOME,
              onAction: () => editor.insertContent(shortCodes.BUSINESS_OUTCOME),
            },
            {
              type: 'menuitem',
              text: menuStrings.TECHNICAL_OUTCOME,
              onAction: () =>
                editor.insertContent(shortCodes.TECHNICAL_OUTCOME),
            },
            {
              type: 'menuitem',
              text: menuStrings.EXECUTIVE_SUMMARY_OVERVIEW,
              onAction: () =>
                editor.insertContent(shortCodes.EXECUTIVE_SUMMARY_OVERVIEW),
            },
            {
              type: 'menuitem',
              text: menuStrings.EXECTUIVE_SUMMARY_DRIVERS,
              onAction: () =>
                editor.insertContent(shortCodes.EXECTUIVE_SUMMARY_DRIVERS),
            },
            {
              type: 'menuitem',
              text: menuStrings.OUTCOME_PROJECT_SCOPE_PHASES,
              onAction: () =>
                editor.insertContent(shortCodes.OUTCOME_PROJECT_SCOPE_PHASES),
            },
            {
              type: 'menuitem',
              text: menuStrings.CUSTOMER,
              onAction: () => editor.insertContent(shortCodes.CUSTOMER),
            },
            {
              type: 'menuitem',
              text: menuStrings.ITEMS_PROVIDED_TO_CUSOMTERS,
              onAction: () =>
                editor.insertContent(shortCodes.ITEMS_PROVIDED_TO_CUSOMTERS),
            },
            {
              type: 'menuitem',
              text: menuStrings.HIGH_LEVEL_SCOPE,
              onAction: () => editor.insertContent(shortCodes.HIGH_LEVEL_SCOPE),
            },
            {
              type: 'menuitem',
              text: menuStrings.CUSTOMER_RESPONSIBILITIES,
              onAction: () =>
                editor.insertContent(shortCodes.CUSTOMER_RESPONSIBILITIES),
            },
            {
              type: 'menuitem',
              text: menuStrings.ASSUMPTION,
              onAction: () => editor.insertContent(shortCodes.ASSUMPTION),
            },
            {
              type: 'menuitem',
              text: menuStrings.OUT_OF_SCOPE,
              onAction: () => editor.insertContent(shortCodes.OUT_OF_SCOPE),
            },
            {
              type: 'menuitem',
              text: menuStrings.RMS_RECURRING_TABLE,
              onAction: () =>
                editor.insertContent(shortCodes.RMS_RECURRING_TABLE),
            },
            {
              type: 'menuitem',
              text: menuStrings.RMS_ONETIME_TABLE,
              onAction: () =>
                editor.insertContent(shortCodes.RMS_ONETIME_TABLE),
            },
          ];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          callback(menuItems as any);
        },
      });
    },
  };

  return {
    customEditorOptions
  };
};
