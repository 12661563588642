import { Practice, RoleAndRate, Task } from '@cdw-selline/common/types';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  useProjectPracticesAndRatesMutation,
  useProjectSitesAndTasks,
} from '@cdw-selline/ui/hooks';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import DeleteIcon from '@mui/icons-material/Delete';
import { Chip, Grid, CircularProgress, Tooltip } from '@mui/material';
import React, { CSSProperties } from 'react';

export interface ActivePracticeListProps {
  projectItemId: string;
  currentPractices: Practice[];
  currentRolesAndRates: RoleAndRate[];
  style?: CSSProperties;
  loading?: boolean;
}

export function ActivePracticeList({
  projectItemId,
  currentPractices,
  currentRolesAndRates,
  style,
  loading,
}: ActivePracticeListProps) {
  const alerts = useAlertsState();
  const { handleRemovePractice, loading: isLoading } =
    useProjectPracticesAndRatesMutation(
      projectItemId,
      currentPractices,
      currentRolesAndRates
    );

  //TODO validate that calculations still work after removing a practice
  const handleDelete = (practice: Practice) => {
    handleRemovePractice(practice);
  };

  if (loading || isLoading) return <CircularProgress />;

  return (
    <Grid aria-label="current practices" container sx={style}>
      {currentPractices?.map((practice) => (
        <Grid item key={`en-${practice.name}`}>
          <Chip
            sx={{ background: '#444', color: '#fff' }}
            label={practice.name}
            onDelete={() => handleDelete(practice)}
            deleteIcon={
              <Tooltip title={`Remove practice: ${practice.name}`}>
                <DeleteIcon />
              </Tooltip>
            }
            variant="outlined"
            color="primary"
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default ActivePracticeList;
