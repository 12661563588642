import { useMutation } from '@apollo/client';
import {
  DefaultAddResponse,
  DefaultMutationResponse,
} from '@cdw-selline/common/types';
import {
  ADD_TASK_MUTATION,
  GET_TASKS_QUERY,
  REMOVE_ADMIN_TASK_MUTATION,
  UPDATE_TASK_MUTATION,
  UPDATE_TASKS_MUTATION,
  CREATE_TEST_VERSION_TASK_MUTATION
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { GridColumns, GridRowsProp } from '@mui/x-data-grid';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';
import { useTasks, useOpenState } from '@cdw-selline/ui/hooks';
import { Task } from '@cdw-selline/common/types';
import { getFilters } from '@cdw-selline/ui/helpers';

import { omit } from 'lodash';
import omitDeep from 'omit-deep-lodash';
import moment from 'moment';

const columns: GridColumns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 120,
    editable: false,
  },
  {
    field: 'taskId',
    headerName: 'TaskId',
    width: 120,
    editable: false,
  },
  {
    field: 'name',
    headerName: 'Name',
    width: 350,
    flex: 1,
  },
  {
    field: 'type',
    headerName: 'Type',
    width: 350,
  },
  {
    field: 'practiceId',
    headerName: 'Practice',
    width: 220,
    flex: 1,
  },
  {
    field: 'category',
    headerName: 'Category',
    width: 200,
    editable: false,
    flex: 1,
  },
  {
    field: 'taskGroupName',
    headerName: 'Task Group',
    width: 220,
    editable: false,
    flex: 1,
  },
  {
    field: 'taskGroupId',
    headerName: 'Task Group Id',
    width: 100,
    editable: false,
  },
  {
    field: 'sowSection',
    headerName: 'Sow Section',
    width: 220,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: 'Created Date',
    type: 'dateTime',
    width: 180,
    editable: false,
    valueFormatter: (params) =>
      params?.value && moment(params?.value).format('MM/DD/YYYY'),
  },
];

export const useAdminTasks = () => {
  const [sortState, setSortState] = useState<any>()
  const [paginationState, setPaginationState] = useState({
    offset: 0,
    limit: 100,
    page: 0,
  });

  const filterModel = JSON.parse(
    localStorage.getItem('TasksCollectionPage-filter')
  );

  const [editTaskId, setEditTaskId] = useState(null);

  const { data, loading, error, refetch } = useTasks({
    filters: getFilters('TasksCollectionPage'),
    offset: paginationState.offset,
    limit: paginationState.limit,
    sort: sortState,
  });
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const alertState = useAlertsState();

  const {
    isOpen: taskFormOpen,
    handleClose: closeTaskForm,
    handleOpen: openTaskForm,
  } = useOpenState();

  const [addTask, { loading: addTaskLoading, error: addTaskError }] =
    useMutation<{ addTask: DefaultAddResponse }>(ADD_TASK_MUTATION, {
      refetchQueries: [GET_TASKS_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new task'),
      onCompleted: (data) => {
        if (data.addTask.success) {
          handleTaskFormClose();
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved task',
          });
        }
      },
    });

  const [updateTask, { loading: updateTaskLoading, error: updateTaskError }] =
    useMutation<{ updateTask: DefaultMutationResponse }>(UPDATE_TASK_MUTATION, {
      refetchQueries: [GET_TASKS_QUERY],
      onError: (error) => handleErrorResponse(error, 'Failed to update task'),
      onCompleted: (data) => {
        if (data.updateTask.success) {
          handleTaskFormClose();
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved task',
          });
        }
      },
    });

  const [
    updateTasks,
    { loading: updateTasksLoading, error: updateTasksError },
  ] = useMutation<{ updateTasks: DefaultMutationResponse }>(
    UPDATE_TASKS_MUTATION,
    {
      refetchQueries: [GET_TASKS_QUERY],
      onError: (error) => handleErrorResponse(error, 'Failed to update tasks'),
      onCompleted: (data) => {
        if (data.updateTasks.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully saved tasks',
          });
        }
      },
    }
  );


  const [
    createTestVersionByTask,
    { loading: testVersionTaskLoading, error: testVersionTaskError },
  ] = useMutation<{ createTestVersionByTask: DefaultMutationResponse }>(
    CREATE_TEST_VERSION_TASK_MUTATION,
    {
      refetchQueries: [GET_TASKS_QUERY],
      awaitRefetchQueries: true,
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create test version task'),
      onCompleted: (data) => {
        if (data.createTestVersionByTask.success) {
          alertState.setAlert({
            type: ALERT_TYPE.MODAL,
            severity: ALERT_SEVERITY.SUCCESS,
            message: 'Successfully created test version task',
          });
        }
      },
    }
  );

  const [removeTask, { loading: removeTaskLoading, error: removeTaskError }] =
    useMutation<{ removeTask: DefaultMutationResponse }>(
      REMOVE_ADMIN_TASK_MUTATION,
      {
        refetchQueries: [GET_TASKS_QUERY],
        onError: (error) => handleErrorResponse(error, 'Failed to remove task'),
        onQueryUpdated(observableQuery) {
          return observableQuery.refetch();
        },
        onCompleted: (data) => {
          if (data.removeTask.success) {
            alertState.setAlert({
              type: ALERT_TYPE.SNACKBAR,
              severity: ALERT_SEVERITY.SUCCESS,
              message: 'Successfully removed task',
            });
          }
        },
      }
    );

  const handleAdd = () => {
    openTaskForm();
  };

  const handleTaskFormClose = () => {
    setEditTaskId(null);
    closeTaskForm();
  };

  const handleDelete = (pid: string) => {
    removeTask({
      variables: { removeTaskId: pid },
    });
  };

  const handleEdit = (id: string) => {
    setEditTaskId(id);
    openTaskForm();
  };

  const handlePageChange = (page: number) => {
    if (page < paginationState.page) return handlePageBack(page);
    sessionStorage.offset = paginationState.offset + paginationState.limit;
    sessionStorage.page = paginationState.page + 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: paginationState.offset,
      limit: paginationState.limit,
      filters: getFilters('TasksCollectionPage'),
    });
  };

  const onFilterModelChange = (filterModel) => {
    refetch({ filters: getFilters('TasksCollectionPage') });
  };

  const handlePageBack = (page: number) => {
    sessionStorage.offset = paginationState.offset - paginationState.limit;
    sessionStorage.page = paginationState.page - 1;
    setPaginationState((p) => ({
      ...p,
      offset: Number(sessionStorage.offset),
      page: Number(sessionStorage.page),
    }));
    refetch({
      offset: Number(sessionStorage.offset),
      limit: paginationState.limit,
      filters: getFilters('TasksCollectionPage'),
    });
  };

  const handlePageSizeChange = (size: number) => {
    setPaginationState((p) => ({
      ...p,
      limit: size,
    }));
    refetch({ offset: paginationState.offset, limit: paginationState.limit });
  };

  const handleTaskFormSave = (task: Task) => {
    if (!task.id) {
      addTask({
        variables: {
          params: omitDeep(task, ['__typename', 'taskGroups']),
        },
      });
    }

    if (task.id) {
      updateTask({
        variables: {
          params: omitDeep(task, ['__typename', 'taskGroups']),
        },
      });
    }
  };

  const handleTasksSave = (tasks: Task[]) => {
    updateTasks({
      variables: {
        params: tasks.map((task) => omit(task, ['__typename', 'taskGroups'])),
      },
    });
  };
  const handleTaskFormTestVersion = (taskId: string) => {
    createTestVersionByTask({
      variables: {
          taskId,
        }
    });
  };
  const handleSort = (sortModel) => {
    let newSort;
    if (sortModel.length) newSort = { [sortModel[0].field]: sortModel[0].sort };
    setSortState(newSort);
  };

  return {
    columns,
    rows: data.tasks || ([] as GridRowsProp),
    handleAdd,
    handleTaskFormDelete: handleDelete,
    handleEdit,
    handlePageChange,
    onFilterModelChange,
    handlePageSizeChange,
    handleSort,
    filterModel: filterModel ?? {
      items: [{ columnField: 'name', operatorValue: 'contains', value: '' }],
    },
    tasks: data.tasks,
    editTaskId,
    addTaskLoading,
    addTaskError,
    removeTaskLoading,
    removeTaskError,
    loading: loading || addTaskLoading || removeTaskLoading,
    error,
    taskFormOpen,
    handleTaskFormClose,
    handleTaskFormSave,
    handleTasksSave,
    handleTaskFormTestVersion,
    rowCount: data.count,
    ...paginationState,
  };
};
