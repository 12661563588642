import '@cdw-selline/ui/theme';
import { DashboardTable } from '@cdw-selline/ui/components';
import { Box } from '@mui/material';
import React, { useState } from 'react';
import { getCurrentUser } from '@cdw-selline/ui/state';
import { getDatabase } from 'firebase/database';
import { getFilters } from '@cdw-selline/ui/helpers';
import { CircularProgress } from '@mui/material';

/* eslint-disable-next-line */
export interface PipelinesTabProps {
  routeTo: (id: string) => void;
}

export const PipelinesTab = (props: PipelinesTabProps) => {
  const currentUser = getCurrentUser();

  const userEmail = currentUser ? currentUser.email : '';
  const localValue = JSON.parse(localStorage.getItem(`OpportunityView-toggle`));
  const [userFilter, setUserFilter] = useState(
    localValue ?? {
      $or: [
        { projectTeam: { $elemMatch: { email: userEmail } } },
        { projectOwner: userEmail },
      ],
    }
  );

  return (
    <Box
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      ></Box>
      <Box>
        <DashboardTable
          routeTo={props.routeTo}
          setUserFilter={setUserFilter}
          userFilter={userFilter}
          userState={Object.keys(userFilter).length === 0 ? true : false}
          userEmail={userEmail}
        />
      </Box>
    </Box>
  );
};

export default PipelinesTab;
