import { useMutation, useQuery } from '@apollo/client';
import {
  DefaultMutationResponseWithId,
  Opportunity,
  UpdateProjectResponse,
} from '@cdw-selline/common/types';
import {
  CREATE_PROJECT_MUTATION,
  GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY,
  GET_OPPORTUNITIES_QUERY,
  GET_PROJECT_PRACTICES_RATES,
  GET_PROJECT_QUERY,
  GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
  UPDATE_PROJECT_MUTATION,
  GET_SERVICE_SUMMARY_QUERY,
  CREATE_JIRA_ISSUE_MUTATION,
} from '@cdw-selline/ui/queries';
import {
  ALERT_SEVERITY,
  ALERT_TYPE,
  useAlertsState,
} from '@cdw-selline/ui/state';
import { useHistory } from 'react-router-dom';

import { useApolloErrors } from './useApolloErrors';

export interface UseProjects {
  id?: string;
}

export const useProjects = ({ id }: UseProjects) => {
  const alertState = useAlertsState();
  const router = useHistory();
  const { handleErrorResponse } = useApolloErrors();
  const { loading, error, data } = useQuery<{ getProject: Opportunity }>(
    GET_PROJECT_QUERY,
    {
      skip: !id,
      variables: { getProjectId: id },
      onError: (error) =>
        handleErrorResponse(error, 'Failed to fetch Project!'),
    }
  );

  const [
    createProject,
    { loading: createProjectLoading, error: createProjectError },
  ] = useMutation<{ createProject: DefaultMutationResponseWithId }>(
    CREATE_PROJECT_MUTATION,
    {
      refetchQueries: [GET_OPPORTUNITIES_QUERY],
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create new project'),
      onCompleted: (data) => {
        router.push(`/project/${data.createProject.id}`);
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Created New Project',
        });
      },
    }
  );

  const [
    updateProject,
    { loading: updateProjectLoading, error: updateProjectError, client },
  ] = useMutation<UpdateProjectResponse>(UPDATE_PROJECT_MUTATION, {
    refetchQueries: [
      GET_OPPORTUNITIES_QUERY,
      GET_PROJECT_PRACTICES_RATES,
      GET_CDW_ROLES_RATES_BY_PRACTICE_IDS_QUERY,
      GET_PROJECT_ITEM_ESTIMATOR_TASKS_QUERY,
      GET_SERVICE_SUMMARY_QUERY,
    ],
    awaitRefetchQueries: true,
    onError: (error) =>
      handleErrorResponse(
        error,
        `Failed to update project: ${error.message}`,
        ALERT_TYPE.MODAL
      ),
    onCompleted: (data) => {
      client.refetchQueries({
        include: [GET_PROJECT_QUERY],
      });
      if (data.success) {
        alertState.setAlert({
          type: ALERT_TYPE.SNACKBAR,
          severity: ALERT_SEVERITY.SUCCESS,
          message: 'Successfully Updated Project',
        });
      }
    },
  });

  const [
    createJiraIssue,
    { loading: createJiraIssueLoading, error: createJiraIssueError },
  ] = useMutation<{ createJiraIssue: DefaultMutationResponseWithId }>(
    CREATE_JIRA_ISSUE_MUTATION,
    {
      onError: (error) =>
        handleErrorResponse(error, 'Failed to create Jira Issue'),
      onCompleted: (data) => {
        if (data?.createJiraIssue?.success) {
          alertState.setAlert({
            type: ALERT_TYPE.SNACKBAR,
            severity: ALERT_SEVERITY.SUCCESS,
            message: `Successfully created Jira Issue ${data?.createJiraIssue?.id}`,
          });
        }
      },
    }
  );

  return {
    data: data?.getProject ?? null,
    error,
    loading,
    createProject,
    createProjectLoading,
    createProjectError,
    updateProject,
    updateProjectLoading,
    updateProjectError,
    createJiraIssue,
  };
};

export default useProjects;
